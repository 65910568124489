import { createAction, handleActions } from 'redux-actions';
import { fetchUserView, setUserView } from 'api/rest/fetchUserView';
import { ITEMS_MAP } from 'Tasks/constants';
import { HEADER_ITEMS_MAP } from 'Queue/constants';
import { assign, isEqual } from 'lodash';

// const EMIT_FETCH_USER_VIEW = 'EMIT_FETCH_USER_VIEW';
const EMIT_FETCH_USER_VIEW_SUCCESS = 'EMIT_FETCH_USER_VIEW_SUCCESS';
const EMIT_FETCH_USER_VIEW_FAILURE = 'EMIT_FETCH_USER_VIEW_FAILURE';
const EMIT_FETCH_USER_VIEW_INPROGRESS = 'EMIT_FETCH_USER_VIEW_INPROGRESS';

const bandAidHelper = (dataArr, srcArr) => {
  dataArr.forEach(savedColumn => {
    const definedColumn = srcArr.find(
      column => column.label === savedColumn.label
    );
    if (!isEqual(savedColumn, definedColumn)) {
      // this actually probably gets fired every time
      // since order is not preserved for some reason
      assign(savedColumn, definedColumn);
    }
  });
};

export const emitFetchUserViewSuccess = createAction(
  EMIT_FETCH_USER_VIEW_SUCCESS,
  data => {
    // reverse lookup to temporarily solve for modified view not reflecting latest changes
    // matches objects based on label and compares, overwriting the old if they are not equal
    // weird queues
    // for some reason, mc is masterCase and masterCase maps to new
    bandAidHelper(data.masterCase, HEADER_ITEMS_MAP.new);
    bandAidHelper(data.mc, HEADER_ITEMS_MAP[undefined]);
    const caseQueues = ['ae', 'pq', 'mi', 'yours'];
    caseQueues.forEach(queueType =>
      bandAidHelper(data[queueType], HEADER_ITEMS_MAP[queueType])
    );
    const taskQueues = ['allTasks', 'yourTasks'];
    taskQueues.forEach(taskType => bandAidHelper(data[taskType], ITEMS_MAP));
    return { data };
  }
);

export const emitFetchQueueFailure = createAction(
  EMIT_FETCH_USER_VIEW_FAILURE,
  (error, data) => ({ error, data })
);

export const emitFetchQueueInProcess = createAction(
  EMIT_FETCH_USER_VIEW_INPROGRESS
);

export const emitFetchUserView = () =>
  fetchUserView(
    emitFetchQueueInProcess,
    emitFetchUserViewSuccess,
    emitFetchQueueFailure
  );

export const emitSetUserView = desiredColumns =>
  setUserView(
    emitFetchQueueInProcess,
    desiredColumns,
    emitFetchUserViewSuccess
  );

export const initialState = {
  userView: {
    ae: [],
    pq: [],
    mi: [],
    mc: [],
    yours: [],
    masterCase: [],
    allTasks: [],
    yourTasks: []
  }
};

const handlers = {
  EMIT_FETCH_USER_VIEW_SUCCESS: (state, action) => ({
    userView: action.payload.data,
    inProgress: false
  }),
  EMIT_FETCH_USER_VIEW_FAILURE: (state, action) => ({
    error: action.payload.error,

    userView: initialState.userView,

    inProgress: false
  }),
  EMIT_FETCH_USER_VIEW_INPROGRESS: () => ({
    inProgress: true
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
