/* eslint no-console:0 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isAuthorized } from 'config/auth';
import withStyles from '../Form/withStyles';
import stylesGenerator from './styles';

const unauthorizedClick = () => {
  console.error('unauthorized click');
};

const AuthorizedLink = props => {
  const {
    children,
    computedStyles,
    accessRole,
    onClick,
    override,
    looseCheck
  } = props;
  const allowed = override || isAuthorized(accessRole, looseCheck);
  return (
    <Fragment>
      <a
        role="link"
        {...props}
        aria-disabled={!allowed}
        data-disabled={!allowed}
        className={allowed ? '' : computedStyles.disabledLink}
        onClick={allowed ? onClick : unauthorizedClick}
      >
        {children}
      </a>
    </Fragment>
  );
};

AuthorizedLink.propTypes = {
  children: PropTypes.node,
  computedStyles: PropTypes.string.isRequired,
  accessRole: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  override: PropTypes.bool, // Determine whether to always allow access (escape-hatch)
  looseCheck: PropTypes.bool
};

AuthorizedLink.defaultProps = {
  children: null,
  computedStyles: PropTypes.string.isRequired,
  onClick: () => {
    console.log('missing click handler');
  },
  override: false,
  looseCheck: false
};

export default withStyles(stylesGenerator)(AuthorizedLink);
