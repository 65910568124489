import React from 'react';
import PropTypes from 'prop-types';

import { genListTerminator } from '../utils';

const ValidationErrors = ({ id, computedStyles, validationErrors }) => {
  const renderValidationError = (validationError, i) =>
    validationError ? (
      <label key={i} htmlFor={id}>
        {`${validationError}${genListTerminator(validationErrors, i)}`}
      </label>
    ) : null;

  if (!validationErrors || !validationErrors.filter(err => !!err).length) {
    return null;
  }

  return (
    <div id={`${id}-errors`} className={computedStyles.errors}>
      {validationErrors.map(renderValidationError)}
    </div>
  );
};

ValidationErrors.defaultProps = {
  validationErrors: []
};

ValidationErrors.propTypes = {
  id: PropTypes.string.isRequired,
  computedStyles: PropTypes.shape({
    errors: PropTypes.object.isRequired
  }).isRequired,
  validationErrors: PropTypes.arrayOf(PropTypes.string)
};

export default ValidationErrors;
