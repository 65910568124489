/* eslint no-console:0, global-require:0 */
import { taskClient } from 'config/apollo';
import {
  isEmpty,
  get,
  set,
  reduce,
  isArray,
  isPlainObject,
  trim,
  endsWith,
  omit,
  cloneDeep,
  startsWith,
  isNil
} from 'lodash';
import { getUser, getUserFirstLastNames, isFalsyValue } from 'Common/utils';
import {
  NEW_TASK_ID,
  PREGNANCY_RELATED_PATH,
  EXPECTED_DELIVERY_DATE_PATH,
  ATTEMPTS_PATH,
  ATTEMPT_REPLY_DATE_PATH,
  ATTEMPT_REPLY_RECEIVED_PATH
} from 'Tasks/constants';

import createTaskMutation from './mutations/task/createTaskMutation';
import { shouldRemoveTypeFields } from './formatter/caseResponse';

const BASE_FILTER = [
  'isEditing',
  'isExpanded',
  'status',
  'form.status',
  'index',
  'masterCaseId',
  'display',
  'status',
  'form.base.display',
  'revisions',
  'createdTimeStamp',
  'creatorId',
  'creatorUserName',
  'lastUpdatedUserId',
  'lastUpdatedUsername'
];
const TYPE_FILTER_MAP = {
  CASEFLOW: BASE_FILTER.concat([
    'form.additional.maxAttempts',
    'form.base.pregnancyRelated',
    'form.base.expectedDueDate',
    'form.additional.attempts',
    'form.additional.attempts'
  ]),
  REVIEW: BASE_FILTER.concat([
    'form.additional.maxAttempts',
    'form.base.pregnancyRelated',
    'form.base.expectedDueDate',
    'form.additional.attempts',
    'form.additional.attempts'
  ]),
  COMMUNICATION: BASE_FILTER.concat([
    'form.base.pregnancyRelated',
    'form.base.expectedDueDate'
  ]),
  PVQUERY: BASE_FILTER,
  PQFOLLOWUP: BASE_FILTER
};

// they are allowed to not enter attempt data but we need it - even though it is null
const updateAttemptData = taskDocument => {
  const updatedTask = cloneDeep(taskDocument);
  const hasAttemptData = get(
    updatedTask,
    'form.additional.attempts.attempt.length',
    0
  );
  if (
    get(updatedTask, 'form.additional.type') === 'COMMUNICATION' &&
    !hasAttemptData
  ) {
    set(updatedTask, 'form.additional.attempts.attempt[0].attemptNumber', null);
    set(updatedTask, 'form.additional.attempts.attempt[0].queryDate', null);
    set(
      updatedTask,
      'form.additional.attempts.attempt[0].recipient.name',
      null
    );
    set(updatedTask, 'form.additional.attempts.attempt[0].method', null);
    set(updatedTask, 'form.additional.attempts.attempt[0].comment', null);
  }
  if (
    get(updatedTask, 'form.additional.type') === 'PVQUERY' &&
    hasAttemptData
  ) {
    const attemptsData = get(updatedTask, 'form.additional.attempts.attempt');
    const updatedAttemptsData = attemptsData.map(attempt => {
      const lettersData = get(attempt, 'letters.letter', []);
      const updatedLetterData = lettersData.filter(
        ltr => !isNil(get(ltr, 'type'))
      );
      set(attempt, 'letters.letter', updatedLetterData);
      return attempt;
    });
    set(updatedTask, 'form.additional.attempts.attempt', updatedAttemptsData);
  }
  return updatedTask;
};

// Prepare the task for a GQL mutation. This could live in api/graphql/formatter/taskRequest
// Try to keep in sync with caseMutationCustomizer in src/api/graphql/formatter/caseRequest.js
const taskMutationCustomizer = (acc, value, key) => {
  if (isNil(key)) {
    return acc;
  } else if (isFalsyValue(value)) {
    // Prune falsy values
    return acc;
  } else if (shouldRemoveTypeFields(key)) {
    // Skip GQL type fields
    return acc;
  } else if (endsWith(trim(key), '.')) {
    console.warn(
      `Key: ${key} and value: ${value} should not exist in the document.`
    );
    return acc;
  } else if (startsWith(key, '/')) {
    // Removes any invalid statePaths (namely, ones starting with a `/`)
    return acc;
  } else if (isPlainObject(value)) {
    // Recurse into objects, excluding empty objects
    const obj = reduce(value, taskMutationCustomizer, {});
    if (Object.keys(obj).length > 0) {
      acc[key] = reduce(value, taskMutationCustomizer, {});
    } else {
      return acc;
    }
  } else if (isArray(value) && value.length > 0) {
    acc[key] = value.map(v => reduce(v, taskMutationCustomizer, {}));
  } else {
    // Pass-through all other values
    acc[key] = value;
  }
  return acc;
};

const submitTaskMutation = (value, tacticalData, taskFragment) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const userName = getUserFirstLastNames(
    getUser(users, value.form.base.assignee)
  );
  set(value, 'form.base.assigneeFirstName', userName.firstName);
  set(value, 'form.base.assigneeLastName', userName.lastName);
  const { type } = value.form.additional;
  // Clear out data applied to the task from case (via genNewTask) if not pregnancy related
  if (get(value, PREGNANCY_RELATED_PATH, false) === false)
    set(value, EXPECTED_DELIVERY_DATE_PATH, null);
  // Clear out the reply data for any attempts that were dismissed
  get(value, ATTEMPTS_PATH, []).forEach(attempt => {
    if (get(attempt, ATTEMPT_REPLY_RECEIVED_PATH) === 'NO')
      set(attempt, ATTEMPT_REPLY_DATE_PATH, null);
  });

  const withAttemptData = updateAttemptData(value);
  const omitted = omit(withAttemptData, TYPE_FILTER_MAP[type]);
  const task = reduce(omitted, taskMutationCustomizer, {});

  if (task.id === NEW_TASK_ID) task.id = '';
  const variables = { task };

  return taskClient
    .mutate({
      mutation: createTaskMutation(taskFragment),
      variables
    })
    .then(({ data }) => {
      const res = data[Object.keys(data)[0]];
      const thawed = Object.isFrozen(res)
        ? JSON.parse(JSON.stringify(res))
        : res;
      return thawed;
    });
};

export default submitTaskMutation;
