import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

const NotFound = props => (
  <div className={props.computedStyles.base}>
    <div className={props.computedStyles.messageContainer}>
      <h1>404</h1>
      <p>THE PAGE YOU ARE LOOKING FOR COULD NOT BE FOUND.</p>
    </div>
  </div>
);

NotFound.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    messageContainer: PropTypes.object.isRequired
  }).isRequired
};

export default withStyles(stylesGenerator)(NotFound);
