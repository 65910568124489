import { generateCSS, genCaret } from 'Common/components/Form';

const stylesGenerator = (theme, props) => ({
  base: generateCSS({
    position: 'relative',
    top: '10px',
    right: '-9px',
    background: theme.colors.base,
    border: `2px solid ${theme.colors.primary}`,
    boxShadow: 'none',
    borderRadius: '0',
    '> div': {
      padding: '20px',
      ...genCaret(
        {
          border: theme.colors.primary,
          arrow: theme.colors.base
        },
        props,
        { top: -12, right: 8 }
      )
    }
  }),

  links: generateCSS({
    position: 'relative',
    '> div > a': {
      padding: '0 0 10px',
      display: 'block',
      fontSize: '16px',
      ...theme.fonts.default,
      color: theme.colors.secondary,
      ':last-child': {
        padding: '0'
      }
    }
  }),

  trigger: generateCSS({
    color: theme.colors.brand,
    fontWeight: 'bold',
    ':disabled': {
      cursor: 'not-allowed',
      color: theme.colors.base
    }
  }),

  triggerDisabled: generateCSS({
    color: theme.colors.primary,
    fontWeight: 'bold',
    cursor: 'not-allowed'
  }),

  triangle: generateCSS({
    width: '15px',
    height: '10px',
    objectFit: 'contain',
    transform: 'rotate(180deg)',
    backgroundColor: '#00a9e0'
  })
});

export default stylesGenerator;
