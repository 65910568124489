import React from 'react';

import Input from '../Input';

const TextArea = props => (
  <Input {...props} type="textarea">
    <textarea />
  </Input>
);

export default TextArea;
