import { isEmpty } from 'lodash';

import {
  generateCSS,
  genDisabledStyles,
  genErrorStyles,
  genLabelStyles
} from '../index';

const styleGenerator = (theme, props) => {
  const disabledStyles = genDisabledStyles(theme);
  const errorStyles = genErrorStyles(theme);
  const labelStyles = genLabelStyles(theme, props);
  const verticalSpacing = props.label !== false ? '10px' : '0';
  const hasErrors = !isEmpty(props.errors);
  const boxShadowColor = hasErrors ? theme.colors.error : theme.colors.tertiary;
  const labelColor = hasErrors ? theme.colors.error : 'inherit';

  return {
    base: generateCSS({
      display: 'inline-flex',
      flexDirection: 'column',
      ...props.layout,
      margin: '0 5px 0 0',

      ' > div:first-child + div, > div:first-child': {
        display: 'flex',
        flexDirection: 'column',
        ...props.styles
      },

      ...props.styles
    }),

    compensateForGroup: generateCSS({
      margin: '0 0 0 10px'
    }),

    ...labelStyles,

    group: generateCSS({
      padding: `${verticalSpacing} 0`,
      '> div': {
        '::selection': { background: 'red' },
        verticalAlign: 'top',
        display: 'inline-block',
        '> label > .checked + span': {
          background: theme.colors.tertiary
        },
        '> label > .focused + span': {
          outline: `2px solid ${theme.colors.brand}`
        },
        '> label > span:nth-child(2)': {
          borderRadius: '0',
          boxShadow: `0 0 0 2px ${boxShadowColor}`,
          background: 'none',
          '::before': {
            background: theme.colors.tertiary
          },
          ...disabledStyles
        },
        '> label + span': {
          color: labelColor
        }
      },
      '.horizontal': {
        display: 'block !important',
        '> div': {
          marginRight: '20px'
        }
      }
    }),

    input: generateCSS({
      display: 'inline-block'
    }),

    optionLabel: generateCSS({
      verticalAlign: 'top',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      height: '20px',
      margin: '0',
      ...theme.fonts.input
    }),

    inputHelpTextCompensation: generateCSS({
      margin: '0 30px 26px 0'
    }),

    inputSet: generateCSS({
      margin: '0 0 0 0px',
      display: 'inline-grid',
      '> .pt-control': {
        margin: '0',
        '> input:disabled ~ .pt-control-indicator': {
          opacity: '0.33'
        }
      }
    }),

    helpText: generateCSS({
      padding: '0 0 10px 26px',
      display: 'block',
      border: 'none',
      color: theme.colors.quaternary,
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    })
  };
};

export default styleGenerator;
