/* eslint import/prefer-default-export:0 */
import { generateCSS } from 'Common/components/Form';

const withOverviewStyles = {
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '100%'
};

const stylesGenerator = () => ({
  sectionsMap: shouldRenderOverview =>
    generateCSS({
      display: 'block',
      flexWrap: 'wrap',
      ...(shouldRenderOverview ? withOverviewStyles : {})
    }),
  content: generateCSS({
    display: 'flex',
    ' > div:last-child': {
      flex: '1 1 80%'
    }
  })
});

export default stylesGenerator;
