import { CASE_SERVICES_API } from 'config/values';

export const fetchLinkCases = (caseId, caseIdArr, onSuccess, onFailure) => {
  // eslint-disable-next-line no-undef
  const url = new URL(`${CASE_SERVICES_API.uri}/linkCases`);
  const fetchOptions = {
    body: JSON.stringify({ caseId, caseIdArr }),
    method: 'PUT',
    headers: {
      'x-api-key': CASE_SERVICES_API.apiKey,
      'content-type': 'application/json'
    }
  };
  return fetch(url.toString(), fetchOptions)
    .then(data => data.json())
    .then(onSuccess, onFailure);
};

export const fetchUnlinkCases = (case1Id, case2Id, onSuccess, onFailure) => {
  // eslint-disable-next-line no-undef
  const url = new URL(`${CASE_SERVICES_API.uri}/unlinkCases`);
  // fetch-whatwg spec for adding query params
  const params = { case1Id, case2Id };
  const fetchOptions = {
    method: 'GET',
    headers: {
      'x-api-key': CASE_SERVICES_API.apiKey
    }
  };
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  // toString to avoid config/fetch.js match error
  return fetch(url.toString(), fetchOptions).then(onSuccess, onFailure);
};
