import gql from 'graphql-tag';

const createCaseMutation = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;

  return gql`
    mutation CreateCase($case: caseInput!, $id: String!) {
      createCase(case: $case, id: $id) {
        ...FullCaseWithSubcases
      }
    }
    ${fragment}
  `;
};

export default createCaseMutation;
