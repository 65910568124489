/* eslint import/prefer-default-export:0 */
import { generateCSS } from '../index';

const stylesGenerator = () => ({
  base: generateCSS({
    position: 'relative'
  })
});

export default stylesGenerator;
