import { isBoolean } from 'lodash';
import { TASK_SERVICES_API } from 'config/values';

const getFetchOptions = (apiKey, data) => {
  const apiProps = {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    }
  };

  return {
    method: 'POST',
    body: JSON.stringify(data),
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

const fetchAssignTaskToUser = (
  actionType = '',
  assigneeDetails = {},
  selectedItemsToAssignOrArchive = []
) => {
  const requestBody = {
    actionType,
    assigneeName: assigneeDetails.assigneeName,
    assigneeFirstName: assigneeDetails.assigneeFirstName,
    assigneeLastName: assigneeDetails.assigneeLastName,
    assigneeId: assigneeDetails.userId,
    tasks: selectedItemsToAssignOrArchive
  };
  const fetchOptions = getFetchOptions(TASK_SERVICES_API.apiKey, requestBody);
  const URL = `${TASK_SERVICES_API.uri}/bulkService`;

  return fetch(URL, fetchOptions).then(res => res.json());
};

export default fetchAssignTaskToUser;
