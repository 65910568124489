import React from 'react';
import PropTypes from 'prop-types';
// import { SimpleButton } from 'Common/components/Form';

class CustomizeQueueResults extends React.PureComponent {
  static propTypes = {
    openQueueModal: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  };
  render() {
    return (
      // These buttons should probably be changed to the SimpleButton component
      // and styles should be using the computedStyles prop
      <div
        style={{ width: '260px', marginRight: '0px', display: 'inline-block' }}
      >
        <button
          style={{
            color: '#FFFFFF',
            border: '1px solid #FFFFFF',
            background: '#071D49',
            margin: '14px 10px 5px 36px',
            cursor: 'pointer',
            padding: '10px'
          }}
          disabled={this.props.disabled}
          onClick={() => this.props.openQueueModal('editColumns')}
        >
          Edit Columns
        </button>
        <button
          style={{
            color: '#FFFFFF',
            border: '1px solid #FFFFFF',
            background: '#071D49',
            marginBottom: '5px',
            cursor: 'pointer',
            padding: '10px'
          }}
          disabled={this.props.disabled}
          onClick={() => this.props.openQueueModal('showFilters')}
        >
          Show Filters
        </button>
      </div>
    );
  }
}

export default CustomizeQueueResults;
