import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import { SERIOUSNESS_INDICATOR_MAP } from 'Common/constants';
import stylesGenerator from './styles';

const SeriousnessIndicator = ({ value, computedStyles, pullRight }) => (
  <div
    className={`${computedStyles.base} ${
      pullRight ? computedStyles.pullRight : null
    }`}
  >
    <span data-id="serious-indicator" className={computedStyles.indicator}>
      {SERIOUSNESS_INDICATOR_MAP[value].label}
    </span>
  </div>
);

SeriousnessIndicator.propTypes = {
  value: PropTypes.string.isRequired,
  pullRight: PropTypes.bool,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    indicator: PropTypes.object.isRequired
  }).isRequired
};

SeriousnessIndicator.defaultProps = {
  pullRight: true
};

export default withStyles(stylesGenerator)(SeriousnessIndicator);
