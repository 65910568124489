import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    maxWidth: '800px',
    height: 'auto'
  }),

  table: generateCSS({
    margin: '30px auto',
    width: '100%',
    borderCollapse: 'collapse'
  }),

  tableWrapper: generateCSS({
    overflow: 'auto'
  }),

  tr: generateCSS({
    height: '40px',
    '> td': {
      padding: '4px',
      borderBottom: `solid 1px ${theme.colors.tableResultsBorder}`
    }
  }),

  headerSectionInitial: generateCSS({
    marginBottom: '-20px'
  }),

  headerSection: generateCSS({
    backgroundColor: theme.colors.background,
    marginRight: '-20px',
    marginLeft: '-20px',
    marginTop: '-20px',
    padding: '20px'
  }),

  actionHeader: generateCSS({
    backgroundColor: theme.colors.background,
    width: '151px'
  }),

  productInfoHeader: generateCSS({
    backgroundColor: theme.colors.tableHeader,
    borderRight: `solid 1px ${theme.colors.base}`
  }),

  productInfoSubHeader: generateCSS({
    backgroundColor: theme.colors.tableHeader,
    textAlign: 'left',
    padding: '3px'
  }),

  productInfoResults: generateCSS({
    backgroundColor: theme.colors.tableResults
  }),

  inputElement: generateCSS({
    display: 'inline-block',
    width: '100%'
  })
});

export default stylesGenerator;
