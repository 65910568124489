import { isBoolean } from 'lodash';
import { TASK_SERVICES_API } from 'config/values';

const getFetchOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': TASK_SERVICES_API.apiKey
    }
  };

  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

const fetchTasksCounts = (
  showSubcases,
  emitFetchTasksCountsSuccess,
  emitFetchTasksCountsFailure
) => async dispatch => {
  const URL = `${
    TASK_SERVICES_API.uri
  }/findTasksCounts?sortBy=asc&showSubcases=${showSubcases}`;
  const fetchOptions = getFetchOptions(TASK_SERVICES_API.apiKey);
  try {
    const response = await fetch(URL, fetchOptions);
    const json = await response.json();
    dispatch(emitFetchTasksCountsSuccess(json));
  } catch (error) {
    dispatch(emitFetchTasksCountsFailure(error));
  }
};

export default fetchTasksCounts;
