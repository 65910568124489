/* eslint no-console:0 */
import { client } from 'config/apollo';
import { FRAGMENT_NAME_CASE } from 'Common/constants';

const submitCaseCSVSearch = async options => {
  const { schemaReducer, fields } = options;
  const variables = {
    q: options.q,
    size: options.size || 1000,
    from: options.from || 0
  };

  const query = fields
    ? options.query(fields)
    : options.query(schemaReducer.fragments[FRAGMENT_NAME_CASE]);
  try {
    const { data } = await client.query({
      query,
      variables,
      fetchPolicy: 'network-only'
    });
    console.log('got data', data);
    const cases = Object.isFrozen(data)
      ? JSON.parse(JSON.stringify(data))
      : data;
    return cases;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default submitCaseCSVSearch;
