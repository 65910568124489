import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  contentItemLabel: generateCSS({
    fontWeight: 'bold',
    paddingRight: '20px',
    color: theme.colors.altBrand
  }),

  sectionTitle: generateCSS({
    display: 'flex',
    fontWeight: 'bold',
    paddingRight: '20px',
    color: theme.colors.altBrand,
    marginTop: '3px'
  })
});

export default stylesGenerator;
