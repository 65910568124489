import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash';
import { FORM_BASE_PATH } from 'CreateCase/constants';

class CaseIdLink extends PureComponent {
  static propTypes = {
    taskBase: PropTypes.shape({
      caseId: PropTypes.string,
      subcaseId: PropTypes.string,
      subcaseType: PropTypes.string
    }).isRequired
  };

  static defaultProps = {
    subcaseId: null,
    subcaseType: null
  };

  genSubcaseRoute = () => {
    const { taskBase: { caseId, subcaseId, subcaseType } } = this.props;

    const subcaseLink = `/${FORM_BASE_PATH}/${caseId}/${subcaseType.toLowerCase()}`;
    if (subcaseType && isNil(caseId) && !isNil(subcaseId)) {
      // extract case id from subcaseId if caseId is null
      const matches = subcaseId.match(/(\w{4}-\w+)-\w+/);
      return !isNil(matches) && matches.length > 1
        ? `/${FORM_BASE_PATH}/${matches[1]}/${subcaseType.toLowerCase()}`
        : subcaseLink;
    }
    return subcaseLink;
  };

  render() {
    const { taskBase: { caseId, subcaseId, subcaseType } } = this.props;
    const routePath = subcaseType
      ? this.genSubcaseRoute()
      : `/${FORM_BASE_PATH}/${caseId}/review`;

    return (
      <Link to={routePath}>
        {!isNil(subcaseId) && !isEmpty(subcaseId) ? subcaseId : caseId}
      </Link>
    );
  }
}

export default CaseIdLink;
