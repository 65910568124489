/* eslint react/jsx-filename-extension:0 */

import { createAction, handleActions } from 'redux-actions';

import { CLIENT_WIDTH_OFFSET } from 'Navigation/constants';
import { EMIT_CLEAN_SLATE } from 'Common/constants';

const EMIT_DEVICE_WIDTH_UPDATE = 'EMIT_DEVICE_WIDTH_UPDATE';
const EMIT_NAV_TAB_SELECTED = 'EMIT_NAV_TAB_SELECTED';

const EMIT_XRAY_TOGGLE = 'EMIT_XRAY_TOGGLE';

export const emitclientWidthUpdate = createAction(
  EMIT_DEVICE_WIDTH_UPDATE,
  () => document.body.clientWidth + CLIENT_WIDTH_OFFSET
);

export const emitNavTabSelected = createAction(EMIT_NAV_TAB_SELECTED);

export const emitXRayToggle = createAction(EMIT_XRAY_TOGGLE);

export const emitCleanSlate = createAction(EMIT_CLEAN_SLATE);

const initialState = {
  clientWidth: document.body.clientWidth + CLIENT_WIDTH_OFFSET,
  navTabActive: 'none',
  modalContent: false
};

const handlers = {
  [EMIT_CLEAN_SLATE]: () => ({
    ...initialState
  }),

  [EMIT_DEVICE_WIDTH_UPDATE]: (state, action) => ({
    ...state,
    clientWidth: action.payload
  }),

  [EMIT_NAV_TAB_SELECTED]: (state, action) => ({
    ...state,
    navTabActive: action.payload
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
