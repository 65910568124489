/* eslint import/prefer-default-export:0 */
import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    position: 'relative',
    float: 'right'
  }),

  status: generateCSS({
    textTransform: 'uppercase',
    font: '16px SackersGothicStd-Medium',
    fontWeight: '900',
    color: theme.colors.primaryContrast,
    marginLeft: '15px'
  }),

  link: generateCSS({
    margin: '7px 0',
    display: 'inline-block',
    height: '2px',
    width: '30px',
    background: `${theme.colors.primaryContrast}`
  }),

  crumb: fillCrumb =>
    generateCSS({
      display: 'inline-block',
      height: '15px',
      width: '15px',
      borderRadius: '50%',
      border: `2px solid ${theme.colors.primaryContrast}`,
      background: fillCrumb ? theme.colors.primaryContrast : 'none'
    })
});

export default stylesGenerator;
