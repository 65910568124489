/* eslint import/prefer-default-export:0 */
import { isNil, isEmpty, find, get } from 'lodash';

/** @module Subscription Utils */

/**
 * @function
 * @param {function} emitNotificationBarClearAction
 * @param {Object} observableInstance
 */
export const unSubscribe = (
  emitNotificationBarClearAction,
  observableInstance
) => {
  if (observableInstance) {
    emitNotificationBarClearAction();
    try {
      observableInstance.unsubscribe();
    } catch (e) {
      console.log('trying to unsubscribe from socket that does not exist');
    }
  }
};

/**
 * @function
 * @param {*} client
 * @param {*} editorHandlers
 * @param {*} gqlObject
 * @param {*} session
 * @param {*} tabId
 */
export const subscribeToCase = (
  client,
  editorHandlers,
  gqlObject,
  session,
  tabId
) =>
  client.subscribe(gqlObject).subscribe({
    next({ data }) {
      if (!isNil(data.subscribeCase.users)) {
        const activeUserState = find(
          data.subscribeCase.users,
          userState => userState.activeUser
        );
        const currentUserState = find(
          data.subscribeCase.users,
          currentUserData => {
            const currentUser = get(currentUserData, 'user', {});
            const currentUserId = get(currentUser, 'id', null);
            return currentUserId === session.sub;
          }
        );
        const activeUser = get(activeUserState, 'user', {});
        const activeUserClientId = get(activeUserState, 'userClientId', null);
        const currentUserClientId = get(currentUserState, 'userClientId', null);
        const isCurrentUserActive = get(activeUser, 'id') === session.sub;
        if (!isCurrentUserActive && !isEmpty(activeUser)) {
          editorHandlers.showMultipleEditorsWarningModal(activeUser);
        } else if (
          (isCurrentUserActive &&
            !isNil(activeUserClientId) &&
            activeUserClientId !== tabId) ||
          currentUserClientId !== tabId
        ) {
          editorHandlers.showSameEditorWarningModal();
        } else if (
          isCurrentUserActive &&
          !isNil(activeUserClientId) &&
          activeUserClientId === tabId
        ) {
          editorHandlers.unlockAndDismissModal();
        }
      }
    },
    error(err) {
      console.error('err', err);
    }
  });
