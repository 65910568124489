import gql from 'graphql-tag';

const caseSubscription = gql`
  subscription subscribeCase($id: String!) {
    subscribeCase(id: $id) {
      id
      users
    }
  }
`;

export default caseSubscription;
