import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { css } from 'glamor';
import { omitBy, isNil, isEmpty, endsWith, filter, isObject } from 'lodash';
import { usersList } from 'Common/utils';
import { filterDependentOptions } from 'CreateCase/utils';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class Select extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    defaultOption: PropTypes.arrayOf(PropTypes.object),
    onBlur: PropTypes.func,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object
    ]).isRequired,
    hasUserList: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    customDelimiter: PropTypes.string
  };

  static defaultProps = {
    ...formElementDefaultProps,
    defaultOption: [{ label: '', value: '' }],
    layout: { width: '100%' },
    styles: { width: '100%' },
    value: '',
    hasUserList: false,
    customDelimiter: '',
    stateParentPath: ''
  };

  MULTI_SEPARATOR = ',';

  genOptions = () => {
    const { multi, options, hasUserList, stateParentPath } = this.props;
    const defaultOption = multi ? [] : this.props.defaultOption;
    const shouldSwapOptionsForUserList =
      hasUserList && !isNil(options) && !isEmpty(options);
    let filteredOptions = options;
    if (
      stateParentPath &&
      !isNil(stateParentPath) &&
      !isEmpty(stateParentPath)
    ) {
      filteredOptions = filterDependentOptions(this.props, stateParentPath);
    }
    return (
      defaultOption
        .concat(
          shouldSwapOptionsForUserList
            ? usersList(filteredOptions)
            : filteredOptions
        )
        // Null options are filtered to prevent blowing up on the first render cycle,
        // however beware that this can hide issues with upstream tactical data or schema data
        // not being loaded correctly! Check CreateCaseController's use of emitSchemaFetch
        // if you see empty Select components.
        .filter(isObject)
    );
  };

  handleChange = selected => {
    const { value } = selected || { value: '' };

    this.props.onChange(value);
  };

  handleMultiChange = valueOrValues => {
    const { onChange, customDelimiter } = this.props;
    const delimiter = customDelimiter || this.MULTI_SEPARATOR;
    const filterEmptyString = filterValue => {
      const values = filterValue.split(delimiter);
      return filter(values, v => v !== '').join(delimiter);
    };

    // If the string ends with a separator character, the blank value was selected after a real value: clear list.
    if (endsWith(valueOrValues, delimiter)) {
      onChange('');
    } else {
      onChange(filterEmptyString(valueOrValues));
    }
  };

  focus = () => this.component.focus();

  handleFocus = () => {
    if (typeof this.props.onFocus == 'function') this.props.onFocus();
  };

  // Adds an id selector testing convenience
  renderOption = ({ label, value }) => (
    <span id={`option-${this.props.id}-${value}`}>{label}</span>
  );

  render() {
    const {
      $id,
      schemaPath,
      className,
      id,
      disabled,
      helpText,
      placeholder,
      value,
      errors,
      computedStyles,
      multi,
      autoFocus,
      data,
      customDelimiter
    } = this.props;

    const selectProps = {
      ref: component => (this.component = component),
      optionRenderer: this.renderOption,
      type: 'text',
      options: this.genOptions(),
      onChange: multi ? this.handleMultiChange : this.handleChange,
      backspaceRemoves: true,
      matchPos: 'any', // https://github.com/JedWatson/react-select/tree/v1.x#filtering-options
      placeholder,
      disabled,
      multi,
      autofocus: autoFocus,
      simpleValue: !!multi,
      clearable: false,
      value,
      delimiter: customDelimiter || this.MULTI_SEPARATOR,
      onFocus: this.handleFocus
    };

    return (
      <div
        className={css(computedStyles.base, className)}
        id={`${id}-container`}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <Label {...this.props} />
        <ReactSelect
          // TRGY-1018 react-select will blow up, in prod mode, if given explicitly null props
          {...omitBy(selectProps, isNil)}
          inputProps={{ id, 'data-id': $id }}
        />
        <span className={computedStyles.helpText}>{helpText}</span>
        <ValidationErrors
          id={`${id}-validation-errors`}
          computedStyles={computedStyles}
          validationErrors={errors}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Select);
