/* eslint import/prefer-default-export:0 */
import { SERIOUSNESS_INDICATOR_MAP } from 'Common/constants';

/**
 * @param {TrilogyCase} trilogyCase
 */
export const processSeriousnessCriteria = trilogyCase => {
  const hasSerious = SERIOUSNESS_INDICATOR_MAP.serious.process(trilogyCase);
  const hasLifeThreat = SERIOUSNESS_INDICATOR_MAP.lifeThreat.process(
    trilogyCase
  );
  const hasDeath = SERIOUSNESS_INDICATOR_MAP.death.process(trilogyCase);
  const hasDeathOutcome = SERIOUSNESS_INDICATOR_MAP.durationOutcome.process(
    trilogyCase
  );

  if (hasDeath || hasDeathOutcome) return 'death';
  else if (hasLifeThreat) return 'lifeThreat';
  else if (hasSerious) return 'serious';
  return null;
};
