import React from 'react';
import PropTypes from 'prop-types';

import FileListItem from './FileListItem';

const FileList = props => {
  const {
    value,
    editing,
    editFile,
    multiple,
    removeFile,
    computedStyles,
    onDownloadAttachment
  } = props;
  if (!value || !value.length) return null;
  const files = multiple ? value : value.slice(-1);

  return (
    <ul className={computedStyles.fileList}>
      {[].concat(files).map((file, i) => {
        const idKey = Object.keys(file).indexOf('_id') !== -1 ? '_id' : 'id';
        const isEditing = editing && file[idKey] === editing;
        return (
          <FileListItem
            key={`item-${i}`}
            onDownloadAttachment={onDownloadAttachment}
            {...props}
            {...{ file, i, computedStyles, editFile, removeFile, isEditing }}
          />
        );
      })}
    </ul>
  );
};

FileList.propTypes = {
  editing: PropTypes.string,
  editFile: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  onDownloadAttachment: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired, // see `Common/utils/attachments` for info
        dataURL: PropTypes.string.isRequired,
        ext: PropTypes.string,
        comment: PropTypes.string,
        name: PropTypes.string,
        upload: PropTypes.string,
        size: PropTypes.number,
        type: PropTypes.string
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        type: PropTypes.string,
        comment: PropTypes.string,
        ext: PropTypes.string
      })
    )
  ]),
  multiple: PropTypes.bool.isRequired,
  computedStyles: PropTypes.objectOf(PropTypes.object).isRequired
};

FileList.defaultProps = {
  editing: null,
  value: []
};

export default FileList;
