import React, { PureComponent } from 'react';
import { Tooltip, Position } from '@blueprintjs/core';
import { kebabCase, get } from 'lodash';
import PropTypes from 'prop-types';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class CopyProductTherapyButton extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    onClick: PropTypes.func.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    ...formElementDefaultProps,
    onChange: () => {}
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props);
    }
  };

  render() {
    const { $id, schemaPath, id, computedStyles, disabled } = this.props;
    const defaultId = id || `${this.props.label.toLowerCase()} smart button`;
    const buttonDisabled = get(this.props, 'data.buttonDisabled');
    const tstyles = {
      zIndex: '9',
      position: 'absolute',
      right: '15px'
    };
    return (
      <div className={computedStyles.base}>
        <Tooltip content="Copy Product Therapy" position={Position.BOTTOM}>
          <button
            id={kebabCase(defaultId)}
            onClick={this.handleClick}
            disabled={buttonDisabled || disabled}
            data-id={$id}
            data-schema-path={schemaPath}
          >
            {this.props.label}
          </button>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(CopyProductTherapyButton);
