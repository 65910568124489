import gql from 'graphql-tag';

const createCaseShell = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;
  return gql`
    mutation CreateCaseShell {
      createCaseShell {
        ...FullCaseWithSubcases
      }
    }
    ${fragment}
  `;
};

export default createCaseShell;
