import React, { PureComponent } from 'react';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from 'CreateCase/components/CollapseSection/styles';
import PropTypes from 'prop-types';
import { Collapse, Icon } from '@blueprintjs/core';

class CollapseSection extends PureComponent {
  static propTypes = {
    sectionContent: PropTypes.element,
    sectionTitle: PropTypes.string.isRequired,
    computedStyles: PropTypes.shape({
      sectionTitle: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    sectionContent: <div />
  };

  state = {
    isCollapsed: false
  };

  handleSectionCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const { computedStyles, sectionTitle, sectionContent } = this.props;
    const { isCollapsed } = this.state;

    const getIconName = () =>
      this.state.isCollapsed ? 'pt-icon-caret-up' : 'pt-icon-caret-down';

    return (
      <div>
        <div
          role="button"
          className={computedStyles.sectionTitle}
          onClick={this.handleSectionCollapse}
        >
          <span>{sectionTitle}</span>
          <Icon iconName={getIconName()} />
        </div>
        <Collapse isOpen={!isCollapsed}>{sectionContent}</Collapse>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(CollapseSection);
