import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, keys, pickBy, isNil } from 'lodash';
import fetchTasksQuery from 'api/graphql/fetchTasksQuery';
import { CMS_PROP_TYPES } from 'Common/constants';
import { TextInput, withStyles, SimpleButton } from 'Common/components/Form';
import SearchBar from 'Common/components/SearchBar';
import SearchInput from 'Common/components/SearchInput';
import MultiToggle from 'Common/components/MultiToggle';
import Notifier from 'Common/components/Notifier';
import { YOUR_TASKS_PATH } from 'Tasks/constants';
import CaseActionsMenu from 'CreateCase/components/PageHeader/CaseActionsMenu';
import AuthorizedLink from 'Common/components/AuthorizedLink';
import ReassignAssigneeModal from 'Common/components/ReassignAssigneeModal';
import CustomizeQueueResults from 'Queue/components/CustomResults';
import stylesGenerator from './styles';

class TasksHeader extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      emitUpdateQuery: PropTypes.func.isRequired,
      emitFetchSearch: PropTypes.func.isRequired,
      emitFilterInputUpdate: PropTypes.func.isRequired
    }).isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      title: PropTypes.object.isRequired,
      search: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired,
      searchBox: PropTypes.object.isRequired
    }).isRequired,
    searchBarQuery: PropTypes.string,
    filters: PropTypes.shape({
      caseId: PropTypes.string,
      assignee: PropTypes.string,
      status: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.arrayOf(PropTypes.string)
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }),
    fragments: PropTypes.objectOf(PropTypes.string).isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    FILTER_PATH_MAP: PropTypes.shape({
      type: PropTypes.string,
      status: PropTypes.string
    }),
    onSearch: PropTypes.func.isRequired,
    isFilteringTasks: PropTypes.bool.isRequired,
    assignCaseOrTask: PropTypes.func,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    handleExportToCSV: PropTypes.func,
    showModifyView: PropTypes.func,
    showFilters: PropTypes.func,
    updateInternalFilter: PropTypes.func,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    appliedFilters: PropTypes.element.isRequired,
    statusFilters: PropTypes.shape({
      OPEN: PropTypes.bool.isRequired,
      CANCELLED: PropTypes.bool.isRequired,
      COMPLETED: PropTypes.bool.isRequired
    }).isRequired,
    typeFilters: PropTypes.shape({
      PVQUERY: PropTypes.bool.isRequired,
      CASEFLOW: PropTypes.bool.isRequired,
      COMMUNICATION: PropTypes.bool.isRequired,
      REVIEW: PropTypes.bool.isRequired,
      PQFOLLOWUP: PropTypes.bool.isRequired
    }).isRequired,
    openQueueModal: PropTypes.func.isRequired,
    model: PropTypes.shape({
      tasks: PropTypes.array
    })
  };

  static defaultProps = {
    tacticalData: {},
    searchBarQuery: '',
    filters: {
      caseId: null,
      assignee: null,
      status: [],
      type: []
    },
    users: [],
    location: {},
    FILTER_PATH_MAP: {
      type: 'form.additional.type',
      status: 'status'
    },
    model: { tasks: [] },
    assignCaseOrTask: () => {},
    selectedItemsToAssignOrArchive: [],
    handleExportToCSV: () => {},
    showModifyView: () => {},
    showFilters: () => {},
    updateInternalFilter: () => {}
  };

  updateCaseIdFilter = value => {
    this.props.actions.emitFilterInputUpdate(value, 'form.base.caseId');
  };

  updateAssigneeFilter = value => {
    this.props.actions.emitFilterInputUpdate(value, 'form.base.assignee');
  };

  handleFilterChange = (value, filterType) => {
    const { emitFilterInputUpdate } = this.props.actions;
    const { FILTER_PATH_MAP, typeFilters, statusFilters } = this.props;
    const filterPath = FILTER_PATH_MAP[filterType];
    const state =
      filterType === 'type'
        ? {
            typeFilters: {
              ...typeFilters,
              [value]: !typeFilters[value]
            }
          }
        : {
            statusFilters: {
              ...statusFilters,
              [value]: !statusFilters[value]
            }
          };

    this.props.updateInternalFilter(state);

    const selectedValues =
      filterType === 'type'
        ? keys(pickBy(state.typeFilters))
        : keys(pickBy(state.statusFilters));

    emitFilterInputUpdate(selectedValues, filterPath);
  };

  updateFiltersRunSearch = (value, filterType) => {
    this.handleFilterChange(value, filterType);
    this.props.onSearch();
  };

  renderSearchInput = () => {
    const { actions, searchBarQuery, onSearch, computedStyles } = this.props;
    return (
      <SearchInput
        value={searchBarQuery}
        onSubmit={onSearch}
        onChange={value => actions.emitUpdateQuery(value)}
        fromStyle={computedStyles}
      />
    );
  };

  reassignSubcaseConfirm = assignee => {
    this.props.assignCaseOrTask(assignee);
  };
  assignSubcaseModal = () => {
    const { actions, selectedItemsToAssignOrArchive, model } = this.props;

    // get the affiliates for each selected task
    const selectedAffiliates = [];
    selectedItemsToAssignOrArchive.forEach(selectedTaskId => {
      selectedAffiliates.push(
        model.tasks.find(taskObj => taskObj.id === selectedTaskId).affiliate
      );
    });
    // check if all affiliates are the same
    // show modal if the same
    if (new Set(selectedAffiliates).size === 1) {
      const content = (
        <ReassignAssigneeModal
          {...this.props}
          selectedAffiliate={selectedAffiliates[0]}
          title="REASSIGN CASE"
          handleAssign={assignTo => this.reassignSubcaseConfirm(assignTo)}
        />
      );
      actions.emitModalContentUpdate(content, true);
      // display toaster message if affiliates are not the same
    } else {
      Notifier.show({
        message:
          'Please reduce Affiliate Locations to one prior to bulk-assigning',
        intent: Notifier.DANGER
      });
    }
  };

  renderActionMenuLinks = () => {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.actionMenu}>
        <CaseActionsMenu>
          <div>
            {this.renderAssignToMeLink()}
            {this.renderAssignToUserLink()}
            {this.exportToCSV()}
          </div>
        </CaseActionsMenu>
      </div>
    );
  };

  renderAssignToUserLink = () => {
    const { selectedItemsToAssignOrArchive } = this.props;
    const shouldDisableOptions = selectedItemsToAssignOrArchive.length <= 0;
    return (
      <AuthorizedLink
        accessRole={shouldDisableOptions ? null : 'edit'}
        looseCheck
        onClick={() => this.assignSubcaseModal()}
        role="button"
      >
        Assign to another Trilogy user
      </AuthorizedLink>
    );
  };

  renderAssignToMeLink = () => {
    const { selectedItemsToAssignOrArchive } = this.props;
    const shouldDisableOptions = selectedItemsToAssignOrArchive.length <= 0;
    return (
      <AuthorizedLink
        accessRole={shouldDisableOptions ? null : 'edit'}
        looseCheck
        onClick={() => this.props.assignCaseOrTask()}
        role="button"
      >
        Assign to me
      </AuthorizedLink>
    );
  };

  exportToCSV = () => (
    <AuthorizedLink
      accessRole={'access'}
      looseCheck
      onClick={() => this.props.handleExportToCSV()}
      role="button"
    >
      Export to csv
    </AuthorizedLink>
  );

  renderActionMenuLinks = () => {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.actionMenu}>
        <CaseActionsMenu>
          <div>
            {this.renderAssignToMeLink()}
            {this.renderAssignToUserLink()}
            {this.exportToCSV()}
          </div>
        </CaseActionsMenu>
      </div>
    );
  };

  render() {
    const {
      computedStyles,
      filters,
      location,
      fragments,
      isFilteringTasks,
      onSearch,
      appliedFilters
    } = this.props;
    const {
      PVQUERY,
      CASEFLOW,
      COMMUNICATION,
      REVIEW,
      PQFOLLOWUP
    } = this.props.typeFilters;
    const { OPEN, CANCELLED, COMPLETED } = this.props.statusFilters;

    const pathname = isNil(location)
      ? get(window.location, 'pathname', '')
      : get(location, 'pathname', '');

    return (
      <div>
        <div className={computedStyles.base}>
          <div className={computedStyles.row}>
            <span className={computedStyles.title}>
              {pathname === YOUR_TASKS_PATH ? 'Your Tasks' : 'Tasks'}
            </span>
          </div>
          <div className={computedStyles.row}>
            <div className={computedStyles.filterRow}>
              <div className={computedStyles.filterColumn1}>
                <SearchBar
                  {...this.props}
                  searchInput={this.renderSearchInput()}
                  onSubmit={onSearch}
                  fetchAction={fetchTasksQuery(fragments)}
                  from="task"
                />
              </div>
              <div>
                <TextInput
                  id="filterCaseId"
                  statePath="form.base.caseId"
                  label="Trilogy Case ID:"
                  value={filters['form.base.caseId']}
                  onChange={this.updateCaseIdFilter}
                  onBlur={this.updateCaseIdFilter}
                  disabled={pathname.indexOf('case') !== -1}
                  locale="US"
                />
              </div>
              <div
                style={{ margin: '17px 32px 0px' }}
                className={computedStyles.searchButton}
              >
                <SimpleButton
                  onClick={onSearch}
                  $id="submitFilterSearch"
                  primary
                  type="submit"
                >
                  SEARCH
                </SimpleButton>
              </div>
            </div>

            <div className={computedStyles.filterRow} />

            {this.renderActionMenuLinks()}
          </div>
        </div>

        <div className={computedStyles.displayFlex}>
          <div
            style={{
              margin: '19px 0px 0px'
            }}
          >
            <CustomizeQueueResults
              tacticalData={this.props.tacticalData}
              showModifyView={this.props.showModifyView}
              showFilters={this.props.showFilters}
              openQueueModal={this.props.openQueueModal}
            />
          </div>
          <div style={{ margin: '0px 90px 0px' }}>
            <h5 className={computedStyles.label}>Task Types: </h5>
            <MultiToggle
              onChange={value =>
                isFilteringTasks
                  ? () => {}
                  : this.updateFiltersRunSearch(value, 'type')
              }
              options={[
                {
                  $id: 'tasks.filters.pvQuery',
                  label: 'PV Query',
                  value: 'PVQUERY',
                  selected: PVQUERY
                },
                {
                  $id: 'tasks.filters.caseFlow',
                  label: 'Case Flow',
                  value: 'CASEFLOW',
                  selected: CASEFLOW
                },
                {
                  $id: 'tasks.filters.communication',
                  label: 'Communication',
                  value: 'COMMUNICATION',
                  selected: COMMUNICATION
                },
                {
                  $id: 'tasks.filters.verifyQc',
                  label: 'Verify/QC',
                  value: 'REVIEW',
                  selected: REVIEW
                },
                {
                  $id: 'tasks.filters.pqFollowUp',
                  label: 'PQ Follow-Up',
                  value: 'PQFOLLOWUP',
                  selected: PQFOLLOWUP
                }
              ]}
            />
          </div>

          <div>
            <h5 className={computedStyles.label}>Task Status: </h5>
            <MultiToggle
              onChange={value =>
                isFilteringTasks
                  ? () => {}
                  : this.updateFiltersRunSearch(value, 'status')
              }
              options={[
                {
                  label: 'Open',
                  value: 'OPEN',
                  selected: OPEN,
                  $id: 'statusOpen'
                },
                {
                  label: 'Cancelled',
                  value: 'CANCELLED',
                  selected: CANCELLED,
                  $id: 'statusCancelled'
                },
                {
                  label: 'Completed',
                  value: 'COMPLETED',
                  selected: COMPLETED,
                  $id: 'statusCompleted'
                }
              ]}
            />
          </div>
        </div>
        {appliedFilters}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(TasksHeader);
