/* eslint import/prefer-default-export:0 */

export const GQL_TYPENAME = '__typename';

export const CASE_SVC_RESPONSE_TYPES = {
  CONFLICT: 'conflict'
};

export const SCHEMA_PATH_FORM = '/form';
export const SCHEMA_PATH_TASKS = '/task';

export const FRAGMENT_NAME_CASE = 'FullCaseWithSubcases';
export const FRAGMENT_NAME_TASK = 'BaseTask';

export const SCHEMA_ID_FORM = 'masterCase';
export const SCHEMA_ID_TASKS = 'task';

export const AUDIT_PATH_CASE = 'case';
export const AUDIT_PATH_TASK = 'task';
