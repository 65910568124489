import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { SCHEMA_ID_FORM } from 'Common/constants';
import * as CaseActions from 'CreateCase/ducks';
import * as NavigationActions from 'Navigation/ducks';
import * as SchemaActions from 'Common/ducks/schema';
import * as LookupActions from 'Lookup/ducks';
import * as SearchBarActions from 'Common/ducks/searchBar';
import LookupController from './controller';

const mapStateToProps = ({
  routerReducer,
  lookupReducer,
  schemaReducer,
  searchBarReducer
}) => ({
  schema: schemaReducer.schemas[SCHEMA_ID_FORM],
  tacticalData: schemaReducer.tacticalData,
  location: routerReducer.location,
  results: lookupReducer.results,
  total: lookupReducer.total,
  searchRun: lookupReducer.searchRun,
  searchBarQuery: searchBarReducer.query,
  filters: searchBarReducer.filters
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...CaseActions,
    ...LookupActions,
    ...SchemaActions,
    ...SearchBarActions,
    ...NavigationActions,
    push
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LookupController);
