import { createAction, handleActions } from 'redux-actions';
import { includes, get } from 'lodash';

import fetchFormSchema from 'api/rest/fetchSchema';
import fetchTacticalData from 'api/rest/fetchTacticalData';
import fetchFragment from 'api/rest/fetchFragment';
import {
  SCHEMA_ID_FORM,
  SCHEMA_ID_TASKS,
  FRAGMENT_NAME_CASE,
  FRAGMENT_NAME_TASK
} from 'Common/constants';
import { mergeMax } from 'Common/utils';
import { getAllowedAffiliates, getRoles } from '../../config/auth';

export const EMIT_SCHEMA_FETCH = 'EMIT_SCHEMA_FETCH';
export const EMIT_SCHEMA_FETCH_FAILURE = 'EMIT_SCHEMA_FETCH_FAILURE';
export const EMIT_TACTICAL_DATA_FETCH = 'EMIT_TACTICAL_DATA_FETCH';
export const EMIT_TACTICAL_DATA_FETCH_SUCCESS =
  'EMIT_TACTICAL_DATA_FETCH_SUCCESS';
export const EMIT_TACTICAL_DATA_FETCH_FAILURE =
  'EMIT_TACTICAL_DATA_FETCH_FAILURE';

export const EMIT_FRAGMENT_FETCH = 'EMIT_FRAGMENT_FETCH';
export const EMIT_FRAGMENT_FETCH_FAILURE = 'EMIT_FRAGMENT_FETCH_FAILURE';
export const EMIT_FRAGMENT_FETCH_SUCCESS = 'EMIT_FRAGMENT_FETCH_SUCCESS';

export const emitSchemaFetchFailure = createAction(EMIT_SCHEMA_FETCH_FAILURE);

export const emitTacticalDataFetchSuccess = createAction(
  EMIT_TACTICAL_DATA_FETCH_SUCCESS,
  (schema, tacticalData) => ({
    schema,
    tacticalData
  })
);

export const emitFormInitializeFailure = createAction(
  EMIT_TACTICAL_DATA_FETCH_FAILURE
);

export const emitFragmentFetchFailure = createAction(
  EMIT_FRAGMENT_FETCH_FAILURE
);

export const emitFragmentFetchSuccess = createAction(
  EMIT_FRAGMENT_FETCH_SUCCESS,
  (type, fragment) => ({
    type,
    fragment
  })
);

export const emitFragmentFetch = (type, fragmentName) => dispatch => {
  dispatch(createAction(EMIT_FRAGMENT_FETCH)());
  return fetchFragment(type, fragmentName)
    .catch(e => dispatch(emitFragmentFetchFailure(e)))
    .then(data => dispatch(emitFragmentFetchSuccess(type, data)));
};

export const emitTacticalDataFetch = schema => async dispatch => {
  const tacticalData = schema.dynamicOptions;
  const requestedTypeIds = [];

  dispatch(createAction(EMIT_TACTICAL_DATA_FETCH)());

  await dispatch(emitFragmentFetch('case', 'FullCaseWithSubcases'));
  await dispatch(emitFragmentFetch('task', 'BaseTask'));

  const userLists = await Promise.all(
    getRoles().reduce((requests, role) => {
      const affiliate = role.match(/access-([a-zA-Z]*)/);
      if (
        affiliate &&
        affiliate[1] &&
        window.regionalAffiliateCodes.includes(affiliate[1].toUpperCase())
      ) {
        const options = {
          documentMeta: {
            documentDataId: 'user-list',
            documentDataType: 'documentDataList',
            tacticalType: 'document-data',
            customUri: `${
              window.trilogyConfig['tactical-data-svc'].uri
            }/affiliate/${affiliate[1].toUpperCase()}`
          }
        };
        return requests.concat(fetchTacticalData(options));
      }
      return requests;
    }, [])
  );
  const dupeSubs = [];
  const userList = userLists.reduce((currArr, affiliateUserListObj) => {
    const affiliateUserList = get(
      affiliateUserListObj,
      'document-data.user-list',
      []
    );
    affiliateUserList.forEach(user => {
      if (!includes(dupeSubs, user.sub)) {
        currArr.push(user);
        dupeSubs.push(user.sub);
      }
    });
    return currArr;
  }, []);

  return Promise.all(
    tacticalData.reduce((requests, option) => {
      const { documentMeta } = option;
      if (
        requestedTypeIds.find(
          ([docType, docId]) =>
            docType === documentMeta.documentDataType &&
            docId === documentMeta.documentDataId
        )
      ) {
        return requests;
      }

      requestedTypeIds.push([
        documentMeta.documentDataType,
        documentMeta.documentDataId
      ]);

      return requests.concat(fetchTacticalData(option));
    }, [])
  )
    .catch(e => {
      console.error(`emitTacticalDataFetch Failed: ${e}`);
      dispatch(emitFormInitializeFailure(e));
    })
    .then(data => {
      dispatch(
        emitTacticalDataFetchSuccess(schema, [
          ...data,
          { 'document-data': { 'user-list': userList } }
        ])
      );
    });
};

export const emitSchemaFetch = type => dispatch => {
  dispatch(createAction(EMIT_SCHEMA_FETCH)());

  return fetchFormSchema(type)
    .catch(e => dispatch(emitSchemaFetchFailure(e)))
    .then(data => dispatch(emitTacticalDataFetch(data)));
};

export const initialState = {
  schemas: {
    /**
     * @typedef {Object} FormSchema
     * @property {Object[]} pages
     */
    [SCHEMA_ID_FORM]: {
      pages: []
    },
    /**
     * @typedef {Object} TasksSchema
     * @property {Object[]} pages
     */
    [SCHEMA_ID_TASKS]: {
      pages: []
    }
  },
  tacticalData: {},
  fragments: {
    [FRAGMENT_NAME_CASE]: '',
    [FRAGMENT_NAME_TASK]: ''
  },
  isFetchingSchema: false,
  isFetchingTacticalData: false,
  isFetchingFragment: false,
  hasCaseAndTaskFragments: false
};

const handlers = {
  [EMIT_SCHEMA_FETCH]: state => ({
    ...state,
    isFetchingSchema: true
  }),

  [EMIT_SCHEMA_FETCH_FAILURE]: state => ({
    ...state,
    isFetchingSchema: false
  }),

  [EMIT_TACTICAL_DATA_FETCH_SUCCESS]: (state, { payload }) => {
    const tacticalData = payload.tacticalData.reduce(
      (merged, json) => mergeMax(merged, json, 2),
      state.tacticalData
    );

    const allowedAffiliates = getAllowedAffiliates(tacticalData);

    const dynamicTacticalData = {
      'allowed-affiliates': allowedAffiliates
    };

    window.affiliateCodes = new Set(
      allowedAffiliates.map(obj => obj.value.toLowerCase())
    );

    return {
      ...state,
      schemas: {
        ...state.schemas,
        [payload.schema.id]: payload.schema
      },
      tacticalData: {
        ...tacticalData,
        'dynamic-tactical-data': dynamicTacticalData
      },
      isFetchingTacticalData: false,
      isFetchingSchema: false
    };
  },

  [EMIT_TACTICAL_DATA_FETCH_FAILURE]: state => ({
    ...state,
    isFetchingTacticalData: false
  }),

  [EMIT_FRAGMENT_FETCH]: state => ({
    ...state,
    isFetchingFragment: true
  }),

  [EMIT_FRAGMENT_FETCH_FAILURE]: state => ({
    ...state,
    isFetchingFragment: false
  }),
  [EMIT_FRAGMENT_FETCH_SUCCESS]: (state, { payload }) => {
    const fragmentName =
      payload.type.toUpperCase() === 'TASK'
        ? FRAGMENT_NAME_TASK
        : FRAGMENT_NAME_CASE;

    const updatedState = {
      ...state,
      isFetchingFragment: false,
      fragments: {
        ...state.fragments,
        [fragmentName]: payload.fragment
      }
    };
    return {
      ...updatedState,
      hasCaseAndTaskFragments:
        updatedState.fragments[FRAGMENT_NAME_CASE] !== '' &&
        updatedState.fragments[FRAGMENT_NAME_TASK] !== ''
    };
  }
};

const reducer = handleActions(handlers, initialState);

export default reducer;
