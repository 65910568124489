import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '../withStyles';
import stylesGenerator from './styles';

const InfoTableRow = ({ computedStyles, children }) => (
  <tr className={computedStyles.base}>{children}</tr>
);

InfoTableRow.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(stylesGenerator)(InfoTableRow);
