import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Route, Switch, Redirect } from 'react-router';

import { CASE_FORM_PATH } from 'CreateCase/constants';
import { TASKS_PATH } from 'Tasks/constants';
import { SEARCH_BASE_PATH } from 'Search/constants';
import { QUEUE_PATH, QUEUE_BASE_PATH, QUEUE_YOUR_CASES } from 'Queue/constants';
import { LOOKUP_PATH } from 'Lookup/constants';
import NavigationContainer from 'Navigation/components/container';
import CreateCaseContainer from 'CreateCase/components/container';
import TasksContainer from 'Tasks/components/container';
import SearchContainer from 'Search/components/container';
import QueueContainer from 'Queue/components/container';
import LookupContainer from 'Lookup/components/container';
import NotFound from 'Common/components/NotFound';
import { history } from 'config';
import AppWrapper from './AppWrapper';

const routes = (
  <NavigationContainer>
    <Switch>
      <Route path={CASE_FORM_PATH} component={CreateCaseContainer} />
      <Route path={SEARCH_BASE_PATH} component={SearchContainer} />
      <Route path={TASKS_PATH} component={TasksContainer} />
      <Route path={QUEUE_PATH} component={QueueContainer} />
      <Route path={LOOKUP_PATH} component={LookupContainer} />
      <Route path="/" exact>
        <Redirect to={{ pathname: `${QUEUE_BASE_PATH}/${QUEUE_YOUR_CASES}` }} />
      </Route>
      <Route component={NotFound} />
      {process.env.BACKEND_ENV === 'sand' ? (
        <Route path="/documentation" component={window.location.reload} />
      ) : null}
    </Switch>
  </NavigationContainer>
);

/*
 * Switch is necessary to ensure that Route components are properly updated upon route changes.
 * The heirarchy of root MUST be ConnectedRouter > Switch > NavigationContainer.
 * Without Switch sitting in the middle, props.match won't update properly!
 */
const Root = () => (
  <AppWrapper>
    <ConnectedRouter history={history}>
      <Switch>{routes}</Switch>
    </ConnectedRouter>
  </AppWrapper>
);

export default Root;
