/* eslint no-console:0 */
import { AUDIT_SVC_API_MAP } from 'config/values';

const fetchAuditData = async (type, id) => {
  const API = AUDIT_SVC_API_MAP[type];
  const fetchOptions = {
    method: 'GET',
    headers: { 'x-api-key': API.apiKey }
  };

  try {
    const uri = `${API.uri}/${id}`;
    const response = await fetch(uri, fetchOptions);

    return await response.json();
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export default fetchAuditData;
