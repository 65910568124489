import { generateCSS } from 'Common/components/Form';

const styleGenerator = theme => ({
  header: generateCSS({
    top: '0',
    height: '50px',
    boxShadow: 'none',
    padding: '0 9%',
    '@media(max-width:1440px)': {
      padding: '0 6%'
    },
    '@media(max-width:1200px)': {
      padding: '0 3%'
    },
    position: 'relative',
    zIndex: 20,
    backgroundColor: theme.colors.header,
    '> .pt-popover-target': {
      '> .pt-navbar-group': {
        padding: '13px 0',
        height: 'auto',
        '> .pt-button': {
          minHeight: 'auto' // Keeps avatar aligned vertically
        }
      }
    }
  }),
  logo: generateCSS({
    width: '110px',
    height: '22px',
    objectFit: 'contain',
    marginRight: '40px',
    '@media(max-width:768px)': {
      marginRight: '30px'
    }
  }),
  headerText: generateCSS({
    fontSize: '18px',
    fontWeight: '300',
    fontFamily: 'Calibri',
    color: theme.colors.base,
    textTransform: 'capitalize',
    '@media(max-width:768px)': {
      fontSize: '14px'
    }
  }),
  headerSpacing: generateCSS({
    padding: '15px 20px 12px 20px',
    display: 'block',
    '&:hover': {
      backgroundColor: theme.colors.active
    },
    '@media(max-width:768px)': {
      padding: '16px',
      marginRight: '0px'
    }
  }),
  link: generateCSS({
    outline: 'none',
    textDecoration: 'none',
    color: theme.colors.base,
    '&:hover': {
      backgroundColor: theme.colors.active
    }
  }),
  selectedNavTab: generateCSS({
    backgroundColor: theme.colors.active
  }),
  tabItems: generateCSS({
    boxShadow: 'none',
    '> div': {
      top: '12px',
      '@media(max-width:768px)': {
        top: '16px'
      },
      borderRadius: '0',
      padding: '15px',
      backgroundColor: theme.colors.active
    }
  }),
  tabItem: generateCSS({
    padding: '15px 0 15px 15px',
    display: 'inline-flex',
    borderRadius: '0',
    backgroundColor: theme.colors.active,
    '&:hover': {
      textDecoration: 'underline'
    },
    '> li:hover': {
      backgroundColor: 'red'
    }
  }),
  logout: generateCSS({
    marginLeft: '10px',
    outline: 'none',
    textDecoration: 'none'
  }),
  logoutText: generateCSS({
    ...theme.fonts.default,
    fontSize: '14px',
    color: theme.colors.base
  }),
  banner: generateCSS({
    fontSize: '14px',
    color: 'white',
    backgroundColor: '#FC7668',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '3px'
  })
});

export default styleGenerator;
