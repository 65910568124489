import { get, capitalize, cloneDeep, each, sortBy, uniqBy, map } from 'lodash';
import { DROPDOWN_FILTERS } from 'Queue/constants';

/* eslint import/prefer-default-export:0 */
export const buildDropdownFilterOptions = (tacticalData, currentPage) => {
  const ddFilters = cloneDeep(DROPDOWN_FILTERS[currentPage]);

  each(ddFilters, (filter, i) => {
    const isCountry = filter.type === 'country' || filter.type === 'aeCountry';
    const isOwner =
      filter.type === 'aeOwner' ||
      filter.type === 'miOwner' ||
      filter.type === 'pqOwner' ||
      filter.type === 'caseOwner';

    if ((filter.type === 'caseCreators' || isOwner) && !filter.options.length) {
      ddFilters[i].options = get(tacticalData, 'document-data.user-list', [])
        .map(u => ({
          value: u.sub,
          label: `${capitalize(u.sn || '')}, ${capitalize(u.fn.trim() || '')}`
        }))
        .sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0));
    } else if (isCountry && !filter.options.length) {
      ddFilters[i].options = get(
        tacticalData,
        'document-data.country-options',
        []
      );
    } else if (
      (filter.type === 'masterCaseProduct' || filter.type === 'product') &&
      !filter.options.length
    ) {
      ddFilters[i].options = get(
        tacticalData,
        'document-data.mastercase-product-options',
        []
      );
    } else if (filter.type === 'suspectProduct' && !filter.options.length) {
      ddFilters[i].options = sortBy(
        uniqBy(
          map(
            get(tacticalData, 'product-data.ae-product-data.products', []),
            productGroup => productGroup.preferredProductName
          ),
          product => product.label
        ),
        product => product.label
      );
    } else if (filter.type === 'miProduct' && !filter.options.length) {
      ddFilters[i].options = get(
        tacticalData,
        'document-data.mi-options.abbvie_product',
        []
      );
    } else if (filter.type === 'pqProduct' && !filter.options.length) {
      ddFilters[i].options = get(
        tacticalData,
        'document-data.pq-options.marketed_name',
        []
      );
    } else if (filter.type === 'methodOfReceipt' && !filter.options.length) {
      ddFilters[i].options = get(
        tacticalData,
        'document-data.mastercase-options.method_of_receipt',
        []
      );
    }
  });
  return ddFilters;
};

export const getDynamicFilterOptions = (tacticalData = {}, source = '') => {
  let filterOptions = [];

  if (source === 'document-data.user-list') {
    filterOptions = get(tacticalData, 'document-data.user-list', [])
      .map(u => ({
        value: u.sub,
        label: `${capitalize(u.sn || '')}, ${capitalize(u.fn.trim() || '')}`
      }))
      .sort((a, b) => a.label.charCodeAt(0) - b.label.charCodeAt(0));
  } else if (
    source === 'product-data.ae-product-data.products.preferredProductName'
  ) {
    filterOptions = sortBy(
      uniqBy(
        map(
          get(tacticalData, 'product-data.ae-product-data.products', []),
          productGroup => productGroup.preferredProductName
        ),
        product => product.label
      ),
      product => product.label
    );
  } else if (source === 'product-data.pq-product-data.products.listNumber') {
    filterOptions = sortBy(
      uniqBy(
        map(
          get(tacticalData, 'product-data.pq-product-data.products', []),
          productGroup => productGroup.listNumber
        ),
        product => product.label
      ),
      product => product.label
    );
  } else if (source === 'product-data.pq-product-data.products.marketedName') {
    filterOptions = sortBy(
      uniqBy(
        map(
          get(tacticalData, 'product-data.pq-product-data.products', []),
          productGroup => productGroup.marketedName
        ),
        product => product.label
      ),
      product => product.label
    );
  } else {
    filterOptions = get(tacticalData, source, []);
  }

  return filterOptions;
};
