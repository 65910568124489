/* eslint no-console:0 */
import { client } from 'config/apollo';
import searchProduct from './queries/case/searchProduct';

const fetchProducts = lotNumber =>
  client
    .query({
      query: searchProduct(lotNumber),
      variables: {
        q: { batch: lotNumber }
      },
      fetchPolicy: 'network-only'
    })
    .then(data => {
      const thawed = Object.isFrozen(data)
        ? JSON.parse(JSON.stringify(data))
        : data;
      return thawed.data.searchProducts.hits;
    });
export default fetchProducts;
