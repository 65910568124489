import { get, isEmpty } from 'lodash';
import {
  generateCSS,
  reverseText,
  determineInputWidth,
  genDisabledStyles,
  genInvalidBorder,
  genErrorStyles,
  genLabelStyles,
  INPUT_SIDE_PADDING
} from '../index';

const styleGenerator = (theme, props) => {
  const calculatedWidth = determineInputWidth(props);
  const disabledStyles = genDisabledStyles(theme);
  const invalidStyles = genInvalidBorder(theme);
  const errorStyles = genErrorStyles(theme);
  const labelStyles = genLabelStyles(theme, props);

  return {
    base: generateCSS({
      position: 'relative',
      width: '100%'
    }),

    input: generateCSS({
      padding: `4px ${INPUT_SIDE_PADDING / 2}px 0`,
      display: 'block',
      margin: '0 0',
      ...calculatedWidth,
      ...props.styles,
      ...theme.fonts.input,
      ...disabledStyles,
      borderRadius: '0',
      border: `2px solid ${theme.colors.primary}`,
      boxShadow: 'none',
      ':focus': {
        border: `2px solid ${theme.colors.active}`
      },
      '::selection': {
        outline: 'none'
      },
      '::placeholder': {
        padding: '0 6px',
        color: theme.colors.quinary,
        fontSize: '18px'
      },
      ...reverseText(get(props, 'config.locale')),
      ...(isEmpty(props.errors) ? {} : invalidStyles)
    }),

    helpText: generateCSS({
      display: 'block',
      border: 'none',
      color: theme.colors.quaternary,
      ...theme.fonts.helpText
    }),

    ...labelStyles,

    errors: generateCSS({
      margin: '0',
      ...theme.fonts.validationError,
      ...errorStyles
    })
  };
};

export default styleGenerator;
