import { compose } from 'recompose';
import { mapValues } from 'lodash';

import withInputWrapper from './withInputWrapper';
import withDataWrapper from './withDataWrapper';

import CheckboxGroup from './CheckboxGroup';
import Checkbox from './Checkbox';
import DateInput from './DateInput';
import DateRangeInput from './DateRangeInput';
import LabelValue from './LabelValue';
import PlainText from './PlainText';
import FileInput from './FileInput';
import RadioGroup from './RadioGroup';
import Radio from './Radio';
import Select from './Select';
import SmartButton from './SmartButton';
import SimpleButton from './SimpleButton';
import Button from './Button';
import TextInput from './TextInput';
import TextArea from './TextArea';
import InputGroup from './InputGroup';

const withAllWrappers = {
  CheckboxGroup,
  DateInput,
  DateRangeInput,
  FileInput,
  LabelValue,
  PlainText,
  RadioGroup,
  Radio,
  Select,
  SmartButton,
  SimpleButton,
  Button,
  TextInput,
  TextArea
};

// Put components here that need to be wired up to data, but not wrapped in a special layout
const withDataWrapperOnly = {
  Checkbox,
  InputGroup
};

export default {
  ...mapValues(withAllWrappers, component =>
    compose(withDataWrapper, withInputWrapper)(component)
  ),
  ...mapValues(withDataWrapperOnly, component => withDataWrapper(component))
};
