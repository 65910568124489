import React from 'react';
import PropTypes from 'prop-types';
import { get, template } from 'lodash';

import { getUserName } from 'Common/utils';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

import Label from '../Label';

class LabelValue extends Label {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    id: PropTypes.string,
    valueTemplate: PropTypes.string,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      label: PropTypes.object.isRequired,
      value: PropTypes.object.isRequired
    }).isRequired,
    onClick: PropTypes.func,
    valueStyles: PropTypes.objectOf(PropTypes.string)
  };

  static defaultProps = {
    ...formElementDefaultProps,
    id: '',
    onChange: () => {},
    valueTemplate: '',
    valueStyles: null,
    value: null
  };

  handleClick = () => {
    const { statePath, disabled, data } = this.props;

    // allows onClick to work for specific statePaths
    const exception = statePath === 'id' || '/clonedFrom'; // || any other exceptions can be added here

    if (this.props.onClick && (!(disabled || data.isReadOnly) || exception)) {
      this.props.onClick(statePath);
    }
  };

  render() {
    const {
      $id,
      schemaPath,
      id,
      label,
      computedStyles,
      valueTemplate,
      value,
      valueStyles
    } = this.props;

    const users = get(this.props.data, 'document-data.user-list', []);
    let templateResult = '';
    if (valueTemplate) {
      const compiled = template(valueTemplate);
      templateResult = compiled({ ...this.props, users, getUserName });
    }

    return (
      <span
        id={id}
        key={label}
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        {label
          ? [
              <span
                className={computedStyles.label}
                data-id={`${$id}.label`}
                key={id}
              >
                {label}
              </span>,
              /* These are inherited from the `Label` component */
              this.wasUpdatedThisVersion() ? this.renderUpdateTag() : null,
              this.needsReconciliation() ? this.renderReconciliationTag() : null
            ]
          : null}
        <span
          className={valueStyles || computedStyles.value}
          onClick={this.handleClick}
          data-id={`${$id}.value`}
          role="button"
        >
          {templateResult || value}
        </span>
      </span>
    );
  }
}

export default withStyles(stylesGenerator)(LabelValue);
