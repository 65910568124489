/* eslint no-console:0 */
import { filter, get, uniq } from 'lodash';

import { client, taskClient } from 'config/apollo';
import { emitFetchTasksSuccess } from 'Tasks/ducks';
import getTasksQuery from 'api/graphql/queries/task/getTasksQuery';
import searchCase from 'api/graphql/queries/case/searchCase';
import { mergeCaseIntoTasks, reduceToIdObject } from 'Tasks/utils';
import { FRAGMENT_NAME_CASE, FRAGMENT_NAME_TASK } from 'Common/constants';

const fetchTasksQuery = fragments => options => async dispatch => {
  const { q, size: size = 20, from: offset = 0 } = options;
  const variables = {
    q,
    size,
    from: offset
  };

  try {
    const { data } = await taskClient.query({
      query: getTasksQuery(fragments[FRAGMENT_NAME_TASK]),
      variables
    });
    const count = data.essearch.total;
    const frozenTasks = data.essearch.hits || [];
    const tasks = Object.isFrozen(frozenTasks)
      ? JSON.parse(JSON.stringify(frozenTasks))
      : frozenTasks;

    // Because we never got designs for a proper error message
    if (tasks.length === 0) console.warn('No tasks returned from task-svc');

    const caseIds = filter(
      uniq(tasks.map(task => get(task, 'form.base.caseId', null))),
      val => val !== null
    );

    const caseData = await client.query({
      query: searchCase(fragments[FRAGMENT_NAME_CASE]),
      variables: {
        q: {
          id: caseIds
        },
        size: caseIds.length,
        from: 0
      }
    });

    if (caseData) {
      // No need to unfreeze after reduce
      const cases = caseData.data.searchCase.hits.reduce(reduceToIdObject, {});
      dispatch(emitFetchTasksSuccess(mergeCaseIntoTasks(tasks, cases), count));
    } else {
      dispatch(emitFetchTasksSuccess(tasks, count));
    }
  } catch (e) {
    console.error(e);
    dispatch(emitFetchTasksSuccess([]));
  }
};

export default fetchTasksQuery;
