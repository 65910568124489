module.exports = {
  description: 'task edit view',
  component: 'Section',
  referencedProperties: {
    'layout.display': {
      relative: true,
      schemaPath: '../../',
      $if: [{ isEditing: { $eq: true } }, { $set: 'flex' }],
      $elseIf: [
        { isComplete: { $eq: true } },
        { isEditing: { $ne: true } },
        { $set: 'flex' }
      ],
      $else: { $set: 'none' }
    }
  },
  elements: [
    {
      component: 'Section',
      layout: {
        width: '33%',
        display: 'flex',
        flexDirection: 'column'
      },
      elements: [
        {
          component: 'PlainText',
          label: 'Case ID',
          pristine: true,
          referencedProperties: {
            value: {
              relative: true,
              $set: {
                $or: [
                  { $get: 'form.base.subcaseId' },
                  { $get: 'form.base.caseId' }
                ]
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'AER Number',
          statePath: 'form.base.aerNumber',
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $or: [
                    { $eq: 'CASEFLOW' },
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'REVIEW' },
                    { $eq: 'PVQUERY' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Argus Number',
          statePath: 'form.base.argusNumber',
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $or: [
                    { $eq: 'CASEFLOW' },
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'REVIEW' },
                    { $eq: 'PVQUERY' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'TextInput',
          label: 'Global ID',
          statePath: 'form.base.globalId',
          validations: {
            pattern: {
              constraint: '^.*\\S.*$',
              errorMessage: ''
            }
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $or: [
                    { $eq: 'CASEFLOW' },
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'REVIEW' },
                    { $eq: 'PVQUERY' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'TextInput',
          label: 'Description',
          statePath: 'form.base.description',
          validations: {
            required: {
              constraint: true,
              errorMessage: 'This field is required'
            },
            pattern: {
              constraint: '^.*\\S.*$',
              errorMessage: ''
            }
          }
        },
        {
          component: 'Select',
          label: 'Follow-Up Type',
          statePath: 'form.base.followUp.type',
          options: [],
          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            }
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'PQFOLLOWUP'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            options: {
              schemaPath: './',
              $get: 'data.document-data.task-options.pq_follow_up_type'
            }
          }
        },
        {
          component: 'TextArea',
          label: 'Comments',
          statePath: 'form.base.followUp.comments',
          validations: {
            required: {
              constraint: false,
              errorMessage: ''
            }
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'PQFOLLOWUP'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            'validations.required.constraint': {
              schemaPath: '../../../../',
              relative: true,
              $if: [{ isComplete: { $eq: true } }, { $set: true }],
              $else: {
                $set: false
              }
            }
          }
        },
        {
          component: 'TextArea',
          label: 'Additional Notes',
          statePath: 'form.base.additionalNotes',
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $or: [{ $eq: 'CASEFLOW' }, { $eq: 'REVIEW' }]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'DateInput',
          label: 'Due Date',
          statePath: 'form.base.dueDate',
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'PVQUERY'
                },
                { $set: 'none' }
              ],
              $else: {
                $set: 'block'
              }
            },
            value: {
              relative: true,
              $get: 'form.base.dueDate'
            }
          }
        },
        {
          id: 'expectedDeliveryDate',
          label: 'Expected Delivery Date',
          statePath: 'form.base.expectedDeliveryDate',
          component: 'DateInput',
          layout: {
            width: '100%'
          },
          validations: {
            required: {
              constraint: true,
              errorMessage: ''
            },
            date: {
              constraint: 'full',
              errorMessage: 'Not a valid full date'
            }
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              $if: [
                { 'form.additional.type': { $eq: 'PVQUERY' } },
                {
                  'form.base.pregnancyRelated': {
                    $eq: true
                  }
                },
                // Following the same logic as the `pregnancyRelated` checkbox. However,
                // because this field occurs BEFORE the checkbox, it can't simply look at the
                // value of the checkbox. That's why this query is identical to the checkboxes
                // own `layout.display` query
                { id: { $eq: '$NEW_TASK' } },
                { '$this.wasVisible': { $eq: false } },
                { 'form.base.expectedDeliveryDate': { $eq: null } },
                {
                  '$this.data.trilogyCase.subcases.adverseEvent.tracking.case_type': {
                    $count: {
                      $filter: {
                        value: { $eq: 'pregnancy_related' }
                      }
                    }
                  }
                },
                { $set: 'block' }
              ],
              $elseIf: [
                { 'form.additional.type': { $eq: 'PVQUERY' } },
                {
                  'form.base.pregnancyRelated': {
                    $eq: true
                  }
                },
                {
                  $set: 'block'
                }
              ],
              $else: { $set: 'none' }
            },
            value: {
              relative: true,
              $if: [
                { '$this.wasVisible': { $eq: false } }, // only setting value automatically if WAS visible
                { '$this.value': { $eq: null } }, // only set to model value if NULL
                { $get: 'form.base.pregnancyRelated' },
                {
                  '$this.data.trilogyCase': {
                    $get:
                      'subcases.adverseEvent.patient.information.expected_delivery_date'
                  }
                }
              ],
              $elseIf: [
                { 'form.additional.type': { $eq: 'PVQUERY' } },
                { $get: 'form.base.pregnancyRelated' },
                { $get: 'form.base.expectedDeliveryDate' }
              ],
              $else: { $set: null }
            }
          }
        },
        {
          component: 'Select',
          label: 'Assignee',
          statePath: 'form.base.assignee',
          hasUserList: true,
          options: [],
          referencedProperties: {
            options: {
              schemaPath: './',
              relative: true,
              $get: 'data.userList'
            }
          }
        },
        {
          component: 'Checkbox',
          label: 'Pregnancy Related?',
          statePath: 'form.base.pregnancyRelated',
          options: [{ value: 'pregnancyRelated' }],
          layout: {
            '> input': {
              order: '2'
            },
            '> span, div': {
              order: '1',
              margin: '3px 3px 3px 6px'
            }
          },
          referencedProperties: {
            value: {
              relative: true,
              $if: [
                { 'form.additional.type': { $eq: 'PVQUERY' } },
                // Only auto-check this if it's a new task
                { id: { $eq: '$NEW_TASK' } },
                { '$this.wasVisible': { $eq: false } },
                { 'form.base.pregnancyRelated': { $eq: null } },
                {
                  '$this.data.trilogyCase.subcases.adverseEvent.tracking.case_type': {
                    $count: {
                      $filter: {
                        value: { $eq: 'pregnancy_related' }
                      }
                    }
                  }
                },
                {
                  $set: true
                }
              ],
              $elseIf: [
                { 'form.additional.type': { $eq: 'PVQUERY' } },
                { $get: 'form.base.pregnancyRelated' }
              ],
              $else: { $set: null }
            },
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'PVQUERY'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Latest Received Date',
          isDate: true,
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'PVQUERY'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            value: {
              relative: true,
              $set: {
                $or: [
                  { $get: 'form.base.display.last_received_date' },
                  'Not Specified'
                ]
              }
            }
          }
        }
      ]
    },
    {
      component: 'Section',
      layout: {
        width: '66%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column'
      },
      elements: [
        {
          label: 'Type',
          component: 'Select',
          statePath: 'form.additional.type',
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'id',
              $if: [{ $eq: '$NEW_TASK' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          },
          options: [
            {
              label: 'Case Flow',
              value: 'CASEFLOW'
            },
            {
              label: 'Communication',
              value: 'COMMUNICATION'
            },
            {
              label: 'Verify/QC',
              value: 'REVIEW'
            },
            {
              label: 'PV Query',
              value: 'PVQUERY'
            },
            {
              label: 'PQ Follow-Up',
              value: 'PQFOLLOWUP'
            }
          ],
          layout: {
            display: 'block'
          }
        },
        {
          component: 'InputGroup',
          statePath: 'form.additional.attempts.attempt',
          label: 'New Attempt',
          addButtonLabel: 'Add another attempt',
          collapsible: true,
          multiple: true,
          length: 1,
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $or: [
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'PVQUERY' },
                    { $eq: 'PQFOLLOWUP' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            label: {
              relative: true,
              $if: [
                {
                  $this: {
                    '../../value.form.additional': {
                      type: {
                        $eq: 'PVQUERY'
                      }
                    }
                  }
                },
                {
                  $if: [
                    { $get: 'attemptNumber' },
                    { $get: 'queryDueDate' },
                    {
                      $concat: [
                        'Attempt ',
                        { $get: 'attemptNumber' },
                        ', ',
                        '<%=moment("',
                        { $date: { $get: 'queryDueDate' } },
                        '", moment.ISO_8601).format("DD MMM YYYY")%>'
                      ]
                    }
                  ],
                  $elseIf: [
                    {
                      $or: [
                        {
                          $and: [
                            { $get: 'attemptNumber' },
                            {
                              $concat: ['Attempt ', { $get: 'attemptNumber' }]
                            }
                          ]
                        },
                        {
                          $and: [
                            { $get: 'queryDueDate' },
                            {
                              $concat: [
                                '<%=moment("',
                                { $date: { $get: 'queryDueDate' } },
                                '", moment.ISO_8601).format("DD MMM YYYY")%>'
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  $else: {
                    $set: 'New Attempt'
                  }
                }
              ],
              $else: {
                $if: [
                  { $get: 'attemptNumber' },
                  { $get: 'queryDate' },
                  {
                    $concat: [
                      'Attempt ',
                      { $get: 'attemptNumber' },
                      ', ',
                      '<%=moment("',
                      { $date: { $get: 'queryDate' } },
                      '", moment.ISO_8601).format("DD MMM YYYY")%>'
                    ]
                  }
                ],
                $elseIf: [
                  {
                    $or: [
                      {
                        $and: [
                          { $get: 'attemptNumber' },
                          {
                            $concat: ['Attempt ', { $get: 'attemptNumber' }]
                          }
                        ]
                      },
                      {
                        $and: [
                          { $get: 'queryDate' },
                          {
                            $concat: [
                              '<%=moment("',
                              { $date: { $get: 'queryDate' } },
                              '", moment.ISO_8601).format("DD MMM YYYY")%>'
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                $else: {
                  $set: 'New Attempt'
                }
              }
            },
            collapsed: {
              schemaPath: './',
              relative: true,
              $if: [
                { collapsed: { $eq: null } },
                { last: { $eq: false } },
                { $set: true }
              ],
              $else: {
                $get: 'collapsed'
              }
            }
          },
          elements: [
            {
              component: 'Select',
              statePath: 'attemptNumber',
              options: [
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 }
              ],
              label: 'Attempt Number',
              layout: {
                width: '50%'
              },
              referencedProperties: {
                value: {
                  schemaPath: './',
                  relative: true,
                  $set: {
                    $if: [
                      { '../value/attemptNumber': { $ne: null } },
                      { $get: '../value/attemptNumber' }
                    ],
                    $elseIf: [
                      { '../attemptNumberOverride': { $ne: null } },
                      { $get: '../attemptNumberOverride' }
                    ]
                  }
                },
                'validations.required.constraint': {
                  schemaPath: '../../../../../../',
                  relative: true,
                  $if: [{ isComplete: { $eq: true } }, { $set: true }],
                  $else: {
                    $set: false
                  }
                },
                'layout.marginRight': {
                  relative: true,
                  statePath: '../../type',
                  $if: [{ $eq: 'PQFOLLOWUP' }, { $set: '50%' }],
                  $else: { $set: '0' }
                }
              },
              validations: {
                required: {
                  errorMessage: 'An Attempt Number is required',
                  constraint: false
                }
              }
            },
            {
              component: 'DateInput',
              label: 'Query Due Date',
              statePath: 'queryDueDate',
              layout: {
                width: '50%'
              },
              validations: {
                required: {
                  constraint: true,
                  errorMessage: 'This field is required'
                },
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date is required'
                }
              },
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'PVQUERY'
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            },
            {
              component: 'DateInput',
              label: 'Query Date',
              statePath: 'queryDate',
              layout: {
                width: '50%'
              },
              referencedProperties: {
                label: {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'COMMUNICATION'
                    },
                    { $set: 'Attempt Date' }
                  ],
                  $else: {
                    $set: 'Query Date'
                  }
                },
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $or: [{ $eq: 'COMMUNICATION' }, { $eq: 'PVQUERY' }]
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                },
                'validations.required.constraint': {
                  schemaPath: '../../../../../../',
                  relative: true,
                  $if: [{ isComplete: { $eq: true } }, { $set: true }],
                  $else: {
                    $set: false
                  }
                }
              },
              validations: {
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date is required'
                },
                required: {
                  errorMessage: 'A Query Date is required',
                  constraint: false
                }
              }
            },
            {
              component: 'DateInput',
              label: 'Follow-Up Date',
              statePath: 'followUp.date',
              layout: {
                width: '50%'
              },
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'PQFOLLOWUP'
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                },
                'validations.required.constraint': {
                  schemaPath: '../../../../../../',
                  relative: true,
                  $if: [{ isComplete: { $eq: true } }, { $set: true }],
                  $else: {
                    $set: false
                  }
                }
              },
              validations: {
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date is required'
                },
                required: {
                  errorMessage: 'A Follow-Up Date is required',
                  constraint: false
                }
              }
            },
            {
              component: 'TextInput',
              label: 'Communication Recipient',
              statePath: 'recipient.name',
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'COMMUNICATION'
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            },
            {
              component: 'Select',
              label: 'Communication Method',
              statePath: 'method',
              options: [
                { label: 'Phone', value: 'PHONE' },
                { label: 'Email', value: 'EMAIL' },
                { label: 'Letter', value: 'LETTER' },
                { label: 'Fax', value: 'FAX' }
              ],
              layout: {
                width: '50%'
              },
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $or: [
                        { $eq: 'COMMUNICATION' },
                        { $eq: 'PVQUERY' },
                        { $eq: 'PQFOLLOWUP' }
                      ]
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            },
            {
              component: 'TextArea',
              label: 'Comments',
              statePath: 'comment',
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'COMMUNICATION'
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            },
            {
              component: 'Select',
              label: 'Reply Received?',
              statePath: 'reply.replyReceived',
              options: [
                { label: 'Yes', value: 'YES' },
                { label: 'No', value: 'NO' }
              ],
              layout: {
                width: '50%'
              },
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $or: [{ $eq: 'PVQUERY' }, { $eq: 'PQFOLLOWUP' }]
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                },
                'validations.required.constraint': {
                  schemaPath: '../../../../../../',
                  relative: true,
                  $if: [{ isComplete: { $eq: true } }, { $set: true }],
                  $else: {
                    $set: false
                  }
                }
              },
              validations: {
                required: {
                  errorMessage: 'A selection is required',
                  constraint: false
                }
              }
            },
            {
              component: 'InputGroup',
              label: '',
              addButtonLabel: 'Add another',
              statePath: 'letters.letter',
              collapsible: false,
              multiple: true,
              length: 1,
              layout: {
                width: '50%'
              },
              elements: [
                {
                  component: 'Select',
                  label: 'Letter',
                  statePath: 'type',
                  layout: {
                    width: '100%'
                  },
                  options: [],
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.task-options.pv_query_letter'
                    }
                  }
                }
              ],
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: '../../type',
                  $if: [
                    {
                      $eq: 'PVQUERY'
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              }
            },
            {
              component: 'DateInput',
              statePath: 'reply.actualReplyDate',
              label: 'Actual Reply Date',
              layout: {
                width: '50%'
              },
              validations: {
                date: {
                  constraint: 'full',
                  errorMessage: 'Full date required'
                },
                required: {
                  errorMessage: 'An Actual Reply Date is required',
                  constraint: false
                },
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              },
              referencedProperties: {
                'layout.display': {
                  relative: true,
                  statePath: 'reply.replyReceived',
                  $if: [{ $eq: 'YES' }, { $set: 'block' }],
                  $else: { $set: 'none' }
                },
                'validations.required.constraint': {
                  schemaPath: '../../../../../../',
                  relative: true,
                  $if: [{ isComplete: { $eq: true } }, { $set: true }],
                  $else: {
                    $set: false
                  }
                },
                value: {
                  relative: true,
                  $if: [
                    { 'reply.replyReceived': { $ne: 'YES' } },
                    { $set: null }
                  ],
                  $else: {
                    $get: 'reply.actualReplyDate'
                  }
                }
              }
            }
          ]
        },
        {
          component: 'TextArea',
          label: 'Feedback',
          statePath: 'form.additional.feedback',
          styles: {
            overflowX: 'hidden'
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'REVIEW'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'TextInput',
          label: 'Requester',
          statePath: 'form.additional.requester',
          required: true,
          validations: {
            required: {
              constraint: true,
              errorMessage: 'This field is required'
            },
            pattern: {
              constraint: '^.*\\S.*$',
              errorMessage: ''
            }
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: 'form.additional.type',
              $if: [
                {
                  $eq: 'REVIEW'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        }
      ]
    }
  ]
};
