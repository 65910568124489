import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SimpleButton, PropTypeSafety } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import { QUEUE_BASE_PATH, QUEUE_YOUR_CASES } from 'Queue/constants';

class SameEditorWarningModal extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      push: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    modalName: 'SameEditorWarningModal'
  };

  DEFAULT_PAGE = `${QUEUE_BASE_PATH}/${QUEUE_YOUR_CASES}`;

  handleProceed = () => {
    const { actions } = this.props;
    actions.emitModalContentClear();
    actions.push(this.DEFAULT_PAGE);
  };

  render() {
    return (
      <PropTypeSafety>
        <span className={modalStyles.title}>
          YOU ARE ALREADY EDITING THIS CASE
        </span>
        <p>
          The case you are attempting to access is currently being edited by you
          on a separate browser tab.
        </p>
        <p>
          To apply updates to this case, please access using the already opened
          browser tab.
        </p>
        <div className={modalStyles.buttonsContainerSingle}>
          <SimpleButton onClick={this.handleProceed} primary>
            GO TO YOUR CASES
          </SimpleButton>
        </div>
      </PropTypeSafety>
    );
  }
}

export default SameEditorWarningModal;
