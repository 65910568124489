import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@blueprintjs/core';
import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

class ColumnSelectors extends React.PureComponent {
  static propTypes = {
    selectedStandardColumnsSize: PropTypes.number.isRequired,
    moveDesiredColumns: PropTypes.func.isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    moveStandardColumns: PropTypes.func.isRequired,
    selectedDesiredColumnsSize: PropTypes.number.isRequired
  };
  render() {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.columnSelector}>
        <button
          disabled={this.props.selectedStandardColumnsSize <= 0}
          className={computedStyles.columnSelectorArrow}
          style={{
            cursor:
              this.props.selectedStandardColumnsSize <= 0
                ? 'not-allowed'
                : 'pointer'
          }}
          onClick={this.props.moveStandardColumns}
        >
          <Icon iconName="arrow-right" iconSize="inherit" />
        </button>
        <button
          disabled={this.props.selectedDesiredColumnsSize <= 0}
          className={computedStyles.columnSelectorArrow}
          style={{
            cursor:
              this.props.selectedDesiredColumnsSize <= 0
                ? 'not-allowed'
                : 'pointer'
          }}
          onClick={this.props.moveDesiredColumns}
        >
          <Icon iconName="arrow-left" iconSize="inherit" />
        </button>
      </div>
    );
  }
}
export default withStyles(stylesGenerator)(ColumnSelectors);
