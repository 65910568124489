const patientFields = [
  {
    statePath: 'patient.patient[0].firstName',
    label: 'First Name',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].lastName',
    label: 'Last Name',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].dob',
    label: 'Date of Birth',
    component: 'DateInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].phone[0].number',
    label: 'Phone Number',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].line1',
    label: 'Address Line 1',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].line2',
    label: 'Address Line 2',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'patient.patient[0].contact_info[0].city',
    label: 'City',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].state',
    label: 'State/Province/Region',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].postal_code',
    label: 'Postal/Zip Code',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].contact_info[0].country',
    label: 'Country',
    component: 'Select',
    optionsPath: 'document-data.country-options'
  }
];

const primaryPatientFields = [
  {
    statePath: 'patient.patient[0].lastName',
    label: 'Last Name',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'patient.patient[0].dob',
    label: 'Date of Birth',
    component: 'DateInput',
    required: false
  }
];

const primaryPatientFieldsRequired = [
  {
    statePath: 'patient.patient[0].lastName',
    label: 'Last Name',
    component: 'TextInput'
  },
  {
    statePath: 'patient.patient[0].dob',
    label: 'Date of Birth',
    component: 'DateInput'
  }
];

const contactFields = [
  {
    statePath: 'contacts.contact[].type',
    label: 'Type of Contact',
    component: 'Select',
    optionsPath: 'document-data.mastercase-options.type_of_contact'
  },
  {
    statePath: 'productPath.complaint.processing.shippingInfo[0].deaNumber',
    label: 'DEA Number',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'contacts.contact[].name.first',
    label: 'First Name',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].name.last',
    label: 'Last Name',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].line1',
    label: 'Address (Line 1)',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].line2',
    label: 'Address (Line 2)',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'contacts.contact[].address[0].city',
    label: 'City',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].state',
    label: 'State',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].postal_code',
    label: 'Postal/Zip Code',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].country',
    label: 'Country',
    component: 'Select',
    optionsPath: 'document-data.country-options'
  },
  {
    statePath: 'contacts.contact[].phone[0].number',
    label: 'Phone Number',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].organization',
    label: 'Organization/Company',
    component: 'TextInput'
  },
  {
    statePath: 'productPath.complaint.processing.shippingInfo[0].accountNumber',
    label: 'Account Number',
    component: 'TextInput'
  }
];

const pharmacyFields = [
  {
    statePath: 'contacts.contact[].type',
    label: 'Type of Contact',
    component: 'Select',
    optionsPath: 'document-data.mastercase-options.type_of_contact'
  },
  {
    statePath: 'productPath.complaint.processing.shippingInfo[0].attention',
    label: 'ATTN:',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'contacts.contact[].name.first',
    label: 'First Name',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].name.last',
    label: 'Last Name',
    component: 'TextInput'
  },
  {
    statePath: 'productPath.complaint.processing.shippingInfo[0].accountNumber',
    label: 'Account Number',
    component: 'TextInput'
  },
  {
    statePath: 'productPath.complaint.processing.shippingInfo[0].deaNumber',
    label: 'DEA Number',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'contacts.contact[].organization',
    label: 'Name of Pharmacy or HCP office',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].line1',
    label: 'Address (Line 1)',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].line2',
    label: 'Address (Line 2)',
    component: 'TextInput',
    required: false
  },
  {
    statePath: 'contacts.contact[].address[0].city',
    label: 'City',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].state',
    label: 'State/Province/Region',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].address[0].postal_code',
    label: 'Postal/Zip Code',
    component: 'TextInput'
  },
  {
    statePath: 'contacts.contact[].country',
    label: 'Country',
    component: 'Select',
    optionsPath: 'document-data.country-options'
  },
  {
    statePath: 'contacts.contact[].phone[0].number',
    label: 'Phone Number',
    component: 'TextInput'
  }
];

const productFields = [
  {
    statePath: 'productPath.details[0].marketedName',
    label: 'Marketed Name',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.details[0].listNumber',
    label: 'List Number',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.details[0].lotNumber',
    label: 'Batch/Lot Number',
    component: 'TextInput',
    required: false,
    disabled: true
  },
  {
    statePath: 'productPath.details[0].reason',
    label: 'Batch/Lot Unavailable Reason',
    component: 'Select',
    optionsPath: 'document-data.pq-options.lot_number_unavailable_reason',
    conditionalPath: 'productPath.details[0].lotNumber'
  },
  {
    statePath: 'productPath.complaint.processing.numberOfUnits',
    label: 'Number of Units to Send',
    component: 'TextInput'
  }
];
const productFieldsCS = [
  {
    statePath: 'productPath.details[0].marketedName',
    label: 'Marketed Name',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.details[0].listNumber',
    label: 'List Number',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.complaint.processing.reasonForReplacement',
    label: 'Reason for Replacement',
    component: 'Select',
    optionsPath: 'document-data.pq-options.reasonForReplacement'
  },
  {
    statePath: 'productPath.complaint.processing.numberOfUnits',
    label: 'Number of Units to Send',
    component: 'TextInput'
  },
  {
    statePath: 'productPath.complaint.processing.urgentReplacement',
    label: 'Urgent',
    component: 'Checkbox',
    required: false
  }
];
const productFieldsPS = [
  {
    statePath: 'productPath.complaint.processing.marketedName',
    label: 'Replacement Marketed Name',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.complaint.processing.listNumber',
    label: 'Replacement List Number',
    component: 'TextInput',
    disabled: true
  },
  {
    statePath: 'productPath.details[0].indication',
    label: 'Indication',
    component: 'Select',
    optionsPath: 'document-data.pq-options.indication'
  },
  {
    statePath: 'productPath.usage[0].trainingKind',
    label: 'Training Patient Received',
    component: 'Select',
    optionsPath: 'document-data.pq-options.trainingKind'
  },
  {
    statePath: 'productPath.complaint.processing.urgentReplacement',
    label: 'Urgent',
    component: 'Checkbox',
    required: false
  },
  {
    statePath: 'productPath.usage[0].usageDuration',
    label: 'Length of Product Use',
    component: 'Select',
    optionsPath: 'document-data.pq-options.usageDuration'
  }
];

const prescriberFields = [
  {
    statePath: 'prescriberPath.name.first',
    label: 'First Name',
    component: 'TextInput',
    disabled: true,
    helpText:
      'If fields are not populated, please indicate a prescriber in the contacts tab.'
  },
  {
    statePath: 'prescriberPath.name.last',
    label: 'Last Name',
    component: 'TextInput',
    disabled: true
  }
];

const mapping = {
  'USCreditOnly.null': {
    sections: {
      'Product Details': productFields,
      'Patient Info': primaryPatientFieldsRequired,
      'Shipping Info': contactFields
    },
    title: 'Send to Customer Service'
  },
  'yes.US Pharmacy Solutions': {
    sections: {
      'Product Details': productFieldsPS,
      'Patient Info': patientFields,
      'Prescriber Info': prescriberFields
    },
    title: 'Send to Pharmacy Solutions'
  },
  'yes.US Customer Service': {
    sections: {
      'Product Details': productFieldsCS,
      'Patient Info': primaryPatientFields,
      'Shipping Info': pharmacyFields
    },
    title: 'Send to Customer Service'
  },
  '': {}
};

export default mapping;
