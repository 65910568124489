import { generateCSS } from '../index';

const stylesGenerator = (theme, { styles }) => ({
  base: generateCSS({
    cursor: 'pointer',
    fontSize: '16px',
    height: '50px',
    textTransform: 'uppercase',
    transition: 'transform 150ms ease',
    width: '160px',
    ':hover': {
      transform: 'scale(1.1)'
    },
    ...styles,
    ':disabled': {
      cursor: 'not-allowed',
      border: `1px solid ${theme.colors.base}`,
      background: theme.colors.primary,
      color: theme.colors.base
    }
  })
});

export default stylesGenerator;
