import { isBoolean } from 'lodash';
import { CASE_SERVICES_API } from 'config/values';

const convertBody = obj =>
  Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

const getFetchOptions = (apiKey, data) => {
  const apiProps = {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  };

  return {
    method: 'POST',
    body: convertBody(data),
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};
const fetchMergeCases = (
  caseIdToArchive,
  caseIdToMerge,
  archiveComments,
  archiveReason,
  onSuccess,
  onFailure
) => {
  // eslint-disable-next-line no-undef
  const url = new URL(`${CASE_SERVICES_API.uri}/mergeAndArchiveCases`);

  const bodyData = {
    subcaseIdToVersion: caseIdToMerge,
    masterCaseToArchive: caseIdToArchive,
    archiveReason,
    archiveComments
  };
  const fetchOptions = getFetchOptions(CASE_SERVICES_API.apiKey, bodyData);
  // toString to avoid config/fetch.js match error
  return fetch(url.toString(), fetchOptions).then(onSuccess, onFailure);
};

export default fetchMergeCases;
