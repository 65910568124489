import gql from 'graphql-tag';

const getTasksQuery = taskFragment => {
  const fragment = gql`
    ${taskFragment}
  `;

  return gql`
    query TaskQuery($q: SearchQuery!, $size: Int, $from: Int) {
      essearch(q: $q, size: $size, from: $from) {
        total
        status
        hits {
          ...BaseTask
        }
        total
      }
    }
    ${fragment}
  `;
};

export default getTasksQuery;
