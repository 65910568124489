import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, isNil } from 'lodash';
import moment from 'moment';
import { Popover, Position, PopoverInteractionKind } from '@blueprintjs/core';
import { DatePicker as BlueprintDatePicker } from '@blueprintjs/datetime';
import ReactSelect from 'react-select';

import { API_DATE_FORMAT } from '../constants';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';
import {
  calculateMinDate,
  calculateMaxDate,
  dateToIsoString,
  parseDateString,
  getDayOptions,
  getMonthOptions,
  getYearOptions
} from '../utils';

class DateInput extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.instanceOf(Date)
    ])
  };

  static defaultProps = {
    ...formElementDefaultProps,
    layout: { width: '100%' },
    styles: { width: '100%' },
    value: ''
  };

  state = {
    dateParts: { ...parseDateString(this.props.value || '') },
    datePickerOpen: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      dateParts: parseDateString(nextProps.value || '')
    });
  }

  handleChange = dateParts => {
    const { onChange } = this.props;
    // Update internal state
    this.setState({ dateParts });
    // Transform date parts to ISO
    const isoDate = dateToIsoString(dateParts);
    onChange(isoDate);
  };

  handleDropdownChange = (key, target) => {
    const dateParts = {
      ...this.state.dateParts,
      [key]: isNil(target) ? '' : target.value
    };
    this.handleChange(dateParts);
  };

  handlePickerChange = (jsDate, hasUserManuallySelectedDate) => {
    if (!hasUserManuallySelectedDate) return;
    // DO NOT CHANGE! Must be ISO string supporting partial dates.
    const newDate = moment(jsDate).format(API_DATE_FORMAT);
    const dateParts = parseDateString(newDate);
    this.setState({ datePickerOpen: false });
    this.handleChange(dateParts);
  };

  handlePopoverInteraction = nextOpenState =>
    this.setState({ datePickerOpen: nextOpenState });

  focus = () => this.component.focus();

  render() {
    const {
      $id,
      schemaPath,
      computedStyles,
      id,
      config,
      value,
      helpText,
      validations,
      errors,
      disabled
    } = this.props;
    const { dateParts, datePickerOpen } = this.state;

    const restriction = get(validations, 'dateRestriction.constraint');
    const minDate = calculateMinDate(
      restriction === 'past' || restriction === 'both'
    ).toDate();
    const maxDate = calculateMaxDate(
      restriction === 'future' || restriction === 'both'
    ).toDate();

    const popoverProps = {
      isOpen: datePickerOpen,
      onInteraction: nextOpenState =>
        this.handlePopoverInteraction(nextOpenState),
      disabled,
      interactionKind: PopoverInteractionKind.CLICK,
      position: Position.BOTTOM_RIGHT,
      inline: false // keeps IE happy
    };

    // Set the date picker value if we have a complete date string
    const protoDate = moment(value, API_DATE_FORMAT, true);
    const dateValue = protoDate.isValid() ? { value: protoDate.toDate() } : {};

    const dateProps = {
      ...dateValue, // Sync the dropdowns with the picker
      disabled,
      className: computedStyles.base,
      minDate,
      maxDate,
      locale: config.locale || window.navigator.language || 'en-US',
      isDisabled: disabled, // May not work in Blueprint 2.0
      onChange: this.handlePickerChange
    };

    const selectProps = {
      ...this.props,
      disabled,
      optionRenderer: this.renderOption,
      type: 'text',
      backspaceRemoves: true,
      clearable: false,
      matchPos: 'start' // https://github.com/JedWatson/react-select#filtering-options
    };

    const defaultOption = [{ label: '', value: null }];
    const handleDayChange = e => this.handleDropdownChange('dd', e);
    const handleMonthChange = e => this.handleDropdownChange('mm', e);
    const handleYearChange = e => this.handleDropdownChange('yyyy', e);

    return (
      <div
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <Label {...this.props} />
        <div className={computedStyles.inputRow}>
          <ReactSelect
            {...selectProps}
            inputProps={{ id: `${id}-dd`, 'data-id': `${$id}-dd` }}
            placeholder="DD" // TODO How will CMS control this value
            options={defaultOption.concat(getDayOptions())}
            onChange={handleDayChange}
            value={dateParts.dd}
          />
          <ReactSelect
            {...selectProps}
            inputProps={{ id: `${id}-mm`, 'data-id': `${$id}-mm` }}
            placeholder="MMM"
            optionRenderer={this.renderOption}
            type="text"
            options={defaultOption.concat(getMonthOptions())}
            onChange={handleMonthChange}
            value={dateParts.mm}
          />
          <ReactSelect
            {...selectProps}
            inputProps={{ id: `${id}-yyyy`, 'data-id': `${$id}-yyyy` }}
            placeholder="YYYY"
            optionRenderer={this.renderOption}
            type="text"
            options={defaultOption.concat(getYearOptions(minDate, maxDate))}
            onChange={handleYearChange}
            value={dateParts.yyyy}
            ref={component => (this.component = component)}
          />
          <Popover {...popoverProps}>
            <div
              id={`${id}-popover-target`}
              role="button"
              aria-disabled={disabled}
              className={this.props.computedStyles.datePickerControl}
            />
            <BlueprintDatePicker {...dateProps} />
          </Popover>
        </div>
        <span className={computedStyles.helpText}>{helpText}</span>
        <ValidationErrors
          id={id}
          computedStyles={computedStyles}
          validationErrors={errors}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(DateInput);
