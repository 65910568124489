import gql from 'graphql-tag';

const createTaskMutation = taskFragment => {
  const fragment = gql`
    ${taskFragment}
  `;

  return gql`
    mutation createTask($task: taskInput!) {
      createTask(task: $task) {
        ...BaseTask
      }
    }
    ${fragment}
  `;
};

export default createTaskMutation;
