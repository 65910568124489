import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '../withStyles';
import stylesGenerator from './styles';

const InfoTableColumn = ({ computedStyles, value, label }) => (
  <td className={computedStyles.base}>{value || label}</td>
);

InfoTableColumn.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InfoTableColumn.defaultProps = {
  value: '',
  label: ''
};

export default withStyles(stylesGenerator)(InfoTableColumn);
