import { isBoolean } from 'lodash';

import { SUBCASE_RECONCILIATION_API } from 'config/values';
import { slashToDotDelimitedPaths } from './formatter/deltaUtils';

const getFetchOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': SUBCASE_RECONCILIATION_API.apiKey,
      'Content-Type': 'text/html'
    }
  };

  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};
/**
 * Request for case reconciliation deltas
 *
 * @function
 * @param  {String} versionId           - The full case-version id (eg. `TE18-1006003-AE01`)
 * @param  {Function} onSuccess(json)   - Callback for successful request
 * @param  {Function} onFailure(error)  - Callback for failed request
 */
export const fetchCaseReconciliationDelta = async (
  versionId,
  onSuccess,
  onFailure
) => {
  try {
    const URL = `${SUBCASE_RECONCILIATION_API.uri}/${versionId}`;
    const fetchOptions = getFetchOptions(SUBCASE_RECONCILIATION_API.apiKey);
    const response = await fetch(URL, fetchOptions);
    const json = await response.json();
    const dotDelimitedPaths = slashToDotDelimitedPaths(json);
    onSuccess(dotDelimitedPaths);
  } catch (e) {
    onFailure(e);
  }
};

export default fetchCaseReconciliationDelta;
