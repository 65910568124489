import { generateCSS } from 'Common/components/Form/index';

const stylesGenerator = theme => ({
  base: generateCSS({
    background: theme.colors.background
  }),

  inner: generateCSS({
    margin: '0 auto'
    // maxWidth: '1200px'
  })
});

export default stylesGenerator;
