import { find, get, isEmpty, isNil, filter, defaultTo, mapKeys } from 'lodash';

/** @module Rendering Utils */

/**
 * @returns value of `p` in the first object which has that key
 */
const getValFromEither = (p, obj1, obj2) => (p in obj1 ? obj1[p] : obj2[p]);

/**
 * merges two objects at most `n` levels
 * @function
 */
export const mergeMax = (source, object, n = 3) => {
  if (!source || !object || n < 0) return defaultTo(object, source);
  if (Array.isArray(source)) {
    // The output array will have the length of the longer list
    return new Array(Math.max(source.length, object.length))
      .fill(0)
      .map(
        (_, i) =>
          typeof (source[i] || object[i]) === 'object'
            ? mergeMax(source[i], object[i], n - 1)
            : getValFromEither(i, object, source)
      );
  }

  return Object.keys(source)
    .concat(Object.keys(object))
    .reduce(
      (merged, prop) =>
        Object.assign(merged, {
          [prop]:
            object[prop] &&
            source[prop] &&
            typeof source[prop] === 'object' &&
            typeof object[prop] === 'object'
              ? mergeMax(source[prop], object[prop], n - 1)
              : getValFromEither(prop, object, source)
        }),
      {}
    );
};

/**
 * Wrapper function for lodash#get but will use `defaultValue` in place of any NIL result (not just undefined)
 * @function
 * @param trilogyCase The case object
 * @param path Key of the path
 * @param defaultValue Default value when value at path is Nil
 * @returns {*}
 */
export const getOrElse = (trilogyCase, path, defaultValue) => {
  const valueOrNil = get(trilogyCase, path);
  return isNil(valueOrNil) ? defaultValue : valueOrNil;
};

const OPTION_TYPES = ['Select', 'CheckboxGroup', 'RadioGroup'];

/**
 * Lookup an option label by its protoValue
 * @function
 * @param elementSchema The schema looked up with Common/utils/formState.js#resolveElementSchemaForPath
 * @param protoValue The value in the protoDocument
 * @param defaultLabel Label to show if not found
 * @returns {*|undefined}
 */
export const getProtoLabel = (elementSchema, protoValue, defaultLabel) => {
  const optionLabelValues = get(elementSchema, 'options', []);
  const matchedOption =
    elementSchema && OPTION_TYPES.includes(elementSchema.component)
      ? find(optionLabelValues, t => t.value === protoValue)
      : elementSchema;
  return get(matchedOption, 'label') || defaultLabel || undefined;
};

/**
 * @function
 * @param {*} optionLabelValues
 * @param {*} value
 * @param {*} defaultLabel
 */
export const getTacticalLabel = (optionLabelValues, value, defaultLabel) => {
  const matchedOption = find(
    optionLabelValues,
    optionGroup => optionGroup.value === value
  );
  return get(matchedOption, 'label') || defaultLabel || undefined;
};

/**
 * @function
 * @param {*} trilogyCase
 * @param {*} formatFn
 */
export const getSubcaseList = (
  trilogyCase,
  formatFn = subcases => `(${subcases.join(', ')})`
) => {
  const subcases = filter([
    get(trilogyCase, 'summary.narrative.categories.adverse_event')
      ? 'AE'
      : null,
    get(trilogyCase, 'summary.narrative.categories.medical_inquiry')
      ? 'MI'
      : null,
    get(trilogyCase, 'summary.narrative.categories.product_quality')
      ? 'PQ'
      : null
  ]);
  return !isEmpty(subcases) ? formatFn(subcases) : '';
};

/**
 * GQL Search Filters should not have array syntax
 * @function
 * @param statePath
 * @returns {*}
 */
export const statePathToSearchCriteria = statePath => {
  const REMOVE_INDICES_RE = /\[\d+\]/g;
  return statePath.replace(REMOVE_INDICES_RE, '');
};

/**
 * GQL Search Filters should not have array syntax
 * @function
 * @param filters Map of statePaths and values to filter on
 * @returns {*} Map with same values, but keys in a GQL acceptable format
 */
export const protoToSearchCriteria = filters =>
  mapKeys(filters, (val, key) => statePathToSearchCriteria(key));

/**
 * @function
 * @param {*} param0
 */
export const genDisplayValue = ({ caseId = '', subcaseId = '' }) =>
  `${caseId.toUpperCase()}${
    subcaseId ? `-${(subcaseId || '').toUpperCase()}` : ''
  }`;

/**
 * Get the friendly name for the country of primary reporter field
 * @function
 * @param tacticalData Data for option/values of country list
 * @param trilogyCase The case
 * @param defaultValue The default value if nil
 * @returns {*}
 */
export const getCountryOfPrimaryReporter = (
  tacticalData,
  trilogyCase,
  defaultValue
) => {
  const country = get(tacticalData, 'document-data.country-options', []).find(
    c => c.value === get(trilogyCase, 'countryOfPrimaryReporter')
  );
  return get(country, 'label', defaultValue);
};

export const formatProductTypes = productType => {
  if (productType) {
    if (productType === 'ABV_AGN') return 'Both';
    return productType;
  }
  return null;
};
