import { get } from 'lodash';

/** @module Uncategorized Common Utils */

/**
 * Removes special characters from strings that cause issues when using ID and class selectors.
 * E.g., Enzyme selectors cannot find elements using ID selectors containing periods or brackets.
 *
 * @function
 * @param {string} string
 * @returns {string} - String with special characters removed.
 */
export const genSafeSelector = (string = '') => string.replace(/[[\].']+/g, '');

/**
 * Parses a String into a templated string evaluating against the given `vars`
 * object.
 *
 * @function
 * @param  {String} value - The String that *may* be formatted as a template string
 * @param  {Object} vars  - An object with properties used the `value` template
 * @return {String}       - The evaluated string
 */
export const parseTemplateString = (value = '', vars = {}) => {
  const RE = /\$\{([a-z$][-_.[\]a-z0-9$]+?)(?:\|\|(.*?))?\}/i;
  let match;

  let val = value;
  /* eslint-disable no-cond-assign */
  while ((match = RE.exec(val))) {
    val = val.replace(
      match[0],
      get(vars, match[1], vars[match[1]]) || match[2] || ''
    );
    if (val === value) break;
  }
  /* eslint-enable no-cond-assign */
  return val;
};

/**
 * Adjusts column widths based on manual values for setting column sizes.
 *
 * @function
 * @param {array} widths -
 * @param {string} modifier - Used to modify width values
 * @returns {object}
 */
export const getWidths = ({ widths = [] }, modifier = '0px') =>
  widths.reduce(
    (acc, width, i) => ({
      ...acc,
      [`:nth-child(${i + 1})`]: {
        minWidth: `calc(${width} + ${modifier})`,
        flexBasis: `calc(${width} + ${modifier})`
      }
    }),
    {}
  );

/**
 * Given full case id i.e. TU17-1000903-AE00 return 'AE' ..
 *
 * @function
 * @param {string} id - full id case id + subcase id
 * @returns {string}
 */
export const getSubcasePrefix = id =>
  id.match(/(?:\w+)-(?:\w+)-([A-Za-z]{2})/)[1];

/**
 *
 * @function
 * @param {function} callback - the callback to call when one of the keys is pressed
 * @param {Object[]} keys - the keyboard keys e.g. ('', 'Enter', etc)
 * @param {string} keys.key
 */
export const handleKeyPress = (callback, ...keys) => e => {
  if (keys.includes(e.key)) {
    e.preventDefault();
    callback();
  }
};

export * from './pruneEmpties';
export * from './indicators';
export * from './routing';
export * from './rendering';
export * from './validations';
export * from './subscriptions';
export * from './userMapping';
export * from './valueToLabel';
export * from './columnValueForCSVExport';
export * from './pdf';
