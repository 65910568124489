import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  previousVersionLink: generateCSS({
    ...theme.fonts.authLink,
    display: 'block',
    color: theme.colors.brand,
    cursor: 'pointer',
    fontSize: '16px',
    paddingLeft: '5px',
    paddingRight: '5px'
  }),

  previousVersionLinkSelected: generateCSS({
    ...theme.fonts.authLink,
    backgroundColor: theme.colors.brand,
    color: 'white',
    fontSize: '16px',
    paddingLeft: '5px',
    paddingRight: '5px'
  }),

  previousVersionList: generateCSS({
    paddingLeft: '0px',
    marginTop: '5px'
  }),

  previousVersionListCol: generateCSS({
    gridColumnStart: 1
  }),

  previousVersionContentCol: generateCSS({
    gridColumnStart: 2,
    gridColumnEnd: 4
  }),

  contentItemLabel: generateCSS({
    fontWeight: 'bold',
    paddingRight: '10px',
    color: theme.colors.altBrand
  }),

  cardSubSection: generateCSS({
    paddingLeft: '25px'
  }),

  elementLabelContainer: generateCSS({
    marginTop: '3px'
  }),

  gridWrapper: generateCSS({
    ...theme.fonts.default,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'minmax(100px, auto)',
    width: '80vw'
  })
});

export default stylesGenerator;
