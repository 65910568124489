import { generateCSS } from 'Common/components/Form';

export default {
  base: generateCSS({
    display: 'block',
    marginTop: 50,
    textAlign: 'center'
  }),

  toast: generateCSS({
    marginTop: 10,
    minWidth: 200,
    maxWidth: 1200
  })
};
