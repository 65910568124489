/* eslint no-console:0 */
import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { isString, mapValues, isEmpty } from 'lodash';

import close from 'public/images/close.svg';
import { withStyles } from 'Common/components/Form';
import { stylesGenerator } from './styles';

/**
 * Mounted once on the NavigationController.
 * Do no re-use elsewhere, instead swap the content using emitModalContentUpdate.
 */
class Modal extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired,
    computedStyles: PropTypes.shape({
      overlay: PropTypes.func.isRequired,
      modal: PropTypes.object.isRequired,
      modalWithOverflow: PropTypes.object.isRequired
    }).isRequired,
    modalContent: PropTypes.node.isRequired,
    modalWithOverflow: PropTypes.bool.isRequired,
    renderXCloseButton: PropTypes.bool.isRequired
  };

  componentWillMount() {
    if (process.env.NODE_ENV !== 'production') {
      this.checkPropsForCollisions();
    }
  }

  /**
   * @function
   */
  checkPropsForCollisions = () => {
    const { modalContent } = this.props;
    const modalContentPropKeys = Object.keys(modalContent.props || {});
    const propKeys = Object.keys(this.props);

    modalContentPropKeys.forEach(modalPropKey => {
      if (propKeys.includes(modalPropKey)) {
        console.warn(
          `Duplicate key found for ${modalPropKey}! Avoid spreading this.props onto modalContent elements.`
        );
      }
    });
  };

  /**
   * @function
   * @returns {boolean}
   */
  shouldRender = () => !isEmpty(this.props.modalContent);

  /**
   *  Workaround for a conflict between Glamor styles and our method of spreading props
   * https://github.com/abv-cfe/ui/pull/511#issuecomment-332289933
   * @function
   * @returns {Object}
   */
  mapSafeStyles = ({ computedStyles }) =>
    mapValues(computedStyles, computedStyle => computedStyle.toString());

  /**
   * @function
   * @returns {node}
   */
  renderModalContent = () => {
    const { modalContent } = this.props;
    const modalContentProps = {
      ...this.props,
      ...(modalContent.props || {}),
      computedStyles: this.mapSafeStyles(this.props)
    };

    return isString(modalContent)
      ? modalContent
      : cloneElement(modalContent, modalContentProps);
  };

  /**
   * @function
   * @returns {node}
   */
  renderModal = () => {
    const {
      actions,
      computedStyles,
      modalContent,
      modalWithOverflow,
      renderXCloseButton
    } = this.props;
    const handleClick = () => actions.emitModalContentClear();
    const handleKeyPress = e => {
      if (e.key === ' ') {
        e.preventDefault();
        actions.emitModalContentClear();
      }
    };

    return (
      <div>
        <div
          className={
            modalWithOverflow
              ? computedStyles.modalWithOverflow
              : computedStyles.modal
          }
        >
          {renderXCloseButton ? (
            <img
              role="button"
              className={computedStyles.close}
              onClick={handleClick}
              src={close}
              tabIndex="0"
              onKeyPress={handleKeyPress}
            />
          ) : null}
          <div className={computedStyles.modalContent}>
            {modalContent ? this.renderModalContent() : null}
          </div>
        </div>
      </div>
    );
  };

  /**
   * @function
   * @returns {node}
   */
  render() {
    const { computedStyles } = this.props;

    return (
      <div className={`modal ${computedStyles.overlay(this.shouldRender())}`}>
        {this.renderModal()}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Modal);
