import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get, find, capitalize, isNil, isString } from 'lodash';

import TableRow from 'Common/components/TableRow';
import {
  AE_STATE_PATHS,
  PQ_STATE_PATHS,
  MASTER_STATE_PATHS,
  SERIOUSNESS_OPTIONS,
  REPLACEMENT_REQUIRED_OPTIONS,
  SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS
} from 'Queue/constants';
import { CMS_PROP_TYPES, NOT_SPECIFIED } from 'Common/constants';
import {
  processSeriousnessCriteria,
  getUserName,
  getOrElse
} from 'Common/utils';
import { abbvieDateFormat } from 'Common/components/Form/utils';
import { Checkbox } from 'Common/components/Form';
import SeriousnessIndicator from 'Common/components/SeriousnessIndicator';
import DescriptionLink from './DescriptionLink';
import moment from 'moment';

class YourCasesRow extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      masterCaseId: PropTypes.object.isRequired,
      subcaseList: PropTypes.object.isRequired,
      actions: PropTypes.object.isRequired,
      productCell: PropTypes.object.isRequired
    }).isRequired,
    row: PropTypes.shape({
      id: PropTypes.string,
      countryOfPrimaryReporterLabel: PropTypes.string,
      status: PropTypes.string
    }).isRequired,
    tasksLink: PropTypes.string.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    processNonMomentDate: PropTypes.func.isRequired,
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    handleSelectCaseOrTask: PropTypes.func
  };

  static defaultProps = {
    tacticalData: {},
    selectedItemsToAssignOrArchive: [],
    handleSelectCaseOrTask: () => {}
  };

  renderActions = row => {
    const { computedStyles, tasksLink } = this.props;

    return (
      <div className={computedStyles.actions}>
        <div>
          <Link to={row.caseLink} target="_blank">
            View
          </Link>
        </div>
        <div>
          <Link to={tasksLink} target="_blank">
            View Tasks {row.tasksCount ? `(${row.tasksCount})` : null}
          </Link>
        </div>
      </div>
    );
  };

  renderAssignee = row => {
    const users = get(this.props.tacticalData, 'document-data.user-list', []);
    return getUserName(users, row.assignee) || 'Unassigned';
  };

  hasStringData = input => !isNil(input) && isString(input) && input.length > 0;
  /**
   * Formats a date object to a string in 'DD MMM YYYY HH:mm:ss' format.
   *
   * @param dt - The date object that will be formatted.
   * @returns {string}
   */
  formatDateTime = dt => moment(dt).format('DD MMM YYYY HH:mm:ss');

  renderCell = column => {
    const {
      computedStyles,
      row,
      processNonMomentDate,
      tacticalData
    } = this.props;
    const seriousnessValue = processSeriousnessCriteria(row);

    const dueDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.DUEDATE)
    );
    const datedueDate = this.hasStringData(dueDate) ? dueDate : NOT_SPECIFIED;

    const affiliateAwarenessDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE)
    );
    const dateAffiliateAwarenessDate = this.hasStringData(
      affiliateAwarenessDate
    )
      ? affiliateAwarenessDate
      : NOT_SPECIFIED;

    const dateOutputAE = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
    );
    const abbvieAwarenessAE = this.hasStringData(dateOutputAE)
      ? dateOutputAE
      : NOT_SPECIFIED;

    const dateOutputPQ = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
    );
    const abbvieAwarenessPQ = this.hasStringData(dateOutputPQ)
      ? dateOutputPQ
      : NOT_SPECIFIED;

    const trilogyLoadDate = abbvieDateFormat(
      getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
    );
    const datetrilogyLoadDate = this.hasStringData(trilogyLoadDate)
      ? trilogyLoadDate
      : NOT_SPECIFIED;

    const seriousnessOptions = SERIOUSNESS_OPTIONS.options;
    const seriousnessOptionsMatch = find(
      seriousnessOptions,
      ({ value }) => value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
    );
    const seriousness = get(seriousnessOptionsMatch, 'label', NOT_SPECIFIED);

    const replacementRequiredOptions = REPLACEMENT_REQUIRED_OPTIONS.options;
    const replacementRequiredMatch = find(
      replacementRequiredOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.REPLACEMENT_REQUIRED)
    );
    const replacementRequired = get(
      replacementRequiredMatch,
      'label',
      NOT_SPECIFIED
    );

    const methodOfReceiptOptions = get(
      tacticalData,
      'document-data.mastercase-options.method_of_receipt',
      []
    );
    const methodOfReceiptMatch = find(
      methodOfReceiptOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
    );
    const methodOfReceipt = get(methodOfReceiptMatch, 'label', NOT_SPECIFIED);

    const translation_statusOptions = get(
      tacticalData,
      'document-data.mastercase-options.translation_status',
      []
    );
    const translation_status_match = find(
      translation_statusOptions,
      ({ value }) =>
        value === getOrElse(row, MASTER_STATE_PATHS.TRANSLATION_STATUS)
    );
    const translation_status = get(
      translation_status_match,
      'label',
      MASTER_STATE_PATHS.TRANSLATION_NA
      // NOT_SPECIFIED
    );

    const highImpact = capitalize(
      get(row, MASTER_STATE_PATHS.HIGH_IMPACT, NOT_SPECIFIED)
    );

    let pQOwner = getOrElse(row, MASTER_STATE_PATHS.PQ_OWNER);
    pQOwner = pQOwner || NOT_SPECIFIED;

    let sampleAvailable = getOrElse(row, MASTER_STATE_PATHS.SAMPLE_AVAILABLE);
    sampleAvailable = capitalize(sampleAvailable) || NOT_SPECIFIED;

    const sampleRequiredforInvestigationOptions =
      SAMPLE_REQUIRED_FOR_INVESTIGATION_OPTIONS.options;
    const sampleRequiredforInvestigationMatch = find(
      sampleRequiredforInvestigationOptions,
      ({ value }) =>
        value ===
        getOrElse(row, MASTER_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION)
    );
    const sampleRequiredforInvestigation = get(
      sampleRequiredforInvestigationMatch,
      'label',
      NOT_SPECIFIED
    );

    let reactions = getOrElse(row, MASTER_STATE_PATHS.REACTIONS);
    reactions = reactions || NOT_SPECIFIED;

    const affiliateLocation = getOrElse(
      row,
      MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL,
      NOT_SPECIFIED
    );

    switch (column.label) {
      case 'Case Information':
        return (
          <td key={column.label}>
            <Link
              to={row.caseLink}
              className={computedStyles.masterCaseId}
              target="_blank"
            >
              {row.id}
            </Link>
            {seriousnessValue ? (
              <SeriousnessIndicator
                value={seriousnessValue}
                className={computedStyles.pullRight}
              />
            ) : null}
            <DescriptionLink row={row} />
          </td>
        );
      case 'AE Suspect Product':
        return (
          <td key={column.label} className={computedStyles.productCell}>
            {row.product}
          </td>
        );
      case 'Country of Reporter':
        return <td key={column.label}>{row.countryOfPrimaryReporterLabel}</td>;
      case 'AE Latest Received Date':
        return (
          <td key={column.label}>
            {processNonMomentDate(
              abbvieDateFormat(
                getOrElse(row, AE_STATE_PATHS.LAST_RECEIVED_DATE)
              )
            )}
          </td>
        );
      case 'Owner':
        return <td key={column.label}>{this.renderAssignee(row)}</td>;
      case 'Status':
        return <td key={column.label}>{row.status}</td>;
      case 'Actions':
        return <td key={column.label}>{this.renderActions(row)}</td>;
      case 'Reactions':
        return <td key={column.label}>{reactions} </td>;
      case 'Due Date':
        return <td key={column.label}>{datedueDate}</td>;
      case 'Affiliate Awareness Date':
        return <td key={column.label}>{dateAffiliateAwarenessDate}</td>;
      case 'Abbvie Awareness (AE)':
        return <td key={column.label}>{abbvieAwarenessAE}</td>;
      case 'Abbvie Awareness (PQ)':
        return <td key={column.label}>{abbvieAwarenessPQ}</td>;
      case 'Trilogy Load Date':
        return <td key={column.label}>{datetrilogyLoadDate}</td>;
      case 'Seriousness':
        return <td key={column.label}>{seriousness}</td>;
      case 'Method of Receipt':
        return <td key={column.label}>{methodOfReceipt}</td>;
      case 'Case Creator':
        return <td key={column.label}>{row.creatorUsername}</td>;
      case 'High Impact':
        return <td key={column.label}>{highImpact || NOT_SPECIFIED}</td>;
      case 'PQ Owner':
        return <td key={column.label}>{pQOwner}</td>;
      case 'Replacement Required':
        return <td key={column.label}>{replacementRequired}</td>;
      case 'Sample Available':
        return <td key={column.label}>{sampleAvailable}</td>;
      case 'Sample Required for Investigation':
        return <td key={column.label}>{sampleRequiredforInvestigation}</td>;
      case 'Affiliate Location':
        return <td key={column.label}>{affiliateLocation}</td>;
      case 'Translation Status':
        return <td key={column.label}>{translation_status}</td>;
      case 'Documents Sent for Translation':
        return (
          <td key={column.label}>{row.sentForTranslation || NOT_SPECIFIED}</td>
        );
      case 'Most Recent Date/Time of Documents Sent':
        const dateTimeFormat = this.formatDateTime(row.documentSentDate);
        return (
          <td>
            {dateTimeFormat !== 'Invalid date' ? dateTimeFormat : NOT_SPECIFIED}
          </td>
        );

      case 'Documents Pending Translation':
        return (
          <td key={column.label}>
            {row.documentPendingTranslation || NOT_SPECIFIED}
          </td>
        );

      default:
        return <td key={column.label}>{NOT_SPECIFIED}</td>;
    }
  };

  render() {
    const { row, selectedItemsToAssignOrArchive } = this.props;
    const isCombined =
      !!getOrElse(row, PQ_STATE_PATHS.ID) &&
      !!getOrElse(row, AE_STATE_PATHS.ID);
    return (
      <TableRow id="queue-yc-table-row">
        <td>
          <Checkbox
            onChange={evt =>
              this.props.handleSelectCaseOrTask(row.id, evt, isCombined)
            }
            value={selectedItemsToAssignOrArchive.includes(row.id)}
          />
        </td>
        {(this.props.userDesiredColumns || []).map(column =>
          this.renderCell(column)
        )}
      </TableRow>
    );
  }
}

export default YourCasesRow;
