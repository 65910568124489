import { generateCSS } from 'Common/components/Form';
import { COLOR_CODE_GREYOUT } from 'Common/constants';

const stylesGenerator = () => ({
  disabledLink: generateCSS({
    cursor: 'not-allowed',
    textDecoration: 'none',
    color: COLOR_CODE_GREYOUT
  })
});

export default stylesGenerator;
