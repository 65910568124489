import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  queuePopup: generateCSS({
    maxWidth: '1000px'
  }),
  modifyContainerPopup: generateCSS({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px'
  }),
  modifyFilterContainerPopup: generateCSS({
    justifyContent: 'center',
    marginTop: '30px',
    maxHeight: '60vh',
    overflowY: 'auto',
    width: '800px'
  }),
  modifyConfirmBtnPopup: generateCSS({
    background: '#071D49',
    color: 'white',
    width: '100px',
    border: '1px solid #071D49',
    borderRadius: '5px',
    padding: '8px',
    margin: '20px auto',
    display: 'block',
    cursor: 'pointer'
  })
});

export default stylesGenerator;
