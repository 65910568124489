import React from 'react';
import ReactDOM from 'react-dom';
import 'react-select/dist/react-select.css';

import NoConfigRoot from 'Common/components/NoConfigRoot';
import 'Common/styles/base.css';

const node = document.getElementById('root');

const render = Component => {
  ReactDOM.render(<Component />, node);
};

render(NoConfigRoot);

if (module.hot) {
  module.hot.accept(NoConfigRoot, () => {
    render(NoConfigRoot, node);
  });
}
