import React from 'react';
import PropTypes from 'prop-types';
import { modalStyles } from 'Common/components/withModal';
import { SimpleButton } from 'Common/components/Form';

const UnsavedChangesModal = ({ handleConfirm, handleDismiss }) => (
  <div className={modalStyles.base}>
    <span className={modalStyles.title}>Continue Editing?</span>
    Unsaved changes will be lost. Do you want to remain in edit mode on this
    task?
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton onClick={handleDismiss}>No</SimpleButton>
      <SimpleButton onClick={handleConfirm} primary>
        Yes
      </SimpleButton>
    </div>
  </div>
);

UnsavedChangesModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired
};

export default UnsavedChangesModal;
