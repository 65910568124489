import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const TotalCount = ({ totalResults }) => (
  <Fragment>{totalResults} cases</Fragment>
);

TotalCount.propTypes = {
  totalResults: PropTypes.number.isRequired
};

export default TotalCount;
