import React from 'react';
import PropTypes from 'prop-types';

import { version } from 'package.json';
import withStyles from 'Common/components/Form/withStyles';
import styleGenerator from './styles';

const Footer = ({ computedStyles }) => {
  const year = new Date().getFullYear();

  return (
    <div className={computedStyles.footer}>
      <div className={computedStyles.footerText}>
        Copyright © {year} AbbVie Inc. North Chicago, Illinois, U.S.A
      </div>
      <span className={computedStyles.hidden}>{version}</span>
    </div>
  );
};

Footer.propTypes = {
  computedStyles: PropTypes.shape({
    footer: PropTypes.object,
    footerText: PropTypes.object
  }).isRequired
};

export default withStyles(styleGenerator)(Footer);
