import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';
import { css } from 'glamor';
import { omitBy, isNil } from 'lodash';
import withStyles from 'Common/components/Form/withStyles';
import SmartButton from 'Common/components/Form/SmartButton';
import stylesGenerator from './styles';

class AffiliateSelect extends PureComponent {
  handleChange = selected => {
    const { value } = selected || { value: '' };
    this.props.onChange(value);
  };

  focus = () => this.component.focus();

  // Adds an id selector testing convenience
  renderOption = ({ label, value }) => (
    <span id={`option-${this.props.id}-${value}`}>{label}</span>
  );

  render() {
    const {
      $id,
      schemaPath,
      className,
      id,
      disabled,
      placeholder,
      value,
      computedStyles,
      multi,
      autoFocus,
      options,
      label,
      onClick
    } = this.props;

    const selectProps = {
      ref: component => (this.component = component),
      optionRenderer: this.renderOption,
      type: 'text',
      options,
      onChange: this.handleChange,
      backspaceRemoves: true,
      matchPos: 'any', // https://github.com/JedWatson/react-select/tree/v1.x#filtering-options
      placeholder,
      disabled,
      multi,
      autofocus: autoFocus,
      clearable: false,
      value
    };

    return (
      <div
        className={css(computedStyles.base, className)}
        id={`${id}-container`}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <SmartButton
          styles={{
            appearance: 'none',
            backgroundColor: '#071D49',
            border: 'none',
            color: '#FFFFFF',
            marginRight: '10px',
            width: '180px',
            height: '30px',
            fontFamily: 'Calibri, "Microsoft Sans Serif"',
            fontSize: '12px',
            verticalAlign: 'middle',
            display: 'inline-block'
          }}
          label={`Save ${label}`}
          onClick={onClick}
        />
        <ReactSelect
          // TRGY-1018 react-select will blow up, in prod mode, if given explicitly null props
          {...omitBy(selectProps, isNil)}
          inputProps={{ id, 'data-id': $id }}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(AffiliateSelect);
