import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { isAuthorized } from 'config/auth';
import PageHeader from 'CreateCase/components/PageHeader';
import PreviousVersion from 'CreateCase/components/PreviousVersion';
import AuditHistory, { AUDIT_TYPE_CASE } from 'Common/components/AuditHistory';
import ReassignAssigneeModal from 'Common/components/ReassignAssigneeModal';
import GenerateEmailModal from 'Common/components/GenerateEmailModal';
import CloneCaseModal from 'Common/components/CloneCaseModal';
import ExportCasePdfModal from 'Common/components/ExportCasePdfModal';
import ExportMCToPdfModal from 'Common/components/ExportMCToPdfModal';
import {
  archiveCase,
  createNewSubcaseVersion,
  assignSubcase,
  assigneeNameSubcase,
  reopenSubcase,
  openDuplicateSearch,
  resetActionFlags,
  canMakeNewVersion,
  DUPLICATE_SEARCH_WINDOW,
  assigneeMastercase,
  assigneeNameMastercase,
  redirectNewCase
} from 'CreateCase/utils';
import { AE_STATE_PATHS, PQ_STATE_PATHS } from 'Queue/constants';
import { fetchAEVersion } from 'api/rest/fetchAEVersion';
import {
  CASE_STATUS_NEW,
  CASE_STATUS_IN_PROGRESS,
  CASE_STATUS_ARCHIVED,
  CASE_STATUS_COMPLETED,
  CC_SUB_CASE_TYPES_MAP,
  CMS_PROP_TYPES
} from 'Common/constants';
import Notifier from 'Common/components/Notifier';
import { isSubcaseRoute, getOrElse, getAssignedUserName } from 'Common/utils';
import { RadioGroup, SimpleButton, TextArea } from 'Common/components/Form';
import AuthorizedLink from 'Common/components/AuthorizedLink';
import { modalStyles } from 'Common/components/withModal';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

class CreateCaseHeader extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      }).isRequired
    }).isRequired,
    triggers: PropTypes.shape({
      onSaveCase: PropTypes.func.isRequired,
      onMergeAndArchive: PropTypes.func.isRequired
    }).isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    actions: PropTypes.shape({
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitSetReconciliation: PropTypes.func.isRequired
    }).isRequired,
    computedStyles: PropTypes.shape({
      modal: PropTypes.object.isRequired,
      previousVersionLink: PropTypes.object.isRequired
    }).isRequired,
    session: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
    ),
    registerDuplicateSearchWindow: PropTypes.func,
    onCompleteWithoutSubmit: PropTypes.func.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    reconciliationData: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    pageSchema: PropTypes.objectOf(PropTypes.oneOfType(PropTypes.object))
  };

  static defaultProps = {
    session: {},
    tempAssignee: '',
    tacticalData: {},
    registerDuplicateSearchWindow: () => {},
    reconciliationData: [],
    pageSchema: {}
  };

  state = {
    archiveReason: '',
    archiveComments: '',
    recipient: '',
    mergeCaseId: null,
    reconciliation: {
      reason: '',
      additionalComments: ''
    },
    isCombinedCase:
      !!getOrElse(this.props.trilogyCase, PQ_STATE_PATHS.ID) &&
      !!getOrElse(this.props.trilogyCase, AE_STATE_PATHS.ID)
  };

  componentDidUpdate(prevProps) {
    if (this.props.trilogyCase.subcases !== prevProps.trilogyCase.subcases) {
      this.onUpdate();
    }
  }

  onUpdate = () => {
    this.setState({
      isCombinedCase:
        !!getOrElse(this.props.trilogyCase, PQ_STATE_PATHS.ID) &&
        !!getOrElse(this.props.trilogyCase, AE_STATE_PATHS.ID)
    });
  };

  handleReconciliationChange = (key, value) => {
    // Namespacing reconciliation data within local state
    const nextState = {
      reconciliation: { ...this.state.reconciliation, [key]: value }
    };

    this.setState(nextState, () => {
      // Updates the displayed modal
      const content = this.renderReconciliationModal(this.state.reconciliation);
      this.props.actions.emitModalContentUpdate(content);
    });
  };

  handleArchiveChange = (key, value) => {
    // TODO: if submission errors occur, it's because the entire state is being used for `handleArchiveChange`
    //       however, this update (the commit to which this comment belongs) added another form, but it has
    //       namespaced it under `reconciliation`
    this.setState({ [key]: value });
    // Modal content needs an action to update its content,
    // so we must explicitly pass the new state for the modal content
    const newState = { ...this.state, [key]: value };
    this.handleShowArchiveModal(newState);
  };

  handleDismiss = () => {
    // Clear action menu modal state on close
    this.setState({
      archiveReason: '',
      archiveComments: '',
      recipient: '',
      mergeCaseId: null,
      reconciliation: {
        reason: '',
        additionalComments: ''
      }
    });
    this.props.actions.emitModalContentClear();
  };

  handleDuplicateSearchLink = (
    showContactsButton,
    trilogyCase,
    fromCreateCase
  ) => {
    const { registerDuplicateSearchWindow } = this.props;
    window[DUPLICATE_SEARCH_WINDOW] = openDuplicateSearch(
      showContactsButton,
      false,
      trilogyCase,
      fromCreateCase
    );
    window[DUPLICATE_SEARCH_WINDOW].mergeCase = this.handleMergeCase; // ref
    window[DUPLICATE_SEARCH_WINDOW].redirectNewCase = redirectNewCase;
    window[DUPLICATE_SEARCH_WINDOW].linkCase = this.handleLinkCase;
    registerDuplicateSearchWindow();
  };

  /**
   * mergeCaseId the subcaseId that was found in dupe search window
   */
  handleMergeCase = mergeCaseId => {
    // Keep ID if we're coming from duplicate search after clicking F/U To Existing Case
    if (mergeCaseId) {
      this.setState({ mergeCaseId }, () => {
        this.handleShowArchiveModal(null);
      });
    } else {
      console.error('No case ID sent from Duplicate Search window');
    }
  };

  // This also in SiteHeader but should be moved to utils.. but Notifier was not working from utils
  handleLinkCase = (parentCaseId, caseIdToLink) => {
    const { actions } = this.props;
    const documentId = parentCaseId;
    if (caseIdToLink) {
      actions.emitLinkCases(parentCaseId, caseIdToLink).then(
        () => {
          actions
            .emitFetchCaseById(documentId)
            .then(this.handleFetchCaseSuccess);
        },
        err => {
          console.log(`Error linking cases: ${err}`);
          Notifier.show({
            message: 'Could not link cases',
            intent: Notifier.DANGER
          });
        }
      );
    }
  };

  handleReconciliationSubmission = () => {
    const {
      reconciliation: { subcaseKey, reason, additionalComments }
    } = this.state;
    const { trilogyCase, actions, reconciliationData } = this.props;
    const subcasePath = CC_SUB_CASE_TYPES_MAP[subcaseKey];
    const updatedReconcileInfo = trilogyCase.subcases[subcasePath]
      .reconciliation
      ? [...trilogyCase.subcases[subcasePath].reconciliation]
      : [];

    trilogyCase.subcases[subcasePath].reconciliationNeeded = false;
    trilogyCase.subcases[subcasePath].reconciled = true;

    updatedReconcileInfo.push({
      comment: additionalComments,
      reason,
      fields: reconciliationData ? reconciliationData.join() : ''
    });

    trilogyCase.subcases[subcasePath].reconciliation = updatedReconcileInfo;
    this.props.triggers.onSaveCase(trilogyCase).then(() => {
      actions.emitSetReconciliation([], false); // Reset reconciliation data and flag
    });
    this.handleDismiss();
  };

  handleArchiveConfirm = () => {
    const { archiveReason, archiveComments, mergeCaseId } = this.state;
    const {
      trilogyCase,
      match,
      triggers,
      session,
      pageSchema: { path }
    } = this.props;

    // auto-assign case to current user if unassigned
    let assignee;
    if (path === 'ae') {
      assignee = trilogyCase.subcases.adverseEvent.assignee;
    } else if (path === 'pq') {
      assignee = trilogyCase.subcases.productQuality.assignee;
    }
    const updatedCase = assignee
      ? trilogyCase
      : this.reassignSubcase(path, session.sub);

    // Merge and archive or standard archive
    if (mergeCaseId) {
      triggers.onMergeAndArchive(
        trilogyCase.id,
        mergeCaseId,
        archiveReason,
        archiveComments
      );
    } else {
      const archivedTrilogyCase = archiveCase(
        match,
        updatedCase,
        archiveComments,
        archiveReason
      );

      this.props.triggers.onSaveCase(archivedTrilogyCase);
    }

    this.handleDismiss();
  };

  handleGenerateEmailModal = explicitState => {
    const { actions, trilogyCase } = this.props;
    const { recipient } = explicitState || this.state;

    const handleRecipientChange = value => {
      this.setState({ recipient: value });
      // Modal content needs an action to update its content,
      // so we must explicitly pass the new state for the modal content
      const newState = { ...this.state, recipient: value };
      this.handleGenerateEmailModal(newState);
    };

    const content = (
      <GenerateEmailModal
        recipient={recipient}
        masterCaseId={trilogyCase.id}
        onRecipientChange={handleRecipientChange}
        onConfirm={this.handleDismiss} // Modal will handle email window logic
        onCancel={this.handleDismiss}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handleGenerateAckEmailModal = explicitState => {
    const { actions, trilogyCase } = this.props;
    const { recipient } = explicitState || this.state;

    const handleRecipientChange = value => {
      // strip spaces and new lines
      const res = value.replace(/[\n|\s|\r]/, '');
      this.setState({ recipient: res });
      // Modal content needs an action to update its content,
      // so we must explicitly pass the new state for the modal content
      const newState = { ...this.state, recipient: res };
      this.handleGenerateAckEmailModal(newState);
    };

    const content = (
      <GenerateEmailModal
        recipient={recipient}
        masterCaseId={trilogyCase.id}
        onRecipientChange={handleRecipientChange}
        onConfirm={this.handleDismiss} // Modal will handle email window logic
        onCancel={this.handleDismiss}
        ack
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handleShowArchiveModal = newState => {
    const { actions } = this.props;
    // Use explicit new state object for modal's form update and this.state for initial load
    const content = this.renderArchiveModal(newState || this.state);
    actions.emitModalContentUpdate(content);
  };

  handleNewVersionClick = e => {
    const { trilogyCase, actions } = this.props;
    const { dataset: { caseType } } = e.target; // data-case-type

    const handleConfirmNewVersion = () => {
      const newCase = createNewSubcaseVersion(trilogyCase, caseType);

      this.props.triggers.onSaveCase(newCase).then(savedTrilogyCase => {
        const caseReset = resetActionFlags(savedTrilogyCase, caseType);
        actions.emitInputBatchUpdate(caseReset, true);
        actions.emitModalContentClear();
      });
    };

    const content = canMakeNewVersion(trilogyCase, caseType)
      ? this.renderConfirmNewVersionModal(handleConfirmNewVersion)
      : this.renderNewVersionErrorModal(caseType);

    actions.emitModalContentUpdate(content);
  };

  reassignSubcase = (subcase, userId) => {
    const { trilogyCase, tacticalData, session } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const assigneeName = userId
      ? getAssignedUserName(users, userId, session)
      : null;
    if (subcase === 'masterCase') {
      let updatedCase = assigneeMastercase(trilogyCase, userId);
      updatedCase = assigneeNameMastercase(updatedCase, assigneeName);
      return updatedCase;
    }
    let updatedCase = assignSubcase(trilogyCase, subcase, userId);
    updatedCase = assigneeNameSubcase(updatedCase, subcase, assigneeName);
    return updatedCase;
  };

  reassignSubcaseConfirm = (subcase, assignee) => {
    this.props.triggers.onSaveCase(this.reassignSubcase(subcase, assignee));
  };

  assignSubcaseModal = (subcase, assignee) => {
    const { actions } = this.props;
    const content = (
      <ReassignAssigneeModal
        {...this.props}
        title="REASSIGN CASE"
        currentAssignee={assignee}
        handleAssign={assignTo =>
          this.reassignSubcaseConfirm(subcase, assignTo)
        }
      />
    );
    actions.emitModalContentUpdate(content, true);
  };

  handlePreviousVersionClick = page => {
    const { actions } = this.props;
    const content = this.renderPreviousVersionModal(page);
    actions.emitModalContentUpdate(content);
  };

  handleAuditHistoryClick = () => {
    const { actions } = this.props;
    const content = this.renderAuditHistoryModal();
    actions.emitModalContentUpdate(content);
  };

  // Unused, but could make a come-back
  handleReopenCase = () => {
    const { actions, trilogyCase, match } = this.props;
    const subcase = match.params.page;
    const handleConfirmed = () => {
      const reopenedCase = reopenSubcase(trilogyCase, subcase);
      this.props.triggers.onSaveCase(reopenedCase).then(savedTrilogyCase => {
        actions.emitInputBatchUpdate(
          resetActionFlags(savedTrilogyCase, subcase),
          true
        );
      });
      actions.emitModalContentClear();
    };

    const content = this.renderConfirmReopenModal(handleConfirmed);
    actions.emitModalContentUpdate(content);
  };

  handleFetchAEVersion = async aeVersion => {
    const aeVersionPage = window.open('', '_blank');
    fetchAEVersion(
      aeVersion,
      adverseEventHtml => {
        aeVersionPage.document.write(adverseEventHtml);
      },
      error => {
        console.error(error);
        aeVersionPage.document.write('Document Failed to Load.');
      }
    );
  };

  renderReconcileCaseLink = subcaseKey => (
    <AuthorizedLink
      accessRole={`edit-${subcaseKey}`}
      data-id="header.actions.reconcile-case"
      onClick={() => this.handleShowReconciliationModal(subcaseKey)}
      role="button"
    >
      Reconcile case
    </AuthorizedLink>
  );

  renderAssignToMeLink = subcaseKey => (
    <AuthorizedLink
      accessRole={subcaseKey !== 'masterCase' ? `edit-${subcaseKey}` : 'edit'}
      looseCheck={
        subcaseKey === 'masterCase' &&
        this.props.trilogyCase.affiliateCountryLabel
      }
      data-id="header.actions.assign-to-me"
      onClick={() =>
        this.reassignSubcaseConfirm(subcaseKey, this.props.session.sub)
      }
      role="button"
    >
      Assign to me
    </AuthorizedLink>
  );

  renderAssignToUserLink = (subcaseKey, subcasePath) => (
    <AuthorizedLink
      accessRole={subcaseKey !== 'masterCase' ? `edit-${subcaseKey}` : 'edit'}
      looseCheck={
        subcaseKey === 'masterCase' &&
        this.props.trilogyCase.affiliateCountryLabel
      }
      data-id="header.actions.assign"
      onClick={() =>
        this.assignSubcaseModal(
          subcaseKey,
          getOrElse(this.props.trilogyCase, subcasePath)
        )
      }
      role="button"
    >
      Assign to another Trilogy user
    </AuthorizedLink>
  );

  renderArchiveLink = page => {
    const { isCombinedCase } = this.state;
    return (
      <AuthorizedLink
        accessRole={isCombinedCase ? '' : `edit-${page}`}
        override={isNil(page) && isAuthorized('edit', true)}
        role="link"
        data-id="header.actions.archive"
        onClick={() => this.handleShowArchiveModal(null)}
      >
        Archive case
      </AuthorizedLink>
    );
  };

  renderGenerateEmailLink = page => (
    <AuthorizedLink
      accessRole={!isNil(page) ? `edit-${page}` : 'edit'}
      looseCheck={isNil(page)}
      data-id="header.actions.generateEmail"
      onClick={() => this.handleGenerateEmailModal(null)}
    >
      Generate Email
    </AuthorizedLink>
  );

  renderGenerateAckEmailLink = page => (
    <AuthorizedLink
      accessRole={!isNil(page) ? `edit-${page}` : 'edit'}
      looseCheck={isNil(page)}
      data-id="header.actions.generateAckEmail"
      onClick={() => this.handleGenerateAckEmailModal(null)}
    >
      Generate Ack Email
    </AuthorizedLink>
  );

  // Unused, but could make a come-back
  renderReopenLink = () => (
    <a
      role="link"
      data-id="header.actions.reopen"
      onClick={() => this.handleReopenCase()}
    >
      Re-open case
    </a>
  );

  // Note: Only renders when Status is new or in progress (see render())
  renderCompleteWithoutSubmit = () => {
    const { match, onCompleteWithoutSubmit } = this.props;

    return (
      <AuthorizedLink
        accessRole={`edit-${match.params.page}`}
        data-id="header.actions.complete"
        onClick={() => onCompleteWithoutSubmit()}
      >
        Complete without submission
      </AuthorizedLink>
    );
  };

  handleShowReconciliationModal = subcaseKey =>
    this.handleReconciliationChange('subcaseKey', subcaseKey);

  renderReconciliationModal = ({ subcaseKey, reason, additionalComments }) => (
    <div
      className={`${modalStyles.base} ${this.props.computedStyles.modal} ${
        modalStyles.tall
      }`}
    >
      <span className={modalStyles.title}>Why is no action needed?</span>
      <RadioGroup
        className={this.props.computedStyles.reason} // eslint-disable-line
        label="Please choose why no action is needed on the case:"
        options={[
          {
            label: `Updated fields not relevant to ${subcaseKey.toUpperCase()}`,
            value: 'not_relevant'
          },
          {
            label: 'Information related to sample return',
            value: 'info_related'
          },
          {
            label: `Updated information not relevant to ${subcaseKey.toUpperCase()}`,
            value: 'relevant_update'
          }
        ]}
        value={reason}
        onChange={value => this.handleReconciliationChange('reason', value)}
        locale="US"
      />
      <TextArea
        label="Comments"
        value={additionalComments}
        required
        validations={{
          required: {
            constraint: true
          }
        }}
        onChange={value =>
          this.handleReconciliationChange('additionalComments', value)
        }
        styles={{ height: '175px' }}
        locale="US"
      />
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={this.handleDismiss}>No, cancel</SimpleButton>
        <SimpleButton
          onClick={this.handleReconciliationSubmission}
          primary
          disabled={reason === '' || additionalComments === ''}
        >
          Reconcile Case
        </SimpleButton>
      </div>
    </div>
  );

  renderArchiveModal = ({ archiveReason, archiveComments }) => {
    const { computedStyles } = this.props;
    const isDisabled = archiveComments === '' || archiveReason === '';
    return (
      <div
        className={`${modalStyles.base} ${computedStyles.modal} ${
          modalStyles.tall
        }`}
      >
        <span className={modalStyles.title}>Archive Cases</span>
        <RadioGroup
          className={computedStyles.reason}
          label="Please choose why this case should be archived:"
          options={[
            {
              label:
                'Case created in error (No confirmation/acknowledgment email)',
              value: 'creation_error'
            },
            { label: 'Case is non-qualifiable', value: 'non_qualifiable' },
            { label: 'Case has insufficient data', value: 'insufficient_data' },
            {
              label: 'Follow-up to Existing Case',
              value: 'follow_up_existing_case'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]}
          value={archiveReason}
          onChange={value => this.handleArchiveChange('archiveReason', value)}
          locale="US"
        />
        <TextArea
          label="Comments"
          value={archiveComments}
          required
          validations={{
            required: {
              constraint: true
            }
          }}
          onChange={value => this.handleArchiveChange('archiveComments', value)}
          styles={{
            height: '175px'
          }}
          locale="US"
        />
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleDismiss}>No, cancel</SimpleButton>
          <SimpleButton
            onClick={this.handleArchiveConfirm}
            primary
            disabled={isDisabled}
          >
            Yes, continue
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderPreviousVersionLink = page => (
    <a
      data-id="header.actions.view-previous-versions"
      role="Link"
      onClick={() => this.handlePreviousVersionClick(page)}
    >
      View previous versions
    </a>
  );

  renderAuditHistoryLink = () => (
    <a
      data-id="header.actions.view-audit-history"
      role="Link"
      onClick={this.handleAuditHistoryClick}
    >
      View audit history
    </a>
  );

  // AE/PQ versions start at 0
  renderPreviousVersionModal = page => {
    const { computedStyles } = this.props;
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>Previous Versions</span>
        <hr />
        <span className={computedStyles.previousVersionSubtitle}>
          For information purposes only – Not for Quality use
        </span>
        <div
          style={{ height: '90vh' }}
          className={computedStyles.previousVersion}
        >
          {<PreviousVersion page={page} {...this.props} />}
          <div style={{ marginBottom: '25px' }}>
            <SimpleButton onClick={this.handleDismiss} primary>
              Back to Case
            </SimpleButton>
          </div>
          <div>
            <SimpleButton
              onClick={() => this.renderExportCasePdfModal(page)}
              primary
            >
              Export PDF of Case
            </SimpleButton>
          </div>
        </div>
      </div>
    );
  };

  renderAuditHistoryModal = () => {
    const { computedStyles } = this.props;
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>Case Audit History</span>
        <hr />
        <div
          style={{ height: '90vh' }}
          className={computedStyles.previousVersion}
        >
          {<AuditHistory auditType={AUDIT_TYPE_CASE} {...this.props} />}
          <div className={modalStyles.buttonsContainer}>
            <SimpleButton onClick={this.handleDismiss} primary>
              Back to Case
            </SimpleButton>
          </div>
        </div>
      </div>
    );
  };

  renderMakeNewVersionLink = page => (
    <AuthorizedLink
      accessRole={`edit-${page}`}
      data-id="header.actions.make-new-version"
      role="button"
      onClick={this.handleNewVersionClick}
      data-case-type={page}
    >
      Make new version
    </AuthorizedLink>
  );

  renderNewVersionErrorModal = (caseType = 'ae') => (
    <div className={modalStyles.base}>
      <span className={modalStyles.title}>Cannot Create New Version</span>
      {caseType === 'ae'
        ? 'Before you can create a new version, this case must be marked as submitted or completed without submission.'
        : 'Before you can create a new version, this case must be marked as submitted.'}
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={this.handleDismiss} primary>
          Back to Case
        </SimpleButton>
      </div>
    </div>
  );

  renderConfirmNewVersionModal = handleConfirm => (
    <div className={modalStyles.base}>
      <span className={modalStyles.title}>Confirm New Version</span>
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={this.handleDismiss}>Cancel</SimpleButton>
        <SimpleButton onClick={handleConfirm} primary>
          Confirm
        </SimpleButton>
      </div>
    </div>
  );

  // Unused, but could make a come-back
  renderConfirmReopenModal = handleConfirm => {
    const { computedStyles } = this.props;
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>Re-Open Case</span>
        <div>
          Please confirm you wish to re-open this case and move the status
          from&nbsp;
          <b>Complete</b> to <b>In Progress</b>.
        </div>
        <div className={computedStyles.reopenModalNote}>
          Note: This will not make a new version of the case.
        </div>
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleDismiss}>Cancel</SimpleButton>
          <SimpleButton onClick={handleConfirm} primary>
            Confirm
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderDuplicateSearchLink = (
    page,
    showContactsButton = false,
    fromCreateCase = false
  ) => (
    <AuthorizedLink
      accessRole={!isNil(page) ? `edit-${page}` : 'edit'}
      looseCheck={isNil(page)}
      role="button"
      onClick={() =>
        this.handleDuplicateSearchLink(
          showContactsButton,
          this.props.trilogyCase,
          fromCreateCase
        )
      }
    >
      Duplicate search
    </AuthorizedLink>
  );

  renderCloneCaseModal = () => {
    const { trilogyCase, actions, pageSchema: { path } } = this.props;
    let content;
    let assignee;
    let assigneeName;

    if (path === 'ae') {
      assignee = trilogyCase.subcases.adverseEvent.assignee;
      assigneeName = trilogyCase.subcases.adverseEvent.assigneeName;
    } else if (path === 'pq') {
      assignee = trilogyCase.subcases.productQuality.assignee;
      assigneeName = trilogyCase.subcases.productQuality.assigneeName;
    }

    // auto-assign case to current user if unassigned
    const { tacticalData, session } = this.props;
    if (!assignee || assignee !== session.sub) {
      this.reassignSubcaseConfirm(path, session.sub);
      assignee = session.sub;
      assigneeName = getAssignedUserName(
        getOrElse(tacticalData, 'document-data.user-list', []),
        session.sub,
        session
      );
    } else {
      this.props.triggers.onSaveCase(trilogyCase);
    }

    // removes X button from modal: used for loading spinner
    const displayXCloseButton = shouldDisplay => {
      actions.emitModalContentUpdate(content, false, shouldDisplay);
    };

    content = (
      <CloneCaseModal
        caseId={trilogyCase.id}
        subcaseType={path}
        assignee={assignee}
        assigneeName={assigneeName}
        onConfirm={this.handleDismiss}
        onCancel={this.handleDismiss}
        displayXCloseButton={displayXCloseButton}
        actions={actions}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  renderCloneCaseLink = page => (
    <AuthorizedLink
      accessRole={`edit-${page}`}
      role="button"
      onClick={() => this.renderCloneCaseModal()}
    >
      Clone Case
    </AuthorizedLink>
  );

  renderExportCasePdfModal = page => {
    const { actions } = this.props;
    actions.emitModalContentUpdate(
      <ExportCasePdfModal
        trilogyCaseId={this.props.trilogyCase.id}
        page={page}
        {...this.props}
      />
    );
  };

  renderExportCasePdfLink = page => (
    <a
      data-id="header.actions.export-pdf"
      role="Link"
      onClick={() => this.renderExportCasePdfModal(page)}
    >
      Export PDF of Case
    </a>
  );

  renderExportMCToPdfModal = () => {
    const { actions } = this.props;
    actions.emitModalContentUpdate(
      <ExportMCToPdfModal trilogyCaseId={this.props.trilogyCase.id} />
    );
  };

  renderExportMCToPDFLink = subcaseKey => (
    <AuthorizedLink
      accessRole={subcaseKey !== 'masterCase' ? `edit-${subcaseKey}` : ''}
      override={subcaseKey === 'masterCase'}
      data-id="header.actions.export_pdf"
      onClick={() => {
        this.renderExportMCToPdfModal();
      }}
      role="button"
    >
      Export Master Case to PDF
    </AuthorizedLink>
  );

  render = () => {
    const { match, trilogyCase } = this.props;
    const { isCombinedCase } = this.state;
    const page = match.params.page;
    const isSubcase = isSubcaseRoute(match);
    const subcasePath = `subcases.${CC_SUB_CASE_TYPES_MAP[page]}`;
    const reconciliationNeeded = getOrElse(
      trilogyCase,
      isSubcase
        ? `${subcasePath}.reconciliationNeeded`
        : 'reconciliationNeeded',
      false
    );
    const status = getOrElse(
      trilogyCase,
      isSubcase ? `${subcasePath}.status` : 'status',
      'NEW'
    );

    const STATUS_ACTIVE_ACTIONS = {
      // Case Creation
      undefined: (
        <div>
          {this.renderAssignToMeLink('masterCase')}
          {this.renderAssignToUserLink('masterCase', 'assignee')}
          {this.renderAuditHistoryLink()}
          {this.renderDuplicateSearchLink(page, true, true)}
          {isCombinedCase ? '' : this.renderArchiveLink(page)}
          {this.renderGenerateEmailLink(page)}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      ),
      ae: (
        <div>
          {this.renderAssignToMeLink('ae')}
          {this.renderAssignToUserLink('ae', 'subcases.adverseEvent.assignee')}
          {this.renderMakeNewVersionLink(page)}
          {reconciliationNeeded ? this.renderReconcileCaseLink('ae') : null}
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderArchiveLink(page)}
          {this.renderCloneCaseLink(page)}
          {this.renderDuplicateSearchLink(page, true)}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateEmailLink(page)}
          {this.renderGenerateAckEmailLink(page)}
          {this.renderCompleteWithoutSubmit()}
        </div>
      ),
      mi: (
        <div>
          {this.renderAssignToMeLink('mi')}
          {this.renderAssignToUserLink('mi', 'subcases.medicalInfo.assignee')}
          {this.renderArchiveLink(page)}
          {this.renderGenerateEmailLink(page)}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      ),
      pq: (
        <div>
          {this.renderAssignToMeLink('pq')}
          {this.renderAssignToUserLink(
            'pq',
            'subcases.productQuality.assignee'
          )}
          {this.renderMakeNewVersionLink(page)}
          {reconciliationNeeded ? this.renderReconcileCaseLink('pq') : null}
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderArchiveLink(page)}
          {this.renderCloneCaseLink(page)}
          {this.renderDuplicateSearchLink(page, true)}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateEmailLink(page)}
          {this.renderGenerateAckEmailLink(page)}
          {this.renderCompleteWithoutSubmit()}
        </div>
      ),
      review: (
        <div>
          {this.renderAssignToMeLink('masterCase')}
          {this.renderAssignToUserLink('masterCase', 'assignee')}
          {this.renderExportMCToPDFLink('masterCase')}
        </div>
      )
    };

    const STATUS_ARCHIVE_ACTIONS = {
      undefined: null,
      ae: (
        <div>
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      ),
      mi: null,
      pq: (
        <div>
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      )
    };

    const STATUS_COMPLETE_ACTIONS = {
      undefined: null,
      ae: (
        <div>
          {this.renderMakeNewVersionLink(page)}
          {reconciliationNeeded ? this.renderReconcileCaseLink('ae') : null}
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderDuplicateSearchLink(page)}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateEmailLink(page)}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      ),
      mi: <div>{this.renderGenerateEmailLink(page)}</div>,
      pq: (
        <div>
          {this.renderMakeNewVersionLink('pq')}
          {reconciliationNeeded ? this.renderReconcileCaseLink('pq') : null}
          {this.renderPreviousVersionLink(page)}
          {this.renderAuditHistoryLink()}
          {this.renderDuplicateSearchLink()}
          {this.renderExportCasePdfLink(page)}
          {this.renderGenerateEmailLink()}
          {this.renderGenerateAckEmailLink(page)}
        </div>
      ),
      review: (
        <div>
          {this.renderAssignToMeLink('')}
          {this.renderAssignToUserLink('', 'assignee')}
        </div>
      )
    };

    const ACTION_MENU_LINKS = {
      [CASE_STATUS_NEW]: STATUS_ACTIVE_ACTIONS,
      [CASE_STATUS_IN_PROGRESS]: STATUS_ACTIVE_ACTIONS,
      [CASE_STATUS_ARCHIVED]: STATUS_ARCHIVE_ACTIONS,
      [CASE_STATUS_COMPLETED]: STATUS_COMPLETE_ACTIONS
    };

    return (
      <PageHeader
        {...this.props}
        actionsMenuLinks={ACTION_MENU_LINKS[status][page]}
      />
    );
  };
}

export default withStyles(stylesGenerator)(CreateCaseHeader);
