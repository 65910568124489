/* eslint no-useless-escape:0 no-template-curly-in-string:0  */
/* eslint-disable global-require */

/**
 * caseReview.js is the schema for the Master Case Dashboard (/form/TExx-xxxxxxx/review/dashboard)
 * It includes/requires additional files to load the schemas for each subcase "Card" shown on the page.
 * These additional files are nested in the mocks/schemas/pages/subcases directory.
 */
module.exports = {
  path: 'review',
  title: 'Master Case',
  component: 'PropTypeSafety',
  tabs: [
    {
      title: 'Dashboard',
      component: 'Tab',
      path: 'dashboard',
      layout: {
        ' > div > div ': {
          alignItems: 'baseline',
          display: 'flex',
          justifyContent: 'flex-start'
        }
      },
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'dashboard' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          $ref: 'SharedCaseNotes',
          layout: {
            width: '43%'
          }
        },
        {
          id: 'subcase-listing',
          component: 'Section',
          title: '',
          statePath: 'subcases',
          layout: {
            alignItems: 'baseline',
            backgroundColor: 'transparent',
            display: 'inline-flex',
            marginLeft: 20,
            width: '48%'
          },
          elements: [
            {
              id: 'subcases-ae',
              statePath: 'adverseEvent',
              component: 'Section',
              layout: {
                display: 'block',
                paddingTop: 15,
                width: '100%',
                ' h3': {
                  cursor: 'pointer',
                  transition: 'transform 100ms ease'
                },
                ' h3:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 100ms ease'
                }
              },
              referencedProperties: {
                'layout.display': {
                  statePath: 'subcases.adverseEvent.createdDate',
                  $if: [{ $ne: null }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              },
              elements: require('./subcases/adverseEvent')
            },
            {
              component: 'Section',
              referencedProperties: {
                'layout.display': {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { 'data.newSubcaseType': { $eq: 'ae' } },
                    { $set: 'block' }
                  ],
                  $elseIf: [
                    {
                      'model.summary.narrative.categories.adverse_event': {
                        $eq: true
                      }
                    },
                    {
                      'model.subcases.adverseEvent.createdDate': {
                        $eq: null
                      }
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              },
              elements: require('./subcases/adverseEvent.form')
            },
            {
              id: 'subcases-pq',
              statePath: 'productQuality',
              component: 'InputGroup',
              multiple: false,
              collapsible: false,
              length: 1,
              layout: {
                display: 'block',
                width: '100%',
                ' h3': {
                  cursor: 'pointer',
                  transition: 'transform 100ms ease'
                },
                ' h3:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 100ms ease'
                }
              },
              elements: [
                {
                  component: 'PlainText',
                  label: 'Product Quality >',
                  value: '',
                  layout: {
                    display: 'block',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    transition: 'transform 100ms ease',
                    '&:hover': {
                      cursor: 'pointer',
                      transform: 'scale(1.1)'
                    }
                  },
                  triggers: {
                    onClick: ['onNavigateToSubcase', 'pq']
                  }
                },
                ...require('./subcases/productQuality')
              ],
              referencedProperties: {
                'layout.display': {
                  statePath: 'subcases.productQuality.createdDate',
                  $if: [{ $ne: null }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              component: 'Section',
              referencedProperties: {
                'layout.display': {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { 'data.newSubcaseType': { $eq: 'pq' } },
                    { $set: 'block' }
                  ],
                  $elseIf: [
                    {
                      'model.summary.narrative.categories.product_quality': {
                        $eq: true
                      }
                    },
                    {
                      'model.subcases.productQuality.createdDate': {
                        $eq: null
                      }
                    },
                    { $set: 'block' }
                  ],
                  $else: {
                    $set: 'none'
                  }
                }
              },
              elements: require('./subcases/productQuality.form')
            },
            {
              id: 'subcases-mi',
              statePath: 'medicalInfo',
              component: 'InputGroup',
              multiple: false,
              collapsible: false,
              length: 1,
              layout: {
                display: 'block',
                width: '100%',
                ' h3': {
                  cursor: 'pointer',
                  transition: 'transform 100ms ease'
                },
                ' h3:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 100ms ease'
                }
              },
              elements: [
                {
                  component: 'PlainText',
                  label: 'Medical Inquiry >',
                  value: '',
                  layout: {
                    display: 'block',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                    transition: 'transform 100ms ease',
                    '&:hover': {
                      cursor: 'pointer',
                      transform: 'scale(1.1)'
                    }
                  },
                  triggers: {
                    onClick: ['onNavigateToSubcase', 'mi']
                  }
                },
                ...require('./subcases/medicalInquiry')
              ],
              referencedProperties: {
                'layout.display': {
                  statePath: '/summary.narrative.categories.medical_inquiry',
                  $if: [{ $eq: true }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              id: 'aeSmartButton',
              component: 'SmartButton',
              label: 'Create Adverse Event',
              triggers: {
                onClick: ['onDashboardSubcaseCreation', 'ae']
              },
              layout: {
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
                width: '100%'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0',
                textAlign: 'center',
                width: '100%'
              },
              referencedProperties: {
                'layout.display': {
                  statePath: 'summary.narrative.categories.adverse_event',
                  $if: [{ $ne: true }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              id: 'pqSmartButton',
              component: 'SmartButton',
              label: 'Create Product Quality Complaint',
              triggers: {
                onClick: ['onDashboardSubcaseCreation', 'pq']
              },
              layout: {
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
                width: '100%'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0',
                textAlign: 'center',
                width: '100%'
              },
              referencedProperties: {
                'layout.display': {
                  statePath: 'summary.narrative.categories.product_quality',
                  $if: [{ $ne: true }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            },
            {
              id: 'miSmartButton',
              component: 'SmartButton',
              label: 'Create Medical Inquiry',
              value: '',
              disabled: true,
              triggers: {
                onClick: ['onSubcaseCreation', 'mi']
              },
              layout: {
                display: 'flex',
                flexDirection: 'row',
                marginTop: 20,
                width: '100%'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0',
                textAlign: 'center',
                width: '100%'
              },
              referencedProperties: {
                'layout.display': {
                  statePath: 'summary.narrative.categories.medical_inquiry',
                  $if: [{ $ne: true }, { $set: 'block' }],
                  $else: { $set: 'none' }
                }
              }
            }
          ]
        },
        {
          $ref: 'DocumentUploader'
        },
        {
          id: 'saveFromDashboard',
          component: 'Section',
          title: '',
          statePath: '',
          layout: {
            backgroundColor: 'transparent',
            flex: '0 1 auto',
            width: '100%'
          },
          elements: [
            {
              id: 'saveSmartButton',
              label: 'Save',
              component: 'SmartButton',
              triggers: {
                onClick: 'onSaveCaseCSE'
              },
              layout: {
                flex: '0 1 auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0'
              }
            }
          ]
        }
      ]
    },
    {
      title: 'Contacts',
      component: 'Tab',
      path: 'contacts',
      reconciliationPaths: ['contacts.contact', 'patient.patient'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'contacts' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'Contact',
          component: 'Section',
          title: 'Case Contacts',
          statePath: 'contacts',

          elements: [
            {
              $ref: 'LookupContactButton'
            },
            {
              $ref: 'ContactList',
              statePath: 'contact',
              label: 'another contact',
              length: 1
            }
          ]
        },
        {
          id: 'patient',
          component: 'Section',
          title: 'Patient Information',
          statePath: 'patient',

          elements: [
            {
              $ref: 'LookupPatientButton'
            },
            {
              $ref: 'PatientInfo',
              statePath: 'patient'
            }
          ]
        },
        {
          id: 'submitFromReviewContacts',
          component: 'Section',
          title: '',
          statePath: '', // Tiger will remove empty statePath
          elements: [
            {
              id: 'saveSmartButton',
              label: 'Save',
              component: 'SmartButton',
              triggers: {
                onClick: 'onSaveCaseCSE'
              },
              layout: {
                width: '50%',
                display: 'flex',
                flexDirection: 'row'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0'
              }
            }
          ],
          layout: {
            background: 'none transparent',
            padding: '20px'
          }
        }
      ]
    },
    {
      title: 'Tracking',
      component: 'Tab',
      path: 'tracking',
      reconciliationPaths: ['tracking', 'comments', 'reference_numbers'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'tracking' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: 'reference_numbers',
          title: 'Reference Numbers',
          elements: [
            { $ref: 'ReferenceNumber' },
            {
              $ref: 'LinkedCases',
              statePath: '/linkedCases'
            },
            {
              $ref: 'ClonedFrom'
            },
            { $ref: 'AERNumbers' }
          ]
        },
        {
          $ref: 'TrackingInformationSection',
          title: 'Tracking Information',
          statePath: 'tracking'
        },
        {
          $ref: 'InternalCommentsSection',
          statePath: 'comments'
        },
        {
          id: 'submitFromReviewTracking',
          component: 'Section',
          title: '',
          statePath: '', // Tiger will remove empty statePath
          elements: [
            {
              id: 'saveSmartButton',
              label: 'Save',
              component: 'SmartButton',
              triggers: {
                onClick: 'onSaveCaseCSE'
              },
              layout: {
                width: '50%',
                display: 'flex',
                flexDirection: 'row'
              },
              styles: {
                backgroundColor: '#F2F2F2',
                color: '#00A9E0',
                border: '2px solid #00A9E0'
              }
            }
          ],
          layout: {
            background: 'none transparent',
            padding: '20px'
          }
        }
      ]
    },
    { $ref: 'SharedInfo' }
  ]
};
