import { Card, Collapse, Icon } from '@blueprintjs/core';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import stylesGenerator from '../CollapseCard/styles';
import withStyles from '../../../Common/components/Form/withStyles';

class CollapseCard extends PureComponent {
  static propTypes = {
    cardContents: PropTypes.element,
    cardTitle: PropTypes.string.isRequired,
    computedStyles: PropTypes.shape({
      cardSection: PropTypes.object.isRequired,
      cardTitle: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    cardContents: <div />
  };

  state = {
    isCollapsed: false
  };

  handleCardCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    const { computedStyles, cardContents, cardTitle } = this.props;
    const { isCollapsed } = this.state;

    const getIconName = () =>
      this.state.isCollapsed ? 'pt-icon-caret-up' : 'pt-icon-caret-down';

    return (
      <Card
        className={`${computedStyles.cardSection}`}
        interactive="false"
        elevation="1"
      >
        <div
          onClick={this.handleCardCollapse}
          className={`${computedStyles.cardTitle}`}
          role="button"
        >
          <h4>{cardTitle}</h4>
          <Icon iconName={getIconName()} />
        </div>
        <Collapse isOpen={!isCollapsed}>{cardContents}</Collapse>
      </Card>
    );
  }
}

export default withStyles(stylesGenerator)(CollapseCard);
