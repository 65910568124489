/* eslint no-useless-escape:0 no-template-curly-in-string:0 */

/**
 * contactLookup.js is the schema for the Contact Lookup window that opens from the Case schema.
 * This window is triggered via masterCase.js schema with 'onLookupContact' action.
 */
module.exports.schema = {
  id: 'contactLookup',
  locale: 'US',
  pages: [
    {
      title: 'Contact Look-up',
      elements: [
        {
          component: 'Section',
          layout: {
            background: 'initial',
            margin: 'initial'
          },
          elements: [
            {
              label: 'First Name',
              component: 'TextInput',
              statePath: 'name.first',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Last Name',
              component: 'TextInput',
              statePath: 'name.last',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Service Provider (PSP)',
              component: 'Select',
              statePath: 'psp.name',
              options: [],
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.psp-options.psp_name'
                }
              }
            },
            {
              label: 'Type of Contact',
              component: 'Select',
              statePath: 'type',
              options: [],
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.mastercase-options.type_of_contact'
                }
              }
            },
            {
              label: 'Organization/Company',
              component: 'TextInput',
              statePath: 'organization',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'City',
              component: 'TextInput',
              statePath: 'address.city',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Country',
              component: 'Select',
              statePath: 'country',
              options: [],
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.country-options'
                }
              }
            },
            {
              label: 'State/Province/Region',
              component: 'TextInput',
              statePath: 'address.state',
              layout: {
                flex: '0 1 auto',
                width: '33%',
                marginRight: '34%'
              }
            },
            {
              label: 'Phone Number',
              component: 'TextInput',
              statePath: 'phone.number',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Email',
              component: 'TextInput',
              statePath: 'email.address',
              layout: {
                flex: '0 1 auto',
                width: '33%',
                marginRight: '34%'
              }
            }
          ]
        }
      ]
    }
  ]
};

// Convenience for rendering the initial form object in plaintext
module.exports.stringified = JSON.stringify(module.exports.form, null, 2);
