import { generateCSS } from 'Common/components/Form';

const stylesGenerator = (theme, { layout, collapsible }) => ({
  base: generateCSS({
    background: theme.colors.base,
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    width: '100%',

    ' > div:only-child': {
      padding: 15,
      marginBottom: 15
    },

    ' > div:last-child': {
      borderTop: collapsible ? `1px solid ${theme.colors.tertiary}` : 'none',
      padding: 0,
      margin: '0 15px',
      height: 50,
      justifyContent: 'flex-start',
      width: '100%',
      color: theme.colors.secondary,
      fontWeight: 'bold',
      fontSize: 14
    },

    ...layout
  }),

  headerText: generateCSS({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15
  }),

  error: generateCSS({
    color: theme.colors.error
  }),

  group: generateCSS({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    position: 'relative',
    width: '100%',
    // if there's a collapsible label AND a title, add spacing
    '> h3 + div > h2': {
      marginTop: 10
    },

    '& + div:last-child': {
      padding: '15px 0',
      marginBottom: 15,
      width: 'calc(100% - 30px)'
    },

    ' > div': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%'
    },

    ' section > span:last-child, section + span:last-child': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      transformOrigin: 'top left',
      marginBottom: 0,
      fontSize: 12,

      ' > button': {
        transform: 'scale(0.7)'
      }
    }
  }),

  title: generateCSS({
    padding: '0 15px 5px',
    ...theme.fonts.inputGroupTitle
  }),

  nowrap: generateCSS({
    '&&': {
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',

      ' > div:last-child': {
        alignSelf: 'flex-start',
        top: 0,
        right: -13
      }
    }
  }),

  label: collapsed =>
    generateCSS({
      fontSize: '18px',
      fontFamily: theme.fonts.default.fontFamily,
      fontWeight: 'bold',
      borderTop: collapsible ? `1px solid ${theme.colors.tertiary}` : 'none',
      padding: '10px 0',
      margin: '0 15px',
      width: '100%',

      ' > img': {
        display: collapsible ? 'initial' : 'none',
        position: 'absolute',
        right: 20,
        transform: collapsible && collapsed ? 'rotate(-90deg)' : 'rotate(0deg)'
      }
    }),

  defaultLabel: generateCSS({
    textTransform: 'capitalize'
  }),

  inlineButton: generateCSS({
    display: 'inline-block',
    position: 'absolute',
    right: -15,
    transform: 'scale(0.8) translate3d(0, 100%, 0)'
  }),

  inlineLabelButton: generateCSS({
    display: 'inline-block',
    position: 'absolute',
    top: 3,
    right: -15,
    transform: 'scale(0.8)'
  }),

  // TODO fix importing these modal styles from src/Common/components/withModal/Modal/styles.js
  modalBase: generateCSS({
    minWidth: '360px'
  }),

  modalTitle: generateCSS({
    margin: '10px 0 15px',
    display: 'block',
    font: '16px SackersGothicStd-Heavy',
    color: '#071D49',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),

  modalButtonsContainer: generateCSS({
    display: 'block',
    margin: '20px 0',
    padding: '15px',
    width: '100%',
    left: '0',
    bottom: '0',
    '> button:nth-child(1)': {
      float: 'left'
    },
    '> button:nth-child(2)': {
      float: 'right'
    }
  })
});

export default stylesGenerator;
