import { get, isEmpty } from 'lodash';

import fileIcon from 'public/images/type-txt.svg';
import importIcon from 'public/images/import-icon.svg';
import editIcon from 'public/images/edit.svg';
import removeIcon from 'public/images/remove.svg';

import {
  generateCSS,
  reverseText,
  genDisabledStyles,
  genInvalidBorder,
  genErrorStyles,
  genLabelStyles
} from '../index';

const stylesGenerator = (theme, props) => {
  const errorStyles = genErrorStyles(theme);
  const disabledStyles = genDisabledStyles(theme);
  const invalidStyles = genInvalidBorder(theme);
  const labelStyles = genLabelStyles(theme, props);

  return {
    base: generateCSS({
      position: 'relative',
      width: '100%',
      ...props.styles,

      ' .pt-overlay.pt-overlay-inline': {
        position: 'absolute',
        top: 40,
        right: 0
      },

      '&> div:not(.modal) > div': {
        height: '1.3em'
      },

      '& > div > div > strong': {
        display: 'inline-block',
        textIndent: 10
      }
    }),

    textInput: generateCSS({
      flex: '1 0 auto',
      ...props.styles,
      ...theme.fonts.input,
      ...disabledStyles,
      borderRadius: '0',
      border: `2px solid ${theme.colors.primary}`,
      boxShadow: 'none',
      ':focus': {
        border: `2px solid ${theme.colors.active}`
      },
      '::selection': {
        outline: 'none'
      },
      '::placeholder': {
        padding: '0 6px',
        color: theme.colors.quinary,
        fontSize: '18px'
      },
      ...reverseText(get(props, 'config.locale')),
      ...(isEmpty(props.errors) ? {} : invalidStyles),
      marginBottom: 3
    }),

    hiddenInput: generateCSS({
      height: 0,
      padding: 0,
      margin: 0,
      display: 'block',
      visibility: 'hidden',
      pointerEvents: 'none'
    }),

    inputRow: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    }),

    label: generateCSS({
      ...theme.fonts.inputLabel
    }),

    helpText: generateCSS({
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    }),

    overlayContainer: generateCSS({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '654px',

      '& > span': {
        display: 'block',
        width: '60vw',
        '> .pt-overlay-content': {
          zIndex: 15
        }
      }
    }),

    inputContainer: generateCSS({
      alignItems: 'center',
      backgroundColor: theme.colors.base,
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      width: '100%',

      ' > div': {
        flex: '1 0 auto',
        width: '100%'
      },

      ' > i': {
        border: '2px solid',
        borderRadius: '100%',
        padding: 2
      },

      pointerEvents: props.disabled ? 'none' : 'all',
      opacity: props.disabled ? '0.5' : '1',
      cursor: props.disabled ? 'not-allowed' : 'initial'
    }),

    modalContainer: generateCSS({
      alignItems: 'center',
      backgroundColor: theme.colors.base,
      display: 'flex',
      cursor: 'pointer',
      flexDirection: 'column',
      minHeight: 600,
      height: '100%',
      width: '60vw'
    }),

    dropzone: generateCSS({
      backgroundImage: `url("${importIcon}")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '60px 60px',
      backgroundPosition: 'center',
      alignItems: 'center',
      border: `1px dashed ${theme.colors.primary}`,
      cursor: 'pointer',
      display: 'flex',
      padding: 10,
      position: 'relative',
      justifyContent: 'center',
      height: 150,
      width: '100%',
      maxWidth: '60vw',

      '& > div': {
        position: 'absolute',
        bottom: 20
      },

      '& > div > strong': {
        cursor: 'pointer'
      }
    }),

    fileList: generateCSS({
      padding: 0,
      margin: 0,
      listStyle: 'none',
      width: '100%'
    }),

    title: generateCSS({
      margin: '15px 15px 30px',
      padding: '0',
      width: '100%',
      textDecoration: 'none',
      marginBottom: 30,
      ...theme.fonts.sectionTitle,
      fontSize: 14
    }),

    upload: generateCSS({
      textDecoration: 'none',
      fontWeight: 'bold',
      color: 'initial',
      marginLeft: '10px'
    }),

    fileListItem: generateCSS({
      borderBottom: `1px solid ${theme.colors.primary}`,
      display: 'flex',
      padding: '15px 0',
      width: '100%',
      flexWrap: 'Wrap',
      // Document icon column
      '& > div:first-child > div:first-child': {
        maxWidth: '45px',
        marginRight: '10px'
      },
      // File info column
      '& > div:first-child > div + div': {
        flex: '1 1 auto',
        width: '100%'
      },
      // File info column - file name
      '& > div:first-child > div + div > span > a': {
        lineHeight: '2em',
        color: theme.colors.secondary
      },
      // Document icon
      '& > div:first-child > div > i': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '40px',
        textIndent: 5,
        position: 'relative',

        backgroundImage: `url("${fileIcon}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        height: 40,
        width: 50
      },

      '& > div:first-child > div > i > span': {
        backgroundColor: theme.colors.base,
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 8,
        textIndent: -2
      }
    }),

    listItemRow: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      flex: '1 0 auto',
      width: '50%',

      '&.with-padding': {
        padding: '10px'
      },

      '&.with-padding:first-child': {
        paddingLeft: '0px'
      },

      '&.with-padding:last-child': {
        paddingRight: '0px'
      }
    }),

    listItemColumn: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '50%'
    }),

    removeButton: generateCSS({
      alignSelf: 'flex-end',
      backgroundImage: `url("${removeIcon}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '20px 20px',
      borderRadius: '100%',
      height: 22,
      width: 22,

      '&:hover, &:active, &:focus': {
        backgroundImage: `url("${removeIcon}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '20px 20px'
      }
    }),

    editButton: generateCSS({
      alignSelf: 'flex-end',
      backgroundImage: `url("${editIcon}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '20px 20px',
      borderRadius: '100%',
      height: 22,
      width: 22,

      '&:hover, &:active, &:focus': {
        backgroundImage: `url("${editIcon}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '20px 20px'
      }
    }),

    editItemContainer: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '50%',

      '&> div': {
        width: '100%'
      },

      pointerEvents: props.disabled ? 'none' : 'all',
      opacity: props.disabled ? '0.5' : '1',
      cursor: props.disabled ? 'not-allowed' : 'initial'
    }),
    someContainer: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      '&> div > textarea': {
        height: 100,
        width: '100%'
      },
      pointerEvents: props.disabled ? 'none' : 'all',
      opacity: props.disabled ? '0.5' : '1',
      cursor: props.disabled ? 'not-allowed' : 'initial'
    }),

    actionContainer: generateCSS({
      justifyContent: 'flex-end',
      display: 'flex',
      flex: '0 0 auto',
      minHeight: 45
    }),

    fileInputText: generateCSS({
      cursor: 'pointer'
    }),

    fileInputTextDisabled: generateCSS({
      color: theme.colors.primary,
      cursor: 'not-allowed'
    }),

    iconDisabled: generateCSS({
      color: theme.colors.primary,
      cursor: 'not-allowed'
    }),

    editFileContainer: generateCSS({
      width: '100%',
      '&> div': {
        marginBottom: 10,
        width: '100%'
      },

      '&> div > textarea': {
        height: 100,
        width: '100%'
      }
    }),

    editLabel: generateCSS({
      ...theme.fonts.helpText
    }),

    modalButtonContainer: generateCSS({
      display: 'flex',
      paddingTop: 15,
      justifyContent: 'space-between',
      width: '100%',
      minHeight: '49px'
    }),

    buttonBase: generateCSS({
      padding: '7px 10px 6px',
      fontFamily: theme.fonts.default.fontFamily,
      color: theme.colors.brand,
      fontWeight: '600',
      fontSize: '16px',
      border: `1px solid ${theme.colors.base}`,
      background: theme.colors.base
    }),

    buttonPrimary: generateCSS({
      color: theme.colors.base,
      fontWeight: '300',
      fontSize: '14px',
      textTransform: 'uppercase',
      border: `1px solid ${theme.colors.brand}`,
      background: theme.colors.brand
    }),
    filter: generateCSS({
      padding: '0 10px 0 0',
      display: 'inline-block',
      width: '100%',
      ':last-child': {
        padding: '0'
      },
      '> .Select': {
        '> .Select-control': {
          boxShadow: 'inset 0 0 0 2px #9B9B9B',
          width: '98%'
        }
      }
    }),
    fileNameBold: generateCSS({
      fontWeight: 'bold'
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
