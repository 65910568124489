import { CASE_SERVICES_API } from 'config/values';

const fetchCloneCase = async (
  parentCaseId,
  assignee,
  assigneeName,
  subcaseToClone,
  cloneAmount,
  onSuccess,
  onFailure
) => {
  // eslint-disable-next-line no-undef
  const url = new URL(`${CASE_SERVICES_API.uri}/cloneCase`);

  try {
    const response = await fetch(url.toString(), {
      method: 'PUT',
      body: JSON.stringify({
        parentCaseId,
        assignee,
        assigneeName,
        subcaseToClone,
        cloneAmount
      }),
      headers: {
        'x-api-key': CASE_SERVICES_API.apiKey,
        'Content-Type': 'application/json'
      }
    });
    const { clonedCases, failedClones, errors } = response;
    if (response.errors || (failedClones && failedClones.length)) {
      throw new Error(
        `${
          !errors ? `${clonedCases.length}` : '0'
        } of ${cloneAmount} total cases created`
      );
    } else {
      onSuccess(response.json);
    }
  } catch (err) {
    onFailure(err);
  }
};

export default fetchCloneCase;
