export const FORM_MI_PATH = 'mi';
export const FORM_PQ_PATH = 'pq';
export const FORM_AE_PATH = 'ae';

export const FORM_BASE_PATH = 'form';
export const CASE_FORM_PATH = `/${FORM_BASE_PATH}/:masterCaseId?/:page?/:tab?`;

// Used to get the master from from the CMS (while we're on mocks, at least)
export const MASTER_FORM_KEY = 'master';

export const MEDICAL_INQUIRY = 'medical_inquiry';
export const PRODUCT_QUALITY = 'product_quality';
export const ADVERSE_EVENT = 'adverse_event';

export const SUB_CASE_FORM_ROUTES_MAP = {
  [MEDICAL_INQUIRY]: FORM_MI_PATH,
  [PRODUCT_QUALITY]: FORM_PQ_PATH,
  [ADVERSE_EVENT]: FORM_AE_PATH
};
