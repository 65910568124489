import { generateCSS } from 'Common/components/Form';

const stylesGenerator = (theme, props) => {
  const columnsLength = props.children.length;

  return {
    base: generateCSS({
      position: 'relative',
      width: '100%',
      border: `2px solid ${theme.colors.background}`,
      background: theme.colors.base,
      ':hover': {
        border: `2px solid ${theme.colors.tertiary}`
      },

      '> td': {
        position: 'relative',
        padding: '10px 10px 10px 5px',
        minWidth: '130px',

        '> div': {
          verticalAlign: 'top',
          height: '60px',
          borderRight: `1px solid ${theme.colors.background}`
        },

        [`:nth-child(${columnsLength}) > div`]: {
          borderRight: 'none'
        }
      },
      '> td:first-child': {
        position: 'relative',
        padding: '10px 10px 10px 5px',
        minWidth: '10px'
      }
    }),

    expandedViewContainer: generateCSS({
      width: '100%',
      borderRight: `1px solid ${theme.colors.background}`,
      background: theme.colors.background,
      '> td': {
        position: 'relative'
      }
    }),

    expandedView: generateCSS({
      padding: '0'
    }),

    transition: generateCSS({
      overflow: 'hidden',
      maxHeight: props.isExpanded ? 'auto' : '0',
      minHeight: props.isExpanded ? '100px' : '0',
      transition: 'all 375ms ease'
    }),

    gap: generateCSS({
      padding: '0'
    })
  };
};

export default stylesGenerator;
