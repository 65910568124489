import React, { PureComponent } from 'react';
import { chain, filter, get, isNil, kebabCase } from 'lodash';
import PropTypes from 'prop-types';

import ascending from 'public/images/ascending.svg';
import descending from 'public/images/descending.svg';
import {
  CONTACT_PATHS,
  PATIENT_PATHS,
  CONTACT_HEADER,
  PATIENT_HEADER,
  LOOKUP_CONTACT
} from 'Lookup/constants';
import {
  getContactDetails,
  getPatientDetails,
  getContactDetailTitle,
  getPatientDetailTitle
} from 'Lookup/utils';
import { withStyles, SimpleButton, LabelValue } from 'Common/components/Form';
import { N_A, CMS_PROP_TYPES } from 'Common/constants';
import { getProtoLabel, getTacticalLabel } from 'Common/utils';
import { abbvieDateFormat } from 'Common/components/Form/utils';
import { modalStyles } from 'Common/components/withModal';
import stylesGenerator from './styles';

class SearchResults extends PureComponent {
  static propTypes = {
    onSortClick: PropTypes.func,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      columnGroup: PropTypes.object.isRequired,
      columnHeader: PropTypes.object.isRequired,
      primaryColor: PropTypes.object.isRequired,
      secondaryColor: PropTypes.object.isRequired,
      sortImage: PropTypes.object.isRequired,
      tableCell: PropTypes.object.isRequired,
      viewLink: PropTypes.object.isRequired
    }).isRequired,
    actions: PropTypes.shape({
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    lookupType: PropTypes.string.isRequired,
    lookupWindow: PropTypes.instanceOf(window.constructor).isRequired,
    caseSchemaFields: PropTypes.shape({
      caseContactType: PropTypes.object,
      country: PropTypes.object,
      patientGender: PropTypes.object
    }),
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired
  };

  static defaultProps = {
    onSortClick: () => {},
    caseSchemaFields: {}
  };

  defineColumnGroups = () => {
    const { computedStyles, lookupType } = this.props;
    const HEADER_DATA =
      lookupType === LOOKUP_CONTACT ? CONTACT_HEADER : PATIENT_HEADER;

    return HEADER_DATA.map((headerData, i) => (
      <colgroup
        key={headerData.groupLabel}
        span={headerData.columns.length}
        className={
          i === 0 ? computedStyles.secondaryColor : computedStyles.primaryColor
        }
      />
    ));
  };

  handleAddToCase = result => {
    const { lookupType, lookupWindow } = this.props;
    if (lookupType === LOOKUP_CONTACT) {
      lookupWindow.handleAddContact(result);
    } else {
      lookupWindow.handleAddPatient(result);
    }
    lookupWindow.close();
  };

  handleViewDetails = result => {
    const { actions, lookupType, schema, tacticalData } = this.props;

    const handleAddToCase = () => {
      actions.emitModalContentClear();
      this.handleAddToCase(result);
    };

    const contentMapping =
      lookupType === LOOKUP_CONTACT
        ? getContactDetails(result, schema, tacticalData)
        : getPatientDetails(result, schema, tacticalData);
    const title =
      lookupType === LOOKUP_CONTACT
        ? getContactDetailTitle(result, schema, tacticalData)
        : getPatientDetailTitle(result);
    const content = this.renderDetailsModal(
      title,
      contentMapping,
      handleAddToCase
    );
    actions.emitModalContentUpdate(content);
  };

  renderTableHeader = () => {
    const { lookupType } = this.props;
    const HEADER_DATA =
      lookupType === LOOKUP_CONTACT ? CONTACT_HEADER : PATIENT_HEADER;
    return (
      <thead>
        <tr>{HEADER_DATA.map(this.renderColumnGroups)}</tr>
        <tr>{HEADER_DATA.map(this.renderTableHeaders)}</tr>
      </thead>
    );
  };

  renderColumnGroups = headerData => {
    const { computedStyles } = this.props;
    return (
      <th
        key={headerData.groupLabel}
        colSpan={headerData.columns.length}
        scope="colgroup"
        className={computedStyles.columnGroup}
      >
        {headerData.groupLabel}
      </th>
    );
  };

  renderDetailsModal = (title, contentMapping, handleConfirm) => {
    const { computedStyles } = this.props;
    return (
      <div className={modalStyles.base}>
        <span className={modalStyles.title}>{title}</span>
        <div className={computedStyles.modalDetails}>
          {filter(contentMapping, mapping => !isNil(mapping.value)).map(
            mapping => (
              <LabelValue
                key={`contact-detail-${kebabCase(mapping.label)}`}
                label={mapping.label}
                value={mapping.value}
              />
            )
          )}
        </div>
        <div className={modalStyles.buttonsContainerSingle}>
          <SimpleButton onClick={handleConfirm} primary role="button">
            Add to Case
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderTableHeaders = headerData => {
    const { computedStyles } = this.props;
    return headerData.columns.map(cell => (
      <th key={cell.id} scope="col" className={computedStyles.columnHeader}>
        {cell.label}
      </th>
    ));
  };

  renderSortArrow = sortBy => {
    const { onSortClick, computedStyles } = this.props;
    const handleSortClick = order => onSortClick(sortBy, order);

    return (
      <div className={computedStyles.sortDiv}>
        <img
          className={computedStyles.sortImage}
          src={ascending}
          onClick={() => handleSortClick('asc')}
        />
        <img
          className={computedStyles.sortImage}
          src={descending}
          onClick={() => handleSortClick('desc')}
        />
      </div>
    );
  };

  renderTableBody = () => {
    const { results, lookupType } = this.props;
    return (
      <tbody>
        {chain(results)
          .filter('id')
          .map(
            result =>
              lookupType === LOOKUP_CONTACT
                ? this.renderContactRow(result)
                : this.renderPatientRow(result)
          )
          .value()}
      </tbody>
    );
  };

  renderContactRow = result => {
    const { computedStyles, tacticalData } = this.props;
    const contactProtoValue = get(result, CONTACT_PATHS.CONTACT_TYPE);
    const contactOptions = get(
      tacticalData,
      'document-data.mastercase-options.type_of_contact',
      []
    );
    const contactTypeFormatted = getTacticalLabel(
      contactOptions,
      contactProtoValue,
      N_A
    );

    const countryProtoValue = get(result, CONTACT_PATHS.COUNTRY, N_A);
    const countryOptions = get(
      tacticalData,
      'document-data.country-options',
      []
    );
    const countryFormatted = getTacticalLabel(
      countryOptions,
      countryProtoValue,
      N_A
    );
    return (
      <tr key={result.id}>
        <td className={computedStyles.tableCell}>
          {get(result, CONTACT_PATHS.LAST_NM, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, CONTACT_PATHS.FIRST_NM, N_A)}
        </td>
        <td className={computedStyles.tableCell}>{contactTypeFormatted}</td>
        <td className={computedStyles.tableCell}>
          {get(result, CONTACT_PATHS.ORGANIZATION, N_A)}
        </td>
        <td className={computedStyles.tableCell}>{countryFormatted}</td>
        <td className={computedStyles.tableCell}>
          {get(result, CONTACT_PATHS.CITY, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, CONTACT_PATHS.STATE, N_A)}
        </td>
        <td className={computedStyles.viewLink}>
          <a onClick={() => this.handleViewDetails(result)} role="button">
            View
          </a>
        </td>
        <td className={computedStyles.viewLink}>
          <a onClick={() => this.handleAddToCase(result)} role="button">
            Add
          </a>
        </td>
      </tr>
    );
  };

  renderPatientRow = result => {
    const { computedStyles, caseSchemaFields, tacticalData } = this.props;
    const genderProtoValue = get(result, PATIENT_PATHS.GENDER, N_A);
    const genderFormatted = getProtoLabel(
      caseSchemaFields.patientGender,
      genderProtoValue,
      N_A
    );
    const countryProtoValue = get(result, PATIENT_PATHS.COUNTRY, N_A);
    const countryOptions = get(
      tacticalData,
      'document-data.country-options',
      []
    );
    const countryFormatted = getTacticalLabel(
      countryOptions,
      countryProtoValue,
      N_A
    );
    return (
      <tr key={result.id}>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.LAST_NM, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.FIRST_NM, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.THIRD_PARTY_ID, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.INITIALS, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {abbvieDateFormat(get(result, PATIENT_PATHS.DOB)) || N_A}
        </td>
        <td className={computedStyles.tableCell}>{genderFormatted}</td>
        <td className={computedStyles.tableCell}>{countryFormatted}</td>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.CITY, N_A)}
        </td>
        <td className={computedStyles.tableCell}>
          {get(result, PATIENT_PATHS.STATE, N_A)}
        </td>
        <td className={computedStyles.viewLink}>
          <a onClick={() => this.handleViewDetails(result)} role="button">
            View
          </a>
        </td>
        <td className={computedStyles.viewLink}>
          <a onClick={() => this.handleAddToCase(result)} role="button">
            Add
          </a>
        </td>
      </tr>
    );
  };

  render() {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.base}>
        <table cellSpacing="0">
          {this.defineColumnGroups()}
          {this.renderTableHeader()}
          {this.renderTableBody()}
        </table>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(SearchResults);
