/* eslint import/prefer-default-export:0 */
import {
  trim,
  isNil,
  isString,
  isArray,
  set,
  mapValues,
  reduce,
  isEmpty,
  isObject,
  isPlainObject
} from 'lodash';

import { EMPTY_SELECTION } from 'Common/components/Form';

/**
 * @param {string|Object[]}
 * @returns {boolean}
 */
export const isFalsyValue = value => {
  const isEmptyString = isString(value) && trim(value) === '';
  const isEmptyArray = isArray(value) && value.length === 0;
  const isEmptySelection = value === EMPTY_SELECTION;
  return isNil(value) || isEmptyString || isEmptyArray || isEmptySelection;
};

export const pruneEmpties = obj => {
  let pruned;

  if (!isObject(obj)) return obj;
  if (isArray(obj))
    pruned = obj.map(v => pruneEmpties(v)).filter(v => !isNil(v));
  if (isPlainObject(obj))
    pruned = reduce(
      mapValues(obj, v => pruneEmpties(v)),
      (filtered, val, key) =>
        !isNil(val) ? set(filtered, key, val) : filtered,
      {}
    );

  return isEmpty(pruned) ? null : pruned;
};
