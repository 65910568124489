import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, NonIdealState } from '@blueprintjs/core';

import withStyles from 'Common/components/Form/withStyles';
import { generateCSS } from 'Common/components/Form';
import stylesGenerator from './styles';
import CreateTaskLink from './CreateTaskLink';

const Layout = ({
  children,
  computedStyles,
  onTaskCreate,
  shouldHideCreateNewTaskLink,
  isLoading,
  inProgressView,
  isAssigningTask,
  ...props
}) => {
  if (isLoading || inProgressView || isAssigningTask) {
    const loadingMessage = (
      <span>Please wait while we retrieve your tasks.</span>
    );
    return (
      <div className={generateCSS({ margin: '100px auto' })}>
        <NonIdealState
          visual={<Spinner />}
          title="Loading"
          description={loadingMessage}
        />
      </div>
    );
  }

  return (
    <div className={computedStyles.base}>
      {!shouldHideCreateNewTaskLink ? (
        <CreateTaskLink {...props} onTaskCreate={onTaskCreate} />
      ) : null}
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  onTaskCreate: PropTypes.func.isRequired,
  shouldHideCreateNewTaskLink: PropTypes.bool.isRequired,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  isLoading: PropTypes.bool,
  inProgressView: PropTypes.bool,
  isAssigningTask: PropTypes.bool
};

Layout.defaultProps = {
  isLoading: false,
  inProgressView: false,
  isAssigningTask: false
};

export default withStyles(stylesGenerator)(Layout);
