import { generateCSS } from 'Common/components/Form';

const styleGenerator = theme => ({
  base: generateCSS({}),

  dueDatePast: generateCSS({
    color: theme.colors.error,
    fontWeight: 'bold'
  }),

  dueDateSoon: generateCSS({
    color: theme.colors.orange,
    fontWeight: 'bold'
  })
});

export default styleGenerator;
