import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  searchBox: generateCSS({
    height: '30px',
    width: '250px',
    margin: '10px 0 15px',
    border: 'none',
    '::placeholder': {
      padding: '0 10px',
      color: '#D1D1D1',
      fontWeight: '200',
      font: '16px Calibri'
    }
  }),

  searchIcon: generateCSS({
    verticalAlign: 'middle',
    marginLeft: '-30px',
    cursor: 'pointer'
  })
});

export default stylesGenerator;
