import { flatten, map, some } from 'lodash';

/** @module Validation Utils */

const getNestedStatePaths = (statePath, elements) => {
  const appendStatePath = element => `${statePath}.${element.statePath}`;

  return elements.map(appendStatePath);
};

const getStatePaths = section => {
  const { statePath, elements } = section;

  if (elements) {
    return getNestedStatePaths(statePath, elements);
  }

  return statePath;
};

/**
 * @function
 * @param {Object} section
 */
export const getSectionStatePaths = section => {
  const statePaths = map(section.elements, getStatePaths);

  return flatten(statePaths);
};

/**
 * @function
 * @param {Object[]} sectionStatePaths
 * @param {Object[]} invalidatedStatePaths
 */
export const sectionHasInvalidations = (
  sectionStatePaths,
  invalidatedStatePaths
) => {
  const includesStatePath = statePath =>
    invalidatedStatePaths.includes(statePath);

  const sectionInvalidations = sectionStatePaths.map(includesStatePath);

  return some(sectionInvalidations, e => e);
};
