import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '20px',
    background: theme.colors.base,
    overflow: 'auto',
    maxHeight:
      window.innerHeight > 1100
        ? window.innerHeight * 0.75
        : window.innerHeight * 0.62,
    clear: 'both'
  })
});

export default stylesGenerator;
