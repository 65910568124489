import { taskClient } from 'config/apollo';
import { emitUpdateTasksCount } from 'CreateCase/ducks';
import getTasksQuery from 'api/graphql/queries/task/getTasksQuery';
import { FRAGMENT_NAME_TASK } from 'Common/constants';

const fetchTasksCountQuery = query => async (dispatch, getState) => {
  const { schemaReducer } = getState();
  const variables = {
    q: query
  };

  let tasksCount = 0;

  try {
    const { data } = await taskClient.query({
      query: getTasksQuery(schemaReducer.fragments[FRAGMENT_NAME_TASK]),
      variables
    });
    // dispatch success
    tasksCount = data.essearch.total;
    dispatch(emitUpdateTasksCount(tasksCount));
  } catch (e) {
    // dispatch error
    console.error('there was an error sending the query', e);
  }

  return tasksCount;
};

export default fetchTasksCountQuery;
