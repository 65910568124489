import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get, isEqual, defer, set, isNil, isEmpty } from 'lodash';
import SchemaUI from '@gmatas/cse';
import PropTypeSafety from 'Common/components/Form/PropTypeSafety';

import moment from 'moment';
import { CMS_PROP_TYPES } from 'Common/constants';
import { withStyles, validFullDate } from 'Common/components/Form';
import SectionsOverview from 'CreateCase/components/SectionsOverview';
import * as formComponents from 'Common/components/Form/formComponents';
import wrappedInputComponents from 'Common/components/Form/wrappedInputComponents';
import { DESKTOP_BREAKPOINT } from 'Common/styles';
import { Position, Toaster, Intent } from '@blueprintjs/core';
import PQBatchLotLookupModal from '../PQBatchLotLookupModal';

import {
  abbvieDateFormat,
  abbvieDateFormatOnlyForFullDates,
  abbvieDateAndAtTime
} from 'Common/components/Form/utils';
import { getOrElse } from 'Common/utils';

import { SUBCASE_TYPE_PATHS } from 'CreateCase/constants';
import {
  genCountryOfPrimaryReporter,
  removeAEProduct,
  removeAEProtocol,
  removePQProduct,
  removePQBatch
} from 'CreateCase/utils/case';

import Notifier from 'Common/components/Notifier';
import fetchProducts from 'api/graphql/fetchProducts';

import stylesGenerator from './styles';
import ProductLookUpModal from '../ProductLookUpModal';
import ProtocolLookupModal from '../ProtocolLookupModal';
import PQProductLookupModal from '../PQProductLookupModal';

class Page extends Component {
  static propTypes = {
    formRef: PropTypes.func,
    pageSchema: PropTypes.shape({
      title: PropTypes.string.isRequired,
      tabs: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    pageChanged: PropTypes.bool.isRequired,
    computedStyles: PropTypes.shape({
      sectionsMap: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        tab: PropTypes.string,
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      }).isRequired
    }).isRequired,
    clientWidth: PropTypes.number.isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    actions: PropTypes.shape({
      emitSaveAttachments: PropTypes.func.isRequired,
      emitDownloadAttachment: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalConfirmChange: PropTypes.func.isRequired,
      emitModalConfirmDelete: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitUpdateValidationErrors: PropTypes.func.isRequired,
      emitLockCase: PropTypes.func.isRequired,
      emitLockCaseManualDismiss: PropTypes.func.isRequired,
      emitSetNewVersion: PropTypes.func.isRequired,
      emitUnlockCase: PropTypes.func.isRequired,
      emitUnlockCaseCompletely: PropTypes.func.isRequired,
      emitFetchVersionDiff: PropTypes.func.isRequired
    }).isRequired,
    isSavingCase: PropTypes.bool.isRequired,
    isCaseLocked: PropTypes.bool,
    isCaseLockedManualDismiss: PropTypes.bool,
    isReadOnly: PropTypes.bool.isRequired,
    isNewVersion: PropTypes.bool.isRequired,
    triggers: PropTypes.shape({
      onSaveCase: PropTypes.func.isRequired
    }).isRequired,
    hasSaved: PropTypes.bool.isRequired,
    formValidationErrors: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string)
    ).isRequired,
    lastUpdate: PropTypes.number,
    isCreatingDashboardSubcase: PropTypes.bool.isRequired,
    newSubcaseType: PropTypes.string,
    reconciliationData: PropTypes.arrayOf(PropTypes.string),
    versionDiff: PropTypes.shape({
      'TE-12345-AE01': PropTypes.arrayOf(PropTypes.string), // example keys
      'TE-12345-PQ02': PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    isFetchingVersionDiff: PropTypes.bool.isRequired
  };

  static defaultProps = {
    isCaseLocked: false,
    isCaseLockedManualDismiss: false,
    formRef: () => {},
    newSubcaseType: null,
    reconciliationData: [],
    lastUpdate: 0
  };

  state = { submitted: false };

  componentDidMount() {
    if (this.props.trilogyCase.contacts) this.setRACValueFromOldCheckbox();

    // Adds support for schema-editor bookmarklet
    if (process.env.NODE_ENV !== 'production') {
      window.UPDATE_TRILOGY_SCHEMA = updatedElementSchema => {
        this.form.actions.emitComponentUpdate(updatedElementSchema);
      };
    }
  }

  setRACValueFromOldCheckbox() {
    const { trilogyCase } = this.props;
    const contacts = trilogyCase.contacts.contact;

    contacts.forEach((contact, index) => {
      if (contact.pq) {
        const regAgency =
          'regulatory_agency_communication' in contact.pq
            ? contact.pq.regulatory_agency_communication
            : null;
        if (regAgency === 'true') {
          set(
            trilogyCase,
            `contacts.contact[${index}].pq.regulatory_agency_communication`,
            'Yes'
          );
        } else if (regAgency === 'false') {
          set(
            trilogyCase,
            `contacts.contact[${index}].pq.regulatory_agency_communication`,
            'No'
          );
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    // Comparing lastUpdate's of the case model. If the time is out-of-sync
    // with CSE's `lastUpdate` then UI has updated the model since.
    const cseLastUpdate = get(
      this.form,
      'state.lastUpdate',
      nextProps.lastUpdate
    );
    // Recon data is retrieved in `Layout/index`
    const newReconData =
      nextProps.reconciliationData.length !==
      this.props.reconciliationData.length;

    const newDiffData = !isEqual(this.props.versionDiff, nextProps.versionDiff);
    // See Layout for pageChanged description
    if (
      newDiffData ||
      newReconData ||
      nextProps.pageChanged ||
      nextProps.lastUpdate > cseLastUpdate
    ) {
      // only set submitted to false when re-setting the form, we don't want
      // to flip the flag if we're only updating due to `lastUpdate` sync
      this.setState({ submitted: !nextProps.pageChanged });

      this.form.actions.emitInitializeModel({
        model: nextProps.trilogyCase,
        data: this.getData(nextProps)
      });
    }
  }

  shouldComponentUpdate() {
    return this.modal !== true;
  }

  componentDidUpdate(prevProps) {
    this.checkIfCaseIsLocked(prevProps);

    if (this.props.isNewVersion) {
      this.form.actions.emitInitializeSchema(
        this.getSchema(this.props.pageSchema),
        this.getConfig(),
        this.props.trilogyCase,
        this.getData()
      );

      this.props.actions.emitSetNewVersion(false);
    }

    this.resolveVersionDiffs();
  }

  subcaseIdPathMap = {
    ae: 'trilogyCase.subcases.adverseEvent.id',
    pq: 'trilogyCase.subcases.productQuality.id',
    mi: 'trilogyCase.subcases.medicalInfo.id' // Future work
  };

  subcaseVersionPathMap = {
    ae: 'trilogyCase.subcases.adverseEvent.version',
    pq: 'trilogyCase.subcases.productQuality.version',
    mi: 'trilogyCase.subcases.medicalInfo.version'
  };

  subcaseAwarenessDatePathMap = {
    ae: 'summary.awareness_date',
    pq: 'summary.productQuality.awareness_date',
    mi: 'summary.medicalInfo.awareness_date'
  };
  subcaseAffiliateAwarenessDatePathMap = {
    ae: 'summary.affiliate_awareness_date',
    pq: 'summary.productQuality.affiliate_awareness_date',
    mi: 'summary.medicalInfo.affiliate_awareness_date'
  };
  subcaseCreatedDatePathMap = {
    ae: 'subcases.adverseEvent.createdDate',
    pq: 'subcases.productQuality.createdDate',
    mi: 'subcases.medicalInfo.createdDate'
  };

  cloneProduct = product => {
    const clonedProduct = {
      ...product,
      attachmentsSent: null,
      complaint: {
        ...product.complaint,
        processing: {
          ...product.complaint.processing,
          patientInfo: null,
          available: null,
          availableReason: null,
          sampleUnavailableReason: null,
          listNumber: null,
          marketedName: null,
          lastCustomerServiceEmailSentDate: undefined
        },
        reportedCategories: [
          {
            ProductType: null,
            reportedCategory: null,
            reportedSubcategory: null
          }
        ]
      },
      details: [
        {
          ...product.details[0],
          additional_comments: null,
          availability: null,
          listNumber: undefined,
          lotNumber: undefined,
          marketedName: undefined,
          productFamily: undefined,
          serialNumber: undefined,
          reason: null
        }
      ],
      tracking: {
        ...product.tracking,
        pqComments: null
      }
    };
    delete clonedProduct.trilogyProductId;
    return clonedProduct;
  };

  cloneProductTherapy = productTherapy => {
    const clonedProductTherapy = cloneDeep(productTherapy);
    clonedProductTherapy.drug_start_date = null;
    clonedProductTherapy.drug_stop_date = null;
    clonedProductTherapy.batch_lot_no = null;
    clonedProductTherapy.expiration_date = null;
    clonedProductTherapy.drug_start_text = null;
    clonedProductTherapy.drug_stop_text = null;
    clonedProductTherapy.reason = null;
    clonedProductTherapy.availability.unavailable = null;
    clonedProductTherapy.availability.unknown = null;
    clonedProductTherapy.related_to_product_complaint = null;
    clonedProductTherapy.additional_comments = null;
    return clonedProductTherapy;
  };

  copyAndAddProduct = element => {
    const { actions, trilogyCase } = this.props;
    const productPath = get(element, '["../"].$id');

    // get productToBeCloned
    const productToBeCloned = get(trilogyCase, productPath);

    // clear attributes that should not be cloned
    const newProduct = this.cloneProduct(productToBeCloned);

    // add new product to the trilogy case
    const productArray = get(
      trilogyCase,
      'subcases.productQuality.pqproduct.products'
    );

    const newProductArray = productArray.concat([newProduct]);
    set(
      trilogyCase,
      'subcases.productQuality.pqproduct.products',
      newProductArray
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  copyAndAddProductTherapy = element => {
    const { actions, trilogyCase } = this.props;
    const productTherapyPath = get(element, '["../"].$id');
    const productTherapyToBeCloned = get(trilogyCase, productTherapyPath);
    const newProductTherapy = this.cloneProductTherapy(
      productTherapyToBeCloned
    );
    const productTherapyArray = get(
      trilogyCase,
      'subcases.adverseEvent.product_section.aeproducts[0].product_therapy'
    );
    const newProductTherapyArray = productTherapyArray.concat([
      newProductTherapy
    ]);
    set(
      trilogyCase,
      'subcases.adverseEvent.product_section.aeproducts[0].product_therapy',
      newProductTherapyArray
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  getData = nextProps => {
    // Need to use `nextProps` args since this can be called in `componentWillReceiveProps`
    const props = nextProps || this.props;
    const {
      tacticalData,
      isCaseLocked,
      isCaseLockedManualDismiss,
      isReadOnly,
      versionDiff,
      session,
      match,
      saveAndSubmitButtonDisabled
    } = props;

    const subcaseVersionId = get(
      this.props,
      this.subcaseIdPathMap[match.params.page]
    );

    return {
      ...tacticalData,
      diff: versionDiff[subcaseVersionId],
      submitted: this.state.submitted,
      reconciliation: props.reconciliationData,
      location: getOrElse(props, 'match.params', {}),
      userMap: getOrElse(tacticalData, 'document-data.user-list', []).reduce(
        (map, u) => ({ ...map, [u.sub]: `${u.fn} ${u.sn}` }),
        {}
      ),
      userList: getOrElse(tacticalData, 'document-data.user-list', []).map(
        u => ({
          label: `${u.fn} ${u.sn}`,
          value: u.sub
        })
      ),
      isReadOnly,
      buttonDisabled: saveAndSubmitButtonDisabled,
      isCaseLocked: isCaseLocked || isCaseLockedManualDismiss,
      roles: session.rs,
      isCreatingDashboardSubcase: this.props.isCreatingDashboardSubcase,
      newSubcaseType: this.props.newSubcaseType
    };
  };

  // Minor conversion to get existing for schema which has "tabs" and "sections" to follow the "elements" convention.
  getSchema = ({ tabs, ...pageSchema }) => ({
    ...pageSchema,
    elements: tabs.map(({ sections, ...tab }) => ({
      ...tab,
      elements: sections
    }))
  });

  getConfig = () => ({
    locale: this.props.schema.locale,
    index: { key: 'statePath', inherit: true },
    location: this.props.match.params
  });

  resolveVersionDiffs = () => {
    const { actions, match, isFetchingVersionDiff, versionDiff } = this.props;

    const subcaseVersionId = get(
      this.props,
      this.subcaseIdPathMap[match.params.page]
    );
    const versionNo = get(
      this.props,
      this.subcaseVersionPathMap[match.params.page]
    );
    if (
      versionNo > 0 &&
      isEmpty(versionDiff[subcaseVersionId]) &&
      !isFetchingVersionDiff
    ) {
      actions.emitFetchVersionDiff(subcaseVersionId);
    }
  };

  checkIfCaseIsLocked(prevProps) {
    const { trilogyCase, isCaseLocked, isCaseLockedManualDismiss } = this.props;

    const lockedStatusChanged =
      isCaseLocked !== prevProps.isCaseLocked ||
      isCaseLockedManualDismiss !== prevProps.isCaseLockedManualDismiss;

    if (lockedStatusChanged) {
      this.form.actions.emitInitializeModel({
        model: trilogyCase,
        data: this.getData()
      });
    }
  }

  handleCreateDashboardSubcase = subcaseType => {
    const { trilogyCase, triggers } = this.props;
    const awarenessDate = get(
      trilogyCase,
      this.subcaseAwarenessDatePathMap[subcaseType]
    );
    const affiliateAwarenessDate = get(
      trilogyCase,
      this.subcaseAffiliateAwarenessDatePathMap[subcaseType]
    );
    if (
      awarenessDate &&
      validFullDate(awarenessDate) &&
      affiliateAwarenessDate &&
      validFullDate(affiliateAwarenessDate)
    ) {
      this.setState(
        prevState => ({
          ...prevState,
          submitted: true
        }),
        () => {
          const newCase = set(
            trilogyCase,
            SUBCASE_TYPE_PATHS[subcaseType],
            true
          );
          set(
            newCase,
            this.subcaseCreatedDatePathMap[subcaseType],
            new Date().toISOString()
          );
          triggers.onSaveCase(newCase);
        }
      );
    } else {
      this.form.actions.emitValidations();
    }
  };

  handleValidate = (element, cseNode) => {
    const { actions, trilogyCase } = this.props;
    const aerInfoSafetyStatepath = 'subcases.adverseEvent.aerinfo.safety';
    
    
    


 




// // *********************START OF THE CHANGE****************************
// This block of code is for making the other_product field mandatory when there is no value selected
// in Product section for AE case now these changes are reverted as tibco is going to handle from their end

// // Function to check if a value is non-null, non-undefined, and non-empty string
// function isNonEmpty(value) {
//   return value !== null && value !== undefined && value !== '';
// }

// // Function to validate fields in nested structures and set flag if any non-empty value is found
// function validateFieldsWithFlag(obj) {
//   let foundNonEmpty = false; // Flag to track if any non-empty value is found
  
//   // Check product_indication
//   if (obj.product_indication && obj.product_indication.length > 0) {
//     for (let i = 0; i < obj.product_indication.length; i++) {
//       for (let key in obj.product_indication[i]) {
//         if (isNonEmpty(obj.product_indication[i][key])) {
//           // console.log(`Found non-empty field '${key}' in product_indication: ${obj.product_indication[i][key]}`);
//           foundNonEmpty = true;
//           return foundNonEmpty; // Terminate early if found non-empty value
//         }
//       }
//     }
//   }

//   // Check product_summary
//   if (obj.product_summary && obj.product_summary.length > 0) {
//     for (let i = 0; i < obj.product_summary.length; i++) {
//       for (let key in obj.product_summary[i]) {
//         if (isNonEmpty(obj.product_summary[i][key])) {
//           // console.log(`Found non-empty field '${key}' in product_summary: ${obj.product_summary[i][key]}`);
//           foundNonEmpty = true;
//           return foundNonEmpty; // Terminate early if found non-empty value
//         }
//       }
//     }
//   }

//   // Check product_therapy
//   if (obj.product_therapy && obj.product_therapy.length > 0) {
//     for (let i = 0; i < obj.product_therapy.length; i++) {
//       for (let key in obj.product_therapy[i]) {
//         if (key === 'availability') {
//           // Special handling for 'availability' object within 'product_therapy'
//           for (let subKey in obj.product_therapy[i].availability) {
//             if (isNonEmpty(obj.product_therapy[i].availability[subKey])) {
//               // console.log(`Found non-empty field 'availability.${subKey}' in product_therapy: ${obj.product_therapy[i].availability[subKey]}`);
//               foundNonEmpty = true;
//               return foundNonEmpty; // Terminate early if found non-empty value
//             }
//           }
//         } else if (isNonEmpty(obj.product_therapy[i][key])) {
//           // console.log(`Found non-empty field '${key}' in product_therapy: ${obj.product_therapy[i][key]}`);
//           foundNonEmpty = true;
//           return foundNonEmpty; // Terminate early if found non-empty value
//         }
//       }
//     }
//   }

//   // Check reporter_causality
//   if (obj.reporter_causality && obj.reporter_causality.length > 0) {
//     for (let i = 0; i < obj.reporter_causality.length; i++) {
//       for (let key in obj.reporter_causality[i]) {
//         if (isNonEmpty(obj.reporter_causality[i][key])) {
//           // console.log(`Found non-empty field '${key}' in reporter_causality: ${obj.reporter_causality[i][key]}`);
//           foundNonEmpty = true;
//           return foundNonEmpty; // Terminate early if found non-empty value
//         }
//       }
//     }
//   }

//   return foundNonEmpty;
// }

// // Function to validate all products in 'aeproducts' and return true if any non-empty value is found
// function validateProducts(products) {
//   for (let i = 0; i < products.length; i++) {
//     const other_product_path= `["../"].["/"].elements[3].elements[0].elements[0].instances[${i}].elements[0].instances[0].elements[5]`
//     const other_product = get(cseNode, other_product_path)
                                                
//     if (!validateFieldsWithFlag(products[i])) {
             
//       set(other_product, 'validations.required.constraint', true);
//       set(trilogyCase,`subcases.adverseEvent.product_section.aeproducts[${i}].product_summary[0].other_product`, undefined)
//       // return false
      
//     }
//      else {
        
//       set(other_product, 'validations.required.constraint', false);
      
      
//       // return true; // Return true if any non-empty field is found
//     }
//   }

 
//   return true; // Return false if no non-empty fields are found
// }

// if(trilogyCase.subcases !=null ){
//   if(trilogyCase.subcases.adverseEvent!=null){

//     // condition to check wheather it should not a intial version
//     if(trilogyCase.subcases.adverseEvent.id != null && trilogyCase.subcases.adverseEvent.id != undefined ){
//       if(trilogyCase.subcases.adverseEvent.id.slice(-2)!='00'){

//       const {product_section} = trilogyCase.subcases.adverseEvent
//       if(product_section !=null && product_section.aeproducts!=null){
//         // product_section.aeproducts && validateProducts(product_section.aeproducts);
//         let foundNonEmpty = product_section.aeproducts && validateProducts(product_section.aeproducts);
//         // Output based on validation
//         actions.emitInputBatchUpdate(trilogyCase)
        
        
//       }

//     }
//     }
    

    

//   }
// }


// // *********************END OF THE CHANGE****************************





    // if (cseNode !== undefined && cseNode !== null) {
    //   let originalDocument = 0;
    //   if (
    //     cseNode.model.documents.attachments !== null &&
    //     cseNode.model.documents.attachments !== undefined
    //   ) {
    //     originalDocument = cseNode.model.documents.attachments.length;
    //   }
    //   const documentPendingTranslation =
    //     cseNode.model.documentPendingTranslation;
    //   const totalExpectedNoOfDocuments =
    //     cseNode.model.totalExpectedNoOfDocuments;

    //   const translationStatus = cseNode.model.translationStatus;  
    //   if ( translationStatus === 'sent_for_translation'  && totalExpectedNoOfDocuments <= Number(originalDocument)) {
        
    //     set(trilogyCase, 'translationStatus', 'translation_complete');
    //     actions.emitInputBatchUpdate(trilogyCase);
    //     set(cseNode.model, 'translationStatus', 'translation_complete');
    //   }
    // }
    // Setting Validation to false for Please Specify Field when Other Gender is not
    // selected in How Person Thinks Of Themselves field

    //   const howPersonField = get(trilogyCase,'patient.patient[0].how_person_thinks_of_themselves')

    //   const pleaseSpecifyField= get(
    //     cseNode,
    //     '["../"].["/"].elements[0].elements[2].elements[1].instances[0].elements[6]'
    //   );

    //   const pleaseSpecifyField_pq=  get(
    //     cseNode,
    //     '["../"].["/"].elements[2].elements[1].elements[1].instances[0].elements[6]'
    //   );

    //   const pleaseSpecifyField_ae=  get(
    //     cseNode,
    //     '["../"].["/"].elements[1].elements[1].elements[1].instances[0].elements[6]'
    //   );


    //  if(howPersonField !== 'other_gender'){
    //     set(pleaseSpecifyField, 'validations.required.constraint', false);
    //     set(pleaseSpecifyField_pq, 'validations.required.constraint', false);
    //     set(pleaseSpecifyField_ae, 'validations.required.constraint', false);
    //  }


    const attachmentField = get(
      cseNode,
      '["../"].["/"].elements[0].elements[2].elements[0]'
    );

    const attachmentField_master= get(
      cseNode,
      '["../"].["/"].elements[0].elements[4].elements[0]'
    );
    if(!(trilogyCase.documents === null || trilogyCase.documents === undefined )) {
      if (
        trilogyCase.documents.attachments !== undefined &&
        trilogyCase.documents.attachments !== null
      ) {
        if (trilogyCase.documents.attachments.length > 0) {
          const items = trilogyCase.documents.attachments.filter(i => {
            return (
              (i.tags !== null
                ? i.tags.includes('PV Submission to Veeva') ||
                  i.tags.includes('PQ Submission to SolTRAQs/OneTrack')
                : false) &&
              (i.size > 20 * 1024 * 1024 || i.name.length > 95)
            );
          });

          if (items.length > 0) {
            set(attachmentField, 'validations.required.constraint', true);
            set(attachmentField_master, 'validations.required.constraint', true);

            const container = document.getElementById('schema-root');
            this.toaster = Toaster.create(
              {
                position: Position.TOP
              },
              container
            );
            this.toaster.show({
              message:
                'File size and/or name length exceeded limits, please update & resubmit',
              intent: Intent.DANGER
            });
          } else {
            set(attachmentField, 'validations.required.constraint', false);
            set(attachmentField_master, 'validations.required.constraint', false);
          }
        }
      }
    }
    //serious
    if (
      get(cseNode, '["/"].path') === 'ae' &&
      get(trilogyCase, `${aerInfoSafetyStatepath}.seriousness`) === 'serious'
    ) {
      const outsourcedPartners = get(
        cseNode,
        'data.document-data.ae-options.outsourced_partners'
      );
      const affiliateLocation = get(trilogyCase, 'affiliateCountryLabel');
      const affiliateAwarenessDate = get(
        trilogyCase,
        `${aerInfoSafetyStatepath}.affiliate_awareness_date`
      );
      const submissionDueDate = abbvieDateFormat(
        moment(affiliateAwarenessDate).add(1, 'days')
      );
      const lateToPPSField = get(
        cseNode,
        '["../"].["/"].elements[7].elements[1].elements[3]'
      );
      let todaysDate = abbvieDateFormat(moment());
      const submissionStatepath = 'subcases.adverseEvent.submission';
      if (outsourcedPartners.includes(affiliateLocation)) {
        if (
          moment(moment(submissionDueDate).add(1, 'days')).isBefore(todaysDate)
        ) {
          set(lateToPPSField, 'validations.required.constraint', true);
        } else {
          set(lateToPPSField, 'validations.required.constraint', false);
          set(trilogyCase, `${submissionStatepath}.late_pps_reason`, null);
          // set(trilogyCase, `${submissionStatepath}.pv_awareness_date`, null);
        }
      } else {
        if (moment(submissionDueDate).isBefore(todaysDate)) {
          set(lateToPPSField, 'validations.required.constraint', true);
        } else {
          set(lateToPPSField, 'validations.required.constraint', false);
          set(trilogyCase, `${submissionStatepath}.late_pps_reason`, null);
          // set(trilogyCase, `${submissionStatepath}.pv_awareness_date`, null);
        }
      }
    }

    //non serious
    if (
      get(cseNode, '["/"].path') === 'ae' &&
      get(trilogyCase, `${aerInfoSafetyStatepath}.seriousness`) === 'nonserious'
    ) {
      const outsourcedPartners = get(
        cseNode,
        'data.document-data.ae-options.outsourced_partners'
      );
      const affiliateLocation = get(trilogyCase, 'affiliateCountryLabel');
      const affiliateAwarenessDate = get(
        trilogyCase,
        `${aerInfoSafetyStatepath}.affiliate_awareness_date`
      );
      const submissionDueDate = abbvieDateFormat(
        moment(affiliateAwarenessDate).add(7, 'days')
      );
      const lateToPPSField = get(
        cseNode,
        '["../"].["/"].elements[7].elements[1].elements[3]'
      );
      let todaysDate = abbvieDateFormat(moment());
      const submissionStatepath = 'subcases.adverseEvent.submission';

      if (outsourcedPartners.includes(affiliateLocation)) {      
        if (
          // moment(moment(submissionDueDate).add(1, 'days')).isBefore(todaysDate)
          moment(submissionDueDate).isBefore(todaysDate)
        ) {
          set(lateToPPSField, 'validations.required.constraint', true);
        } else {
          set(lateToPPSField, 'validations.required.constraint', false);
          set(trilogyCase, `${submissionStatepath}.late_pps_reason`, null);
          // set(trilogyCase, `${submissionStatepath}.pv_awareness_date`, null);
        }
      } else {
        if (moment(submissionDueDate).isBefore(todaysDate)) {
          set(lateToPPSField, 'validations.required.constraint', true);
        } else if (moment(submissionDueDate).isAfter(todaysDate)) {
          set(lateToPPSField, 'validations.required.constraint', false);
          set(trilogyCase, `${submissionStatepath}.late_pps_reason`, null);
          // set(trilogyCase, `${submissionStatepath}.pv_awareness_date`, null);
        } else {
          set(lateToPPSField, 'validations.required.constraint', false);
          set(trilogyCase, `${submissionStatepath}.late_pps_reason`, null);
          // set(trilogyCase, `${submissionStatepath}.pv_awareness_date`, null);
        }
      }
    }

    if (attachmentField && attachmentField.validations) {
      if (attachmentField.validations.required.constraint === true) {
        return;
      }
    }

    if (attachmentField_master && attachmentField_master.validations) {
      if (attachmentField_master.validations.required.constraint === true) {
        return;
      }
    }

    this.setState(
      prevState => ({
        ...prevState,
        submitted: true
      }),
      () => defer(this.form.actions.emitValidations)
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleInputUpdate = (action, state) => {
    if (
      !/ADD|REMOVE|INPUT_UPDATE|UPDATE_STATE|VALIDATIONS|INITIALIZE/.test(
        action.type
      )
    )
      return;
    const { actions, triggers } = this.props;
    /**
     * only emit an inputBatchUpdate action when there is
     * an actual change to avoid unnecessarily showing the warning modal when the user attempts to navigate away
     * and for performance reasons as well.
     */
    if (/ADD|REMOVE|INPUT_UPDATE|UPDATE_MODEL/.test(action.type)) {
      const newDocument = { ...state.model };
      // Also update country of primary reporter (if contacts or patient updated)
      set(
        newDocument,
        'countryOfPrimaryReporter',
        genCountryOfPrimaryReporter(newDocument)
      );

      const hasSaved = /UPDATE_MODEL/.test(action.type)
        ? this.props.hasSaved
        : false;

      actions.emitInputBatchUpdate(newDocument, hasSaved, state.lastUpdate);
    }

    if (!isEqual(state.errors, this.props.formValidationErrors)) {
      actions.emitUpdateValidationErrors(cloneDeep(state.errors));
    }

    if (action.type === 'EMIT_VALIDATIONS_PASS') {
      triggers.onSubmitValidationSuccess();
    } else if (action.type === 'EMIT_VALIDATIONS_FAIL') {
      triggers.onSubmitValidationFailure(action.payload.errors);
    }
  };

  handleConfirmModal = ({ title, text }, onConfirm) => {
    this.props.actions.emitModalConfirmChange(
      title,
      text,
      () => {
        this.modal = true;
        onConfirm();
        this.props.actions.emitModalContentClear();
        setTimeout(() => (this.modal = false), 10);
      },
      this.props.actions.emitModalContentClear
    );
  };

  handleProductLookUp = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onProductSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };

    const content = (
      <ProductLookUpModal
        onProductSelected={onProductSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handlePQBatchSearch = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const updatedCase = cloneDeep(trilogyCase);
    const lotNumber = get(updatedCase, `${$id}.lotNumber`, null);
    if (!lotNumber || lotNumber === '') {
      return;
    }
    fetchProducts(lotNumber).then(data => {
      if (!data || data.length === 0) {
        // handle failure
        set(updatedCase, `${$id}.listNumber`, null);
        set(updatedCase, `${$id}.marketedName`, null);
        set(updatedCase, `${$id}.productFamily`, null);
        const message = data
          ? 'No results! Please verify the Batch/Lot Number.'
          : 'Something went wrong with the Batch/Lot Number lookup. Please use the List Number lookup instead.';
        Notifier.show({
          message,
          intent: Notifier.DANGER
        });
      } else if (data) {
        // handle success
        set(updatedCase, `${$id}.listNumber`, data[0].listNumber);
        set(updatedCase, `${$id}.marketedName`, data[0].trilogyMarketedName);
        set(updatedCase, `${$id}.productFamily`, data[0].productFamily);
      }
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    });
  };

  handlePQProductLookup = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onPQProductSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };

    const content = (
      <PQProductLookupModal
        onProductSelected={onPQProductSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };
  handlePQBatchLotLookup = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;

    const updatedCase = cloneDeep(trilogyCase);
   
    const onPQProductSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };

    const content = (
      <PQBatchLotLookupModal
        onProductSelected={onPQProductSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
     actions.emitModalContentUpdate(content);
  };
  handlePQProductLookupClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const productPath = get(cseNode, '["../"].$id');
    if (isNil(productPath)) {
      console.error('Could not get statePath for PQ Product Removal');
      return;
    }

    const newDocument = removePQProduct(trilogyCase, productPath);

    actions.emitInputBatchUpdate(newDocument);
  };
  handlePQBatchLookUpClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const productPath = get(cseNode, '["../"].$id');
    if (isNil(productPath)) {
      console.error('Could not get statePath for PQ Product Removal');
      return;
    }

    const newDocument = removePQBatch(trilogyCase, productPath);
  
    actions.emitInputBatchUpdate(newDocument);
  };

  //onChange handler for  Reported Category Group to set and reset the dependent element dynamically based on the selection.

  handlePQCategorySelectChange = (callback, cseNode) => {
    const { value, statePath } = cseNode || { value: '' };
    const { trilogyCase, actions } = this.props;
    const i = cseNode.$id.substring(75, 76);
    const j = cseNode.$id.substring(43, 44);
    const categoryId = `subcases.productQuality.pqproduct.products[${j}].complaint.reportedCategories[${i}].reportedCategory`;
    const subcatgoryId = `subcases.productQuality.pqproduct.products[${j}].complaint.reportedCategories[${i}].reportedSubcategory`;

    if (statePath === 'ProductType') {
      set(trilogyCase, cseNode.$id, value);
      set(trilogyCase, categoryId, null);
      set(trilogyCase, subcatgoryId, null);
      actions.emitInputBatchUpdate(trilogyCase);
      callback();
    } else if (statePath === 'reportedCategory') {
      set(trilogyCase, cseNode.$id, value);
      set(trilogyCase, subcatgoryId, null);
      callback();
    } else {
      set(trilogyCase, cseNode.$id, value);
      callback();
    }
  };

  handleProductLookUpClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const productPath = get(cseNode, '["../"].$id');
    // To get the expiration date for the current InputGroup instance, go up to the common parent,
    // and then find the Expiration Date field.
    const expirationDatePath = get(
      cseNode,
      '["../"]["../"]["../"].elements[3].instances[0].elements[1].$id'
    );
    if (isNil(productPath)) {
      console.error('Could not get statePath for Product Removal');
      return;
    }
    const newDocument = removeAEProduct(
      trilogyCase,
      productPath,
      expirationDatePath
    );

    actions.emitInputBatchUpdate(newDocument);
  };

  handleProtocolLookup = element => {
    const $id = element['../'].$id;
    const { actions, trilogyCase } = this.props;
    const onProtocolSelected = updatedCase => {
      actions.emitInputBatchUpdate(updatedCase);
      actions.emitModalContentClear();
      this.forceUpdate();
    };
    const content = (
      <ProtocolLookupModal
        onProtocolSelected={onProtocolSelected}
        baseStatePath={$id}
        trilogyCase={trilogyCase}
      />
    );
    actions.emitModalContentUpdate(content);
  };

  handleProtocolLookUpClear = cseNode => {
    const { trilogyCase, actions } = this.props;
    const statePath = get(cseNode, '["../"].$id');
    if (isNil(statePath)) {
      console.error('Could not get statePath for Protocol Removal');
      return;
    }
    const newDocument = removeAEProtocol(trilogyCase, statePath);

    actions.emitInputBatchUpdate(newDocument);
  };

  // copies over the list number and marketed name
  // into the replacement fields for current product
  replaceOriginalProduct = cseNode => {
    const { actions, trilogyCase } = this.props;
    const basePath = get(cseNode, '["../"].["../"].$id');
    const replacementPath = get(cseNode, '["../"].$id');
    set(
      trilogyCase,
      `${replacementPath}.listNumber`,
      get(trilogyCase, `${basePath}.details[0].listNumber`)
    );
    set(
      trilogyCase,
      `${replacementPath}.marketedName`,
      get(trilogyCase, `${basePath}.details[0].marketedName`)
    );
    actions.emitInputBatchUpdate(trilogyCase);
  };

  syncPatientBiometrics = (callback, cseNode) => {
    const { trilogyCase } = this.props;
    const biometricsMapping = {
      age: 'age_at_event',
      ageUnits: 'age_at_event_unit',
      weight: 'weight',
      weightUnits: 'weight_unit'
    };
    if (cseNode.relativePath === 'subcases.adverseEvent.patient.information') {
      set(
        trilogyCase,
        `patient.patient[0].${Object.keys(biometricsMapping).find(
          key => biometricsMapping[key] === cseNode.statePath
        )}`,
        cseNode.value
      );
    } else {
      set(
        trilogyCase,
        `subcases.adverseEvent.patient.information.${
          biometricsMapping[cseNode.statePath]
        }`,
        cseNode.value
      );
    }
    set(trilogyCase, cseNode.$id, cseNode.value);
    callback();
  };

  //if patient is pregnant, create a pregnancy case type
  createPregnancyCaseType = (callback, cseNode) => {
    //cseNode is the last argument passed to a trigger
    const { actions, trilogyCase } = this.props;
    let created = false; //is the case type created in this flow?
    const valueSelected = cseNode.value;
    if (valueSelected == 'yes') {
      const caseTypesStatePath = 'subcases.adverseEvent.tracking.case_type';
      let caseTypes = get(trilogyCase, caseTypesStatePath);

      if (!caseTypes || (caseTypes.length === 1 && !caseTypes[0].value))
        //if caseType is null or has one element with value as undefined(default)
        caseTypes = [];

      let alreadyExists = caseTypes.some(
        caseType => caseType.value == 'pregnancy_related'
      );
      if (!alreadyExists) {
        const pregnancyCase = { value: 'pregnancy_related' };
        let newCaseTypes = cloneDeep(caseTypes);
        newCaseTypes.push(pregnancyCase);
        set(trilogyCase, caseTypesStatePath, newCaseTypes);
        created = true;
      }
    }
    set(trilogyCase, cseNode.$id, valueSelected);
    if (created) actions.emitInputBatchUpdate(trilogyCase);
    else callback();
  };
  // Render on case creation, form pages but not the review screen
  shouldRenderOverview = () => {
    const { match } = this.props;
    const tab = getOrElse(match, 'params.tab');
    return (
      this.props.clientWidth >= DESKTOP_BREAKPOINT &&
      (tab || !match.params.page)
    );
  };

  currentTabForPath = page => {
    const { match } = this.props;
    return page.tabs.find(t => t.path === match.params.tab);
  };

  renderSectionsOverview = page => {
    const tab = this.currentTabForPath(page);
    const schema = getOrElse(this.form, 'state.schema', {});
    return this.shouldRenderOverview() ? (
      <SectionsOverview {...this.props} {...tab} schema={schema} />
    ) : null;
  };

  disableSafetySourcesOptions = cseNode => {
    const optionsDisableMap = {}; //which value should be disabled based on selected value
    optionsDisableMap['Solicited'] = ['Clinical Study', 'Spontaneous'];
    optionsDisableMap['Spontaneous'] = ['Clinical Study', 'Solicited'];
    optionsDisableMap['Clinical Study'] = ['Solicited', 'Spontaneous'];
    optionsDisableMap['E2B Health Authority'] = ['Health Authority'];
    const valueIndexedOptions = {};
    const options = cseNode.options;
    for (let option of options) {
      option.disabled = false;
      if (option.value == 'E2B Health Authority') option.disabled = true;
      valueIndexedOptions[option.value] = option;
    }
    const { actions, trilogyCase } = this.props;
    const selectedSourcesObj = get(
      trilogyCase,
      'subcases.adverseEvent.aerinfo.safety.source'
    );
    const dropdownNo = cseNode['../'].index;
    if (selectedSourcesObj) {
      selectedSourcesObj.forEach((sourceObj, index) => {
        if (sourceObj && dropdownNo != index) {
          const value = sourceObj.sources;
          if (optionsDisableMap[value]) {
            for (let valueToDisable of optionsDisableMap[value]) {
              const targetOption = valueIndexedOptions[valueToDisable];
              targetOption.disabled = true;
            }
          }
        }
      });
    }
  };

  render() {
    const {
      computedStyles,
      actions,
      match,
      pageSchema,
      trilogyCase,
      triggers,
      formRef
    } = this.props;

    if (!pageSchema || !trilogyCase) return null;

    const handleFormRef = cseInstance => {
      this.form = cseInstance;
      formRef(cseInstance);
    };

    return (
      <div className={computedStyles.content}>
        {this.renderSectionsOverview(pageSchema)}
        <div
          className={computedStyles.sectionsMap(this.shouldRenderOverview())}
        >
          <SchemaUI
            debug={!!process.env.CSE_DEBUG}
            key={match.params.page}
            ref={handleFormRef}
            components={{ ...formComponents, ...wrappedInputComponents }}
            config={this.getConfig()}
            defaultComponent={PropTypeSafety}
            schema={this.getSchema(pageSchema)}
            model={trilogyCase}
            onChange={this.handleInputUpdate}
            data={this.getData()}
            triggers={{
              ...triggers,
              onCreateDashboardSubcase: this.handleCreateDashboardSubcase,
              onValidate: this.handleValidate,
              onModalConfirmDelete: actions.emitModalConfirmDelete,
              onModalContentClear: actions.emitModalContentClear,
              onModalContentUpdate: actions.emitModalContentUpdate,
              onUploadAttachments: actions.emitSaveAttachments,
              onDownloadAttachment: actions.emitDownloadAttachment,
              onProductLookUp: this.handleProductLookUp,
              onPQBatchSearch: this.handlePQBatchSearch,
              onPQProductLookup: this.handlePQProductLookup,
              onPQBatchLotLookup: this.handlePQBatchLotLookup,
              onPQProductLookUpClear: this.handlePQProductLookupClear,
              onPQBatchLookUpClear: this.handlePQBatchLookUpClear,
              onPQCategorySelectChange: this.handlePQCategorySelectChange,
              onProductLookUpClear: this.handleProductLookUpClear,
              onProtocolLookUp: this.handleProtocolLookup,
              onProtocolLookupClear: this.handleProtocolLookUpClear,
              showConfirmModal: this.handleConfirmModal,
              getAbbvieDate: abbvieDateFormat,
              getAbbvieDateIfValidFullDate: abbvieDateFormatOnlyForFullDates,
              getAbbvieDateAndAtTime: abbvieDateAndAtTime,
              onCopyProduct: this.copyAndAddProduct,
              onCopyProductTherapy: this.copyAndAddProductTherapy,
              replaceOriginalProduct: this.replaceOriginalProduct,
              syncPatientBiometrics: this.syncPatientBiometrics,
              onSafetySourcesDropdownFocus: this.disableSafetySourcesOptions,
              onPregnancyChange: this.createPregnancyCaseType
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Page);
