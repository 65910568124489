export const TASK_CMS_PROPERTIES = [
  'isEditing',
  'isExpanded',
  'index',
  'display.caseId'
];

export const TYPE_MAP = {
  COMMUNICATION: 'COMMUNICATION',
  CASEFLOW: 'CASE FLOW',
  REVIEW: 'VERIFY/QC',
  PVQUERY: 'PV QUERY',
  PQFOLLOWUP: 'PQ FOLLOW-UP'
};
export const TASKS_FILTERS = 'TASKS FILTERS';
export const COLUMN_WIDTHS = ['360px', '160px', '100px'];
export const PREGNANCY_RELATED_VALUE = 'pregnancy_related';
export const SERIOUSNESS = 'form.base.display.seriousness';
export const REACTIONS = 'form.base.display.reactions';
export const ASSIGNEE = 'form.base.assignee';

export const SERIOUSNESS_OPTIONS = {
  serious: 'Serious',
  nonserious: 'Non Serious',
  NOT_SPECIFIED: 'Not Specified'
};

// NOTE: this is the path on a CASE
export const EXPECTED_DELIVERY_DATE_KEY =
  'subcases.adverseEvent.patient.information.expected_delivery_date';
// NOTE: this is the path on a CASE
export const CASE_TYPE_KEY_START = 'subcases.adverseEvent.tracking.case_type';
// NOTE: this is the path on a CASE
export const LAST_RECEIVED_DATE_KEY =
  'subcases.adverseEvent.aerinfo.safety.last_received_date';
// NOTE: this is a path on a TASK
export const PREGNANCY_RELATED_PATH = 'form.base.pregnancyRelated';
// NOTE: this is a path on a TASK
export const EXPECTED_DELIVERY_DATE_PATH = 'form.base.expectedDeliveryDate';
// NOTE: this is a path on a TASK
export const ATTEMPTS_PATH = 'form.additional.attempts.attempt';
// NOTE: this is a path on a TASK
export const ATTEMPT_REPLY_RECEIVED_PATH = 'reply.replyReceived';
// NOTE: this is a path on a TASK
export const ATTEMPT_REPLY_DATE_PATH = 'reply.actualReplyDate';
export const TASKS_PER_PAGE = 20;
export const NUMBER_OF_ATTEMPTS =
  'form.additional.attempts.attempt[0].attemptNumber';
export const LRD = 'form.base.display.last_received_date';

export const RECORDS_COUNT_DOWNLOAD_CSV = 1000;

export const RECORDS_COUNT_DOWNLOAD_CSV_ERROR = `Please limit your export to under ${RECORDS_COUNT_DOWNLOAD_CSV} records`;
