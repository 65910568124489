import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'Common/components/Form';
import searchIcon from 'public/images/search-icon.svg';
import stylesGenerator from './styles';

const SearchInput = ({
  computedStyles,
  onChange,
  onSubmit,
  value,
  fromStyle
}) => (
  <span>
    <input
      type="text"
      name="searchBarQuery"
      className={fromStyle ? fromStyle.searchBox : computedStyles.searchBox}
      placeholder="Search on all fields"
      value={value}
      onChange={event => onChange(event.target.value)}
    />
    <img
      src={searchIcon}
      className={computedStyles.searchIcon}
      onClick={onSubmit}
    />
  </span>
);

SearchInput.propTypes = {
  computedStyles: PropTypes.shape({
    searchBox: PropTypes.object.isRequired,
    searchIcon: PropTypes.object.isRequired
  }).isRequired,
  fromStyle: PropTypes.shape({
    searchBox: PropTypes.object,
    searchIcon: PropTypes.object
  }),

  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

SearchInput.defaultProps = {
  value: '',
  fromStyle: null
};

export default withStyles(stylesGenerator)(SearchInput);
