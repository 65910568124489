import { isBoolean } from 'lodash';
import { CASE_SERVICE_AUDIT_API } from 'config/values';

const getFetchOptions = (apiKey, data) => {
  const apiProps = {
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    }
  };

  return {
    method: 'POST',
    body: JSON.stringify(data),
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

const fetchBulkArchiveCase = (
  actionType,
  archiveComments,
  archiveReason,
  elementsToArchive,
  assigneeId,
  assigneeName
) => {
  const requestData = {
    actionType,
    archiveComments,
    archiveReason,
    cases: elementsToArchive,
    assigneeId,
    assigneeName
  };

  const fetchOptions = getFetchOptions(
    CASE_SERVICE_AUDIT_API.apiKey,
    requestData
  );
  const URL = `${CASE_SERVICE_AUDIT_API.uri}/bulkService`;

  return fetch(URL, fetchOptions).then(res => res.json());
};

export default fetchBulkArchiveCase;
