import {
  generateCSS,
  genErrorStyles,
  genInvalidOptionsStyles,
  genLabelStyles
} from '../index';

const stylesGenerator = (theme, props) => {
  const errorStyles = genErrorStyles(theme);
  const invalidOptionsStyles = genInvalidOptionsStyles(theme);
  const labelStyles = genLabelStyles(theme, props);

  return {
    base: generateCSS({
      margin: 0
    }),

    compensateForGroup: generateCSS({
      margin: 0
    }),

    group: generateCSS({
      padding: '5px 0 0',
      ...invalidOptionsStyles(props),

      minHeight: '38px',
      verticalAlign: 'top',

      '> label > .focused + span': {
        outline: `2px solid ${theme.colors.brand}`
      },

      ...props.styles
    }),

    input: generateCSS({
      display: 'inline-block',
      margin: '0 30px 0 0'
    }),

    optionLabel: generateCSS({
      verticalAlign: 'top',
      display: 'inline',
      whiteSpace: 'nowrap',
      height: '20px',
      minHeight: 'auto',
      margin: '0',
      ...theme.fonts.input,
      '> label.checked > span.pt-control-indicator': {
        background: theme.colors.tertiary
      }
    }),

    inputHelpTextCompensation: generateCSS({
      margin: '0 30px 18px 0'
    }),

    helpText: generateCSS({
      display: 'block',
      border: 'none',
      color: theme.colors.quaternary,
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
