import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import { SCHEMA_ID_FORM } from 'Common/constants';
import * as CaseActions from 'CreateCase/ducks';
import * as SchemaActions from 'Common/ducks/schema';
import * as NavigationActions from 'Navigation/ducks';
import CreateCaseController from './controller';

const mapStateToProps = ({
  routerReducer,
  caseReducer,
  schemaReducer,
  navigationReducer,
  commonReducer
}) => ({
  session: commonReducer.jwt,
  location: routerReducer.location,
  tasksCount: caseReducer.tasksCount,
  schema: schemaReducer.schemas[SCHEMA_ID_FORM],
  fragments: schemaReducer.fragments,
  tacticalData: schemaReducer.tacticalData,
  isFetchingSchema: schemaReducer.isFetchingSchema,
  isFetchingTacticalData: schemaReducer.isFetchingTacticalData,
  isNewVersion: caseReducer.isNewVersion,
  hasCaseAndTaskFragments: schemaReducer.hasCaseAndTaskFragments,
  hasSaved: caseReducer.hasSaved,
  lastUpdate: caseReducer.lastUpdate,
  modelUpdateCount: caseReducer.modelUpdateCount,
  formValidationErrors: caseReducer.formValidationErrors,
  isSavingCase: caseReducer.isSavingCase,
  caseStatus: caseReducer.caseStatus,
  isLinkingCase: caseReducer.isLinkingCase,
  isRegisteringDuplicateSearch: caseReducer.isRegisteringDuplicateSearch,
  trilogyCase: caseReducer.trilogyCase,
  userResolvedConflicts: caseReducer.userResolvedConflicts,
  formInvalidated: caseReducer.formInvalidated,
  clientWidth: navigationReducer.clientWidth,
  notificationBarMessage: navigationReducer.notificationBarMessage,
  caseIdAttempts: caseReducer.caseIdAttempts,
  reconciliationFetched: caseReducer.reconciliationFetched,
  reconciliationData: caseReducer.reconciliationData,
  versionDiff: caseReducer.versionDiff,
  isFetchingVersionDiff: caseReducer.isFetchingVersionDiff
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...CaseActions,
    ...SchemaActions,
    ...NavigationActions,
    push,
    replace
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  CreateCaseController
);
