import searchBarReducer from 'Common/ducks/searchBar';
import modalReducer from 'Common/ducks/modal';
import schemaReducer from 'Common/ducks/schema';
import userViewReducer from 'Common/ducks/userview';
import caseReducer from 'CreateCase/ducks';
import commonReducer from 'Common/ducks';
import navigationReducer from 'Navigation/ducks';
import tasksReducer from 'Tasks/ducks';
import searchReducer from 'Search/ducks';
import queueReducer from 'Queue/ducks';
import lookupReducer from 'Lookup/ducks';

// Convenience for importing actions from Common/components/Dev/ReduxDevTools.jsx
export * from 'Common/ducks';
export * from 'Common/ducks/schema';
export * from 'Common/ducks/searchBar';
export * from 'Common/ducks/modal';
export * from 'CreateCase/ducks';
export * from 'Navigation/ducks';
export * from 'Tasks/ducks';
export * from 'Queue/ducks';
export * from 'Search/ducks';
export * from 'Lookup/ducks';
export * from 'Common/ducks/userview';

// Convenience for importing reducers from config/store.js
export default {
  caseReducer,
  commonReducer,
  modalReducer,
  schemaReducer,
  navigationReducer,
  tasksReducer,
  queueReducer,
  searchReducer,
  lookupReducer,
  searchBarReducer,
  userViewReducer
};
