import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  modal: generateCSS({
    minHeight: '100px',
    ' > div': {
      marginTop: '20px'
    },
    ' div label': {
      width: '100%'
    }
  }),

  previousVersion: generateCSS({
    marginBottom: '40px',
    marginTop: '20px'
  }),

  previousVersionSubtitle: generateCSS({
    fontStyle: 'italic',
    fontSize: '15pt'
  }),

  previousVersionLink: generateCSS({
    ...theme.fonts.authLink,
    display: 'block',
    fontWeight: 'bold',
    color: theme.colors.brand,
    cursor: 'pointer'
  }),

  reopenModalNote: generateCSS({
    fontStyle: 'italic',
    marginTop: '20px'
  })
});

export default stylesGenerator;
