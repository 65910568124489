import { createAction, handleActions } from 'redux-actions';
import { isEmpty } from 'lodash'; // eslint-disable-line filenames/match-regex

export const SESSION_AUTHORIZE = 'SESSION_AUTHORIZE';
export const SESSION_START = 'SESSION_START';
export const SESSION_END = 'SESSION_END';
export const SESSION_ERROR = 'SESSION_ERROR';

export const actionTypes = [SESSION_START, SESSION_END, SESSION_ERROR];

const login = createAction(SESSION_AUTHORIZE);
const start = createAction(SESSION_START, payload => payload);
const end = createAction(SESSION_END, () => {});
const error = createAction(SESSION_ERROR, payload => payload);

export const actions = {
  login,
  start,
  end,
  throwError: error
};

// Set the session's default value to whatever the auth module resolves
export const initialState = {
  authorizing: true,
  hasValidRoles: false
};

const handlers = {
  [SESSION_END]: state => ({
    ...state,
    access_token: null,
    jwt: null,
    hasValidRoles: false
  }),
  [SESSION_AUTHORIZE]: state => ({ ...state, authorizing: true }),
  [SESSION_START]: (state, { payload }) => {
    const { jwt: { rs } } = payload;
    return {
      ...state,
      ...payload,
      hasValidRoles: !isEmpty(rs),
      authorizing: false
    };
  },
  [SESSION_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload.error.replace(/_/g, ' '),
    error_description:
      payload.error_description && payload.error_description.replace(/\+/g, ' ')
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
