const task = require('./tasks').schema;
const masterCase = require('./masterCase').schema;
const advancedSearch = require('./advancedSearch').schema;
const contactLookup = require('./contactLookup').schema;
const patientLookup = require('./patientLookup').schema;

module.exports = {
  task,
  masterCase,
  advancedSearch,
  contactLookup,
  patientLookup
};
