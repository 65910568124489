import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withStyles from 'Common/components/Form/withStyles';
import MetaInformation from './MetaInformation';
import styleGenerator from './styles';

const SiteFooter = ({ computedStyles, ...props }) => {
  const year = new Date().getFullYear();
  const documentationLink = (
    <div
      style={{
        float: 'right',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'uppercase'
      }}
    >
      <Link to="/documentation/index.html" target="_blank">
        Documentation
      </Link>
    </div>
  );

  return (
    <div className={computedStyles.footer}>
      <div className={computedStyles.footerText}>
        Copyright © {year} AbbVie Inc. North Chicago, Illinois, U.S.A
        {process.env.NODE_ENV !== 'production' ? documentationLink : null}
      </div>
      <MetaInformation {...props} />
    </div>
  );
};

SiteFooter.propTypes = {
  computedStyles: PropTypes.shape({
    footer: PropTypes.object,
    footerText: PropTypes.object
  }).isRequired
};

export default withStyles(styleGenerator)(SiteFooter);
