import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'Common/components/Form';
import { handleKeyPress } from 'Common/utils';
import successCheckmark from 'public/images/success-checkmark.svg';
import AuthorizedLink from '../../../Common/components/AuthorizedLink';
import stylesGenerator from './styles';

const CompleteTask = props => {
  const { isEditing, computedStyles, task, emitTaskComplete } = props;
  if (!isEditing) {
    if (task.status === 'COMPLETED') {
      return (
        <div>
          <img
            src={successCheckmark}
            className={computedStyles.successCheckmark}
          />
          <span className={computedStyles.completed}>Completed</span>
        </div>
      );
    }
    if (task.status === 'OPEN') {
      const handleClick = () => emitTaskComplete(task);
      return (
        <span>
          <AuthorizedLink
            accessRole={'edit'}
            looseCheck
            onClick={handleClick}
            id="completeTask"
            role="button"
            onKeyPress={handleKeyPress(handleClick, ' ', 'Enter')}
            tabIndex="0"
            override
          >
            Complete Task
          </AuthorizedLink>
        </span>
      );
    }
    if (task.status === 'CANCELLED') {
      return <span className={computedStyles.cancelled}>Cancelled</span>;
    }
  }
  return null;
};

CompleteTask.propTypes = {
  isEditing: PropTypes.bool,
  emitTaskComplete: PropTypes.func.isRequired,
  task: PropTypes.shape({
    form: PropTypes.shape({
      subcaseType: PropTypes.string
    })
  }).isRequired,
  computedStyles: PropTypes.shape({
    action: PropTypes.object.isRequired,
    successCheckmark: PropTypes.object.isRequired,
    completed: PropTypes.object.isRequired,
    cancelled: PropTypes.object.isRequired
  }).isRequired,
  override: PropTypes.bool
};

CompleteTask.defaultProps = {
  isEditing: null,
  override: false
};

export default withStyles(stylesGenerator)(CompleteTask);
