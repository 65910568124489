import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    width: '100%',
    padding: '35px',
    background: theme.colors.base,
    borderCollapse: 'collapse'
  }),

  tableBody: generateCSS({
    boxShadow: `3px 3px 8px 1px ${theme.colors.quaternary}`
  })
});

export default stylesGenerator;
