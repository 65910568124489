import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  standardColContainer: generateCSS({
    width: '43%',
    '> p': {
      textAlign: 'center',
      font: '16px SackersGothicStd-Heavy',
      color: '#071D49',
      fontWeight: '900',
      textTransform: 'uppercase',
      marginBottom: '10px'
    }
  }),
  standardhiddenDiv: generateCSS({
    margin: '10px',
    padding: '10px',
    border: '1px dashed #071D49',
    borderRadius: '5px',
    height: '42px'
  }),
  standardULContainer: generateCSS({
    border: '2px solid #ccc',
    margin: '0 30px',
    //  borderRadius: 10,
    overflowY: 'auto',
    padding: '0 15px',
    minHeight: 395,
    maxHeight: 395,
    minWidth: 268
  }),
  standardLIContainer: generateCSS({
    margin: '10px',
    padding: '3px',
    border: '2px solid #0082BA',
    color: '#0082BA',
    borderRadius: '5px',
    fontFamily: 'Calibri, "Microsoft Sans Serif"',
    fontSize: '16px',
    textAlign: 'center',
    cursor: 'pointer',
    '&.selected': {
      background: '#0082BA',
      border: '2px solid #0082BA',
      color: 'white'
    },
    '&.notSelected': {
      background: '#FFFFFF'
    }
  })
});

export default stylesGenerator;
