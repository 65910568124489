import { generateCSS } from 'Common/components/Form';

const stylesGenerator = ({ colors }) => ({
  base: generateCSS({
    margin: '0 0 40px 0',
    color: colors.brand,
    fontWeight: '800',
    cursor: 'pointer',
    outline: 'none',
    ':hover': {
      textDecoration: 'underline'
    },
    ':focus': {
      border: `1px solid ${colors.quaternary}`
    }
  })
});

export default stylesGenerator;
