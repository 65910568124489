import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    'section > div': {
      width: '100%'
    },
    '> td': {
      minWidth: '10px'
    }
  }),

  descriptionColumn: generateCSS({
    minWidth: '260px',
    maxWidth: '450px'
  }),

  field: generateCSS({
    display: 'block',
    font: '16px Calibri',
    fontWeight: '100',
    textAlign: 'left',
    color: theme.colors.secondary,
    maxHeight: '35px',
    overflow: 'auto',
    minWidth: '200px'
  }),

  type: generateCSS({
    display: 'block',
    font: '12px Calibri',
    textAlign: 'left',
    color: theme.colors.quinary,
    marginTop: '5px'
  }),

  product: generateCSS({
    display: 'block',
    font: '14px Calibri',
    textAlign: 'left',
    color: theme.colors.quinary,
    textTransform: 'uppercase',
    fontWeight: '100',
    marginTop: '5px'
  }),

  caseId: generateCSS({
    font: '16px Calibri',
    fontWeight: '600',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: theme.colors.brand
  }),

  caseColumn: generateCSS({
    minWidth: '160px',
    maxWidth: '450px'
  }),

  buttons: generateCSS({
    backgroundColor: theme.colors.base,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 60,
    marginTop: -90,
    marginBottom: 60,
    position: 'relative',
    zIndex: 2,

    '> button': {
      cursor: 'pointer',
      fontSize: '16px',
      height: '50px',
      textTransform: 'uppercase',
      transition: 'transform 150ms ease',
      width: '160px',
      ':hover': {
        transform: 'scale(1.1)'
      }
    }
  }),

  cancelButton: generateCSS({
    backgroundColor: '#FFFFFF',
    color: '#071D49',
    border: 'none'
  }),

  saveButton: generateCSS({
    backgroundColor: '#071D49',
    color: '#FFFFFF',
    border: 'none',
    marginLeft: 'auto'
  }),

  auditHistory: generateCSS({
    marginBottom: '40px',
    marginTop: '20px',
    height: '90vh'
  }),

  selectionBox: generateCSS({
    paddingLeft: '5px',
    '> div': {
      '> div': {
        marginTop: '20px',
        marginLeft: '25%'
      }
    }
  }),

  dueDatesRow: generateCSS({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 4px'
  })
});

export default stylesGenerator;
