import React from 'react';
import PropTypes from 'prop-types';

/*
 * (｡◕ ‿ ◕｡)
 * The most adorable, tiniest pure functional component ever.
 * Exists solely to prevent the Warning: Unknown props `match`, `location`, etc. on <div> tag,
 * which can be triggered when Modal's use of React.cloneElement provides props to a cloned element.
 * https://facebook.github.io/react/warnings/unknown-prop.html
 */
const PropTypeSafety = ({ children }) => <div>{children}</div>;

PropTypeSafety.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired
  ]).isRequired
};

export default PropTypeSafety;
