import { generateCSS, genDisabledStyles } from '../index';

const stylesGenerator = theme => ({
  base: generateCSS({
    display: 'inline-block',
    margin: '0 30px 0 0',

    '> span:nth-child(2)': {
      border: `3px solid ${theme.colors.base}`,
      boxShadow: `0 0 0 2px ${theme.colors.tertiary}`,
      '::before': {
        background: 'none'
      },
      ...genDisabledStyles(theme)
    }
  }),

  focused: generateCSS({
    '> .focused + span': {
      outline: `2px solid ${theme.colors.brand}`
    }
  })
});

export default stylesGenerator;
