import React from 'react';
import AppWrapper from './AppWrapper';
import NotFound from './NotFound';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

/*
 * This page renders a 404 with header and footer but no navigation elements.
 */
const NoConfigRoot = () => (
  <AppWrapper>
    <SiteHeader />
    <NotFound />
    <SiteFooter />
  </AppWrapper>
);

export default NoConfigRoot;
