import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, get, set, cloneDeep } from 'lodash';
import fetchProducts from 'api/graphql/fetchProducts';
import { CMS_PROP_TYPES } from 'Common/constants';
import { handleKeyPress } from 'Common/utils';
import { withStyles, SimpleButton, TextInput } from 'Common/components/Form';
import NoResults from 'Common/components/NoResults';
import { modalStyles } from 'Common/components/withModal';
import * as Fuse from 'fuse.js';
import stylesGenerator from './styles';

const stripWildcard = s => (isEmpty(s) ? s : s.replace(/\*/, ''));

class PqBatchLotLookupModal extends PureComponent {
  static propTypes = {
    onProductSelected: PropTypes.func.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      table: PropTypes.object.isRequired,
      tableWrapper: PropTypes.object.isRequired,
      tr: PropTypes.object.isRequired,
      headerSection: PropTypes.object.isRequired,
      headerSectionInitial: PropTypes.object.isRequired,
      actionHeader: PropTypes.object.isRequired,
      productInfoHeader: PropTypes.object.isRequired,
      productInfoSubHeader: PropTypes.object.isRequired,
      productInfoResults: PropTypes.object.isRequired,
      inputElement: PropTypes.object.isRequired
    }).isRequired,
    baseStatePath: PropTypes.string.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired
  };

  state = {
    batchLotNumber:'',
    listNumber: '',
    marketedName: '',
    searchResults: [],
    hasSearched: false
  };


  handleProductSearch = e => {
  
    e.preventDefault();
    const { tacticalData,actions } = this.props;
    const { listNumber, marketedName, batchLotNumber } = this.state;
    //const products = get(tacticalData, 'product-data.pq-product-data.products');

    //const batchLotProducts = get(tacticalData,'')
    this.setState({ searchResults: [] });
    
    fetchProducts(batchLotNumber).then(searchData => {
      // filtering for exact search
      const data = searchData.filter((a)=>a.batch===batchLotNumber);
   
      const products = get(tacticalData, 'product-data.pq-product-data.products');
      if (!data || data.length === 0) {
            //no data found
            this.setState({searchResults: []})
      } else  {   

          this.setState({searchResults: data})

      }
    });
  

    this.setState({ hasSearched: true });
  };

  handleSearchInput = (key, value) => {
    this.setState({ [key]: value });
   
  };

  handleAddToCase = result => {
    const { baseStatePath, trilogyCase } = this.props;
    const updatedCase = cloneDeep(trilogyCase);
    set(updatedCase, `${baseStatePath}.listNumber`, result.listNumber);
    set(updatedCase, `${baseStatePath}.lotNumber`, result.batch);
    set(
      updatedCase,
      `${baseStatePath}.marketedName`,
      result.trilogyMarketedName
    );
    // way to discern base product from the other ones
    // (associatedProduct, replacement)
    // if (baseStatePath.endsWith(']')) {
    //   set(
    //     updatedCase,
    //     `${baseStatePath}.productFamily`,
    //     result.productFamilyName
    //   );
    //   set(updatedCase, `${baseStatePath}.lotNumber`, null);
    //   set(updatedCase, `${baseStatePath}.availability`, true);
    // }
    this.props.onProductSelected(updatedCase);
  };

  renderSearchResults = result => {
    const handleClick = () => {
      this.handleAddToCase(result);
    };

    return (
      <tr
        className={this.props.computedStyles.tr}
        key={`${result.listNumber}`}
      >
        <td className={this.props.computedStyles.productInfoResults}>
          {result.batch}
        </td>
        <td className={this.props.computedStyles.productInfoResults}>
          {result.listNumber}
        </td>
        <td className={this.props.computedStyles.productInfoResults}>
          {result.SAPMaterialDescription}
        </td>
        <td>
          <a
            data-id="pq_product_modal_add_to_case"
            role="link"
            onClick={handleClick}
            onKeyPress={handleKeyPress(handleClick, ' ', 'Enter')}
            tabIndex="0"
          >
            Add to Case
          </a>
        </td>
      </tr>
    );
  };
  render() {
    const { listNumber, marketedName, searchResults, batchLotNumber } = this.state;
    const headerSectionClassName = isEmpty(searchResults.length)
      ? `${this.props.computedStyles.headerSectionInitial} ${
          this.props.computedStyles.headerSection
        }`
      : `${this.props.computedStyles.headerSection}`;
    let resultContent = null;
    if (isEmpty(searchResults) && this.state.hasSearched) {
      resultContent = <NoResults>No results found.</NoResults>;
    } else if (!isEmpty(searchResults)) {
      const batchHeading = "BATCH/ LOT <br> NUMBER";
      const listHeading = "LIST <br> NUMBER";
      const materialHeading = "MATERIAL <br> DESCRIPTION";
      resultContent = (
        <div className={this.props.computedStyles.tableWrapper}>
          <table className={this.props.computedStyles.table}>
            <thead>
              <tr>
                <th
                  colSpan="3"
                  className={this.props.computedStyles.productInfoHeader}
                >
                  PRODUCT INFORMATION
                </th>
                <th
                  rowSpan="2"
                  className={this.props.computedStyles.actionHeader}
                >
                  ACTIONS
                </th>
              </tr>
              <tr>
              <th className={this.props.computedStyles.productInfoSubHeader} dangerouslySetInnerHTML={{ __html: batchHeading }}>
                  
                </th>
                <th className={this.props.computedStyles.productInfoSubHeader} dangerouslySetInnerHTML={{ __html: listHeading }}>
                  
                </th>
                <th
                  className={`${
                    this.props.computedStyles.productInfoSubHeader
                  } `} dangerouslySetInnerHTML={{ __html: materialHeading }}
                >
                  
                </th>
              </tr>
            </thead>
            <tbody>{map(searchResults, this.renderSearchResults)}</tbody>
          </table>
        </div>
      );
    }

    return (
      <form onSubmit={this.handleProductSearch}>
        <div className={modalStyles.base}>
          <div className={headerSectionClassName}>
            <span className={modalStyles.title}>SEARCH FOR A PRODUCT</span>
            <div>
              <div className={this.props.computedStyles.inputElement}>
                <TextInput
                  data-id="batch_lot_number"
                  label="Batch/ Lot Number"
                  value={batchLotNumber}
                  styles={{ width: '100%', display: 'inline-block' }}
                  onChange={value =>
                    this.handleSearchInput('batchLotNumber', value)
                  }
                  locale="US"
                />
              </div>

              {/* <div className={this.props.computedStyles.inputElement}>
                <TextInput
                  data-id="marketed_name"
                  label="Marketed Name"
                  value={marketedName}
                  styles={{ width: '90%', display: 'inline-block' }}
                  onChange={value =>
                    this.handleSearchInput('marketedName', value)
                  }
                  locale="US"
                />
              </div> */}
            </div>
            <div className={modalStyles.buttonsContainerSingle}>
              <SimpleButton
                data-id="pqproduct_modal_search"
                type="submit"
                primary
               >
                Search
              </SimpleButton>
            </div>
          </div>
          {resultContent}
        </div>
      </form>
    );
  }
}

export default withStyles(stylesGenerator)(PqBatchLotLookupModal);
