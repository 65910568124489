import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '../withStyles';
import stylesGenerator from './styles';
import { csePropTypes } from '../propTypes';

const labels = {
  add: 'Add',
  remove: 'Remove'
};

const Button = ({
  $id,
  schemaPath,
  type,
  label,
  align,
  className,
  computedStyles: styles,
  disabled,
  ...props
}) => (
  <span
    className={`${styles.base} ${styles[align] || ''} ${className}`}
    data-id={$id}
    data-schema-path={schemaPath}
  >
    <button
      className={`${styles.groupButton} ${styles[type]}`}
      {...props}
      disabled={disabled}
    >
      <span className={styles[`${type}Icon`]} />
      <span className={styles[`${type}Icon`]} />
    </button>
    <span data-id={`${$id}-label`}>{label || labels[type]}</span>
  </span>
);

Button.propTypes = {
  ...csePropTypes,
  align: PropTypes.oneOf(['left', 'right', '']),
  type: PropTypes.oneOf(['add', 'remove']).isRequired,
  label: PropTypes.string,
  className: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  computedStyles: PropTypes.shape({
    base: PropTypes.any
  }).isRequired
};

Button.defaultProps = {
  label: '',
  align: '',
  className: {},
  disabled: false
};

export default withStyles(stylesGenerator)(Button);
