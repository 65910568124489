import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => {
  const disabledStyles = {
    outline: 'none',
    color: theme.colors.primary,
    background: theme.colors.quaternary
  };

  return {
    base: selected =>
      generateCSS({
        margin: '0 15px 0 0',
        ...theme.fonts.default,
        color: theme.colors.base,
        background: theme.colors.brand,
        border: 'none',
        borderRadius: '4px',
        width: 'auto',
        height: '19px',
        ':last-child': {
          margin: '0'
        },
        ...(!selected ? disabledStyles : {})
      })
  };
};

export default stylesGenerator;
