import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FileForm from './FileForm';

class FileListItem extends Component {
  static propTypes = {
    i: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    editFile: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    statePath: PropTypes.string.isRequired,
    onDownloadAttachment: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    isEditing: false,
    disabled: false
  };

  handleNameChange = ({ target: { value, name } }) => {
    this.props.onChange(this.props.file, name, value);
  };

  handleClick = () => {
    this.props.onDownloadAttachment(this.props.file);
  };

  renderFileName = () => {
    const { file, computedStyles, isEditing } = this.props;

    if (isEditing) {
      return (
        <input
          type="text"
          id={`name-${file.id}`}
          name="name"
          className={`pt-input pt-fill ${computedStyles.textInput}`}
          value={file.name}
          onChange={this.handleNameChange}
        />
      );
    }

    return (
      <Fragment>
        <span
          className={[
            computedStyles.fileName,
            computedStyles.fileNameBold
          ].join(' ')}
        >
          <a role="button" onClick={this.handleClick}>
            {file.name}
          </a>
        </span>
        <div className={computedStyles.fileName}>
          {file.tags && file.tags.replace(/,/g, ', ')}
        </div>
      </Fragment>
    );
  };

  renderUploadProgress = () => {
    const { file } = this.props;
    if (!('upload' in file)) return null;
    if (file.upload === '100%') return null;

    return (
      <div className="pt-progress-bar pt-intent-primary pt-no-stripes">
        <div className="pt-progress-meter" style={{ width: file.upload }} />
      </div>
    );
  };

  render() {
    const {
      file,
      i,
      computedStyles,
      isEditing,
      statePath,
      disabled
    } = this.props;
    const editFile = () => this.props.editFile(file);
    const removeFile = () => this.props.removeFile(file);

    return (
      <li
        id={`${statePath}-file-${i}`}
        key={`${statePath}-file-${i}`}
        className={computedStyles.fileListItem}
      >
        <div className={`with-padding ${computedStyles.listItemRow}`}>
          <div className={computedStyles.listItemColumn}>
            <i className={`${file.ext}`}>
              <span>{file.ext}</span>
            </i>
          </div>
          <div className={computedStyles.listItemColumn}>
            {this.renderFileName()}
            {this.renderUploadProgress()}
          </div>
        </div>
        <div className={`with-padding ${computedStyles.editItemContainer}`}>
          <div className={`${computedStyles.actionContainer}`}>
            <button
              className={`${computedStyles.editButton} pt-button pt-minimal`}
              onClick={editFile}
              disabled={disabled}
            />
            <button
              className={`${computedStyles.removeButton} pt-button pt-minimal`}
              onClick={removeFile}
              disabled={disabled}
            />
          </div>
        </div>
        <div className={`with-padding ${computedStyles.listItemRow}`}>
          <FileForm
            {...this.props}
            file={file}
            computedStyles={computedStyles}
            isEditing={isEditing}
          />
        </div>
      </li>
    );
  }
}

export default FileListItem;
