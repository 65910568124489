import React from 'react';
import PropTypes from 'prop-types';

import arrow from 'public/images/accordian-open.svg';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

const TableExpander = ({ computedStyles, onClick, expansionLocked, $id }) => {
  const handleClick = expansionLocked ? null : onClick;

  return (
    <td className={computedStyles.base}>
      <button
        id={`${$id}-expandButton-locked-${expansionLocked}`}
        className={computedStyles.button}
        onClick={handleClick}
      >
        <img src={arrow} className={computedStyles.arrow} />
      </button>
    </td>
  );
};

TableExpander.propTypes = {
  expansionLocked: PropTypes.bool,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    button: PropTypes.object.isRequired,
    arrow: PropTypes.object.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  $id: PropTypes.string.isRequired
};

TableExpander.defaultProps = {
  expansionLocked: false
};

export default withStyles(stylesGenerator)(TableExpander);
