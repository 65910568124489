import { generateCSS } from 'Common/components/Form';

import incompleteIcon from 'public/images/status-incomplete.svg';
import softErrorIcon from 'public/images/status-error-gray.svg';
import hardErrorIcon from 'public/images/status-error-red.svg';

const stylesGenerator = theme => ({
  base: generateCSS({
    display: 'block',
    flex: '1 1 auto',
    marginRight: '20px',
    position: 'relative',
    width: 'calc(20% + 10px)',

    ' > div': {
      top: '40px'
    },

    ' > div > div:last-of-type': {
      borderBottom: 'solid 1px #6bbbae',
      marginBottom: 20
    },

    ' > div > p > span,  > div > p > strong': {
      color: theme.colors.charcoal
    },

    ' > div > p:first-of-type': {
      fontWeight: 100,
      marginBottom: 20,
      fontSize: 12
    },

    ' > div > p:last-child': {
      fontWeight: 100,
      fontSize: 12
    },

    ' > div > p:last-child > span': {
      textTransform: 'uppercase'
    }
  }),

  sticky: generateCSS({
    ' > div': {
      position: 'fixed',
      top: 50
    }
  }),

  sectionOverview: generateCSS({
    // Allow scrolling on overview list container
    overflowY: 'scroll',
    maxHeight: `${window.innerHeight - 300}px`,

    // Hide scrollbars, without disabling scrolling
    msOverflowStyle: 'none', // IE 10+
    overflow: '-moz-scrollbars-none', // Firefox
    '::-webkit-scrollbar': {
      display: 'none' // Safari and Chrome
    },

    // Disable scrolling on children of list container
    ' > ul > li, > div, > div > div': {
      overflow: 'hidden'
    },

    ' > a': {
      display: 'block',
      paddingRight: 23,
      marginBottom: 5,
      transition: 'color 200ms ease',
      ...theme.fonts.sectionNavSectionLink
    },

    ':not(li) > a:last-child': {
      marginBottom: 20
    },

    ' > ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      marginBottom: 20
    },

    // sub-groups
    ' > ul > li > a': {
      display: 'inline-block',
      textIndent: 5,
      textTransform: 'capitalize',
      marginBottom: 0,
      transition: 'color 200ms ease',
      ...theme.fonts.sectionNavGroupLink
    },

    ' > ul > li > ul': {
      marginLeft: '10px'
    }
  }),

  error: generateCSS({
    ' > a': {
      color: `${theme.colors.error} !important` // very tough to make use of the CASCADE
    }
  }),

  statusIncomplete: generateCSS({
    backgroundSize: '20px',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',

    '&:not(div)': {
      backgroundSize: '15px',
      backgroundPosition: 'center right 2px'
    }
  }),

  statusRequired: generateCSS({
    backgroundImage: `url("${incompleteIcon}")`
  }),

  statusSoftError: generateCSS({
    backgroundImage: `url("${softErrorIcon}")`,
    backgroundSize: '20px',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',

    '&:not(div)': {
      backgroundSize: '15px',
      backgroundPosition: 'center right 2px'
    }
  }),

  statusError: generateCSS({
    backgroundImage: `url("${hardErrorIcon}")`,
    backgroundSize: '20px',
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',

    '&:not(div)': {
      backgroundSize: '15px',
      backgroundPosition: 'center right 2px'
    },

    ' > a': {
      color: `${theme.colors.error} !important`
    },

    ' > ul > li > a': {
      color: `${theme.colors.error} !important`
    }
  })
});

export default stylesGenerator;
