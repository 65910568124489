import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  bold: generateCSS({
    fontWeight: 'bold'
  }),
  generateEmailLink: generateCSS({
    marginLeft: '20px'
  })
});

export default stylesGenerator;
