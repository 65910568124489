import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    color: theme.colors.header,
    padding: '34px'
  }),
  title: generateCSS({
    display: 'inline-block',
    margin: '10px 0 15px',
    font: '30px SackersGothicStd-Medium',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),
  row: generateCSS({
    width: '100%'
  }),
  searchButtons: generateCSS({
    marginRight: '20px'
  }),
  filterButton: generateCSS({
    float: 'right',
    ' button': {
      background: 'transparent',
      border: '1px solid #0082BA',
      color: theme.colors.altBrand,
      fontWeight: 100,
      fontSize: '14px',
      textTransform: 'uppercase'
    }
  })
});

export default stylesGenerator;
