import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import StandardColumns from '../StandardColumns';
import DesiredColumns from '../DesiredColumns';
import ColumnSelectors from '../ColumnSelectors';
import stylesGenerator from './styles';

const getStandardColumnsList = (
  standardColumns = [],
  userDesiredColumns = []
) => {
  let stcolmns;
  if (userDesiredColumns && userDesiredColumns.length > 0) {
    stcolmns = standardColumns.filter(
      column =>
        !userDesiredColumns.map(col => col.label).includes(column.label) &&
        !column.isMandatoryColumn
    );
  } else {
    stcolmns = standardColumns.filter(col => !col.isMandatoryColumn);
  }
  return stcolmns;
};

const getSortColumns = userDesiredColumns =>
  userDesiredColumns.sort((a, b) => a.label.localeCompare(b.label));

const updateOrder = desiredColumn => {
  desiredColumn.forEach((col, index) => {
    col.order = index; // eslint-disable-line no-param-reassign
  });
  return desiredColumn;
};

class QueueResultsModal extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    onCloseModifyView: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    pageName: PropTypes.string
  };
  static defaultProps = {
    pageName: ''
  };
  constructor(props) {
    super(props);
    this.state = {
      standardColumns: getStandardColumnsList(
        props.columns,
        props.userDesiredColumns
      ),
      desiredColumns: updateOrder(props.userDesiredColumns),
      selectedStandardColumns: [],
      selectedDesiredColumns: []
    };
    this.handleStandardColumnSelect = this.handleStandardColumnSelect.bind(
      this
    );
    this.handleDesiredColumnSelect = this.handleDesiredColumnSelect.bind(this);
    this.moveStandardColumns = this.moveStandardColumns.bind(this);
    this.moveDesiredColumns = this.moveDesiredColumns.bind(this);
    this.updateDesiredColumns = this.updateDesiredColumns.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userDesiredColumns !== nextProps.userDesiredColumns) {
      this.setState({
        desiredColumns: nextProps.userDesiredColumns,
        standardColumns: getStandardColumnsList(
          nextProps.columns,
          nextProps.userDesiredColumns
        )
      });
    }
  }
  handleStandardColumnSelect(col) {
    const standardColumns = this.state.selectedStandardColumns;
    if (standardColumns.map(column => column.label).includes(col.label)) {
      this.setState({
        selectedStandardColumns: standardColumns.filter(
          column => column.label !== col.label
        )
      });
    } else {
      this.setState({
        selectedStandardColumns: [...standardColumns, col]
      });
    }
  }
  handleDesiredColumnSelect(col) {
    const desiredColumns = this.state.selectedDesiredColumns;
    if (!col.isMandatoryColumn) {
      if (desiredColumns.map(column => column.label).includes(col.label)) {
        this.setState({
          selectedDesiredColumns: desiredColumns.filter(
            column => column.label !== col.label
          )
        });
      } else {
        this.setState({
          selectedDesiredColumns: [...desiredColumns, col]
        });
      }
    }
  }
  moveStandardColumns() {
    const standardColumnsToMove = this.state.selectedStandardColumns;
    this.setState({
      desiredColumns: this.state.desiredColumns.concat(standardColumnsToMove),
      standardColumns: this.state.standardColumns.filter(
        column =>
          !standardColumnsToMove.map(col => col.label).includes(column.label)
      ),
      selectedStandardColumns: []
    });
  }
  moveDesiredColumns() {
    const desiredColumnsToMove = this.state.selectedDesiredColumns;
    this.setState({
      standardColumns: this.state.standardColumns.concat(desiredColumnsToMove),
      desiredColumns: this.state.desiredColumns.filter(
        column =>
          !desiredColumnsToMove.map(col => col.label).includes(column.label)
      ),
      selectedDesiredColumns: []
    });
  }
  updateDesiredColumns(desiredColumns) {
    this.setState({ desiredColumns: updateOrder(desiredColumns) });
  }

  render() {
    const { computedStyles, pageName } = this.props;
    return (
      <div
        className={`${computedStyles.queuePopup} ${modalStyles.base} ${
          modalStyles.modal
        }`}
      >
        <div className={computedStyles.modifyContainerPopup}>
          <StandardColumns
            standardColumns={getSortColumns(this.state.standardColumns)}
            selectedStandardColumns={this.state.selectedStandardColumns}
            handleStandardColumnSelect={this.handleStandardColumnSelect}
          />
          <ColumnSelectors
            selectedStandardColumnsSize={
              this.state.selectedStandardColumns.length
            }
            selectedDesiredColumnsSize={
              this.state.selectedDesiredColumns.length
            }
            moveStandardColumns={this.moveStandardColumns}
            moveDesiredColumns={this.moveDesiredColumns}
          />
          <DesiredColumns
            desiredColumns={this.state.desiredColumns}
            selectedDesiredColumns={this.state.selectedDesiredColumns}
            handleDesiredColumnSelect={this.handleDesiredColumnSelect}
            updateDesiredColumns={this.updateDesiredColumns}
          />
        </div>
        <button
          className={computedStyles.modifyConfirmBtnPopup}
          onClick={() => {
            this.props.onCloseModifyView(this.state.desiredColumns, pageName);
            this.props.closeModal();
          }}
        >
          CONFIRM
        </button>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(QueueResultsModal);
