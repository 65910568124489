/* eslint no-underscore-dangle:0 */
import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import styleGenerator from './styles';

const AppWrapper = ({ computedStyles, children }) => (
  <div className={computedStyles.base}>
    <div className={computedStyles.inner} data-id="NoConfigApp">
      {children}
    </div>
  </div>
);

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired
};

export default withStyles(styleGenerator)(AppWrapper);
