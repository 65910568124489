/* eslint no-console:0 */
import {
  CASE_GRAPHQL_FRAGMENT_API,
  TASK_GRAPHQL_FRAGMENT_API
} from 'config/values';

// eslint-disable-next-line
const fetchFragment = async (type, fragmentName) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      'x-api-key':
        type.toUpperCase() === 'TASK'
          ? TASK_GRAPHQL_FRAGMENT_API.apiKey
          : CASE_GRAPHQL_FRAGMENT_API.apiKey
    }
  };

  try {
    const url =
      type.toUpperCase() === 'TASK'
        ? TASK_GRAPHQL_FRAGMENT_API.uri(type, fragmentName)
        : CASE_GRAPHQL_FRAGMENT_API.uri(type, fragmentName);
    const response = await fetch(url, fetchOptions);
    return response.text();
  } catch (e) {
    console.error(e);
  }
};

export default fetchFragment;
