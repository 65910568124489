import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { find, filter } from 'lodash';
import scrollToElement from 'scroll-to-element';
import { Tooltip } from '@blueprintjs/core';

import { withStyles } from 'Common/components/Form';
import { getByUserName, getOrElse, parseTemplateString } from 'Common/utils';
import { CMS_PROP_TYPES, DATE_FORMAT_HOURS, N_A } from 'Common/constants';
import stylesGenerator from './styles';

class SectionsOverview extends PureComponent {
  static propTypes = {
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      sectionOverview: PropTypes.object.isRequired
    }).isRequired,
    formInvalidated: PropTypes.bool.isRequired,
    saveHotkey: PropTypes.bool,
    formValidationErrors: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string)
    ).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        tab: PropTypes.string,
        page: PropTypes.string,
        masterCaseId: PropTypes.string
      }).isRequired
    }).isRequired
  };

  static defaultProps = {
    saveHotkey: true
  };

  state = { sticky: false, navWidth: 'auto' };

  componentDidMount() {
    window.addEventListener('scroll', this.ensureVisibility);
  }

  componentWillUpdate() {
    const width = this.el.offsetWidth;
    if (width && !this.state.navWidth) this.setState({ navWidth: width }); // eslint-disable-line
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.ensureVisibility);
  }

  getStatusClass = hasErrors => {
    const { computedStyles, formInvalidated } = this.props;

    if (formInvalidated && hasErrors) return computedStyles.statusError;
    if (hasErrors) return computedStyles.statusSoftError;
    return computedStyles.statusIncomplete;
  };

  getSectionsFromSchema = () => {
    const { schema, match: { params } } = this.props;
    const tab = find(schema.elements, ele => ele.path === params.tab);
    return tab ? tab.elements : [];
  };

  ensureVisibility = () => {
    const STICKY_THRESHOLD = 40;
    const offsetTop = this.el.getBoundingClientRect().top;

    if (offsetTop < STICKY_THRESHOLD !== this.state.sticky) {
      this.setState({
        sticky: offsetTop < STICKY_THRESHOLD,
        // since fixed doesn't respect parent dimensions, we need to keep a
        // reference here
        navWidth: this.el.offsetWidth
      });
    }
  };

  hasErrors = ({ schemaPath }) => {
    const { formValidationErrors } = this.props;

    return !!find(Object.keys(formValidationErrors), path =>
      path.startsWith(schemaPath)
    );
  };

  renderInstance = (instance, index) => {
    const { id, label: groupLabel, component } = instance;
    const { computedStyles, match: { params: { page } } } = this.props;

    // exclude rendering the clone button
    if (id === 'clone-product') {
      return null;
    }

    const hasErrors = this.hasErrors(instance);
    const label = parseTemplateString(groupLabel, {
      ...instance,
      order: index + 1
    });
    const scrollToGroup = () => {
      const selector = `${
        component === 'InputGroup' ? 'div' : 'section'
      }[data-id="${instance.$id}"]`;
      const element = document.querySelector(selector);
      scrollToElement(element, { offset: index === 0 ? -110 : -40 });
    };

    return (
      <li
        key={instance.$id}
        className={`${computedStyles.sectionOverview} ${this.getStatusClass(
          hasErrors
        )}`}
      >
        <a key={instance.$id} role="link" onClick={scrollToGroup}>
          {label || id}
        </a>
        {page === 'pq' && instance.addButtonLabel === 'Add another product' ? (
          <ul>{instance.elements.map(this.renderInstance)}</ul>
        ) : null}
      </li>
    );
  };

  renderGroupList = section => {
    const elements = filter(
      section.elements,
      e =>
        e.component === 'InputGroup' &&
        e.visible &&
        (e.multiple || section.title === 'Processing Info')
    );
    return (
      <ul>{elements.map(ele => ele.instances.map(this.renderInstance))}</ul>
    );
  };

  renderSectionOverview = (section, i) => {
    if (!section.visible) {
      return null;
    }
    const { title, elements } = section;
    const { computedStyles } = this.props;

    if (!elements) return null;

    const scrollToSection = () => {
      const element = document.getElementById(`section-${title}`);
      scrollToElement(element, { offset: -110 });
    };

    const inputGroups =
      (elements.length <= 2 || section.title === 'Processing Info') && // yes, less than or equal to two (2)
      find(elements, { component: 'InputGroup' });
    const hasErrors = this.hasErrors(section);
    const errorClass = hasErrors
      ? computedStyles.error
      : this.getStatusClass(hasErrors);

    const className = `${errorClass} ${computedStyles.sectionOverview}`;

    return (
      <div key={i} className={className}>
        <a onClick={scrollToSection} role="link">
          {title}
        </a>
        {inputGroups ? this.renderGroupList(section) : null}
      </div>
    );
  };

  renderFooter = () => {
    const { trilogyCase, saveHotkey, tacticalData } = this.props;
    const users = getOrElse(tacticalData, 'document-data.user-list', []);
    const latestDraft = getOrElse(trilogyCase, 'latestDraft');
    const lastUpdatedUn = getOrElse(trilogyCase, 'lastUpdatedUsername');
    const savedBy = lastUpdatedUn
      ? getByUserName(users, lastUpdatedUn) || lastUpdatedUn
      : null;
    const saveDate = latestDraft
      ? moment(latestDraft).format(DATE_FORMAT_HOURS)
      : N_A;

    return (
      <Fragment>
        {saveHotkey ? <p>Press Ctrl+S to save changes</p> : null}
        <p>
          <strong>Last saved:</strong>
          <br />
          <Tooltip content={latestDraft || N_A}>{saveDate}</Tooltip>
          <br />
          {savedBy ? <span>By {savedBy}</span> : null}
        </p>
      </Fragment>
    );
  };

  render() {
    const { schema, computedStyles } = this.props;
    const stickyClass = this.state.sticky ? computedStyles.sticky : '';
    const sections = this.getSectionsFromSchema(schema);

    return (
      <div
        className={`${computedStyles.base} ${stickyClass}`}
        ref={el => (this.el = el)}
      >
        <div style={{ width: this.state.navWidth }}>
          {sections.map(this.renderSectionOverview)}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(SectionsOverview);
