import { get, isEmpty } from 'lodash';
import {
  generateCSS,
  reverseText,
  genDisabledStyles,
  genInvalidBorder,
  genErrorStyles,
  genLabelStyles,
  genCaret,
  genInvalidBoxShadow,
  INPUT_SIDE_PADDING
} from '../index';

import datePickerIcon from '../../../../../public/images/date-picker-icon.svg';

// react-select doesn't seem to take on Glamor styles applied directly to it
const stylesGenerator = (theme, props) => {
  const errorStyles = genErrorStyles(theme);
  const disabledStyles = genDisabledStyles(theme);
  const invalidStyles = genInvalidBorder(theme);
  const labelStyles = genLabelStyles(theme, props);
  const invalidBoxShadow = genInvalidBoxShadow(theme);

  return {
    // TODO: Reference base from ../Select/styles
    base: generateCSS({
      position: 'relative',
      margin: 0,

      ' .pt-overlay.pt-overlay-inline': {
        position: 'absolute',
        top: 40,
        right: 0
      },

      ' input.pt-input': {
        padding: `0 ${INPUT_SIDE_PADDING / 2}px 0`,
        display: 'block',
        margin: '0px 0',
        ...props.styles,
        ...theme.fonts.input,
        ...disabledStyles,
        borderRadius: '0',
        border: `2px solid ${theme.colors.primary}`,
        boxShadow: 'none',
        ':focus': {
          border: `2px solid ${theme.colors.active}`
        },
        '::selection': {
          outline: 'none'
        },
        '::placeholder': {
          padding: '0 6px',
          color: theme.colors.quinary,
          fontSize: '18px'
        },
        ...reverseText(get(props, 'config.locale')),
        ...(isEmpty(props.errors) ? {} : invalidStyles)
      },

      '> div > .Select': {
        width: '30%',
        margin: '0 5px 5px 0',

        border: 'none',

        '> .Select-control': {
          ' > span > div.Select-value > span.Select-value-label': {
            display: 'inline-block',
            textAlign: 'center',
            width: 'calc(100% - 18px)'
          },

          '> .Select-multi-value-wrapper': {
            '> .Select-placeholder': {
              padding: '0 15px',
              color: theme.colors.quinary,
              fontSize: '18px'
            },
            '> .Select-input': {
              textAlign: 'center',
              height: '30px',
              ...theme.fonts.input,

              '> input': {
                padding: '7px 0',
                textAlign: 'center',
                ...theme.fonts.input
              }
            }
          },
          '> .Select-clear-zone': {
            '> .Select-clear': {
              display: 'none'
            }
          },
          height: '30px',
          boxShadow: `inset 0 0 0 2px ${theme.colors.primary}`,
          ...(isEmpty(props.errors) ? {} : invalidBoxShadow),
          border: 'none',
          borderRadius: '0',
          ...theme.fonts.input,
          '> .Select-arrow-zone': {
            '> .Select-arrow': {
              position: 'absolute',
              top: '10px',
              right: '11px',
              borderColor: `${theme.colors.tertiary} transparent transparent`,
              borderWidth: '10px 7px 1px'
            }
          }
        },
        '> .Select-menu-outer': {
          padding: '0 1px 0 0',
          zIndex: '10',
          top: '38px',
          border: 'none',
          borderRadius: '0',
          ...genCaret({
            border: theme.colors.primary,
            arrow: theme.colors.base
          }),
          '> .Select-menu': {
            border: `solid 2px ${theme.colors.primary}`,
            ...theme.fonts.input,
            '> .is-focused': {
              color: theme.colors.base,
              background: theme.colors.active,
              borderRadius: '0'
            }
          }
        },
        '&.is-focused': {
          '> .Select-control': {
            boxShadow: `inset 0 0 0 2px ${theme.colors.tertiary}`
          },
          '> .Select-menu-outer': {
            boxShadow: 'none'
          }
        }
      }
    }),

    inputRow: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',

      ' .pt-datepicker': {
        backgroundColor: theme.colors.background
      },

      ' .DayPicker-NavButton.DayPicker-NavButton--next': {
        color: theme.colors.tertiary
      },

      ' .pt-datepicker .pt-datepicker-caption select': {
        color: theme.colors.secondary
      },

      ' .DayPicker-NavButton.DayPicker-NavButton--prev': {
        color: theme.colors.tertiary
      },

      ' .DayPicker-Weekday': {
        color: theme.colors.header,
        fontWeight: 600
      },

      ' .pt-datepicker .DayPicker-Day:hover': {
        backgroundColor: theme.colors.tertiary,
        color: theme.colors.base
      },

      ' .pt-popover .pt-popover-arrow-fill': {
        fill: theme.colors.background
      },

      '> .pt-popover-target': {
        pointerEvents: props.disabled ? 'none' : null // BlueprintDatePicker has no disabled toggle
      }
    }),

    datePickerControl: generateCSS({
      appearance: 'none',
      border: 'none',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      backgroundImage: `url("${datePickerIcon}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      margin: '2px',
      height: 25,
      width: 25
    }),

    label: generateCSS({
      ...theme.fonts.inputLabel
    }),

    helpText: generateCSS({
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
