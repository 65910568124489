import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'Common/components/Form/withStyles';
import logo from 'public/images/trilogy-logo.png';
import logo2x from 'public/images/trilogy-logo@2x.png';
import logo3x from 'public/images/trilogy-logo@3x.png';
import styleGenerator from './styles';

const Header = ({ computedStyles }) => (
  <nav className={`pt-navbar ${computedStyles.header}`}>
    <div className="pt-navbar-group pt-align-left">
      <img
        alt="Logo"
        src={logo}
        srcSet={`${logo2x} 2x, ${logo3x} 3x`}
        className={computedStyles.logo}
      />
    </div>
  </nav>
);

Header.propTypes = {
  computedStyles: PropTypes.shape({
    authItem: PropTypes.object
  }).isRequired
};

export default withStyles(styleGenerator)(Header);
