import { get, isNil, lowerCase, merge } from 'lodash';

import { PRODUCTS_MAP_FULL_PATH, NOT_SPECIFIED } from 'Common/constants';
import { genDisplayValue, getOrElse } from 'Common/utils';
import { processLastReceivedDate } from 'Tasks/constants';

export const CASE_STATE_PATHS = {
  PR_DEFAULT: 'subcases.adverseEvent.tracking.case_type',
  EDD: 'subcases.adverseEvent.patient.information.expected_delivery_date',
  LRD: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
  REACTIONS: 'subcases.adverseEvent.reactions.reaction[0].adverse_event',
  SERIOUSNESS:
    'subcases.adverseEvent.reactions.reaction[0].reaction_seriousness',
  AFFILIATE_COUNTRY_LABEL: 'affiliateCountryLabel'
};

export const TASK_STATE_PATHS = {
  EDD: 'form.base.expectedDeliveryDate'
};

export const getDisplayCaseId = (caseId, subcaseId) =>
  !isNil(subcaseId) && subcaseId.length > 2
    ? subcaseId.toUpperCase()
    : genDisplayValue({ caseId, subcaseId });

export const mergeCaseIntoTask = (task, caseData) => {
  const caseId = get(task, 'form.base.caseId', '');
  const subCaseId = get(task, 'form.base.subcaseId', '');

  const TASK_CASE_STATE_PATHS = merge({}, CASE_STATE_PATHS, {
    PRODUCTS_MAP:
      PRODUCTS_MAP_FULL_PATH[
        lowerCase(get(task, 'form.base.subcaseType')) || 'mc'
      ]
  });

  const taskCase = get(caseData, caseId, caseData);
  const pregnancyValues = get(taskCase, TASK_CASE_STATE_PATHS.PR_DEFAULT) || [];
  // First check if task already has its own values, if not, use case values
  const isPregnancyRelated = get(
    task,
    'form.base.pregnancyRelated',
    pregnancyValues
      .map(({ value }) => value)
      .some(value => value === 'pregnancy_related')
  );
  // First check if task already has its own values, if not, use case values
  const expectedDeliveryDate = get(
    task,
    TASK_STATE_PATHS.EDD,
    get(taskCase, CASE_STATE_PATHS.EDD)
  );

  return {
    ...task,
    display: {
      ...get(task, 'display', {}),
      product: getOrElse(taskCase, TASK_CASE_STATE_PATHS.PRODUCTS_MAP, '')
    },
    form: {
      ...get(task, 'form', {}),
      base: {
        ...get(task, 'form.base', {}),
        expectedDeliveryDate,
        pregnancyRelated: isPregnancyRelated,
        display: {
          caseId: getDisplayCaseId(caseId, subCaseId),
          reactions: getOrElse(
            taskCase,
            TASK_CASE_STATE_PATHS.REACTIONS,
            NOT_SPECIFIED
          ),
          seriousness: getOrElse(
            taskCase,
            TASK_CASE_STATE_PATHS.SERIOUSNESS,
            'NOT_SPECIFIED'
          ),
          last_received_date: processLastReceivedDate(
            getOrElse(taskCase, CASE_STATE_PATHS.LRD, NOT_SPECIFIED)
          ),
          affiliateCountryLabel: getOrElse(
            taskCase,
            'affiliateCountryLabel',
            NOT_SPECIFIED
          )
        }
      }
    }
  };
};

// Expects a list of tasks and an object of cases keyed by case ID
export const mergeCaseIntoTasks = (tasks, caseData) =>
  tasks.map(task => mergeCaseIntoTask(task, caseData));

export const reduceToIdObject = (acc, val) => ({
  ...acc,
  [val.id]: val
});
