import React, { PureComponent } from 'react';
import { kebabCase, get } from 'lodash';
import PropTypes from 'prop-types';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class SmartButton extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    onClick: PropTypes.func.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    ...formElementDefaultProps,
    onChange: () => {}
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props);
    }
  };

  render() {
    const { $id, schemaPath, id, computedStyles, disabled } = this.props;
    const defaultId = id || `${this.props.label.toLowerCase()} smart button`;
    const buttonDisabled = get(this.props, 'data.buttonDisabled');
    return (
      <button
        id={kebabCase(defaultId)}
        className={computedStyles.base}
        onClick={this.handleClick}
        disabled={buttonDisabled || disabled}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        {this.props.label}
      </button>
    );
  }
}

export default withStyles(stylesGenerator)(SmartButton);
