import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { preventDuplicateKeys } from 'config/utils';
import { MODAL_ACTIONS_ALIAS } from 'Common/constants';
import * as ModalActions from 'Common/ducks/modal';
import Modal from './Modal';

/*
 * Higher order component that adds a smart modal to the wrapped component.
 * Note that, since it wraps the entire application body,
 * NavigationController is responsible for overlay behavior
 * based on the precense of content provided to this modal.
 *
 * -:¦:-•:*'""*:•.-:¦:-•** IMPORTANT **•-:¦:-•:*'""*:•-:¦:-
 * Do not spread this.props onto any modalContent element! They are provided automagically,
 * and manually re-spreading them will cause the props to be overwritten by stale props.
 */
const withModal = WrappedComponent => {
  class ComponentWithModal extends PureComponent {
    static propTypes = {
      actions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired,
      modalActions: PropTypes.objectOf(PropTypes.func.isRequired).isRequired
    };

    MODAL_ACTIONS_ALIAS = 'modalActions';
    EXPECTED_CONFLICTS = ['default', 'emitCleanSlate'];

    // Convenience method to merge modal actions from `props.modalActions` into `props.actions`
    genAliasedProps = () => {
      const { actions, modalActions } = this.props;
      const filteredModalActions = omit(modalActions, this.EXPECTED_CONFLICTS);

      preventDuplicateKeys(actions, filteredModalActions, 'actions');

      const aliasedProps = {
        ...this.props,
        actions: { ...actions, ...filteredModalActions }
      };
      return omit(aliasedProps, MODAL_ACTIONS_ALIAS);
    };

    render() {
      const aliasedProps = this.genAliasedProps();

      return (
        <div>
          <Modal {...aliasedProps} />
          <WrappedComponent {...aliasedProps} />
        </div>
      );
    }
  }

  const mapStateToProps = props => ({
    modalContent: props.modalReducer.modalContent,
    modalWithOverflow: props.modalReducer.modalWithOverflow,
    renderXCloseButton: props.modalReducer.renderXCloseButton,
    isCaseLocked: props.modalReducer.isCaseLocked,
    isCaseLockedManualDismiss: props.modalReducer.isCaseLockedManualDismiss
  });

  const mapDispatchToProps = dispatch => ({
    [MODAL_ACTIONS_ALIAS]: bindActionCreators(ModalActions, dispatch)
  });

  return connect(mapStateToProps, mapDispatchToProps)(ComponentWithModal);
};

export default withModal;
