import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '10px'
  }),

  item: generateCSS({
    textAlign: 'left',
    padding: '0 26px 0 3px',
    textTransform: 'uppercase',
    font: '14px Calibri',
    fontWeight: '600',
    color: theme.colors.secondary
  }),

  selectBox: generateCSS({
    padding: '0 0 0 10px',
    textAlign: 'left'
  })
});

export default stylesGenerator;
