import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, SimpleButton } from 'Common/components/Form';
import stylesGenerator from './styles';

const NotAuthorized = ({ computedStyles, onRetry }) => (
  <div className={computedStyles.base}>
    <div className={computedStyles.messageContainer}>
      <div className={computedStyles.errorCodeWrapper}>
        <h1>401</h1>
      </div>
      <div className={computedStyles.messageWrapper}>
        <p>ACCESS NOT AUTHORIZED.</p>
        <p>PLEASE TRY TO LOGIN AGAIN.</p>
        <div className={computedStyles.buttonsContainerSingle}>
          <SimpleButton onClick={onRetry} primary>
            Retry Login
          </SimpleButton>
        </div>
      </div>
    </div>
  </div>
);

NotAuthorized.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    messageContainer: PropTypes.object.isRequired,
    errorCodeWrapper: PropTypes.object.isRequired,
    messageWrapper: PropTypes.object.isRequired,
    buttonsContainerSingle: PropTypes.object.isRequired
  }).isRequired,
  onRetry: PropTypes.func.isRequired
};

export default withStyles(stylesGenerator)(NotAuthorized);
