import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, reduce, trim, filter, isBoolean, isString } from 'lodash';
import SchemaUI from '@gmatas/cse';
import { advancedSearch } from 'mocks';

// TODO: Do not return full case with subcases. Only query on fields needed by Search.
import {
  withStyles,
  SimpleButton,
  CheckboxGroup
} from 'Common/components/Form';
import { getOrElse } from 'Common/utils';
import {
  writeToExistingInstance,
  searchCriteriaCopy,
  lastIndexForGroup
} from 'CreateCase/utils';
import PropTypeSafety from 'Common/components/Form/PropTypeSafety';
import * as formComponents from 'Common/components/Form/formComponents';
import wrappedInputComponents from 'Common/components/Form/wrappedInputComponents';
import SearchBar from 'Common/components/SearchBar';
import SearchInput from 'Common/components/SearchInput';
import {
  CMS_PROP_TYPES,
  DUPE_WITH_CONTACTS,
  PARENT_CASE,
  SOURCE_LOCATION,
  CASE_STATUS_NEW,
  CASE_STATUS_IN_PROGRESS,
  CASE_STATUS_COMPLETED,
  CASE_STATUS_ARCHIVED
} from 'Common/constants';
import stylesGenerator from './styles';

class SearchHeader extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      title: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired,
      advanced: PropTypes.object.isRequired,
      noMatch: PropTypes.object.isRequired,
      noMatchLink: PropTypes.object.isRequired
    }).isRequired,
    isDuplicateSearch: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      emitUpdateQuery: PropTypes.func.isRequired,
      emitFilterInputUpdate: PropTypes.func.isRequired,
      emitFetchSearch: PropTypes.func.isRequired,
      emitClearSearch: PropTypes.func.isRequired,
      emitSearchQuerySuccess: PropTypes.func.isRequired // TODO: Avoid this pattern later
    }).isRequired,
    filters: PropTypes.shape({}).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    fetchResults: PropTypes.func.isRequired,
    match: PropTypes.isRequired
  };

  state = {
    showFilters: this.props.isDuplicateSearch,
    // Fields that are not filters and should be a space delimited ES query string
    queryObj: {
      basic: '' // more defined as `generalQuery: true` in /mocks/schemas/advancedSearch.js
    },
    reloadSchema: 1
  };

  componentWillMount() {
    this.handleResetFilters(this.props.isDuplicateSearch);
  }

  handleFetchSearch = () => {
    this.props.fetchResults();
  };

  handleCopyContacts = existingCase => {
    const { filters, actions } = this.props;

    if (!existingCase) {
      actions
        .emitFetchNewCaseId()
        .then(res =>
          this.handleDuplicateSearchCopyContacts(
            filters,
            res.payload.newCase,
            true
          )
        );
    } else {
      this.handleDuplicateSearchCopyContacts(filters, existingCase, false);
    }
  };

  handleDuplicateSearchCopyContacts = (data, currCase, isNewCase) => {
    const { actions, match } = this.props;
    // Replace the last instance of the contact or patient input group with the lookup value
    const lastContactIndex = lastIndexForGroup(currCase, 'contacts.contact');
    const lastPatientIndex = lastIndexForGroup(currCase, 'patient.patient');

    const writeToExistingContact = writeToExistingInstance(
      currCase,
      'contacts.contact',
      lastContactIndex
    );

    const contactAndPatientCriteria = searchCriteriaCopy(
      currCase,
      data,
      writeToExistingContact ? lastContactIndex : lastContactIndex + 1,
      lastPatientIndex
    );

    actions.emitInputBatchUpdate(contactAndPatientCriteria);
    actions.emitSaveCase(contactAndPatientCriteria, match.params).then(() => {
      if (isNewCase) {
        window.redirectNewCase(currCase.id);
      } else if (window[SOURCE_LOCATION]) {
        window.redirectNewCase(null, window[SOURCE_LOCATION]);
      }
      window.close();
    });
  };

  handleFilterUpdate = (statePath, value) => {
    this.props.actions.emitFilterInputUpdate(value, statePath);
  };

  // For filters that need to be set in the ES Query
  handleGeneralQueryUpdate = (field, value) => {
    const { actions } = this.props;
    const queryObj = {
      ...this.state.queryObj,
      ...{ [field]: value }
    };
    this.setState({ queryObj });
    const queryStr = reduce(
      queryObj,
      (result, val) => trim(`${result} ${val}`),
      ''
    );
    actions.emitUpdateQuery(queryStr);
  };

  handleToggleFilters = showFilters => {
    this.handleResetFilters(showFilters);
  };

  handleResetFilters = (showFilters = false) => {
    const { actions } = this.props;
    this.setState({
      showFilters,
      queryObj: { basic: '' },
      schema: this.getSchema()
    });
    actions.emitClearSearch();
    actions.emitFilterInputUpdate(
      [CASE_STATUS_NEW, CASE_STATUS_IN_PROGRESS, CASE_STATUS_COMPLETED],
      'status'
    );
  };

  handleStatusFiltersChange = ({ value, $id }) => {
    const { actions, filters } = this.props;
    const status = filters.status || [];
    if (!isBoolean(value) || !isString($id)) {
      return;
    }
    let newStatusFilter = [];
    if (value) {
      newStatusFilter = [...status, $id];
    } else {
      newStatusFilter = filter(status, item => item !== $id);
    }

    actions.emitFilterInputUpdate(newStatusFilter, 'status');
    this.handleFetchSearch();
  };

  // might not need this anymore
  renderStickyFilters = () => {
    const { computedStyles } = this.props;
    return <div className={computedStyles.stickyFilters} />;
  };

  renderCopyContactsButton = () => {
    const { computedStyles, isDuplicateSearch } = this.props;
    const dupeWithContacts = !!window[DUPE_WITH_CONTACTS];
    return isDuplicateSearch && dupeWithContacts ? (
      <div>
        <div className={computedStyles.transparentButton}>
          <SimpleButton onClick={() => this.handleCopyContacts()} type="button">
            CREATE NEW CASE
          </SimpleButton>
        </div>
        {window[SOURCE_LOCATION] ? (
          <div className={computedStyles.transparentButton}>
            <SimpleButton
              onClick={() => this.handleCopyContacts(window[PARENT_CASE])}
              type="button"
            >
              ADD TO CURRENT CASE
            </SimpleButton>
          </div>
        ) : null}
      </div>
    ) : null;
  };

  renderNoMatchGroup = () => {
    const { computedStyles } = this.props;
    return (
      <div>
        <div
          className={`${computedStyles.noMatch} ${computedStyles.noMatchLink}`}
        >
          <a role="button" onClick={() => this.handleNoMatch()}>
            No match? Click here to create case&nbsp;
            <i
              role="button"
              className="pt-icon pt-icon-large pt-icon-circle-arrow-right floatButton subcaseArrow"
            />
          </a>
        </div>
        <p>
          If you don&apos;t need to create a new case, please close this window
        </p>
      </div>
    );
  };

  getConfig = () => ({
    locale: advancedSearch.locale,
    index: { key: 'statePath', inherit: true },
    location: 'search'
  });

  getSchema = () => advancedSearch.pages[0];

  handleChange = action => {
    if (!/EMIT_INPUT_UPDATE/.test(action.type)) return;
    if (action.payload.generalQuery) {
      this.handleGeneralQueryUpdate(action.payload.$id, action.payload.value);
    } else {
      this.handleFilterUpdate(action.payload.$id, action.payload.value);
    }
  };

  renderAdvancedFields = () => {
    const { filters, tacticalData } = this.props;
    return (
      <SchemaUI
        key={`advancedSearch-${this.state.reloadSchema}`} // Need to mount a "new" SchemaUI component (with a new key) in order to refresh the search inputs
        components={{ ...formComponents, ...wrappedInputComponents }}
        config={this.getConfig()}
        defaultComponent={PropTypeSafety}
        schema={this.getSchema()}
        model={cloneDeep(filters)} // CSE will mutate the props without cloneDeep
        onChange={this.handleChange}
        data={tacticalData}
        triggers={{}}
      />
    );
  };

  handleClearSearch = () => {
    this.handleResetFilters(true); // clears state
    this.setState({ reloadSchema: this.state.reloadSchema + 1 }); // change the key of SchemaUI in order to force a remount
  };

  renderSearchInput = () => (
    <SearchInput
      value={this.state.queryObj.basic}
      onSubmit={this.handleFetchSearch}
      onChange={value => this.handleGeneralQueryUpdate('basic', value)}
    />
  );

  renderStatusFilters = () => {
    const { computedStyles, filters } = this.props;
    const statusFilters = getOrElse(filters, 'status', []);
    const options = [
      {
        label: 'New',
        value: CASE_STATUS_NEW,
        checked: statusFilters.includes(CASE_STATUS_NEW)
      },
      {
        label: 'In Progress',
        value: CASE_STATUS_IN_PROGRESS,
        checked: statusFilters.includes(CASE_STATUS_IN_PROGRESS)
      },
      {
        label: 'Complete',
        value: CASE_STATUS_COMPLETED,
        checked: statusFilters.includes(CASE_STATUS_COMPLETED)
      },
      {
        label: 'Archived',
        value: CASE_STATUS_ARCHIVED,
        checked: statusFilters.includes(CASE_STATUS_ARCHIVED)
      }
    ];

    return (
      <CheckboxGroup
        className={computedStyles.checkBox}
        renderHorizontal
        options={options}
        onChange={this.handleStatusFiltersChange}
      />
    );
  };

  render = () => {
    const { computedStyles, isDuplicateSearch } = this.props;
    const { showFilters } = this.state;

    return (
      <div id="searchHeader" className={computedStyles.base}>
        <div className={computedStyles.row}>
          <span className={computedStyles.title}>
            {isDuplicateSearch ? 'Duplicate Search' : 'Search Cases'}
          </span>
        </div>
        <SearchBar
          {...this.props}
          searchInput={!showFilters ? this.renderSearchInput() : null}
          hideFiltersText="General Search"
          showFiltersText="Advanced Search"
          stickyFilters={this.renderStickyFilters()}
          onToggleFilters={this.handleToggleFilters}
          onSubmit={this.handleFetchSearch}
          showFilters={showFilters}
          renderCopyContactsButton={this.renderCopyContactsButton}
          handleClearSearch={this.handleClearSearch}
          showClearButton
        >
          {this.renderAdvancedFields()}
        </SearchBar>
        <div
          style={{
            marginLeft: '32px'
          }}
        >
          {this.renderStatusFilters()}
        </div>
      </div>
    );
  };
}

export default withStyles(stylesGenerator)(SearchHeader);
