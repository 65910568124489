import { get, find, startsWith, filter, reduce } from 'lodash';

export const getAETasksCount = (tasksCounts, masterCaseId) => {
  const allVersions = filter(tasksCounts, tc =>
    startsWith(tc.key, `${masterCaseId}-AE`)
  );
  return reduce(allVersions, (sum, tc) => tc.count + sum, 0);
};

export const getTasksCount = (tasksCounts, caseId) =>
  get(find(tasksCounts, { key: caseId }), 'count', 0);

export * from './filtering';
export * from './display';
