import React from 'react';
import PropTypes from 'prop-types';
import { modalStyles } from 'Common/components/withModal';
import { SimpleButton } from 'Common/components/Form';

const CancelModal = ({ handleConfirm, handleDismiss }) => (
  <div className={modalStyles.base}>
    <span className={modalStyles.title}>SAVE TASK</span>
    Do you want to close this task? Changes you made will not be saved.
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton onClick={handleConfirm}>STAY</SimpleButton>
      <SimpleButton onClick={handleDismiss} primary>
        CLOSE
      </SimpleButton>
    </div>
  </div>
);

CancelModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired
};

export default CancelModal;
