import { generateCSS } from 'Common/components/Form';

const stylesGenerator = (theme, props) => ({
  base: generateCSS({
    width: '37px',
    height: '100%'
  }),

  button: generateCSS({
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '0',
    borderRadius: '0',
    height: '100%',
    width: '37px',
    border: 'none',
    backgroundColor: '#f2f2f2',
    outline: 'none',
    cursor: props.expansionLocked ? 'arrow' : 'pointer',
    ':hover': {
      backgroundColor: '#e2e2e2'
    },
    ':focus': {
      border: `1px solid ${theme.colors.quaternary}`
    }
  }),

  arrow: generateCSS({
    transition: 'transform 200ms ease',
    filter: props.expansionLocked ? 'saturate(0)' : null,
    opacity: props.expansionLocked ? '0.3' : '1',
    transform: props.isExpanded ? null : 'rotate(-90deg)'
  })
});

export default stylesGenerator;
