import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find, isNil } from 'lodash';

import { CMS_PROP_TYPES } from 'Common/constants';
import CreateCaseHeader from 'CreateCase/components/CreateCaseHeader';
import StickyTabs from 'CreateCase/components/StickyTabs';
import { FORM_BASE_PATH, MASTER_FORM_KEY } from 'CreateCase/constants';
import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';
import Page from '../Page';

class Layout extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        page: PropTypes.string
      })
    }).isRequired,
    actions: PropTypes.shape({
      replace: PropTypes.func
    }).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    triggers: PropTypes.shape({
      on404: PropTypes.func
    }).isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired,
    onCompleteWithoutSubmit: PropTypes.func.isRequired,
    reconciliationData: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  componentWillMount() {
    this.redirectToFirstTab();
  }

  // React router has the correct props and nextProps here, but not in Page (child component),
  // so we determine if the page route has changed here.
  componentWillReceiveProps(nextProps) {
    const { match } = nextProps;
    const { params: nextParams } = match;
    // If the subcase changes (or view changes to mastercase dash) this is true
    this.pageChanged = nextParams.page !== this.props.match.params.page;
  }

  componentDidUpdate() {
    this.redirectToFirstTab();
  }

  redirectToFirstTab = () => {
    const { match: { params }, actions } = this.props;
    const page = this.resolvePage();
    if (isNil(this.currentTabForPath(page))) {
      actions.replace(
        `/${FORM_BASE_PATH}/${params.masterCaseId}/${params.page}/${
          page.tabs[0].path
        }`
      );
    }
  };

  resolvePage = () => {
    const { schema, match } = this.props;
    const pagePath = match.params.page || MASTER_FORM_KEY;
    return find(schema.pages, { path: pagePath });
  };

  currentTabForPath = page => {
    const { match } = this.props;
    return page.tabs.find(t => t.path === match.params.tab);
  };

  renderStickyTabs = page =>
    page.tabs.length > 1 ? (
      <StickyTabs
        {...this.props}
        pageSchema={page}
        reconciliationData={this.props.reconciliationData}
      />
    ) : null;

  render = () => {
    const {
      computedStyles,
      triggers,
      onCompleteWithoutSubmit,
      reconciliationData
    } = this.props;

    const page = this.resolvePage();
    if (!page) {
      console.error('Could not resolve page for schema.');
      triggers.on404();
      return null;
    }
    return (
      <div className={computedStyles.base}>
        <CreateCaseHeader
          {...this.props}
          onCompleteWithoutSubmit={() => onCompleteWithoutSubmit(this.form)}
          pageSchema={page}
        />
        {this.renderStickyTabs(page)}
        <Page
          {...this.props}
          formRef={component => (this.form = component)}
          pageSchema={page}
          pageChanged={this.pageChanged || false}
          reconciliationData={reconciliationData}
        />
      </div>
    );
  };
}

export default withStyles(stylesGenerator)(Layout);
