import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { find, get, isNil, isString, capitalize } from 'lodash';

import TableRow from 'Common/components/TableRow';
import { CMS_PROP_TYPES, STATUSES_MAP, NOT_SPECIFIED } from 'Common/constants';
import { getOrElse, getSubcaseList, formatProductTypes } from 'Common/utils';
import { Checkbox, abbvieDateFormat } from 'Common/components/Form';
import { MASTER_STATE_PATHS, SERIOUSNESS_OPTIONS } from 'Queue/constants';
import { getTasksCount } from 'Queue/utils';
import DescriptionLink from './DescriptionLink';
import moment from 'moment';

class MasterCaseRow extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      masterCaseId: PropTypes.object.isRequired,
      subcaseList: PropTypes.object.isRequired,
      actions: PropTypes.object.isRequired,
      productCell: PropTypes.object.isRequired
    }).isRequired,
    row: PropTypes.shape({
      id: PropTypes.string,
      countryOfPrimaryReporterLabel: PropTypes.string.isRequired,
      status: PropTypes.string
    }).isRequired,
    caseLink: PropTypes.string.isRequired,
    tasksLink: PropTypes.string.isRequired,
    tasksCounts: PropTypes.arrayOf(PropTypes.object),
    tacticalData: CMS_PROP_TYPES.tacticalData,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSelectCaseOrTask: PropTypes.func
  };

  static defaultProps = {
    tacticalData: {},
    tasksCounts: [],
    selectedItemsToAssignOrArchive: [],
    handleSelectCaseOrTask: () => {}
  };

  hasStringData = input => !isNil(input) && isString(input) && input.length > 0;

  renderMCActions = () => {
    const { computedStyles, caseLink } = this.props;

    return (
      <div className={computedStyles.actions}>
        <div>
          <Link to={caseLink} target="_blank">
            View
          </Link>
        </div>
        {this.renderTasksLink()}
      </div>
    );
  };

  renderTasksLink = () => {
    const { row, tasksLink, tasksCounts } = this.props;
    const tasksCount = getTasksCount(tasksCounts, row.masterCaseId);

    return (
      <div>
        <Link to={tasksLink} target="_blank">
          View Tasks {tasksCount ? `(${tasksCount})` : null}
        </Link>
      </div>
    );
  };
  /**
   * Formats a date object to a string in 'DD MMM YYYY HH:mm:ss' format.
   *
   * @param dt - The date object that will be formatted.
   * @returns {string}
   */
  formatDateTime = dt => moment(dt).format('DD MMM YYYY HH:mm:ss');

  renderCell(column) {
    const { computedStyles, row, caseLink, tacticalData } = this.props;

    switch (column.label) {
      case 'Case Information':
        return (
          <td>
            <Link
              to={caseLink}
              className={computedStyles.masterCaseId}
              target="_blank"
            >
              {row.masterCaseId}
            </Link>
            <span className={computedStyles.subcaseList}>
              {getSubcaseList(row, subcases => subcases.join(', '))}
            </span>
            <DescriptionLink row={row} />
          </td>
        );
      case 'Country of Reporter':
        return <td>{row.countryOfPrimaryReporterLabel}</td>;
      case 'Method of Receipt': {
        const methodOfReceiptOptions = get(
          tacticalData,
          'document-data.mastercase-options.method_of_receipt',
          []
        );
        const methodOfReceiptMatch = find(
          methodOfReceiptOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.METHOD_OF_RECEIPT)
        );

        const methodOfReceipt = get(
          methodOfReceiptMatch,
          'label',
          NOT_SPECIFIED
        );
        return <td>{methodOfReceipt}</td>;
      }
      case 'Abbvie Awareness (AE)': {
        const dateOutputAE = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_AE)
        );

        const abbvieAwarenessAE = this.hasStringData(dateOutputAE)
          ? dateOutputAE
          : NOT_SPECIFIED;
        return <td>{abbvieAwarenessAE}</td>;
      }
      case 'Abbvie Awareness (PQ)': {
        const dateOutputPQ = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.AWARENESS_DATE_PQ)
        );

        const abbvieAwarenessPQ = this.hasStringData(dateOutputPQ)
          ? dateOutputPQ
          : NOT_SPECIFIED;
        return <td>{abbvieAwarenessPQ}</td>;
      }
      case 'Status':
        if (column.sortpath === 'subcases.medicalInfo.status') {
          return this.renderTD(MASTER_STATE_PATHS.MISTATUS, NOT_SPECIFIED);
        }
        return <td>{STATUSES_MAP[get(row, 'status', 'NEW')]}</td>;
      case 'Actions':
        return <td>{this.renderMCActions()}</td>;
      case 'Affiliate':
        return <td>{row.affiliate}</td>;
      case 'Current Revision':
        return <td>{row.currentRevision}</td>;
      case 'Archived':
        return <td>{row.archived}</td>;
      case 'Last Updated User Id':
        return <td>{row.lastUpdatedUserId}</td>;
      case 'Last Updated Username':
        return <td>{row.lastUpdatedUsername}</td>;
      case 'Subcase Creator UserName':
        return <td>{row.subcaseCreatorUserName}</td>;
      case 'Case Creator':
        return <td>{row.creatorUsername || NOT_SPECIFIED}</td>;
      case 'Subcase Creator Id':
        return <td>{row.subcaseCreatorid}</td>;
      case 'Affiliate Awareness Date': {
        const affiliateAwarenessDate = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.SUMMARY_AFFILIATE_AWARENESS_DATE)
        );

        const dateAffiliateAwarenessDate = this.hasStringData(
          affiliateAwarenessDate
        )
          ? affiliateAwarenessDate
          : NOT_SPECIFIED;
        return <td>{dateAffiliateAwarenessDate}</td>;
      }
      case 'Seriousness': {
        const seriousnessOptions = SERIOUSNESS_OPTIONS.options;
        const seriousnessOptionsMatch = find(
          seriousnessOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.SERIOUSNESS)
        );

        const seriousness = get(
          seriousnessOptionsMatch,
          'label',
          NOT_SPECIFIED
        );
        return <td>{seriousness}</td>;
      }
      case 'Master Case Product':
      case 'Product': {
        const masterCaseProductOptions = get(
          tacticalData,
          'document-data.mastercase-product-options'
        );

        const masterCaseProductMatch = find(
          masterCaseProductOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.PRODUCT_NAME)
        );

        const masterCaseProduct = get(
          masterCaseProductMatch,
          'label',
          NOT_SPECIFIED
        );
        return <td>{masterCaseProduct}</td>;
      }
      case 'Trilogy Load Date': {
        const trilogyLoadDate = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.TRILOGYLOADDATE)
        );

        const datetrilogyLoadDate = this.hasStringData(trilogyLoadDate)
          ? trilogyLoadDate
          : NOT_SPECIFIED;
        return <td>{datetrilogyLoadDate}</td>;
      }
      case 'Type of Contact': {
        const typeofContactOptions = get(
          tacticalData,
          'document-data.mastercase-options.type_of_contact',
          []
        );
        const typeofContactMatch = find(
          typeofContactOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.CONTACT_TYPE)
        );

        const typeofContact = get(typeofContactMatch, 'label', NOT_SPECIFIED);
        return <td>{typeofContact}</td>;
      }
      case 'Third Party Patient ID':
        return this.renderTD(
          MASTER_STATE_PATHS.PATIENT_REPORTER,
          NOT_SPECIFIED
        );
      case 'Service Provider': {
        const serviceProviderOptions = get(
          tacticalData,
          'document-data.psp-options.psp_name',
          []
        );
        const serviceProviderMatch = find(
          serviceProviderOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.SERVICE_PROVIDER)
        );

        const serviceProvider = get(
          serviceProviderMatch,
          'label',
          NOT_SPECIFIED
        );
        return <td>{serviceProvider}</td>;
      }
      case 'Program ID/Name': {
        const programIDNameOptions = get(
          tacticalData,
          'document-data.psp-options.psp_opi_number',
          []
        );
        const programIDNameMatch = find(
          programIDNameOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.PROGRAM_ID_NAME)
        );
        const programIDName = get(programIDNameMatch, 'label', NOT_SPECIFIED);
        return <td>{programIDName}</td>;
      }
      case 'Product Marketed Name':
        return this.renderTD(
          MASTER_STATE_PATHS.PROGRAM_MARKETED_NAME,
          NOT_SPECIFIED
        );
      case 'Date of Birth': {
        let patientDOB = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.PATIENT_DOB)
        );
        patientDOB = patientDOB || NOT_SPECIFIED;
        return <td>{patientDOB}</td>;
      }
      case 'Initials':
        return this.renderTD(
          MASTER_STATE_PATHS.PATIENT_INITIALS,
          NOT_SPECIFIED
        );
      case 'Contact Reference Number':
        return this.renderTD(
          MASTER_STATE_PATHS.CONTACT_REFERENCE_NUMBER,
          NOT_SPECIFIED
        );
      case 'Due Date': {
        const dueDate = abbvieDateFormat(
          getOrElse(row, MASTER_STATE_PATHS.DUEDATE)
        );
        const datedueDate = this.hasStringData(dueDate)
          ? dueDate
          : NOT_SPECIFIED;
        return <td>{datedueDate}</td>;
      }
      case 'High Impact': {
        const highImpact = capitalize(
          get(row, MASTER_STATE_PATHS.HIGH_IMPACT, '')
        );
        return <td>{highImpact}</td>;
      }
      case 'PQ Owner':
        return this.renderTD(MASTER_STATE_PATHS.PQ_OWNER, NOT_SPECIFIED);
      case 'Replacement Required':
        return this.renderTD(
          MASTER_STATE_PATHS.REPLACEMENT_REQUIRED,
          NOT_SPECIFIED
        );
      case 'Sample Available':
        return this.renderTD(
          MASTER_STATE_PATHS.SAMPLE_AVAILABLE,
          NOT_SPECIFIED
        );
      case 'Sample Required for Investigation':
        return this.renderTD(
          MASTER_STATE_PATHS.SAMPLE_REQUIRED_FOR_INVESTIGATION,
          NOT_SPECIFIED
        );
      case 'Death':
        return this.renderTD(MASTER_STATE_PATHS.DEATH, NOT_SPECIFIED);
      case 'AE Serious': {
        const aeSeriousOptionsMatch = find(
          SERIOUSNESS_OPTIONS.options,
          ({ value }) =>
            value ===
            getOrElse(row, MASTER_STATE_PATHS.ADVERSEEVENT_SAFETY_SERIOUSNESS)
        );

        const aeSerious = get(aeSeriousOptionsMatch, 'label', NOT_SPECIFIED);
        return <td>{aeSerious}</td>;
      }
      case 'Batch/Lot Number':
        return this.renderTD(
          MASTER_STATE_PATHS.BATCH_LOT_NUMBER,
          NOT_SPECIFIED
        );
      case 'Reactions':
        return this.renderTD(MASTER_STATE_PATHS.REACTIONS, NOT_SPECIFIED);
      case 'Owner':
        return this.renderTD(MASTER_STATE_PATHS.OWNER, NOT_SPECIFIED);
      case 'Automated Process':
        return this.renderTD(
          MASTER_STATE_PATHS.AUTOMATION_STATUS,
          NOT_SPECIFIED
        );
      case 'AGN/ABV': {
        const productType = formatProductTypes(
          getOrElse(row, MASTER_STATE_PATHS.PRODUCT_TYPE, NOT_SPECIFIED)
        );
        return <td>{productType}</td>;
      }
      case 'Translation Status': {
        const translation_statusOptions = get(
          tacticalData,
          'document-data.mastercase-options.translation_status',
          []
        );
        const translation_status_match = find(
          translation_statusOptions,
          ({ value }) =>
            value === getOrElse(row, MASTER_STATE_PATHS.TRANSLATION_STATUS)
        );
        const translation_status = get(
          translation_status_match,
          'label',
          MASTER_STATE_PATHS.TRANSLATION_NA
          // NOT_SPECIFIED
        );

        return <td>{translation_status}</td>;
      }
      case 'Documents Sent for Translation': {
        return this.renderTD(
          MASTER_STATE_PATHS.SENT_FOR_TRANSLATION,
          NOT_SPECIFIED
        );
      }
      case 'Most Recent Date/Time of Documents Sent': {
        const dateTimeFormat = this.formatDateTime(row.documentSentDate);
        return (
          <td>
            {dateTimeFormat !== 'Invalid date' ? dateTimeFormat : NOT_SPECIFIED}
          </td>
        );
      }
      case 'Documents Pending Translation': {
        return this.renderTD('documentPendingTranslation', NOT_SPECIFIED);
      }
      case 'Affiliate Location':
        return this.renderTD(
          MASTER_STATE_PATHS.AFFILIATE_COUNTRY_LABEL,
          NOT_SPECIFIED
        );
      default:
        return <td>{row.sortPath ? row.sortPath : NOT_SPECIFIED} </td>;
    }
  }

  renderTD(label, nullValue) {
    const { row } = this.props;
    const value = getOrElse(row, label, nullValue);
    return <td>{value}</td>;
  }

  render() {
    const { row, selectedItemsToAssignOrArchive } = this.props;

    return (
      <TableRow id="queue-mc-table-row">
        <td>
          <Checkbox
            onChange={evt => this.props.handleSelectCaseOrTask(row.id, evt)}
            value={selectedItemsToAssignOrArchive.includes(row.id)}
          />
        </td>
        {(this.props.userDesiredColumns || []).map(column =>
          this.renderCell(column)
        )}
      </TableRow>
    );
  }
}

export default MasterCaseRow;
