/* eslint import/prefer-default-export:0 */
import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    display: 'block',
    margin: '30px 15px 30px',
    width: '100%'
  }),

  h2: generateCSS({
    margin: '15px 15px 30px',
    padding: '0',
    width: '100%',
    textDecoration: 'none',
    marginBottom: 30,
    ...theme.fonts.sectionTitle
  }),

  h3: generateCSS({
    fontSize: '18px',
    fontFamily: theme.fonts.default.fontFamily,
    fontWeight: 'bold',
    borderTop: `1px solid ${theme.colors.tertiary}`,
    padding: '10px 0 0'
  }),

  h4: generateCSS({
    fontSize: '16px',
    fontFamily: theme.fonts.default.fontFamily,
    fontWeight: 'bold'
  }),

  controls: generateCSS({
    alignItems: 'center',
    float: 'right',
    display: 'flex',

    '> img, > button': {
      cursor: 'pointer',
      transformOrigin: 'center',
      transition: 'transform 150ms ease',
      marginLeft: 10
    }
  }),

  collapsed: generateCSS({
    transform: 'rotate(-90deg)'
  })
});

export default stylesGenerator;
