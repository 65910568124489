import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '../../../Form/withStyles/index';

import defaultStyles from './styles';

// TODO: Determine `remove` cases
// import Button from 'Common/components/Form/Button';
// <Button align="right" type="remove" onClick={onRemove} />
const SectionTitle = ({ size, title, children = null, ...props }) => {
  const {
    collapsed,
    computedStyles,
    styles: additionalStyles,
    ...headingProps // eslint-disable-line
  } = props;

  return (
    <h2
      id={`section-${title}`}
      className={`${computedStyles.base} ${computedStyles[size]}`}
    >
      {title}
      <div className={computedStyles.controls}>{children}</div>
    </h2>
  );
};

SectionTitle.propTypes = {
  collapsed: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(['h2', 'h3', 'h4']),
  styles: PropTypes.object, // eslint-disable-line
  children: PropTypes.node,
  computedStyles: PropTypes.shape({
    base: PropTypes.object
  })
};

SectionTitle.defaultProps = {
  size: 'h2',
  children: [],
  collapsed: false,
  title: '',
  styles: {},
  computedStyles: {
    base: {}
  }
};

export default withStyles(defaultStyles)(SectionTitle);
