import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, map, sortBy } from 'lodash';

import withStyles from '../withStyles';
import stylesGenerator from './styles';

class InfoTable extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired,
    children: PropTypes.node.isRequired,
    sortByKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  };

  static defaultProps = {
    sortByKeys: false
  };

  renderSortedChildren = () => {
    const { children, sortByKeys } = this.props;

    if (!sortByKeys) return children;

    return sortBy(children, sortByKeys.map(key => `props.${key}`));
  };

  getTableColumns = () => get(this.props.children, '[0].props.children');

  renderTableRowHeaders = () => {
    const titles = map(this.getTableColumns(), child =>
      get(child, 'props.title')
    );
    return <tr>{titles.map(title => <th key={title}>{title}</th>)}</tr>;
  };

  render() {
    const { computedStyles, children } = this.props;
    const rowElements = get(children, '[0].props.elements', []);
    const hasData = !!map(rowElements, 'value').filter(e => e).length;

    return hasData ? (
      <table cellSpacing="0" className={computedStyles.base}>
        <tbody>
          {this.getTableColumns() ? this.renderTableRowHeaders() : null}
          {this.renderSortedChildren()}
        </tbody>
      </table>
    ) : null;
  }
}

export default withStyles(stylesGenerator)(InfoTable);
