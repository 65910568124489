import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from 'Common/components/Form/withStyles';
import TableHeader from 'Common/components/TableHeader';
import stylesGenerator from './styles';

const TasksTable = ({
  children,
  computedStyles,
  triggers,
  data,
  selectedItemsToAssignOrArchive,
  areAllCasesSelected
}) => (
  <table className={computedStyles.base}>
    <TableHeader
      items={data.userDesiredColumns || []}
      {...data}
      onSortClick={triggers.onSortClick}
      handleSelectAllCasesOrTasks={triggers.handleSelectAllCasesOrTasks}
      isSelectionRequired
      areAllCasesSelected={areAllCasesSelected}
    />
    <tbody className={computedStyles.tableBody}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          key: child.props.value.id, // New id will remount first component (important for $NEW_TASK)
          selectedItemsToAssignOrArchive
        })
      )}
    </tbody>
  </table>
);

TasksTable.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    tableBody: PropTypes.object.isRequired
  }).isRequired,
  data: PropTypes.shape({
    sortBy: PropTypes.objectOf(PropTypes.string.isRequired).isRequired
  }).isRequired,
  children: PropTypes.node,
  triggers: PropTypes.shape({
    onSortClick: PropTypes.func,
    handleSelectAllCasesOrTasks: PropTypes.func
  }).isRequired,
  selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
  areAllCasesSelected: PropTypes.bool
};

TasksTable.defaultProps = {
  children: null,
  selectedItemsToAssignOrArchive: [],
  areAllCasesSelected: false
};

const mapStateToProps = ({ tasksReducer }) => ({
  selectedItemsToAssignOrArchive: tasksReducer.selectedItemsToAssignOrArchive,
  areAllCasesSelected: tasksReducer.areAllCasesSelected
});

export default connect(mapStateToProps)(
  withStyles(stylesGenerator)(TasksTable)
);
