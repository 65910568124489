/* eslint import/prefer-default-export:0 */
import { generateCSS } from '../index';

const stylesGenerator = theme => ({
  base: generateCSS({
    alignItems: 'center',
    fontSize: '14px',
    display: 'inline-flex',
    justifyContent: 'center',
    height: '30px',
    order: 1,

    '> span:last-child': {
      display: 'inline-block',
      textIndent: '10px'
    }
  }),

  left: generateCSS({
    float: 'left'
  }),

  right: generateCSS({
    float: 'right'
  }),

  groupButton: generateCSS({
    flex: '0 1 auto',
    backgroundColor: theme.colors.base,
    borderRadius: '100%',
    transform: 'scale(1)',
    fontWeight: 'bold',
    color: theme.colors.base,
    cursor: 'pointer',
    pointerEvents: 'all',

    appearance: 'none',
    position: 'relative',
    height: '30px',
    width: '30px',
    padding: 0,
    transition: 'transform 200ms ease',
    textAlign: 'center',

    ':hover': {
      transform: 'scale(1.1)'
    },

    ':disabled': {
      opacity: '0.5',
      cursor: 'not-allowed',

      ':hover': {
        transform: 'scale(1)'
      }
    },

    ':active': {
      boxShadow: '0 0 rgba(0,0,0,0.8)'
    }
  }),

  add: generateCSS({
    border: `2px solid ${theme.colors.tertiary}`
  }),

  remove: generateCSS({
    border: `2px solid ${theme.colors.error}`
  }),

  addIcon: generateCSS({
    backgroundColor: theme.colors.tertiary,
    display: 'inline-block',
    position: 'absolute',
    top: '5px',
    left: '11px',
    height: '17px',
    width: '4px',

    ':last-child': {
      transform: 'rotate(90deg)'
    }
  }),

  removeIcon: generateCSS({
    backgroundColor: theme.colors.error,
    display: 'inline-block',
    position: 'absolute',
    top: '5px',
    left: '11px',
    height: '17px',
    width: '4px',

    ':first-child': {
      transform: 'rotate(-45deg)'
    },

    ':last-child': {
      transform: 'rotate(45deg)'
    }
  })
});

export default stylesGenerator;
