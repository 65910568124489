import { get, some } from 'lodash';
import { NOT_SPECIFIED, PRODUCTS_MAP_FULL_PATH } from 'Common/constants';
import { getDisplayCaseId, CASE_STATE_PATHS } from 'Tasks/utils/formatting';
import { validFullDate } from 'Common/components/Form';
import { getOrElse } from 'Common/utils';
import { EXPECTED_DELIVERY_DATE_KEY, LAST_RECEIVED_DATE_KEY } from './display';

export * from './display';
export * from './header';

export { TASK_STATE_PATHS, CASE_STATE_PATHS } from 'Tasks/utils/formatting';

export const TASKS_BASE_PATH = 'tasks';
export const YOUR_TASKS_PATH = `/${TASKS_BASE_PATH}/yours`;
export const ALL_TASKS_PATH = `/${TASKS_BASE_PATH}`;
export const TASKS_PATH = `/${TASKS_BASE_PATH}/:masterCaseId?/:subCase?`;

export const NEW_TASK_ID = '$NEW_TASK';

// Number of attempts that fit within animated row's height limit
export const ATTEMPTS_WITHIN_HEIGHT_LIMIT = 2;

// on master case (with subcases) task page '/' count
export const MASTERCASE_TASK_PAGE_SLASH_COUNT = 2;

// only show LRD if its a valid full date otherwise not specified
export const processLastReceivedDate = lrd =>
  validFullDate(lrd) ? lrd : NOT_SPECIFIED;

const getSubcasePath = subCaseType => {
  if (subCaseType === 'ae') return 'adverseEvent';
  if (subCaseType === 'pq') return 'productQuality';
  return 'medicalInfo';
};

const attemptTaskTypes = ['COMMUNICATION', 'PVQUERY', 'PQFOLLOWUP'];

/**
 * Creates a new task given the `taskType`, the associated `trilogyCase`,
 * `subCaseType` and the `userId`
 *
 * @param  {String} taskType     - One of CASEFLOW, REVIEW, PVQUERY, PQFOLLOWUP, or COMMUNICATION
 * @param  {Object} trilogyCase  - The trilogy case object
 * @param  {String} subcaseType  - The value from `params.match.subCase`
 * @param  {String} userId       - The user's id `sub` from active `session`
 * @param  {Object} existingTask - (optional) retains shared values from any values in here
 * @param attempts
 * @return {Object}              - The new task
 */
export const genNewTask = (
  taskType,
  trilogyCase,
  subcaseType,
  userId,
  existingTask = {}
) => {
  const subcaseId = get(
    trilogyCase.subcases,
    `${getSubcasePath(subcaseType)}.id`
  );

  let initialAERIndex;
  let initialArgusIndex;
  if (
    subcaseType === 'ae' &&
    trilogyCase.subcases.adverseEvent.subcaseVersions
  ) {
    const { subcaseVersions } = trilogyCase.subcases.adverseEvent;
    initialAERIndex = subcaseVersions.findIndex(
      value => value.aerNumber != null
    );
    initialArgusIndex = subcaseVersions.findIndex(
      value => value.argusNumber != null
    );
  }
  return {
    affiliate: get(trilogyCase, 'affiliate'),
    affiliateCountryLabel: get(trilogyCase, 'affiliateCountryLabel'),
    id: NEW_TASK_ID,
    form: {
      base: {
        // field shared for all types
        assignee: get(existingTask, 'form.base.assignee', null),
        // field shared for all types
        description: get(existingTask, 'form.base.description', null),
        // field shared for CASEFLOW and REVIEW only
        additionalNotes:
          taskType === 'CASEFLOW' || taskType === 'REVIEW'
            ? get(existingTask, 'form.base.additionalNotes', null)
            : null,
        // Due date is on every task type EXCEPT PVQuery
        dueDate:
          taskType !== 'PVQUERY'
            ? get(existingTask, 'form.base.dueDate', null)
            : null,
        caseId: trilogyCase.id,
        subcaseId: (subcaseId || '').toUpperCase(),
        subcaseType: (subcaseType || '').toUpperCase(),
        // globalId, aer and argus number only on AE task types (all but PQ follow-up)
        aerNumber:
          subcaseType === 'ae' &&
          get(trilogyCase, 'subcases.adverseEvent.subcaseVersions') &&
          (taskType === 'CASEFLOW' ||
            taskType === 'COMMUNICATION' ||
            taskType === 'REVIEW' ||
            taskType === 'PVQUERY')
            ? get(
                trilogyCase,
                `subcases.adverseEvent.subcaseVersions[${initialAERIndex}].aerNumber`
              )
            : null,
        argusNumber:
          subcaseType === 'ae' &&
          (taskType === 'CASEFLOW' ||
            taskType === 'COMMUNICATION' ||
            taskType === 'REVIEW' ||
            taskType === 'PVQUERY')
            ? get(
                trilogyCase,
                `subcases.adverseEvent.subcaseVersions[${initialArgusIndex}].argusNumber`
              )
            : null,
        globalId:
          subcaseType === 'ae' &&
          (taskType === 'CASEFLOW' ||
            taskType === 'COMMUNICATION' ||
            taskType === 'REVIEW' ||
            taskType === 'PVQUERY')
            ? get(existingTask, 'form.base.globalId', null)
            : null,
        pregnancyRelated:
          taskType === 'PVQUERY'
            ? some(get(trilogyCase, CASE_STATE_PATHS.PR_DEFAULT, []), {
                value: 'pregnancy_related'
              })
            : null,
        expectedDeliveryDate:
          taskType === 'PVQUERY'
            ? getOrElse(trilogyCase, EXPECTED_DELIVERY_DATE_KEY, '')
            : null,
        display: {
          caseId: getDisplayCaseId(trilogyCase.id, subcaseId),
          last_received_date: processLastReceivedDate(
            getOrElse(trilogyCase, LAST_RECEIVED_DATE_KEY, NOT_SPECIFIED)
          )
        }
      },
      additional: {
        type: taskType,
        maxAttempts: 5,
        requester: null,
        attempts: attemptTaskTypes.includes(taskType)
          ? { attempt: [{ attemptNumber: 1 }] }
          : null
      }
    },
    display: {
      product: getOrElse(
        trilogyCase,
        PRODUCTS_MAP_FULL_PATH[subcaseType || 'mc']
      ),
      updated: Date.now()
    },
    createdTimestamp: Date.now(),
    closedTimestamp: null,
    cancelledTimestamp: null,
    creatorId: userId,
    status: 'OPEN'
  };
};
