import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { promptDocumentSaved } from 'Common/utils';
import withStyles from 'Common/components/Form/withStyles';
import NavigationIntercept from 'Common/components/NavigationIntercept';
import { CASE_STATUS_COMPLETED } from 'Common/constants';
import { NEW_TASK_ID } from 'Tasks/constants';
import EditButton from './EditButton';
import Footer from './Footer';
import stylesGenerator from './styles';
import { isAuthorized, getRoles } from '../../../../../config/auth';

class TaskExpandedView extends PureComponent {
  static propTypes = {
    isEditing: PropTypes.bool,
    hasUnsavedFormData: PropTypes.bool.isRequired,
    onTaskSave: PropTypes.func.isRequired,
    onEditToggleClick: PropTypes.func.isRequired,
    onEditCancelClick: PropTypes.func.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired,
    children: PropTypes.node.isRequired,
    task: PropTypes.shape({
      creator: PropTypes.string.isRequired,
      createdTimestamp: PropTypes.number.isRequired
    }).isRequired,
    userList: PropTypes.arrayOf(
      PropTypes.shape({
        sub: PropTypes.string,
        email: PropTypes.string,
        un: PropTypes.string,
        fn: PropTypes.string,
        sn: PropTypes.string
      })
    ).isRequired,
    showModal: PropTypes.func.isRequired,
    clearModal: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
  };

  static defaultProps = {
    isEditing: false
  };

  genOnNavigate = task => () => this.props.onTaskSave({ value: task });

  genShouldNavigate = task => location =>
    !promptDocumentSaved(location, this.genOnNavigate(task));

  render() {
    const {
      computedStyles,
      children,
      isEditing,
      hasUnsavedFormData,
      onEditToggleClick,
      onEditCancelClick,
      task,
      id,
      userList,
      showModal,
      clearModal
    } = this.props;
    const isNew = task.id === NEW_TASK_ID;
    const isCompleted = task.status === CASE_STATUS_COMPLETED;
    const editingLocked = isNew || isCompleted;
    console.log(this.props);
    return (
      <div className={computedStyles.base}>
        <NavigationIntercept
          shouldPromptOnUnload
          hasUnsavedData={hasUnsavedFormData}
          shouldIntercept={hasUnsavedFormData}
          shouldNavigate={this.genShouldNavigate(task)}
        />
        {!['CANCELLED', 'COMPLETED'].includes(task.status) &&
        isAuthorized('edit', true) ? (
          <EditButton
            id={id}
            editingLocked={editingLocked}
            isEditing={isEditing}
            onEditToggleClick={onEditToggleClick}
            onEditCancelClick={onEditCancelClick}
          />
        ) : null}
        {children}
        <Footer
          creatorId={task.creatorId}
          createdTimestamp={task.createdTimestamp}
          userList={userList}
          caseId={task.form.base.caseId}
          taskId={id}
          type={task.form.additional.type}
          showModal={showModal}
          clearModal={clearModal}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(TaskExpandedView);
