import { generateCSS, genLabelStyles } from '../index';

const stylesGenerator = (theme, props) => {
  const labelStyles = genLabelStyles(theme, props);

  return {
    base: generateCSS({
      position: 'relative',
      color: theme.colors.secondary,
      fontSize: '16px',
      fontWeight: 300,
      ...theme.fonts.default,
      ...props.styles
    }),

    label: generateCSS({
      ...theme.fonts.inputLabel
    }),

    helpText: generateCSS({
      ...theme.fonts.helpText
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
