import { isEmpty } from 'lodash';

import {
  generateCSS,
  genDisabledStyles,
  genErrorStyles,
  genLabelStyles
} from '../index';

const styleGenerator = (theme, props) => {
  const disabledStyles = genDisabledStyles(theme);
  const errorStyles = genErrorStyles(theme);
  const labelStyles = genLabelStyles(theme, props);
  const hasErrors = !isEmpty(props.errors);
  const boxShadowColor = hasErrors ? theme.colors.error : theme.colors.tertiary;
  const labelColor = hasErrors ? theme.colors.error : 'inherit';

  return {
    base: generateCSS({
      display: 'inline-block',
      marginRight: 0,
      width: '50%',
      ...props.layout
    }),

    input: generateCSS({
      appearance: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      position: 'relative',
      border: `2px solid ${theme.colors.tertiary}`,
      margin: '0 10px 0 0',
      height: '20px',
      width: '20px',
      minWidth: '20px',
      '::before': {
        background: theme.colors.tertiary
      },
      ':checked': {
        background: theme.colors.tertiary,
        boxShadow: `0 0 0 2px ${boxShadowColor}`,
        '::after': {
          backgroundColor: theme.colors.tertiary,
          display: 'block',
          lineHeight: 1.4,
          position: 'absolute',
          top: 0,
          left: 0,
          content: '✔︎',
          color: 'white',
          fontSize: 10,
          width: 18,
          textAlign: 'center'
        }
      },
      ...props.styles,
      ...(props.disabled ? disabledStyles : {})
    }),

    ...labelStyles,

    group: generateCSS({
      '.horizontal': {
        display: 'block !important',
        '> div': {
          marginRight: '20px'
        }
      }
    }),

    optionLabel: generateCSS({
      verticalAlign: 'top',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      height: '20px',
      margin: '0',
      ...theme.fonts.input,
      '> input + span': {
        color: labelColor
      }
    }),

    inputHelpTextCompensation: generateCSS({
      margin: '0 30px 26px 0'
    }),

    helpText: generateCSS({
      padding: '0 0 10px 26px',
      display: 'block',
      border: 'none',
      color: theme.colors.quaternary,
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      '> input': {
        borderColor: theme.colors.error
      },
      '> input + span': {
        ...errorStyles
      }
    }),

    fullWidth: generateCSS({
      width: '100%'
    })
  };
};

export default styleGenerator;
