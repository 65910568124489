import React from 'react';
import ReactDOM from 'react-dom';
import registerInterceptors from 'config/fetch';
import {
  initSessionBroadcast,
  initIdleHandler,
  initWebWorker
} from 'config/auth';
import 'react-select/dist/react-select.css';

import Root from 'Common/components/Root';
import 'Common/styles/base.css';

registerInterceptors();
initSessionBroadcast();
initIdleHandler(window.sessionTimeoutMs);
initWebWorker();

const node = document.getElementById('root');

const render = Component => {
  ReactDOM.render(<Component />, node);
};

render(Root);

// Store as env for debugging
const env = process.env;

if (env.HOT && module.hot) {
  module.hot.accept(Root, () => {
    render(Root, node);
  });
}
