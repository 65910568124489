/* eslint import/prefer-default-export:0 */
import { mapValues, findLast } from 'lodash/fp';

/** @module Path Utils */

const replaceDashedIndex = string =>
  string
    .replace('.-', '\\[[\\d]\\]')
    .replace('.-.', '\\[[\\d]\\].')
    .replace(/\[(\d)]/g, '\\[$1\\]');

/**
 * Resolves a state path's missing index (represented by a dash)
 * by checking current available elements.
 * Necessary for add op conflicts where an input group's path contains
 * a "-" in place of an integer.
 * @function
 */
export const resolveIndexes = (elements = {}) => conflicts => {
  const elementKeys = Object.keys(elements);
  const incrementedIndexes = mapValues((changeSet = { path: '' }) => {
    const dashedIndexPathMatch =
      changeSet.path.match(/.[-]/) || changeSet.path.match(/.[-]./);

    if (!dashedIndexPathMatch) return changeSet;

    const elementRegex = replaceDashedIndex(dashedIndexPathMatch.input);
    const elementByRegex = elementKey => elementKey.match(elementRegex);
    const findMatchingElement = findLast(elementByRegex);
    const matchingElement = findMatchingElement(elementKeys);
    if (matchingElement) {
      const matches = matchingElement.match(/\[[\d]]/g);
      const currentIndexStr = matches[matches.length - 1].replace(/\[|\]/g, '');
      const currentIndex = parseInt(currentIndexStr, 10);
      const incrementedPath = changeSet.path.replace(
        /.[-]/,
        `[${currentIndex + 1}]`
      );

      return { ...changeSet, path: incrementedPath };
    }

    return changeSet;
  });

  return mapValues(incrementedIndexes)(conflicts);
};
