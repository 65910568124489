import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '3px 4px',
    border: `1px solid ${theme.colors.quaternary}`
  })
});

export default stylesGenerator;
