import React, { PureComponent } from 'react';
import { cloneDeep, mapKeys } from 'lodash';
import PropTypes from 'prop-types';
import SchemaUI from '@gmatas/cse';
import { contactLookup, patientLookup } from 'mocks';

import PropTypeSafety from 'Common/components/Form/PropTypeSafety';
import * as formComponents from 'Common/components/Form/formComponents';
import wrappedInputComponents from 'Common/components/Form/wrappedInputComponents';
import SEARCH_CONTACTS from 'api/graphql/queries/case/searchContacts';
import SEARCH_PATIENT from 'api/graphql/queries/case/searchPatient';
import submitCaseSearch from 'api/graphql/submitCaseSearch';
import { withStyles, SimpleButton } from 'Common/components/Form';
import SearchBar from 'Common/components/SearchBar';
import SearchInput from 'Common/components/SearchInput';
import { CMS_PROP_TYPES } from 'Common/constants';
import { pruneEmpties } from 'Common/utils';
import { LOOKUP_CONTACT } from 'Lookup/constants';
import stylesGenerator from './styles';

class SearchHeader extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      title: PropTypes.object.isRequired,
      row: PropTypes.object.isRequired
    }).isRequired,
    lookupType: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      emitUpdateQuery: PropTypes.func.isRequired,
      emitFetchSearch: PropTypes.func.isRequired,
      emitUpdateFilters: PropTypes.func.isRequired,
      emitLookupQuerySuccess: PropTypes.func.isRequired, // TODO: Avoid this pattern later
      emitLookupQueryFailure: PropTypes.func.isRequired
    }).isRequired,
    searchBarQuery: PropTypes.string,
    filters: PropTypes.shape({}).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    lookupWindow: PropTypes.instanceOf(window.constructor)
  };

  static defaultProps = {
    searchBarQuery: '',
    lookupWindow: window
  };

  getConfig = () => ({
    locale:
      this.props.lookupType === LOOKUP_CONTACT
        ? contactLookup.locale
        : patientLookup.locale,
    index: { key: 'statePath', inherit: true },
    location: 'lookup'
  });

  getSchema = () =>
    this.props.lookupType === LOOKUP_CONTACT
      ? contactLookup.pages[0]
      : patientLookup.pages[0];

  getFiltersFromCSE = () => pruneEmpties(this.form.state.model) || {};

  handleFetchSearch = () => {
    const { lookupType } = this.props;
    const filters = this.getFiltersFromCSE();
    this.props.actions.emitUpdateFilters(filters);
    this.props.actions.emitFetchSearch(
      submitCaseSearch,
      lookupType === LOOKUP_CONTACT ? SEARCH_CONTACTS : SEARCH_PATIENT,
      data => this.handleSuccess(data, lookupType),
      this.handleFailure
    );
  };

  handleSuccess = (data, lookupType) =>
    this.props.actions.emitLookupQuerySuccess(data, lookupType);
  handleFailure = data => this.props.actions.emitLookupQueryFailure(data);

  handleCopyContacts = () => {
    const { lookupWindow, lookupType } = this.props;
    const keyPrefix =
      lookupType === LOOKUP_CONTACT ? 'contacts.contact' : 'patient.patient';
    const caseCriteria = mapKeys(
      this.getFiltersFromCSE(),
      (val, key) => `${keyPrefix}[0].${key}`
    );
    lookupWindow.handleCopyCriteria(lookupType, caseCriteria);
    lookupWindow.close();
  };

  renderCopyContactsButton = lookupType => {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.searchButtons}>
        <div className={computedStyles.filterButton}>
          <SimpleButton onClick={this.handleCopyContacts} type="button">
            {`COPY ${lookupType} TO CASE`}
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderAdvancedFields = () => {
    const { filters, lookupType, tacticalData } = this.props;
    return (
      <SchemaUI
        ref={formSchema => (this.form = formSchema)}
        key={lookupType === LOOKUP_CONTACT ? 'contactLookup' : 'patientLookup'}
        components={{ ...formComponents, ...wrappedInputComponents }}
        config={this.getConfig()}
        defaultComponent={PropTypeSafety}
        schema={this.getSchema()}
        model={cloneDeep(filters)} // CSE will mutate the props without cloneDeep
        onChange={() => {}} // needed by cse 2.1.0 or it will throw an error
        data={tacticalData}
        triggers={{}}
      />
    );
  };

  renderSearchInput = () => {
    const { actions, searchBarQuery } = this.props;
    return (
      <SearchInput
        value={searchBarQuery}
        onSubmit={this.handleFetchSearch}
        onChange={value => actions.emitUpdateQuery(value)}
      />
    );
  };

  render = () => {
    const { computedStyles, lookupType } = this.props;
    return (
      <div className={computedStyles.base}>
        <div className={computedStyles.row}>
          <span className={computedStyles.title}>{this.getSchema().title}</span>
        </div>
        <SearchBar
          {...this.props}
          searchInput={this.renderSearchInput()}
          hideFiltersText="General Search"
          showFiltersText="Advanced Search"
          onSubmit={this.handleFetchSearch}
          showFilters
        >
          {this.renderAdvancedFields()}
          {this.renderCopyContactsButton(lookupType)}
        </SearchBar>
      </div>
    );
  };
}

export default withStyles(stylesGenerator)(SearchHeader);
