import { createAction, handleActions } from 'redux-actions';
import set from 'lodash/set';
import { chunk } from 'lodash';
import { EMIT_FETCH_SEARCH } from 'Common/ducks/searchBar';
import { mergeCaseIntoTask } from 'Tasks/utils';
import { client } from 'config/apollo';
import submitTaskMutation from 'api/graphql/submitTaskMutation';
import fetchGqlQuery from 'api/graphql/fetchGqlQuery';
import getCaseByIdQuery from 'api/graphql/queries/case/getCaseByIdQuery';
import caseResponseFormatter from 'api/graphql/formatter/caseResponse';
import { genNewTask, NEW_TASK_ID } from 'Tasks/constants';
import {
  FRAGMENT_NAME_TASK,
  FRAGMENT_NAME_CASE,
  EMIT_CLEAN_SLATE,
  PAGE_STATUS
} from 'Common/constants';
import fetchAssignTaskToUser from 'api/rest/fetchAssignTaskToUser';

const EMIT_TASK_UPDATE = 'EMIT_TASK_UPDATE';
const EMIT_CANCEL_TASK_EDIT = 'EMIT_CANCEL_TASK_EDIT';

const EMIT_TASK_SAVE = 'EMIT_TASK_SAVE';
const EMIT_TASK_CANCEL = 'EMIT_TASK_CANCEL';
const EMIT_TASK_SAVE_SUCCESS = 'EMIT_TASK_SAVE_SUCCESS';
const EMIT_TASK_SAVE_ERROR = 'EMIT_TASK_SAVE_ERROR';

const EMIT_TASK_COMPLETE = 'EMIT_TASK_COMPLETE';
const EMIT_TASK_COMPLETE_SUCCESS = 'EMIT_TASK_COMPLETE_SUCCESS';
const EMIT_TASK_REOPEN = 'EMIT_TASK_REOPEN';
const EMIT_TASK_REOPEN_SUCCESS = 'EMIT_TASK_REOPEN_SUCCESS';
const EMIT_TASK_REASSIGN = 'EMIT_TASK_REASSIGN';

const EMIT_FETCH_TASK_CASE = 'EMIT_FETCH_TASK_CASE';
const EMIT_FETCH_TASK_CASE_SUCCESS = 'EMIT_FETCH_TASK_CASE_SUCCESS';
const EMIT_FETCH_TASK_CASE_FAILURE = 'EMIT_FETCH_TASK_CASE_FAILURE';

const EMIT_FETCH_TASKS_SUCCESS = 'EMIT_FETCH_TASKS_SUCCESS';
// Not async, created locally in memory
const EMIT_TASK_CREATE = 'EMIT_TASK_CREATE';
const EMIT_TASK_FILTER = 'EMIT_TASK_FILTER';
const EMIT_TASK_EDIT = 'EMIT_TASK_EDIT';
const EMIT_TASK_EDIT_STOP = 'EMIT_TASK_EDIT_STOP';
const EMIT_UPDATE_TASK_SELECTION = 'EMIT_UPDATE_TASK_SELECTION';
const EMIT_UPDATE_ASSIGN_TASK_TO_USER = 'EMIT_UPDATE_ASSIGN_TASK_TO_USER';
const EMIT_UPDATE_ASSIGN_TASK_TO_USER_COMPLETE =
  'EMIT_UPDATE_ASSIGN_TASK_TO_USER_COMPLETE';

export const emitTaskUpdate = createAction(EMIT_TASK_UPDATE);
export const emitTaskEditCancel = createAction(EMIT_CANCEL_TASK_EDIT);
export const emitTaskFilter = createAction(EMIT_TASK_FILTER);
export const emitTaskEdit = createAction(EMIT_TASK_EDIT);
export const emitTaskEditStop = createAction(EMIT_TASK_EDIT_STOP);
export const emitTaskCreate = createAction(
  EMIT_TASK_CREATE,
  (caseId, subcaseId, subcaseType, caseObj, userId) => ({
    caseId,
    subcaseId,
    subcaseType,
    caseObj,
    userId
  })
);

export const emitFetchTaskCase = (caseId, onSuccess, onFailure) => (
  dispatch,
  getState
) => {
  dispatch(createAction(EMIT_FETCH_TASK_CASE)());
  const { schemaReducer } = getState();
  return dispatch(
    fetchGqlQuery(
      { id: caseId },
      onSuccess,
      onFailure,
      client,
      getCaseByIdQuery(schemaReducer.fragments[FRAGMENT_NAME_CASE])
    )
  );
};

export const emitFetchTaskCaseSuccess = createAction(
  EMIT_FETCH_TASK_CASE_SUCCESS,
  data => ({ data })
);

export const emitFetchTaskCaseFailure = createAction(
  EMIT_FETCH_TASK_CASE_FAILURE,
  data => ({ data })
);

export const emitFetchTasksSuccess = createAction(
  EMIT_FETCH_TASKS_SUCCESS,
  (data, count) => ({ data, count })
);

/**
 * Whenever a task is saved (creation, editing, cancelling) this action is dispatched.
 * Some actions (ie. `emitTaskCancel`) do not want to re-render UI, so the `updateTimestamp`
 * value can overridden to `false` (this is what the cancel action does)
 *
 * @param  {Object}   task            - The task that was saved
 * @param  {String}   originalId      - The id of the task _before_ it was saved
 * @param  {Boolean}  updateTimestamp - Whether the `lastUpdate` state value should be updated after save
 */
const emitTaskSaveSuccess = createAction(
  EMIT_TASK_SAVE_SUCCESS,
  (task, originalId, updateTimestamp = true) => ({
    task,
    originalId,
    updateTimestamp
  })
);

const emitTaskSaveError = createAction(EMIT_TASK_SAVE_ERROR, error => ({
  error
}));

export const emitTaskCancel = ({ value: task }) => (dispatch, getState) => {
  const cancelledTask = {
    ...task,
    cancelledTimestamp: Date.now()
  };
  const { schemaReducer } = getState();
  dispatch({ type: EMIT_TASK_CANCEL, payload: cancelledTask });

  if (cancelledTask.id === NEW_TASK_ID) return Promise.resolve(cancelledTask);

  return submitTaskMutation(
    cancelledTask,
    schemaReducer.tacticalData,
    schemaReducer.fragments[FRAGMENT_NAME_TASK]
  ).then(
    updatedTask => {
      dispatch(emitTaskSaveSuccess(updatedTask, cancelledTask.id));
      return updatedTask;
    },
    error => emitTaskSaveError(error)
  );
};

export const emitTaskCompleteSuccess = createAction(
  EMIT_TASK_COMPLETE_SUCCESS,
  task => ({
    task
  })
);
export const emitTaskComplete = task => (dispatch, getState) => {
  const completedTask = {
    ...task,
    closedTimestamp: Date.now()
  };
  dispatch({ type: EMIT_TASK_COMPLETE, payload: completedTask });
  const { schemaReducer } = getState();
  return submitTaskMutation(
    completedTask,
    schemaReducer.tacticalData,
    schemaReducer.fragments[FRAGMENT_NAME_TASK]
  ).then(
    updatedTask => {
      dispatch(emitTaskCompleteSuccess(updatedTask));
      return updatedTask;
    },
    error => emitTaskSaveError(error)
  );
};

export const emitTaskReopenSuccess = createAction(
  EMIT_TASK_REOPEN_SUCCESS,
  task => ({
    task
  })
);
export const emitTaskReopen = task => (dispatch, getState) => {
  const reopenedTask = {
    ...task,
    closedTimestamp: null
  };
  dispatch({ type: EMIT_TASK_REOPEN, payload: reopenedTask });
  const { schemaReducer } = getState();
  return submitTaskMutation(
    reopenedTask,
    schemaReducer.tacticalData,
    schemaReducer.fragments[FRAGMENT_NAME_TASK]
  ).then(
    updatedTask => {
      dispatch(emitTaskReopenSuccess(updatedTask));
      return updatedTask;
    },
    error => emitTaskSaveError(error)
  );
};

export const emitTaskReassign = (task, userId) => (dispatch, getState) => {
  const assignedTask = {
    ...task,
    form: {
      base: {
        ...task.form.base,
        assignee: userId
      },
      additional: {
        ...task.form.additional
      }
    }
  };
  dispatch({ type: EMIT_TASK_REASSIGN, payload: assignedTask });
  const { schemaReducer } = getState();
  return submitTaskMutation(
    assignedTask,
    schemaReducer.tacticalData,
    schemaReducer.fragments[FRAGMENT_NAME_TASK]
  ).then(
    updatedTask => {
      dispatch(emitTaskSaveSuccess(updatedTask, assignedTask.id));
      return updatedTask;
    },
    error => emitTaskSaveError(error)
  );
};

export const emitTaskSave = ({ value }) => (dispatch, getState) => {
  const { tasksReducer, schemaReducer } = getState();
  if (tasksReducer.isSavingTask) {
    return Promise.reject();
  }
  dispatch({ type: EMIT_TASK_SAVE, payload: value });
  return submitTaskMutation(
    value,
    schemaReducer.tacticalData,
    schemaReducer.fragments[FRAGMENT_NAME_TASK]
  ).then(
    updatedTask => {
      dispatch(emitTaskSaveSuccess(updatedTask, value.id));
      return updatedTask;
    },
    error => dispatch(emitTaskSaveError(error))
  );
};

export const emitUpdateTaskSelection = createAction(
  EMIT_UPDATE_TASK_SELECTION,
  (selectedItemsToAssignOrArchive, areAllCasesSelected) => ({
    selectedItemsToAssignOrArchive,
    areAllCasesSelected
  })
);

export const emitUpdateAssignTaskToUserComplete = createAction(
  EMIT_UPDATE_ASSIGN_TASK_TO_USER_COMPLETE
);

export const emitUpdateAssignTaskToUser = (
  actionType = '',
  assigneeDetails = {},
  selectedItemsToAssignOrArchive = []
) => dispatch => {
  dispatch(createAction(EMIT_UPDATE_ASSIGN_TASK_TO_USER)());
  const chunkedArrayToAssign = chunk(selectedItemsToAssignOrArchive, 50);
  const multipleAssignServices = chunkedArrayToAssign.map(elementsToAssign =>
    fetchAssignTaskToUser(actionType, assigneeDetails, elementsToAssign)
  );
  return Promise.all(multipleAssignServices);
};

// Regularly scheduled reducer
const initialState = {
  formValidationErrors: {},
  case: {},
  isSavingTask: false,
  taskStatus: PAGE_STATUS.INIT,
  formsInvalidated: {},
  isFilteringTasks: false,
  model: { tasks: [], totalTasks: 0 },
  editTaskCount: 0,
  areAllCasesSelected: false,
  selectedItemsToAssignOrArchive: [],
  isAssigningTask: false
};

const handlers = {
  [EMIT_TASK_UPDATE]: (state, { payload }) => ({
    ...state,
    model: payload
  }),

  [EMIT_TASK_CANCEL]: (state, { payload }) => ({
    ...state,
    isSavingTask: payload.id !== NEW_TASK_ID,
    editTaskCount: state.editTaskCount - 1,
    model:
      payload.id !== NEW_TASK_ID
        ? state.model
        : // removing first task (which, if `NEW_TASK_ID` exists, will be the not-yet-created task)
          { tasks: state.model.tasks.slice(1) }
  }),

  [EMIT_TASK_SAVE]: state => ({
    ...state,
    isSavingTask: true
  }),

  [EMIT_TASK_SAVE_SUCCESS]: (
    state,
    { payload: { task: savedTask, originalId, updateTimestamp } }
  ) => ({
    ...state,
    isSavingTask: false,
    editTaskCount: state.editTaskCount - 1 < 0 ? 0 : state.editTaskCount - 1,
    lastUpdate: updateTimestamp ? Date.now() : state.lastUpdate,
    model: {
      ...state.model,
      tasks: state.model.tasks.map(task => {
        if (task.id === NEW_TASK_ID && originalId === NEW_TASK_ID) {
          return mergeCaseIntoTask(savedTask, state.case);
        } else if (task.id === savedTask.id) {
          return mergeCaseIntoTask(savedTask, state.case);
        }
        return task;
      })
    }
  }),

  [EMIT_TASK_SAVE_ERROR]: state => ({
    ...state,
    isSavingTask: false
  }),

  [EMIT_TASK_CREATE]: (state, { payload }) => {
    const { subcaseType, caseObj, userId } = payload;
    const newTask = genNewTask('CASEFLOW', caseObj, subcaseType, userId);

    return {
      ...state,
      lastUpdate: Date.now(),
      model: {
        ...state.model,
        totalTasks: state.model.totalTasks + 1,
        tasks: [newTask].concat(state.model.tasks)
      }
    };
  },

  [EMIT_FETCH_TASK_CASE]: state => ({
    ...state,
    taskStatus: PAGE_STATUS.LOADING
  }),

  [EMIT_FETCH_TASK_CASE_SUCCESS]: (state, { payload }) => ({
    ...state,
    taskStatus: PAGE_STATUS.LOADED,
    case: {
      ...caseResponseFormatter(payload.data.case)
    }
  }),

  [EMIT_FETCH_TASK_CASE_FAILURE]: state => ({
    ...state,
    taskStatus: PAGE_STATUS.FAILED
  }),

  [EMIT_FETCH_SEARCH]: state => ({
    ...state,
    loadedNextPage: false
  }),

  [EMIT_FETCH_TASKS_SUCCESS]: (state, { payload }) => ({
    ...state,
    lastUpdate: Date.now(),
    model: {
      ...state.model,
      totalTasks: payload.count,
      tasks: payload.data // Note: fetchTasksQuery will have already called mergeCaseIntoTask
    },
    loadedNextPage: true,
    taskStatus: PAGE_STATUS.LOADED,
    isFilteringTasks: false,
    editTaskCount: 0
  }),

  [EMIT_TASK_COMPLETE]: state => ({
    ...state,
    isSavingTask: true
  }),

  [EMIT_TASK_COMPLETE_SUCCESS]: (state, { payload: { task: savedTask } }) => ({
    ...state,
    lastUpdate: Date.now(),
    isSavingTask: false,
    model: {
      ...state.model,
      tasks: state.model.tasks.map(task => {
        if (task.id === savedTask.id) {
          return mergeCaseIntoTask(savedTask, state.case);
        }
        return task;
      })
    }
  }),

  [EMIT_TASK_REOPEN]: state => ({
    ...state,
    isSavingTask: true
  }),

  [EMIT_TASK_REOPEN_SUCCESS]: (state, { payload: { task: savedTask } }) => ({
    ...state,
    lastUpdate: Date.now(),
    isSavingTask: false,
    model: {
      ...state.model,
      tasks: state.model.tasks.map(task => {
        if (task.id === savedTask.id) {
          return mergeCaseIntoTask(savedTask, state.case);
        }
        return task;
      })
    }
  }),

  [EMIT_CANCEL_TASK_EDIT]: (state, { payload: task }) => ({
    ...state,
    lastUpdate: Date.now(),
    model: {
      ...state.model,
      tasks: state.model.tasks.map(t => {
        if (t.id === task.id) return set(task, 'display.updated', Date.now());
        return t;
      })
    }
  }),

  [EMIT_CLEAN_SLATE]: () => ({
    ...initialState
  }),

  [EMIT_TASK_FILTER]: state => ({
    ...state,
    isFilteringTasks: true,
    selectedItemsToAssignOrArchive: [],
    areAllCasesSelected: false
  }),
  [EMIT_TASK_EDIT]: state => ({
    ...state,
    editTaskCount: state.editTaskCount + 1
  }),
  [EMIT_TASK_EDIT_STOP]: state => ({
    ...state,
    editTaskCount: state.editTaskCount - 1 < 0 ? 0 : state.editTaskCount - 1
  }),
  [EMIT_UPDATE_TASK_SELECTION]: (state, { payload }) => ({
    ...state,
    selectedItemsToAssignOrArchive: payload.selectedItemsToAssignOrArchive,
    areAllCasesSelected: payload.areAllCasesSelected
  }),
  [EMIT_UPDATE_ASSIGN_TASK_TO_USER]: state => ({
    ...state,
    isAssigningTask: true,
    selectedItemsToAssignOrArchive: [],
    areAllCasesSelected: false
  }),

  [EMIT_UPDATE_ASSIGN_TASK_TO_USER_COMPLETE]: state => ({
    ...state,
    isAssigningTask: false
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
