import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize, isString } from 'lodash';

import stylesGenerator from './styles';
import withStyles from '../withStyles';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';
import { DATE_FORMAT } from '../../Form';

class PlainText extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isDate: PropTypes.bool,
    shouldCapitalize: PropTypes.bool,
    letter: PropTypes.bool
  };

  static defaultProps = {
    ...formElementDefaultProps,
    layout: { width: '100%' },
    styles: { width: '100%' },
    value: '',
    isDate: false,
    shouldCapitalize: false,
    letter: false
  };

  focus = () => this.component.focus();
  handleClick = e => this.props.onClick && this.props.onClick(e);

  render() {
    const {
      $id,
      id,
      schemaPath,
      helpText,
      value,
      isDate,
      shouldCapitalize,
      computedStyles
    } = this.props;

    let renderValue = value;
    if (isDate && moment(value).isValid()) {
      renderValue = moment(value).format(DATE_FORMAT);
    }
    if (shouldCapitalize) renderValue = capitalize(value);

    return (
      <div
        role="link"
        onClick={this.handleClick}
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <Label {...this.props} />
        {isString(renderValue) ? <div id={id}>{renderValue}</div> : null}
        <span className={computedStyles.helpText}>{helpText}</span>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(PlainText);
