import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import * as NavigationActions from 'Navigation/ducks';
import * as CaseActions from 'CreateCase/ducks';
import NavigationController from './controller';

const mapStateToProps = ({
  routerReducer,
  navigationReducer,
  caseReducer,
  commonReducer,
  modalReducer,
  schemaReducer
}) => ({
  hasSaved: caseReducer.hasSaved,
  session: commonReducer.jwt,
  hasValidRoles: commonReducer.hasValidRoles,
  xrayActive: navigationReducer.xrayActive,
  location: routerReducer.location,
  clientWidth: navigationReducer.clientWidth,
  selectedNavTab: navigationReducer.navTabActive,
  modalContent: modalReducer.modalContent,
  schemas: schemaReducer.schemas
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...CaseActions,
    ...NavigationActions,
    push
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  NavigationController
);
