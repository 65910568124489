import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { find } from 'lodash';

import withStyles from 'Common/components/Form/withStyles';
import Indicator from 'Common/components/Indicator';
import { SHARED_INFO_FIELDS } from 'Common/constants';
import { TABS_TOP_MARGIN } from 'CreateCase/constants';
import stylesGenerator from './styles';

class StickyTabs extends PureComponent {
  static propTypes = {
    pageSchema: PropTypes.shape({
      tabs: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        tab: PropTypes.string
      }).isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    computedStyles: PropTypes.shape({
      tabs: PropTypes.func.isRequired,
      tabTitle: PropTypes.func.isRequired
    }).isRequired,
    formValidationErrors: PropTypes.objectOf(
      PropTypes.arrayOf(PropTypes.string)
    ).isRequired,
    reconciliationData: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    reconciliationData: []
  };

  state = {
    tabsSticky: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.ensureTabVisibility);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.ensureTabVisibility);
  }

  getTabTopBounding = () => this.tabs.getBoundingClientRect().top;

  ensureTabVisibility = () => {
    const tabsAnchorVisible = this.getTabTopBounding() > TABS_TOP_MARGIN;

    if (!tabsAnchorVisible && !this.state.tabsSticky) {
      this.setState({ tabsSticky: true });
    } else if (tabsAnchorVisible) {
      this.setState({ tabsSticky: false });
    }
  };

  bindTabPositionalRef = tabs => (this.tabs = tabs);

  hasErrors = ({ schemaPath }) => {
    const { formValidationErrors } = this.props;

    return !!find(Object.keys(formValidationErrors), path =>
      path.startsWith(schemaPath)
    );
  };

  renderTab = (tab, i) => {
    const { match, computedStyles, reconciliationData } = this.props;
    const oldPath = `/${match.params.tab}`;
    const tabPath = `${match.url.replace(oldPath, '')}/${tab.path}`;
    const isActive = match.params.tab === tab.path;
    // Page/index has `getSchema` which transforms path `tabs` into `elements`
    // Each tab is the root of all component paths within CSE`
    const hasErrors = this.hasErrors({ schemaPath: `elements[${i}]` });
    let className = computedStyles.tabTitle(isActive);
    if (hasErrors) className = `${className} ${computedStyles.errors}`;

    const hasUnreconciledFields = !!reconciliationData.find(
      reconPath =>
        // Tabs specify ROOT (ie. always the base string) of paths it contains
        (tab.reconciliationPaths || []).find(p => reconPath.indexOf(p) === 0) ||
        // Shared info is only defined in schema and not something BE supports
        // so, we need to compensate for that by explicitely checkig if the field is shared
        // AND if the tab is shared info
        (tab.path === 'sharedinfo' &&
          SHARED_INFO_FIELDS.find(p => reconPath.indexOf(p) >= 0))
    );

    return (
      <Link key={i} to={tabPath} className={className}>
        {tab.title}
        {hasUnreconciledFields ? <Indicator>R</Indicator> : null}
      </Link>
    );
  };

  render() {
    const { computedStyles, pageSchema } = this.props;
    const tabsComputedStyles = computedStyles.tabs(this.state.tabsSticky);

    return (
      <div id="form-tabs" className={computedStyles.base}>
        <div // Non-fixed element used to guage if tabs would be visible when not fixed
          ref={this.bindTabPositionalRef}
          className={computedStyles.hidden}
        />
        <div className={tabsComputedStyles}>
          {pageSchema.tabs.map(this.renderTab)}
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(StickyTabs);
