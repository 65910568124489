import {
  isObject,
  isArray,
  get,
  omit,
  find,
  compose,
  reduce,
  omitBy,
  mapKeys,
  mapValues
} from 'lodash/fp';
import { isNil } from 'lodash';

import { CASE_SVC_RESPONSE_TYPES } from 'Common/constants';
import {
  CONFLICT_DIFFS,
  CONFLICT_OP_TYPES,
  AUTO_RESOLVABLE_PROTO_KEY_REGEXES,
  EXTRANEOUS_VALUES_TO_OMIT,
  SUBMISSION_PATHS
} from 'CreateCase/constants';
import { resolveIndexes } from './path';

/**
 * Set of functions to convert JSON patch conflicts into a more amenable format
 * for rendering the conflict within React components.
 *
 * All functions in this module use lodash/fp methods!
 * lodash/fp methods are immutable, auto-curried, iteratee-first, and data-last.
 * https://github.com/lodash/lodash/wiki/FP-Guide
 * @module Conflict Utils
 */

// https://github.com/lodash/lodash/wiki/FP-Guide#convert
const mapValuesWithKey = mapValues.convert({ cap: false });

const { CURRENT, ATTEMPTED } = CONFLICT_DIFFS;
const { ADD, REMOVE } = CONFLICT_OP_TYPES;

const matchAnAutoResolvableKeyRegex = elementKey =>
  AUTO_RESOLVABLE_PROTO_KEY_REGEXES.some(regex => elementKey.match(regex));

const isStandAloneAdd = conflict => {
  const changeSetKeys = Object.keys(conflict);

  if (changeSetKeys.length === 1) {
    return get(conflict[changeSetKeys[0]], 'op') === ADD;
  }

  return false;
};

/**
 * @function
 * @memberof module:Conflict Utils
 * @param {Object} graphQLErrors
 * @returns {boolean}
 */
export const hasConflicts = graphQLErrors =>
  get('[0].type', graphQLErrors) === CASE_SVC_RESPONSE_TYPES.CONFLICT;

const isMultiple = (elements = {}, conflictStatePath) => {
  const multipleFlagPaths = ['multiple', 'parentElement.multiple'];

  return multipleFlagPaths
    .map(path => get(path, elements[conflictStatePath]))
    .some(prop => prop === true);
};

const isNonConflictingAdd = (conflict, isOpType) => {
  const currentIsAdd =
    isOpType(ADD)(CURRENT) && !get('value', conflict[ATTEMPTED]);
  const attemptedIsAdd =
    isOpType(ADD)(ATTEMPTED) && !get('value', conflict[CURRENT]);

  return currentIsAdd || attemptedIsAdd;
};

/**
 * Determines if a conflict is auto-resolvable.
 * @function
 * @memberof module:Conflict Utils
 * @param elements {Object[]}
 * @returns {function}
 */
export const isAutoResolvable = elements =>
  /**
   * Curried by isAutoResolvable. Determines if a conflict is auto-resolvable.
   * @function
   * @param conflict {Object}
   * @returns {boolean}
   */
  conflict => {
    const elementKeys = Object.keys(elements);
    const conflictStatePath = get(`[${CURRENT}].path`, conflict, false);
    const isOpType = op => type => get(`[${type}].op`, conflict) === op;

    const isNotInputField = () =>
      !elements[conflictStatePath] ||
      !elementKeys.some(matchAnAutoResolvableKeyRegex);

    const isAutoResolvableInputField =
      isStandAloneAdd(conflict) || isNonConflictingAdd(conflict, isOpType);

    const replacedWithIdenticalValue = () =>
      get('value', conflict[CURRENT]) === get('value', conflict[ATTEMPTED]);

    const removedValue = () => [CURRENT, ATTEMPTED].some(isOpType(REMOVE));

    // No current value is specified by one of the changesets in the 409 error.
    const replacedOldValue = () => !conflict[CURRENT] || !conflict[ATTEMPTED];

    return (
      isNotInputField() ||
      (isMultiple(elements, conflictStatePath) && isAutoResolvableInputField) ||
      replacedWithIdenticalValue() ||
      replacedOldValue() ||
      removedValue()
    );
  };

/**
 * Gets first valid changeSetKey.
 * @function
 * @memberof module:Conflict Utils
 * @param {Object} conflict
 * @returns {string} changeSetKey - "current" or "attempted", whichever first one is found with defined properties.
 */
export const getChangeSetKey = conflict =>
  Object.keys(omitBy(e => e === undefined, conflict))[0];

/**
 * @function
 * @param {Object} error
 */
export const findCreatedPath = (error = {}) => {
  const { attemptedChangeset, currentChangeset } = error;
  return (
    find(({ path }) => SUBMISSION_PATHS.includes(path), attemptedChangeset) ||
    find(({ path }) => SUBMISSION_PATHS.includes(path), currentChangeset)
  );
};

/**
 * @function
 * @param {string} name
 * @returns {string}
 */
export const getPreviousCaseUser = compose(
  (name = 'someone') => ` ${name} `,
  get('lastUpdatedUserName')
);

/**
 * @function
 * @param {Object} acc
 * @param {Object} conflict
 * @returns {Object}
 */
export const genProtoResolutions = (acc, conflict) => {
  const changeSetKey = getChangeSetKey(conflict);
  const path = get(`[${changeSetKey}].path`, conflict);
  const value = get(`[${changeSetKey}].value`, conflict);
  const protoResolution = { [path]: value };

  return { ...acc, ...protoResolution };
};

const omitExtraneousValues = omit(EXTRANEOUS_VALUES_TO_OMIT);
const omitExtraneousValuesFromChangeSet = changeSet => ({
  ...changeSet,
  value: omitExtraneousValues(changeSet.value)
});

/**
 * Converts a JSON path to the equivalent state path format used by CSE.
 * @param {string} jsonPath
 * @returns {string}
 */
const convertToStatePath = jsonPath =>
  jsonPath.replace(/\//g, '.').replace(/\.(\d+)\./g, '[$1].');

const parseJSONPatch = string => convertToStatePath(string).substring(1);

const getPath = item => (item[CURRENT] || item[ATTEMPTED]).path;

const reduceConflict = (acc, item) => ({
  ...acc,
  [parseJSONPatch(item.path)]: {
    ...acc[item.path],
    ...item,
    path: parseJSONPatch(item.path)
  }
});

const reduceChangeSets = attempted => (acc, item) => ({
  ...acc,
  [item.path]: {
    [CURRENT]: item,
    [ATTEMPTED]: attempted[item.path]
  }
});

const omitExtraneousKeysDeeply = mapValuesWithKey((value, key) => {
  if (EXTRANEOUS_VALUES_TO_OMIT.includes(key)) {
    return null;
  }

  if (isArray(value)) {
    return mapValues(omitExtraneousValues)(value);
  }

  return value;
});

const genProtoKey = item => key =>
  `${item[getChangeSetKey(item)].path}.${convertToStatePath(key)}`.replace(
    /[.](\d)[.]/g,
    '[$1].'
  );

// TODO Deprecated
// This may need to be redone now that CSE no longer uses collapsed protodocuments
const genProtoObj = (object, separator = '.') =>
  Object.assign(
    {},
    ...(function _flatten(child = {}, path = []) {
      return [].concat(
        ...Object.keys(child).map(
          key =>
            typeof child[key] === 'object' && !isNil(child[key])
              ? _flatten(child[key], path.concat([key]))
              : { [path.concat([key]).join(separator)]: child[key] }
        )
      );
    })(object)
  );

const flattenItemValues = (item, type) =>
  mapKeys(
    genProtoKey(item),
    genProtoObj(omitExtraneousKeysDeeply(item[type].value))
  );

const generateFlatItemMap = item => {
  const genAttempted = path => ({
    [ATTEMPTED]: {
      path,
      value: flattenItemValues(item, ATTEMPTED)[path],
      op: item[ATTEMPTED].op
    }
  });

  return mapValuesWithKey(
    (value, path) => ({
      [CURRENT]: { path, value, op: item[CURRENT].op },
      ...(item[ATTEMPTED] ? genAttempted(path) : {})
    }),
    flattenItemValues(item, CURRENT)
  );
};

const getItemValues = item => [
  get('value', item[CURRENT], false),
  get('value', item[ATTEMPTED], false)
];

/**
 * JSON Patch conflicts are not provided in a format that is appropriate
 * for rendering a flat list of current/attempted option mappings.
 *
 * Each item's value must be resolved, and conflict "values" that are
 * actually an object tree must be flattened into this format:
 *
 * [statePath]: {
 *   current: { value: 'somePrimitiveValue', ... },
 *   attempted: { value: 'somePrimitiveValue', ... }
 * }
 *
 * @param {Object} item
 * @param {Object[]} elements
 * @returns {Object}
 */
const resolveItems = (item, elements) => {
  const itemValues = getItemValues(item);
  const some = (values, func) => values.map(func).some(e => e);

  if (some(itemValues, isObject)) {
    const filteredItem = mapValues(omitExtraneousValuesFromChangeSet)(item);
    const flattenedItem = generateFlatItemMap(filteredItem, elements);

    return flattenedItem;
  }

  return { [getPath(item)]: item };
};

const reduceObjValues = elements => conflicts =>
  reduce(
    (acc, item) => ({ ...acc, ...resolveItems(item, elements) }),
    {},
    conflicts
  );

/**
 * @param {Object} jsonPatch
 * @param {Object} jsonPatch.currentChangeset
 * @param {Object} jsonPatch.attemptedChangeset
 */
const groupByStatePath = ({ currentChangeset, attemptedChangeset }) => ({
  ...reduce(reduceChangeSets(currentChangeset), {}, attemptedChangeset),
  ...reduce(reduceChangeSets(attemptedChangeset), {}, currentChangeset)
});

/**
 * @param {Object} jsonPatch
 * @param {Object} jsonPatch.currentChangeset
 * @param {Object} jsonPatch.attemptedChangeset
 */
const reduceConflictsTypes = ({ currentChangeset, attemptedChangeset }) => ({
  currentChangeset: reduce(reduceConflict, {}, currentChangeset),
  attemptedChangeset: reduce(reduceConflict, {}, attemptedChangeset)
});

/**
 * @function
 * @param {Object} conflicts
 * @param {Object}
 * @returns {function}
 */
export const genConflictOpMap = (conflicts, elements) =>
  compose(
    reduceObjValues(elements), // Doesn't actually need elements?
    resolveIndexes(elements),
    groupByStatePath,
    reduceConflictsTypes
  )(conflicts);
