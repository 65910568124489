import React from 'react';
import PropTypes from 'prop-types';
import AuthorizedLink from 'Common/components/AuthorizedLink';

import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

const CreateTaskLink = ({ computedStyles, onTaskCreate, override }) => (
  <div className={computedStyles.base} id="createNewTaskLink">
    <AuthorizedLink
      className={computedStyles.base}
      accessRole={'edit'}
      looseCheck
      onClick={() => onTaskCreate()}
      role="button"
      override={override}
    >
      Create New Task
    </AuthorizedLink>
  </div>
);

CreateTaskLink.defaultProps = {
  override: false
};

CreateTaskLink.propTypes = {
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  onTaskCreate: PropTypes.func.isRequired,
  override: PropTypes.bool
};

export default withStyles(stylesGenerator)(CreateTaskLink);
