import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  updatedTag: generateCSS({
    order: 1,
    textTransform: 'uppercase',
    border: '2px solid rgb(169, 223, 255)',
    backgroundColor: 'rgb(218, 235, 255)',
    display: 'inline-block',
    padding: '0px 5px',
    color: 'rgb(7, 100, 131)',
    fontWeight: 'bold',
    fontSize: '0.7em',
    margin: '0.1em 0.5em',
    verticalAlign: 'top'
    // maxHeight: '1.6em' // remains relative to default font-size
  })
});

export default stylesGenerator;
