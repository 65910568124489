const fetchGQLQuery = (
  variables,
  onSuccess,
  onFailure,
  client,
  query
) => async () => {
  try {
    const { data } = await client.query({
      query,
      variables
    });

    const thawed = Object.isFrozen(data)
      ? JSON.parse(JSON.stringify(data))
      : data;
    onSuccess(thawed);
  } catch (e) {
    onFailure(e);
  }
};

export default fetchGQLQuery;
