import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Notifier from 'Common/components/Notifier';
import fetchCloneCase from 'api/rest/fetchCloneCase';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { SimpleButton, generateCSS } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import NumberInput from '../Form/NumberInput';

// NOTIFIER KEY
let cloningCase;

class CloneCaseModal extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    subcaseType: PropTypes.string.isRequired,
    assignee: PropTypes.string.isRequired,
    assigneeName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    displayXCloseButton: PropTypes.func.isRequired,
    actions: PropTypes.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      numberOfClones: null,
      isValidEntry: null,
      screenOne: true,
      isLoading: false
    };
  }

  static defaultProps = {
    onConfirm: () => {}
  };

  handleCloneCase = caseId => {
    const {
      onConfirm,
      actions,
      subcaseType,
      assignee,
      assigneeName
    } = this.props;
    const { numberOfClones } = this.state;

    const onSuccess = () => {
      this.setState({ isLoading: false });
      actions.emitUnlockCase();
      actions.emitFetchCaseById(caseId).then(() =>
        Notifier.show({
          message: `${numberOfClones} case${
            numberOfClones > 1 ? 's have' : ' has'
          } been cloned`,
          iconName: 'tick-circle',
          intent: Notifier.SUCCESS
        })
      );
      onConfirm();
    };

    const onFailure = err => {
      this.setState({ isLoading: false });
      actions.emitUnlockCase();
      cloningCase = Notifier.update(cloningCase, {
        message: `${err}`,
        iconName: 'error',
        intent: Notifier.DANGER
      });
      onConfirm();
    };

    const whileLoading = () => {
      actions.emitLockCase();
      this.setState({ isLoading: true });
      cloningCase = Notifier.update(cloningCase, {
        message: `Cloning Case ${caseId}...`,
        iconName: 'time',
        intent: Notifier.PRIMARY,
        timeout: 0
      });
    };

    whileLoading();
    fetchCloneCase(
      caseId,
      assignee,
      assigneeName,
      subcaseType,
      numberOfClones,
      onSuccess,
      onFailure
    );
  };

  handleNumberOfClonesChange = value => {
    this.setState({ numberOfClones: value });
    this.checkIfValidEntry(value);
  };

  checkIfValidEntry = value => {
    this.setState({ isValidEntry: value >= 1 && value <= 100 });
  };

  handleNextScreen = () => {
    this.setState({ screenOne: !this.state.screenOne });
  };

  renderLoadingSpinner = (caseId, numberOfClones) => {
    const { displayXCloseButton } = this.props;
    displayXCloseButton(false);
    return (
      <div className={generateCSS({ margin: '100 auto', height: '140px' })}>
        <NonIdealState
          visual={<Spinner />}
          title="Cloning Case"
          description={`Creating ${numberOfClones} clone${
            numberOfClones > 1 ? 's' : ''
          } of case ${caseId}`}
        />
      </div>
    );
  };

  renderScreenOne = (numberOfClones, isValidEntry, onCancel) => (
    <div>
      <NumberInput
        label="How many clones would you like to create and assign to yourself?"
        value={numberOfClones}
        required
        helpText={
          !isValidEntry ? 'Please enter a number from 1 to 100' : <br />
        }
        onChange={this.handleNumberOfClonesChange}
        min={1}
        max={100}
        maxLength={3}
      />
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={onCancel}>Cancel</SimpleButton>
        <SimpleButton
          onClick={() => this.handleNextScreen()}
          primary
          disabled={!isValidEntry}
        >
          OK
        </SimpleButton>
      </div>
    </div>
  );

  renderScreenTwo = (numberOfClones, caseId, isValidEntry) => (
    <div>
      <span>
        {`Are you sure you want to create ${numberOfClones} clone${
          numberOfClones > 1 ? 's' : ''
        }?`}
      </span>
      <br />
      <div className={modalStyles.buttonsContainer}>
        {/* in case we want to add a back button */}
        <SimpleButton onClick={this.handleNextScreen}>Back</SimpleButton>
        {/* <SimpleButton onClick={onCancel}>Cancel</SimpleButton> */}
        <SimpleButton
          onClick={() => this.handleCloneCase(caseId)}
          primary
          disabled={!isValidEntry}
        >
          Confirm
        </SimpleButton>
      </div>
    </div>
  );

  render = () => {
    const { caseId, onCancel } = this.props;
    const { numberOfClones, isValidEntry, screenOne, isLoading } = this.state;

    return (
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        {isLoading ? (
          this.renderLoadingSpinner(caseId, numberOfClones)
        ) : (
          <div>
            <span className={modalStyles.title}>Clone Case</span>
            {screenOne
              ? this.renderScreenOne(numberOfClones, isValidEntry, onCancel)
              : this.renderScreenTwo(numberOfClones, caseId, isValidEntry)}
          </div>
        )}
      </div>
    );
  };
}

export default CloneCaseModal;
