import { generateCSS } from 'Common/components/Form/styles';

const styleGenerator = theme => ({
  footer: generateCSS({
    height: '50px',
    backgroundColor: theme.colors.quinary
  }),
  footerText: generateCSS({
    fontFamily: 'Calibri',
    color: theme.colors.secondary,
    fontWeight: 300,
    fontSize: '12px',
    marginLeft: '35px',
    paddingTop: '20px'
  }),
  hidden: generateCSS({
    display: 'none'
  })
});

export default styleGenerator;
