import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

class DesiredColumns extends React.PureComponent {
  static propTypes = {
    desiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    selectedDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleDesiredColumnSelect: PropTypes.func.isRequired,
    updateDesiredColumns: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      showDragAreaforColumn: -1
      // desiredColumns: props.desiredColumns
    };
  }
  findClassName = lbl => {
    const { computedStyles, selectedDesiredColumns } = this.props;
    return selectedDesiredColumns.map(column => column.label).includes(lbl)
      ? `selected ${computedStyles.standardLIContainer}`
      : `notSelected ${computedStyles.standardLIContainer}`;
  };
  onDragEnterColumns = col => {
    this.setState({ showDragAreaforColumn: col.order });
  };
  allowDropColumns = ev => {
    ev.preventDefault(); // Necessary. Allows us to drop.
  };
  dropColumns = (_column, ev) => {
    ev.preventDefault();
    let { desiredColumns } = this.props;
    const { selectedColumn, showDragAreaforColumn } = this.state;
    desiredColumns = desiredColumns.filter(
      x => x.label !== selectedColumn.label
    );
    desiredColumns.splice(showDragAreaforColumn, 0, selectedColumn);
    this.props.updateDesiredColumns(desiredColumns);
    this.setState({ showDragAreaforColumn: '' });
  };

  dragColumns(col, ev) {
    ev.stopPropagation();
    ev.dataTransfer.setData('text', col.order);
    this.setState({ selectedColumn: col });
  }
  onDragEnd = () => {
    this.setState({ selectedColumn: -1, showDragAreaforColumn: '' });
  };

  render() {
    const { computedStyles, desiredColumns } = this.props;
    const { showDragAreaforColumn, selectedColumn } = this.state;
    return (
      <div className={computedStyles.standardColContainer}>
        <p> View Columns</p>
        <div className={computedStyles.standardULContainer}>
          {desiredColumns.map(col => (
            <div
              className={
                selectedColumn === col.order
                  ? computedStyles.showDropULContainer
                  : ''
              }
              onDrop={e => {
                this.dropColumns(col, e);
              }}
              onDragEnter={e => {
                this.onDragEnterColumns(col, e);
              }}
              onDragOver={this.allowDropColumns}
              onDragEnd={this.onDragEnd}
            >
              {showDragAreaforColumn === col.order ? (
                <div
                  className={computedStyles.standardhiddenDiv}
                  onDrop={e => {
                    this.dropColumns(col, e);
                  }}
                />
              ) : (
                ''
              )}
              <div
                id={col.label}
                className={this.findClassName(col.label)}
                key={col.label}
                draggable
                onClick={() => this.props.handleDesiredColumnSelect(col)}
                onDragStart={e => {
                  this.dragColumns(col, e);
                }}
                role="button"
              >
                {col.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default withStyles(stylesGenerator)(DesiredColumns);
