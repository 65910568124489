import gql from 'graphql-tag';

const searchContacts = () => gql`
  query SearchContacts($q: ContactSearchQuery!, $size: Int, $from: Int) {
    searchContacts(q: $q, size: $size, from: $from) {
      total
      status
      hits {
        country
        other {
          third_party
          type
        }
        address {
          line1
          line2
          city
          state
          postal_code
        }
        gender
        createdTimestamp
        reporter {
          primary
          private
        }
        type
        employee {
          type
        }
        psp {
          name
          opi_number
        }
        source_reference
        aer {
          occupation
          reporter_type
          hcp
        }
        currentRevision
        hcp {
          specialty
          license_number
        }
        phone {
          number
          extension
          isFax
        }
        organization
        name {
          first
          last
        }
        id
        non_patient {
          type
        }
        email {
          address
        }
      }
    }
  }
`;

export default searchContacts;
