import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isString, isEmpty } from 'lodash';

import {
  abbvieDateFormat,
  withStyles,
  API_DATE_FORMAT
} from 'Common/components/Form';
import stylesGenerator from './styles';

class DueDateDisplay extends PureComponent {
  static propTypes = {
    date: PropTypes.string,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      dueDatePast: PropTypes.object.isRequired,
      dueDateSoon: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    date: null
  };

  getClassName = date => {
    const dueDate = moment(date, [moment.ISO_8601, API_DATE_FORMAT], true);
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    if (dueDate.isBefore(today, 'days')) {
      return this.props.computedStyles.dueDatePast;
    }
    if (dueDate.isSame(today, 'days') || dueDate.isSame(tomorrow, 'days')) {
      return this.props.computedStyles.dueDateSoon;
    }
    return '';
  };

  render() {
    const { date } = this.props;

    if (!isString(date) || isEmpty(date)) {
      return <div>Not Specified</div>;
    }

    return (
      <div className={this.getClassName(date)}>{abbvieDateFormat(date)}</div>
    );
  }
}

export default withStyles(stylesGenerator)(DueDateDisplay);
