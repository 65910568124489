/* eslint import/prefer-default-export:0 */
import PropTypes from 'prop-types';

export const CMS_PROP_TYPES = {
  trilogyCase: PropTypes.objectOf(PropTypes.any),
  schema: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.object)
  }),
  tacticalData: PropTypes.objectOf(PropTypes.object),
  value: PropTypes.any
};

export const TABLE_PROP_TYPES = {
  SORT_PATH: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.number
      ])
    )
  ]),
  SORT_BY: PropTypes.objectOf(PropTypes.any)
};
