import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

class StandardColumns extends React.PureComponent {
  static propTypes = {
    standardColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
    selectedStandardColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleStandardColumnSelect: PropTypes.func.isRequired
  };
  findClassName = lbl => {
    const { computedStyles, selectedStandardColumns } = this.props;
    return selectedStandardColumns.map(column => column.label).includes(lbl)
      ? `selected ${computedStyles.standardLIContainer}`
      : `notSelected ${computedStyles.standardLIContainer}`;
  };
  render() {
    const { computedStyles } = this.props;
    return (
      <div className={computedStyles.standardColContainer}>
        <p>Standard Columns</p>
        <div className={computedStyles.standardULContainer}>
          {this.props.standardColumns.map(col => (
            <div
              className={this.findClassName(col.label)}
              key={col.label}
              role="button"
              onClick={() => this.props.handleStandardColumnSelect(col)}
            >
              {col.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(StandardColumns);
