import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';

import PageButton from './PageButton';

class Paginator extends PureComponent {
  static propTypes = {
    totalPages: PropTypes.number.isRequired,
    pageOffset: PropTypes.number,
    previousLength: PropTypes.number,
    nextLength: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    pageOffset: 0,
    previousLength: 2,
    nextLength: 2,
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = { pageOffset: props.pageOffset };
  }

  componentWillReceiveProps(props) {
    this.setState({
      pageOffset: props.pageOffset
    });
  }

  goTo = page => {
    const prevPage = this.state.pageOffset;
    if (page === prevPage) return;

    this.setState(
      {
        // Go no lower than `0`, and no higher than `totalPages`
        pageOffset: Math.min(this.props.totalPages, Math.max(0, page))
      },
      () => this.props.onPageChange(page, prevPage)
    );
  };

  firstPage = e => {
    e.preventDefault();
    this.goTo(0);
  };

  prevPage = e => {
    e.preventDefault();
    this.goTo(this.state.pageOffset - 1);
  };

  nextPage = e => {
    e.preventDefault();
    this.goTo(this.state.pageOffset + 1);
  };

  lastPage = e => {
    e.preventDefault();
    this.goTo(this.props.totalPages - 1);
  };

  renderPageRange = (start, end) =>
    range(start, end).map(pageNumber => (
      <PageButton
        key={pageNumber}
        isActive={pageNumber === this.state.pageOffset}
        onClick={this.goTo}
        page={pageNumber}
        disabled={this.props.disabled}
      >
        {pageNumber + 1}
      </PageButton>
    ));

  render() {
    const { totalPages, previousLength, nextLength, disabled } = this.props;
    const { pageOffset } = this.state;
    // If the total number of shown pages is even, we use this to convert to an
    // odd count, so we can center the "active" page
    const centerPadding = (previousLength + nextLength) % 3;
    // If we cannot add sufficient padding (next pages) to the right side
    // we account for them on the left (previous pages)
    const startPadding =
      pageOffset >= totalPages - previousLength
        ? previousLength - (totalPages - pageOffset) + centerPadding
        : 0;
    const startPage = Math.max(0, pageOffset - previousLength - startPadding);
    // If we cannot add sufficient padding (previous pages) to the left side
    // we account for them on the right (next pages)
    const endPadding = pageOffset < nextLength ? nextLength - pageOffset : 0;
    const endPage = Math.min(
      totalPages,
      pageOffset + nextLength + endPadding + centerPadding
    );
    const hasPages = totalPages > 1;
    return (
      <div className="pt-button-group pt-fill minimal">
        <PageButton
          page={0}
          onClick={this.goTo}
          disabled={pageOffset === 0 || disabled || !hasPages}
        >
          <i className="pt-icon-standard pt-icon-double-chevron-left" />
        </PageButton>
        <PageButton
          page={pageOffset - 1}
          onClick={this.goTo}
          disabled={pageOffset === 0 || disabled || !hasPages}
        >
          <i className="pt-icon-standard pt-icon-chevron-left" />
        </PageButton>
        {this.renderPageRange(startPage, endPage)}
        <PageButton
          page={pageOffset + 1}
          onClick={this.goTo}
          disabled={pageOffset === totalPages - 1 || disabled || !hasPages}
        >
          <i className="pt-icon-standard pt-icon-chevron-right" />
        </PageButton>
        <PageButton
          page={totalPages - 1}
          disabled={pageOffset === totalPages - 1 || disabled || !hasPages}
          onClick={this.goTo}
        >
          <i className="pt-icon-standard pt-icon-double-chevron-right" />
        </PageButton>
      </div>
    );
  }
}

export default Paginator;
