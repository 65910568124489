/* eslint no-useless-escape:0 no-template-curly-in-string:0 */
module.exports = {
  path: 'mi',
  title: 'Medical Inquiry',
  component: 'PropTypeSafety',
  tabs: [
    {
      title: 'Requests',
      component: 'Tab',
      path: 'requests',
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'requests' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'requests',
          component: 'Section',
          title: 'Requests',
          statePath: 'subcases.medicalInfo',

          elements: [
            {
              component: 'InputGroup',
              id: 'Request',
              label: 'another request',
              statePath: 'request',
              multiple: true,
              length: 1,

              referencedProperties: {
                label: {
                  statePath: 'topic',
                  relative: true,
                  $or: [
                    // TODO: Add functionality to reference the dropdown label ie: $get: { 'topic.label' }
                    {
                      $if: [{ $eq: 'option1' }, 'Request - Option #1']
                    },
                    {
                      $if: [{ $eq: 'option2' }, 'Request - Option #2']
                    },
                    {
                      $if: [{ $eq: 'option3' }, 'Request - Option #3']
                    },
                    { $set: 'Request' }
                  ]
                }
              },

              elements: [
                {
                  component: 'PlainText',
                  statePath: '/summary.narrative.long2',
                  label: 'Initial Description',
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'TextArea',
                  statePath: 'description',
                  label: 'Request',
                  layout: {
                    width: '50%'
                  },
                  styles: {
                    height: '120px'
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    }
                  }
                },
                {
                  component: 'Select',
                  statePath: 'priority',
                  label: 'Priority',
                  placeholder: 'Select',
                  options: [
                    { label: 'High', value: 'High' },
                    { label: 'Medium', value: 'Medium' },
                    { label: 'Low', value: 'Low' },
                    { label: 'No Preference', value: 'No Preference' }
                  ],
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'DateInput',
                  statePath: 'dueDate',
                  label: 'Due Date',
                  layout: {
                    width: '50%'
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    }
                  }
                },
                {
                  component: 'Select',
                  statePath: 'productName',
                  label: 'AbbVie Product',
                  placeholder: 'Select Product',
                  options: [],
                  layout: {
                    width: '50%'
                  },
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.mi-options.abbvie_product'
                    }
                  }
                },
                {
                  component: 'Select',
                  statePath: 'productIndication',
                  label: 'Indication',
                  placeholder: 'Select Indication',
                  options: [],
                  layout: {
                    width: '50%'
                  },
                  referencedProperties: {
                    options: {
                      schemaPath: './',
                      $get: 'data.document-data.mi-options.indication'
                    }
                  }
                },
                {
                  component: 'Select',
                  statePath: 'category',
                  label: 'Category',
                  placeholder: 'Select Inquiry Category',
                  options: [
                    { label: 'Adverse Reactions', value: 'Adverse Reactions' },
                    {
                      label: 'Clinical Development Program',
                      value: 'Clinical Development Program'
                    },
                    {
                      label: 'Clinical Pharmacology',
                      value: 'Clinical Pharmacology'
                    },
                    {
                      label: 'Combination Therapy',
                      value: 'Combination Therapy'
                    },
                    { label: 'Comparison', value: 'Comparison' },
                    { label: 'Description', value: 'Description' },
                    {
                      label: 'Disease State Information',
                      value: 'Disease State Information'
                    },
                    {
                      label: 'Dosage and Administration',
                      value: 'Dosage and Administration'
                    },
                    {
                      label: 'Drug Abuse and Dependence',
                      value: 'Drug Abuse and Dependence'
                    },
                    { label: 'Drug Interactions', value: 'Drug Interactions' },
                    { label: 'Guidelines', value: 'Guidelines' },
                    {
                      label: 'Indications & Usage',
                      value: 'Indications & Usage'
                    },
                    { label: 'Miscellaneous', value: 'Miscellaneous' },
                    { label: 'Monitoring', value: 'Monitoring' },
                    {
                      label: 'Non Clinical Toxicology',
                      value: 'Non Clinical Toxicology'
                    },
                    { label: 'Outcomes', value: 'Outcomes' },
                    { label: 'Overdose', value: 'Overdose' },
                    {
                      label: 'Payer/Patient Access',
                      value: 'Payer/Patient Access'
                    },
                    { label: 'Product Alerts', value: 'Product Alerts' },
                    { label: 'Resistance', value: 'Resistance' },
                    {
                      label: 'Storage & Handling',
                      value: 'Storage & Handling'
                    },
                    {
                      label: 'Use in Special Populations',
                      value: 'Use in Special Populations'
                    },
                    {
                      label: 'Warnings and Precautions',
                      value: 'Warnings and Precautions'
                    }
                  ],
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'TextInput',
                  statePath: 'topic',
                  label: 'Topic',
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'Select',
                  statePath: 'classification',
                  label: 'Classification',
                  placeholder: 'Select Inquiry Classification',
                  options: [
                    { label: 'On Label', value: 'On Label' },
                    { label: 'Off Label', value: 'Off Label' },
                    { label: 'Non Medical', value: 'Non Medical' },
                    { label: 'Technical', value: 'Technical' }
                  ],
                  layout: {
                    width: '50%'
                  }
                },
                {
                  component: 'Select',
                  statePath: 'handling_type',
                  label: 'Handling Type',
                  placeholder: 'Select Handling Type',
                  options: [
                    { label: 'Email', value: 'Email' },
                    { label: 'Phone', value: 'Phone' },
                    { label: 'Mail', value: 'Mail' },
                    { label: 'Courier', value: 'Courier' },
                    { label: 'Fax', value: 'Fax' },
                    { label: 'In Person', value: 'In Person' }
                  ],
                  layout: {
                    width: '50%',
                    marginRight: '50%'
                  },
                  referencedProperties: {
                    value: {
                      statePath: 'handling',
                      relative: true,
                      $if: [{ $eq: 'verbal' }, { $set: 'Phone' }]
                    }
                  }
                },
                {
                  component: 'RadioGroup',
                  statePath: 'handling',
                  label: 'Handling',
                  options: [
                    { value: 'verbal', label: 'Verbal Only' },
                    { value: 'written', label: 'Written Only' },
                    { value: 'verbal_written', label: 'Verbal and Written' }
                  ],
                  styles: {
                    margin: '0',
                    ' div:first-child + div': {
                      display: 'flex',
                      flexDirection: 'row'
                    }
                  },
                  validations: {
                    required: {
                      constraint: true,
                      errorMessage: ''
                    }
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Select',
                  statePath: 'resolution',
                  label: 'Resolution',
                  placeholder: 'Select Resolution',
                  options: [
                    {
                      label: 'Contact Center Completed',
                      value: 'Contact Center Completed'
                    },
                    {
                      label: 'Contact Center Escalation',
                      value: 'Contact Center Escalation'
                    },
                    {
                      label: 'Medical Information Completed',
                      value: 'Medical Information Completed'
                    },
                    {
                      label: 'Ref to Medical Science Liaison',
                      value: 'Ref to Medical Science Liaison'
                    },
                    {
                      label: 'Ref to Medical Outcome Science Liaison',
                      value: 'Ref to Medical Outcome Science Liaison'
                    },
                    {
                      label: 'Ref to Other Department',
                      value: 'Ref to Other Department'
                    },
                    {
                      label: 'Ref to Co-marketer',
                      value: 'Ref to Co-marketer'
                    },
                    {
                      label: 'Product Reimbursement',
                      value: 'Product Reimbursement'
                    },
                    {
                      label: 'Product Replacement',
                      value: 'Product Replacement'
                    },
                    { label: 'Unable to Contact', value: 'Unable to Contact' }
                  ],
                  layout: {
                    width: '50%',
                    marginRight: '50%',
                    display: 'none'
                  },
                  referencedProperties: {
                    'layout.display': {
                      statePath: 'handling',
                      relative: true,
                      $if: [{ $eq: 'verbal' }, { $set: 'block' }],
                      $else: { $set: 'none' }
                    }
                  }
                },
                {
                  component: 'TextArea',
                  statePath: 'response',
                  label: 'Response',
                  layout: {
                    width: '50%'
                  },
                  styles: {
                    height: '120px'
                  }
                },
                {
                  $ref: 'DisplayValueToggle',
                  component: 'Checkbox',
                  statePath: 'label',
                  label: 'Label Offered?',
                  layout: {
                    padding: 15,
                    width: '50%',
                    display: 'none'
                  },
                  referencedProperties: {
                    'layout.display': {
                      statePath: 'handling',
                      relative: true,
                      $if: [{ $eq: 'verbal' }, { $set: 'block' }],
                      $else: { $set: 'none' }
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          component: 'Section',
          $ref: 'DocumentUploader'
        },
        {
          component: 'Section',
          $ref: 'MISaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Contacts',
      component: 'Tab',
      path: 'contacts',
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'contacts' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'Contact',
          component: 'Section',
          title: 'Case Contacts',
          statePath: 'contacts',

          elements: [
            {
              $ref: 'LookupContactButton'
            },
            {
              $ref: 'ContactList',
              statePath: 'contact',
              label: 'another contact',
              length: 1
            }
          ]
        },
        {
          id: 'patient',
          component: 'Section',
          title: 'Patient Information',
          statePath: 'patient',

          elements: [
            {
              $ref: 'LookupPatientButton'
            },
            {
              $ref: 'PatientInfo',
              statePath: 'patient'
            }
          ]
        },
        {
          component: 'Section',
          $ref: 'MISaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Tracking',
      component: 'Tab',
      path: 'tracking',
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'tracking' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: 'reference_numbers',
          title: 'Reference Numbers',
          elements: [
            { $ref: 'ReferenceNumber' },
            {
              $ref: 'LinkedCases',
              statePath: '/linkedCases'
            },
            {
              $ref: 'ClonedFrom'
            },
            { $ref: 'AERNumbers' }
          ]
        },
        {
          component: 'Section',
          $ref: 'TrackingInformationSection',
          title: 'Master Case Information',
          statePath: 'tracking'
        },
        {
          component: 'Section',
          $ref: 'InternalCommentsSection',
          statePath: 'subcases.medicalInfo.comments'
        },
        {
          component: 'Section',
          $ref: 'MISaveSubmitActionSection'
        }
      ]
    },
    { $ref: 'SharedInfo' }
  ]
};
