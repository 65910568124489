import PropTypes from 'prop-types';

/**
 * Note that these constants are scoped to form components,
 * and kept separate from the main app's constants.
 * This is to make extraction to a distinct form components lib easier in the future
 * This uses a more modern regex than the one from RFC 5322
 * It was sourced from here: https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
 */
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const EMAILS_REGEX = /^(([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;

export const INPUT_SIDE_PADDING = 15; // pixels

export const CHARACTER_EX_UNITS = {
  text: 2.1,
  tel: 1.1,
  date: 1.1
};

export const EMPTY_SELECTION = '';

export const formElementPropType = PropTypes.shape({
  component: PropTypes.oneOf([
    'CheckboxGroup',
    'Select',
    'TextInput',
    'PhoneInput',
    'RadioGroup'
  ])
});

// AbbVie Date Format for display
export const DATE_FORMAT = 'DD MMM YYYY';

export const DATE_FORMAT_HOURS = 'DD MMM YYYY [AT] HH:mm';

// Date format used by the Date form component and sent to the BE
export const API_DATE_FORMAT = 'YYYY-MM-DD'; // DO NOT CHANGE! Must be ISO string supporting partial dates.

// TODO: Confirm business requirement
export const DATE_RESTRICTIONS = {
  PAST: {
    yyyy: '1900',
    mm: '01',
    dd: '01'
  },
  FUTURE: {
    yyyy: '2100',
    mm: '12',
    dd: '31'
  }
};
