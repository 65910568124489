import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    backgroundColor: theme.colors.base,
    color: theme.colors.header,
    padding: '1.5em',
    '> table': {
      width: '100%'
    }
  }),
  caseIdLink: generateCSS({
    backgroundColor: theme.colors.base,
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.caseIdLink,
    '> a': {
      color: theme.colors.brand
    },
    padding: '0.5em'
  }),
  columnGroup: generateCSS({
    border: '1px solid white',
    ...theme.fonts.coloumnGroupLabel,
    padding: '0.75em 0'
  }),
  columnHeader: generateCSS({
    border: '1px solid white',
    ...theme.fonts.coloumnHeaderLabel,
    padding: '0.5em'
  }),
  primaryColor: generateCSS({
    backgroundColor: theme.colors.background
  }),
  secondaryColor: generateCSS({
    backgroundColor: '#ebf5fa' // TODO: Abstract to theme ?
  }),
  sortImage: generateCSS({
    float: 'right',
    marginBottom: '3px',
    ':hover': {
      cursor: 'pointer'
    }
  }),
  sortDiv: generateCSS({
    display: 'inline-block',
    float: 'right',
    width: '9px'
  }),
  tableCell: generateCSS({
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.tabelCellLabel,
    height: '46px',
    padding: '0.5em',
    backgroundColor: '$red1'
  }),
  tableFooter: generateCSS({
    ' tr': {
      height: '46px',
      textAlign: 'center',
      ' span': {
        ...theme.fonts.resultsFooterLabel
      }
    }
  }),
  viewLink: generateCSS({
    backgroundColor: theme.colors.base,
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.tabelCellLabel,
    '> a': {
      color: theme.colors.charcoal
    },
    fontSize: '14px',
    fontWeight: '900',
    padding: '0.5em'
  }),
  modalDetails: generateCSS({
    width: '100%',
    lineHeight: '2',
    ' > span ': {
      width: '100%',
      display: 'flex'
    },
    ' > span > span:nth-child(1) ': {
      width: '50%',
      color: theme.colors.black
    },
    ' > span > span:nth-child(2) ': {
      width: '50%'
    }
  })
});

export default stylesGenerator;
