import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

const NoResults = props => (
  <div
    className={`${props.className} ${props.computedStyles.noResultsWrapper}`}
  >
    <div className={props.computedStyles.noResults}>{props.children}</div>
  </div>
);

NoResults.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.string,
  computedStyles: PropTypes.shape({
    noResults: PropTypes.object.isRequired,
    noResultsWrapper: PropTypes.object.isRequired
  }).isRequired
};

NoResults.defaultProps = {
  className: '',
  children: 'No results found.'
};

export default withStyles(stylesGenerator)(NoResults);
