module.exports = [
  {
    component: 'LabelValue',
    label: '',
    value: '(NEW)',
    triggers: {
      onClick: ['onNavigateToSubcase', 'mi']
    },
    layout: {
      display: 'flex',
      flexDirection: 'row'
    },
    styles: {
      background: 'transparent',
      borderColor: 'transparent',
      width: 'inherit',
      fontSize: '20px',
      fontFamily: 'SackersGothicStd-Heavy',
      textAlign: 'inherit'
    },
    referencedProperties: {
      value: {
        $compose: [
          { $get: 'subcases.medicalInfo.status' },
          {
            $or: [
              {
                $and: [{ $eq: 'NEW' }, { $set: '(NEW)' }]
              },
              {
                $and: [{ $eq: 'IN_PROGRESS' }, { $set: '(IN PROGRESS)' }]
              },
              {
                $and: [{ $eq: 'ARCHIVED' }, { $set: '(ARCHIVED)' }]
              },
              {
                $and: [{ $eq: 'COMPLETED' }, { $set: '(COMPLETED)' }]
              },
              {
                $set: ''
              }
            ]
          }
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'AE Owner: ',
    statePath: 'assignee',
    layout: {
      width: '100%',
      ' > span span:nth-child(2)': {
        cursor: 'pointer',
        fontWeight: 'bold'
      },
      display: 'none'
    },
    triggers: {
      onClick: ['onAssignSubcase', 'mi']
    },
    valueTemplate: '<%= getUserName(users, value) %>',
    referencedProperties: {
      "layout[' > span span:nth-child(2)'].cursor": {
        $if: [{ $get: 'assignee' }, { $set: 'initial' }],
        $elseIf: [
          { 'subcases.medicalInfo.status': { $eq: 'ARCHIVED' } },
          { $set: 'not-allowed' }
        ],
        $else: { $set: 'pointer' }
      },
      "layout[' > span span:nth-child(2)'].fontWeight": {
        $if: [{ $get: 'assignee' }, { $set: 'initial' }],
        $else: { $set: 'bold' }
      },
      'triggers.onClick': {
        $if: [{ 'subcases.medicalInfo.assignee': { $ne: null } }, { $set: '' }],
        $else: { $set: ['onAssignSubcase', 'mi'] }
      },
      valueTemplate: {
        $or: [
          {
            $and: [
              { $get: 'subcases.medicalInfo.assignee' },
              {
                $concat: [
                  '<%=data.userMap["',
                  { $get: 'subcases.medicalInfo.assignee' },
                  '"] || "',
                  { $get: 'subcases.medicalInfo.assignee' },
                  '"%>'
                ]
              }
            ]
          },
          'Assign to me'
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Submitted? ',
    layout: {
      width: '100%',
      display: 'none'
    },
    referencedProperties: {
      value: {
        statePath: 'subcases.medicalInfo.submitted',
        $if: [{ $eq: true }, { $set: 'Yes' }],
        $else: { $set: 'No' }
      }
    }
  }
];
