import { createAction, handleActions } from 'redux-actions';

import { mergeWith, cloneDeep, get, set, flow } from 'lodash';

import submitCaseMutation from 'api/graphql/submitCaseMutation';
import submitCaseQuery from 'api/graphql/submitCaseQuery';
import fetchCaseId from 'api/graphql/fetchCaseId';
import { fetchLinkCases, fetchUnlinkCases } from 'api/rest/fetchLinkCases';
import fetchMergeCases from 'api/rest/fetchMergeCases';
import fetchTasksCountQuery from 'api/graphql/fetchTasksCount';
import fetchSubcaseVersionDiff from 'api/rest/fetchSubcaseVersionDiff';

import { getOrElse } from 'Common/utils';
import { uploadAttachments, downloadAttachment } from 'CreateCase/utils';
import { AE_STATE_PATHS, PQ_STATE_PATHS } from 'CreateCase/constants';
import { ATTACHMENT_API } from 'config/values';
import { FRAGMENT_NAME_CASE, PAGE_STATUS } from 'Common/constants';
import sendPqMessageMutation from '../../api/graphql/sendPqMessageMutation';
import Notifier from '../../Common/components/Notifier';
import { attachmentErrorList } from '../constants';

const EMIT_CASE_RESET = 'EMIT_CASE_RESET';
const EMIT_SAVE_CASE = 'EMIT_SAVE_CASE';
const EMIT_SAVE_CASE_SUCCESS = 'EMIT_SAVE_CASE_SUCCESS';
const EMIT_SAVE_CASE_FAILURE = 'EMIT_SAVE_CASE_FAILURE';
const EMIT_FETCH_CASE_ID = 'EMIT_FETCH_CASE_ID';
const EMIT_FETCH_CASE_ID_SUCCESS = 'EMIT_FETCH_CASE_ID_SUCCESS';
const EMIT_FETCH_CASE_ID_FAILURE = 'EMIT_FETCH_CASE_ID_FAILURE';
const EMIT_FETCH_CASE_BY_ID = 'EMIT_FETCH_CASE_BY_ID';
const EMIT_FETCH_CASE_BY_ID_SUCCESS = 'EMIT_FETCH_CASE_BY_ID_SUCCESS';
const EMIT_FETCH_CASE_BY_ID_FAILURE = 'EMIT_FETCH_CASE_BY_ID_FAILURE';

const EMIT_INPUT_UPDATE = 'EMIT_INPUT_UPDATE';
const EMIT_INPUT_BATCH_UPDATE = 'EMIT_INPUT_BATCH_UPDATE';
const EMIT_SAVE_ATTACHMENTS = 'EMIT_SAVE_ATTACHMENTS';
const EMIT_DOWNLOAD_ATTACHMENT = 'EMIT_DOWN_ATTACHMENT';

const EMIT_RESOLVED_CONFLICTS_UPDATE = 'EMIT_RESOLVED_CONFLICTS_UPDATE';
const EMIT_RESOLVED_CONFLICTS_CLEAR = 'EMIT_RESOLVED_CONFLICTS_CLEAR';
const EMIT_REGISTER_DUPLICATE_SEARCH = 'EMIT_REGISTER_DUPLICATE_SEARCH';
const EMIT_UPDATE_VALIDATION_ERRORS = 'EMIT_UPDATE_VALIDATION_ERRORS';

const EMIT_FETCH_TASKS_COUNT = 'EMIT_FETCH_TASKS_COUNT';
const EMIT_UPDATE_TASKS_COUNT = 'EMIT_UPDATE_TASKS_COUNT';
const EMIT_LINK_CASE = 'EMIT_LINK_CASE';
const EMIT_UNLINK_CASE = 'EMIT_UNLINK_CASE';
const EMIT_LINK_CASE_SUCCESS = 'EMIT_LINK_CASE_SUCCESS';
const EMIT_LINK_CASE_FAILURE = 'EMIT_LINK_CASE_FAILURE';

const EMIT_MERGE_CASES = 'EMIT_MERGE_CASES';
const EMIT_MERGE_CASES_SUCCESS = 'EMIT_MERGE_CASES_SUCCESS';
const EMIT_MERGE_CASES_FAIL = 'EMIT_MERGE_CASES_FAIL';

const EMIT_SET_NEW_VERSION = 'EMIT_SET_NEW_VERSION';

const EMIT_SET_RECONCILIATION = 'EMIT_SET_RECONCILIATION';
const EMIT_FETCH_VERSION_DIFF = 'EMIT_FETCH_VERSION_DIFF';
const EMIT_FETCH_VERSION_DIFF_SUCCESS = 'EMIT_FETCH_VERSION_DIFF_SUCCESS';
const EMIT_FETCH_VERSION_DIFF_FAIL = 'EMIT_FETCH_VERSION_DIFF_FAIL';

export const emitCaseReset = createAction(EMIT_CASE_RESET);

export const emitFetchTasksCount = query => (dispatch, getState) => {
  createAction(EMIT_FETCH_TASKS_COUNT)();
  return fetchTasksCountQuery(query)(dispatch, getState);
};

export const emitUpdateTasksCount = createAction(
  EMIT_UPDATE_TASKS_COUNT,
  tasksCount => ({
    tasksCount
  })
);

export const emitSaveCaseSuccess = createAction(EMIT_SAVE_CASE_SUCCESS);

export const emitSaveCaseFailure = createAction(EMIT_SAVE_CASE_FAILURE);

export const emitSaveCase = (
  trilogyCase,
  params,
  isFromCreateCaseBtn = false
) => (dispatch, getState) => {
  const { caseReducer, schemaReducer } = getState();
  dispatch(
    createAction(EMIT_SAVE_CASE, (theCase, page) => ({
      theCase,
      page
    }))(trilogyCase, params.page)
  );
  return submitCaseMutation(
    caseReducer,
    {
      trilogyCase,
      params
    },
    isFromCreateCaseBtn,
    schemaReducer.fragments[FRAGMENT_NAME_CASE],
    schemaReducer.tacticalData
  ).then(
    savedTrilogyCase => dispatch(emitSaveCaseSuccess(savedTrilogyCase)),
    err => {
      console.log(`got error ${err.toString()}`);
      dispatch(emitSaveCaseFailure(err));
      return Promise.reject(err);
    }
  );
};

export const emitSendPqMessage = (trilogyCase, productId) => (
  dispatch,
  getState
) => {
  const { caseReducer, schemaReducer } = getState();
  dispatch(
    createAction(EMIT_SAVE_CASE, (theCase, page) => ({
      theCase,
      page
    }))(trilogyCase, 'pq')
  );
  return sendPqMessageMutation(
    caseReducer,
    { trilogyCase },
    productId,
    schemaReducer.fragments[FRAGMENT_NAME_CASE]
  ).then(
    savedTrilogyCase => dispatch(emitSaveCaseSuccess(savedTrilogyCase)),
    err => {
      console.log(`got error ${err.toString()}`);
      dispatch(emitSaveCaseFailure(err));
      return Promise.reject(err);
    }
  );
};

export const emitFetchNewCaseIdSuccess = createAction(
  EMIT_FETCH_CASE_ID_SUCCESS,
  newCase => ({ newCase })
);
export const emitFetchNewCaseIdFailure = createAction(
  EMIT_FETCH_CASE_ID_FAILURE,
  error => error
);

export const emitFetchNewCaseId = () => (dispatch, getState) => {
  const { schemaReducer } = getState();
  dispatch(createAction(EMIT_FETCH_CASE_ID)({}));
  return fetchCaseId(
    flow(emitFetchNewCaseIdSuccess, dispatch),
    flow(emitFetchNewCaseIdFailure, dispatch),
    schemaReducer.fragments[FRAGMENT_NAME_CASE]
  );
};

export const emitFetchCaseByIdSuccess = createAction(
  EMIT_FETCH_CASE_BY_ID_SUCCESS
);
export const emitFetchCaseByIdFailure = createAction(
  EMIT_FETCH_CASE_BY_ID_FAILURE
);

export const emitFetchCaseById = masterCaseId => (dispatch, getState) => {
  const { caseReducer, schemaReducer } = getState();

  dispatch(
    createAction(EMIT_FETCH_CASE_BY_ID)({
      masterCaseId
    })
  );

  return submitCaseQuery(
    caseReducer,
    masterCaseId,
    schemaReducer.fragments[FRAGMENT_NAME_CASE]
  ).then(
    data => {
      dispatch(emitFetchCaseByIdSuccess({ trilogyCase: data }));
    },
    err => {
      console.log(`got error ${err.toString()}`);
      dispatch(emitFetchCaseByIdFailure(err));
      return Promise.reject(err);
    }
  );
};

export const emitLinkCaseSuccess = createAction(EMIT_LINK_CASE_SUCCESS);
export const emitLinkCaseFailure = createAction(EMIT_LINK_CASE_FAILURE);

export const emitLinkCases = (case1Id, case2Id) => dispatch => {
  dispatch(
    createAction(EMIT_LINK_CASE)({
      case1Id,
      case2Id
    })
  );

  return fetchLinkCases(
    case1Id,
    case2Id,
    data => {
      dispatch(emitLinkCaseSuccess({ casesLinked: data }));
      return Promise.resolve(data);
    },
    err => {
      dispatch(emitLinkCaseFailure(err));
      return Promise.reject(err);
    }
  );
};
export const emitMergeCasesSuccess = createAction(EMIT_MERGE_CASES_SUCCESS);
export const emitMergeCasesFail = createAction(EMIT_MERGE_CASES_FAIL);
export const emitMergeCases = (
  caseIdToArchive,
  caseIdToMerge,
  archiveComments,
  archiveReason
) => dispatch => {
  dispatch(
    createAction(EMIT_MERGE_CASES)({
      caseIdToArchive,
      caseIdToMerge,
      archiveComments,
      archiveReason
    })
  );
  return fetchMergeCases(
    caseIdToArchive,
    caseIdToMerge,
    archiveComments,
    archiveReason,
    () => {
      dispatch(emitMergeCasesSuccess());
      return Promise.resolve();
    },
    err => {
      dispatch(emitMergeCasesFail(err));
      return Promise.reject(err);
    }
  );
};

export const emitUnlinkCases = (case1Id, case2Id) => dispatch => {
  dispatch(
    createAction(EMIT_UNLINK_CASE)({
      case1Id,
      case2Id
    })
  );

  return fetchUnlinkCases(
    case1Id,
    case2Id,
    () => {
      dispatch(emitLinkCaseSuccess());
      return Promise.resolve();
    },
    err => {
      dispatch(emitLinkCaseFailure(err));
      return Promise.reject(err);
    }
  );
};

export const emitUserResolvedConflictsUpdate = createAction(
  EMIT_RESOLVED_CONFLICTS_UPDATE,
  conflict => ({ conflict })
);

export const emituserResolvedConflictsClear = createAction(
  EMIT_RESOLVED_CONFLICTS_CLEAR
);

export const emitRegisterDuplicateSearch = createAction(
  EMIT_REGISTER_DUPLICATE_SEARCH
);

export const emitUpdateValidationErrors = createAction(
  EMIT_UPDATE_VALIDATION_ERRORS,
  formValidationErrors => ({ formValidationErrors })
);

export const emitInputUpdate = createAction(
  EMIT_INPUT_UPDATE,
  (statePath, value) => ({
    value,
    statePath
  })
);

export const emitInputBatchUpdate = createAction(
  EMIT_INPUT_BATCH_UPDATE,
  (trilogyCase, hasSaved = false, lastUpdate) => ({
    trilogyCase,
    hasSaved,
    lastUpdate: lastUpdate || Date.now()
  })
);

const handleAttachmentRejections = result => {
  if (result.status === 'rejected') {
    if (result.reason) {
      let msg = attachmentErrorList(result)[result.reason.response];
      if (msg == null) {
        msg = attachmentErrorList(result).default;
      }
      Notifier.show({
        message: msg,
        iconName: 'error',
        intent: Notifier.DANGER,
        partOfMultiError: true
      });
    } else {
      Notifier.show({
        message:
          'There was a problem processing your attachments. Please try again or contact the help desk if this problem persists.',
        iconName: 'error',
        intent: Notifier.DANGER
      });
    }
    return null;
  }
  return result.value;
};

const handleAttachmentResponses = (results, callback, dispatch, statePath) => {
  if (results.some(result => result.status === 'rejected')) {
    callback(true);
    const sanitizedFiles = results.filter(handleAttachmentRejections);
    dispatch(emitInputUpdate(statePath, sanitizedFiles.value));
  } else {
    callback(false);
    dispatch(emitInputUpdate(statePath, results.map(result => result.value)));
  }
};

const saveAttachments = createAction(
  EMIT_SAVE_ATTACHMENTS,
  (value, caseId, statePath, callback, dispatch) => {
    Notifier.clear();
    const uri = `${ATTACHMENT_API.uri}/uploadAttachment/${caseId}`;
    uploadAttachments(uri)(value, onProgressFiles =>
      dispatch(emitInputUpdate(statePath, onProgressFiles))
    ).then(results =>
      handleAttachmentResponses(results, callback, dispatch, statePath)
    );
  }
);

export const emitSaveAttachments = (
  value,
  caseId,
  statePath,
  callback
) => dispatch =>
  dispatch(saveAttachments(value, caseId, statePath, callback, dispatch));

export const emitDownloadAttachment = createAction(
  EMIT_DOWNLOAD_ATTACHMENT,
  (file, caseId) => {
    const caseIdUri = file.caseIdOverride ? file.caseIdOverride : caseId;
    const uri = `${ATTACHMENT_API.uri}/downloadAttachment/${caseIdUri}/${
      file.id
    }?fileName=${file.name}.${file.ext}`;
    downloadAttachment(uri)(file);
  }
);

export const emitSetNewVersion = createAction(EMIT_SET_NEW_VERSION);

export const emitSetReconciliation = createAction(
  EMIT_SET_RECONCILIATION,
  (reconciliationData, reconciliationFetched = true) => ({
    reconciliationData,
    reconciliationFetched
  })
);

export const emitFetchVersionDiffSuccess = createAction(
  EMIT_FETCH_VERSION_DIFF_SUCCESS
);
export const emitFetchVersionDiffFailure = createAction(
  EMIT_FETCH_VERSION_DIFF_FAIL
);

export const emitFetchVersionDiff = subcaseVersionId => dispatch => {
  dispatch(
    createAction(EMIT_FETCH_VERSION_DIFF)({
      subcaseVersionId
    })
  );

  return fetchSubcaseVersionDiff(subcaseVersionId).then(
    diff => dispatch(emitFetchVersionDiffSuccess({ subcaseVersionId, diff })),
    err => dispatch(emitFetchVersionDiffFailure(err))
  );
};

const getProductSection = updatedState => {
  let productSection = null;
  const { subcases } = updatedState.trilogyCase;
  if (subcases) {
    const { adverseEvent } = subcases;
    if (adverseEvent) {
      productSection = adverseEvent.product_section;
    } else {
      return null;
    }
  } else {
    return null;
  }
  const reactions = updatedState.trilogyCase.subcases.adverseEvent.reactions;
  const reactionLength =
    reactions && reactions.reaction ? reactions.reaction.length : 0;

  if (productSection === null) {
    const newProductSection = {};
    const aeproducts = [];
    const product = {};
    product.reporter_causality = [];
    for (let i = 0; i < reactionLength; i += 1) {
      product.reporter_causality.push({
        reporter_causality: null,
        alternative_etiology: null
      });
    }
    aeproducts.push(product);
    newProductSection.aeproducts = aeproducts;
    return newProductSection;
  }
  return productSection;
};

const initialState = {
  caseStatus: PAGE_STATUS.INIT,
  isLinkingCase: false,
  isSavingCase: false,
  userResolvedConflicts: {},
  formValidationErrors: {},
  // Controls some validation behavior before initial submission attempt
  formInvalidated: false,
  isRegisteringDuplicateSearch: false,
  hasSaved: true,
  caseIdAttempts: 0,
  /**
   * @typedef {Object} TrilogyCase
   * @enum {string} caseProperties
   * @property {Object} subcases
   * @property {Object[]} revisions
   * @property {Object} revisions.revision
   * @property {Object} summary
   * @property {Object[]} contacts
   * @property {Object} contacts.contact
   * @property {boolean} archived
   * @property {boolean} completed
   */
  trilogyCase: {},
  isNewVersion: false,
  isMergingCases: false,
  reconciliationFetched: false,
  reconciliationData: [],
  isFetchingVersionDiff: false,
  versionDiff: {}
};

const handlers = {
  [EMIT_CASE_RESET]: () => ({
    ...initialState,
    isSavingCase: false
  }),

  [EMIT_FETCH_CASE_ID]: state => ({
    ...state,
    caseIdAttempts: state.caseIdAttempts + 1,
    caseStatus: PAGE_STATUS.LOADING,
    trilogyCase: {},
    reconciliationFetched: false,
    reconciliationData: [],
    versionDiff: {}
  }),

  [EMIT_FETCH_TASKS_COUNT]: state => state,

  [EMIT_UPDATE_TASKS_COUNT]: (state, { payload }) => ({
    ...state,
    tasksCount: payload.tasksCount
  }),

  [EMIT_FETCH_CASE_ID_SUCCESS]: (state, { payload: { newCase } }) => {
    const trilogyCase = {
      ...(state.trilogyCase || {}),
      ...newCase // New case "shell" from BE
    };
    return {
      ...state,
      caseIdAttempts: 0,
      caseStatus: PAGE_STATUS.LOADED,
      hasSaved: true,
      trilogyCase
    };
  },

  [EMIT_FETCH_CASE_ID_FAILURE]: state => ({
    ...state,
    caseStatus: PAGE_STATUS.FAILED
  }),

  [EMIT_FETCH_CASE_BY_ID]: (state, { payload }) => ({
    ...state,
    ...payload,
    caseStatus: PAGE_STATUS.LOADING,
    hasSaved: true,
    trilogyCase: {},
    reconciliationFetched: false,
    reconciliationData: [],
    versionDiff: {}
  }),

  [EMIT_FETCH_CASE_BY_ID_FAILURE]: (state, { payload }) => ({
    ...state,
    error: payload,
    caseStatus: PAGE_STATUS.FAILED
  }),

  [EMIT_FETCH_CASE_BY_ID_SUCCESS]: (state, action) => {
    const newState = handlers[EMIT_INPUT_BATCH_UPDATE](state, {
      payload: {
        hasSaved: true,
        trilogyCase: action.payload.trilogyCase
      }
    });
    return {
      ...newState,
      caseStatus: PAGE_STATUS.LOADED
    };
  },

  [EMIT_SAVE_CASE]: (state, { payload: { theCase, page } }) => ({
    ...state,
    isSavingCase: true,
    caseStatus: PAGE_STATUS.SAVING,
    validationErrors: {},
    trilogyCase: {
      ...state.trilogyCase
    },
    isNewVersion: getOrElse(
      theCase,
      page === 'ae'
        ? AE_STATE_PATHS.NEW_VERSION_FLAG
        : PQ_STATE_PATHS.NEW_VERSION_FLAG,
      false
    ),
    reconciliationFetched: false,
    reconciliationData: []
  }),

  [EMIT_SAVE_CASE_SUCCESS]: (state, { payload }) => {
    const newState = handlers[EMIT_INPUT_BATCH_UPDATE](state, {
      payload: {
        hasSaved: true,
        trilogyCase: payload
      }
    });
    const updatedState = {
      ...newState,
      // The `requestFormatter` for cases strips out empty values (including objects
      // and arrays). In order to retain the UI as the user has configured it after
      // a save, we retain any objects/arrays that were removed upon the request.`
      trilogyCase: mergeWith(
        state.trilogyCase,
        newState.trilogyCase,
        (curValue, newValue) =>
          newValue === null && typeof curValue === 'object'
            ? curValue
            : newValue
      ),
      lastUpdate: Date.now(),
      isSavingCase: false,
      caseStatus: PAGE_STATUS.LOADED,
      versionDiff: {}
    };
    const newProductSection = getProductSection(updatedState);
    if (newProductSection === null) {
      return updatedState;
    }
    return {
      ...updatedState,
      trilogyCase: {
        ...updatedState.trilogyCase,
        subcases: {
          ...updatedState.trilogyCase.subcases,
          adverseEvent: {
            ...updatedState.trilogyCase.subcases.adverseEvent,
            product_section: newProductSection
          }
        }
      }
    };
  },

  [EMIT_SAVE_CASE_FAILURE]: (state, { error }) => ({
    ...state,
    caseStatus: PAGE_STATUS.FAILED,
    error
  }),

  [EMIT_SET_NEW_VERSION]: (state, { payload = false }) => ({
    ...state,
    isNewVersion: payload
  }),

  [EMIT_LINK_CASE]: state => ({
    ...state,
    isLinkingCase: true
  }),

  [EMIT_LINK_CASE_SUCCESS]: state => ({
    ...state,
    isLinkingCase: false
  }),

  [EMIT_LINK_CASE_FAILURE]: state => ({
    ...state,
    isLinkingCase: false
  }),

  [EMIT_RESOLVED_CONFLICTS_UPDATE]: (state, { payload }) => ({
    ...state,
    userResolvedConflicts: {
      ...state.userResolvedConflicts,
      [payload.conflict.path]: payload.conflict.value
    }
  }),

  [EMIT_RESOLVED_CONFLICTS_CLEAR]: state => ({
    ...state,
    userResolvedConflicts: initialState.userResolvedConflicts
  }),

  [EMIT_REGISTER_DUPLICATE_SEARCH]: (state, { payload }) => ({
    ...state,
    isRegisteringDuplicateSearch: payload
  }),

  [EMIT_UPDATE_VALIDATION_ERRORS]: (state, { payload }) => ({
    ...state,
    formValidationErrors: payload.formValidationErrors
  }),

  [EMIT_SAVE_ATTACHMENTS]: state => state,

  [EMIT_INPUT_UPDATE]: (state, { payload }) => {
    const { statePath, value } = payload;

    const prevValue = get(state.trilogyCase, statePath);

    // NOTE: Never call set() on state without a cloneDeep or your components will not see the updates!
    const updatedCase = set(
      cloneDeep(state.trilogyCase),
      statePath,
      value === '' ? null : value
    );

    return {
      ...state,
      hasSaved: value === prevValue, // if value changes `hasSaved` = false
      trilogyCase: updatedCase
    };
  },

  [EMIT_INPUT_BATCH_UPDATE]: (state, { payload }) => {
    const { trilogyCase, hasSaved, lastUpdate } = payload;
    return {
      ...state,
      lastUpdate,
      hasSaved,
      trilogyCase: {
        ...trilogyCase
      }
    };
  },
  [EMIT_MERGE_CASES]: state => ({
    ...state,
    isMergingCases: true
  }),
  [EMIT_MERGE_CASES_SUCCESS]: state => ({
    ...state,
    isMergingCases: false
  }),

  [EMIT_MERGE_CASES_FAIL]: state => ({
    ...state,
    isMergingCases: false
  }),

  [EMIT_SET_RECONCILIATION]: (state, { payload }) => {
    const { reconciliationData, reconciliationFetched } = payload;
    return {
      ...state,
      reconciliationFetched,
      reconciliationData
    };
  },

  [EMIT_FETCH_VERSION_DIFF]: state => ({
    ...state,
    isFetchingVersionDiff: true
  }),

  [EMIT_FETCH_VERSION_DIFF_SUCCESS]: (state, { payload }) => {
    const { subcaseVersionId, diff } = payload;
    return {
      ...state,
      versionDiff: {
        ...state.versionDiff,
        [subcaseVersionId]: diff
      },
      isFetchingVersionDiff: false
    };
  },

  [EMIT_FETCH_VERSION_DIFF_FAIL]: state => ({
    ...state,
    versionDiff: {},
    isFetchingVersionDiff: false
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
