import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '35px 34px 40px',
    color: theme.colors.header,
    backgroundColor: theme.colors.base,
    overflow: 'auto',
    maxHeight:
      window.innerHeight > 1100
        ? window.innerHeight * 0.75
        : window.innerHeight * 0.63,
    clear: 'both'
  }),

  table: generateCSS({
    width: '100%',
    padding: '35px',
    background: theme.colors.base,
    borderCollapse: 'collapse',
    '> tbody tr td': {
      '&:first-child': {
        minWidth: '70px',
        paddingLeft: '10px'
      }
    }
  }),

  tableBody: generateCSS({
    boxShadow: `3px 3px 8px 1px ${theme.colors.quaternary}`
  }),

  masterCaseId: generateCSS({
    color: theme.colors.altBrand,
    fontWeight: 'bold',
    display: 'block'
  }),

  subcaseList: generateCSS({
    color: theme.colors.black,
    fontSize: '12px'
  }),

  actions: generateCSS({
    '> div': {
      '> a': {
        color: theme.colors.brand,
        fontWeight: 'bold'
      }
    }
  }),

  productCell: generateCSS({
    wordBreak: 'break-all'
  }),

  nonIdealState: generateCSS({
    margin: '100px auto'
  }),

  totals: generateCSS({
    background: theme.colors.base,
    textAlign: 'right',
    width: '100%',
    display: 'block',
    float: 'right',
    margin: '0',
    padding: '0 34px 10px'
  }),

  productsData: generateCSS({
    minWidth: '180px'
  }),
  customerResponse: generateCSS({
    overflowY: 'auto'
  })
});

export default stylesGenerator;
