import React from 'react';
import PropTypes from 'prop-types';
import { Radio as BPRadio } from '@blueprintjs/core';

import stylesGenerator from './styles';
import withStyles from '../withStyles';
import { spreadPropsIfTruthy } from '../utils';
import { csePropTypes } from '../propTypes';

const Radio = props => {
  const { $id, schemaPath, focused, computedStyles } = props;
  const className = `${focused ? computedStyles.focused : ''} ${
    computedStyles.base
  }`;
  const defaultChecked = spreadPropsIfTruthy(
    {
      defaultChecked: props.defaultChecked
    },
    props.defaultChecked
  );

  return (
    <BPRadio
      id={props.id}
      className={className}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      onChange={props.onChange}
      onBlur={props.onBlur}
      checked={props.checked}
      value={props.value || null}
      disabled={props.disabled}
      data-id={`${$id}-${props.value}`}
      data-schema-path={schemaPath}
      {...defaultChecked}
      {...spreadPropsIfTruthy({ checked: props.checked }, props.checked)}
    >
      {props.children}
    </BPRadio>
  );
};

Radio.propTypes = {
  ...csePropTypes,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]).isRequired,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  focused: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    focused: PropTypes.object.isRequired
  }).isRequired,

  // There should be no provided default or onChange props for controlled Radios
  /* eslint-disable react/require-default-props */
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func
  /* eslint-enable react/require-default-props */
};

Radio.defaultProps = {
  className: '',
  disabled: false,
  focused: false,
  onKeyDown: () => {},
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {}
};

export default withStyles(stylesGenerator)(Radio);
