import {
  reduce,
  isEmpty,
  isNil,
  pickBy,
  set,
  zipObject,
  isObject,
  keys
} from 'lodash';

import {
  CC_SUB_CASE_TYPES_MAP,
  CASE_STATUS_NEW,
  CASE_STATUS_IN_PROGRESS,
  CASE_STATUS_COMPLETED
} from 'Common/constants';
import {
  DROPDOWN_FILTER_PATHS,
  QUEUE_CASES_TO_REVIEW,
  QUEUE_YOUR_CASES,
  QUEUE_ADVERSE_EVENT,
  AE_STATE_PATHS,
  PQ_STATE_PATHS,
  QUEUE_PRODUCT_QUALITY
} from '../constants';

export const isSubcaseView = pageType =>
  pageType &&
  pageType !== QUEUE_CASES_TO_REVIEW &&
  pageType !== QUEUE_YOUR_CASES;

/* eslint import/prefer-default-export:0 */
export const hasAssignedCase = (row, userId) =>
  !isNil(userId) && row.assignee === userId;

export const getStatusFilters = payload => {
  const { pageType } = payload;
  const { includeArchived, NEW, IN_PROGRESS, COMPLETED } = payload.filters;

  const statuses = [];
  if (NEW) statuses.push(CASE_STATUS_NEW);
  if (IN_PROGRESS) statuses.push(CASE_STATUS_IN_PROGRESS);
  if (COMPLETED) statuses.push(CASE_STATUS_COMPLETED);
  const status = statuses.length ? { status: statuses } : {};

  return isSubcaseView(pageType)
    ? {
        subcases: {
          [CC_SUB_CASE_TYPES_MAP[pageType]]: {
            archived: includeArchived,
            ...status
          }
        }
      }
    : {
        archived: includeArchived,
        ...status
      };
};

/**
 * Fun function to deal with the fact that we're using two checkboxes for a binary selection
 * @param payload
 * @returns {{}}
 */
export const getSubmittedFilters = payload => {
  const { pageType } = payload;
  const { submitted, nonSubmitted } = payload.filters;
  const submittedFilters = [];
  if (submitted) {
    submittedFilters.push(true);
  }
  if (nonSubmitted) {
    submittedFilters.push(false);
  }
  const returnValue = {};
  if (pageType === QUEUE_ADVERSE_EVENT && submittedFilters.length) {
    set(returnValue, AE_STATE_PATHS.SUBMITTED, submittedFilters);
  }
  if (pageType === QUEUE_PRODUCT_QUALITY && submittedFilters.length) {
    set(returnValue, PQ_STATE_PATHS.SUBMITTED, submittedFilters);
  }
  return returnValue;
};

export const getReconciliationFilters = payload => {
  const { reconciliationNeeded } = payload.filters;
  const returnValue = {};
  if (reconciliationNeeded) {
    // reconciliationNeeded is the subcase type where reconciliation is needed
    const subcaseType = CC_SUB_CASE_TYPES_MAP[reconciliationNeeded];
    set(returnValue, `subcases.${subcaseType}.reconciliationNeeded`, true);
  }
  return returnValue;
};

const getFiltersMap = (pageType, filters) => {
  const {
    masterCaseProduct,
    caseCreators,
    methodOfReceipt,
    country,
    aeCountry,
    seriousness,
    caseType,
    product, // your cases
    aeOwner,
    miOwner,
    pqOwner,
    pqProduct,
    suspectProduct,
    miProduct,
    submitted,
    highImpact,
    caseOwner
  } = filters;

  let filtersMap = {};
  switch (pageType) {
    case 'new':
      filtersMap = zipObject(
        [
          DROPDOWN_FILTER_PATHS.masterCaseProduct,
          DROPDOWN_FILTER_PATHS.methodOfReceipt,
          DROPDOWN_FILTER_PATHS.country,
          DROPDOWN_FILTER_PATHS.caseOwner
        ],
        [masterCaseProduct, methodOfReceipt, country, caseOwner]
      );
      break;
    case 'yours':
      filtersMap = zipObject(
        [
          'query',
          DROPDOWN_FILTER_PATHS.seriousness,
          DROPDOWN_FILTER_PATHS.country
        ],
        [`${product || ''}`.trim(), seriousness, country]
      );
      break;
    case 'ae':
      filtersMap = zipObject(
        [
          DROPDOWN_FILTER_PATHS.seriousness,
          DROPDOWN_FILTER_PATHS.caseType,
          DROPDOWN_FILTER_PATHS.aeCountry,
          DROPDOWN_FILTER_PATHS.aeOwner,
          DROPDOWN_FILTER_PATHS.suspectProduct,
          DROPDOWN_FILTER_PATHS.aeSubmitted
        ],
        [seriousness, caseType, aeCountry, aeOwner, suspectProduct, submitted]
      );
      break;
    case 'mi':
      filtersMap = zipObject(
        [
          DROPDOWN_FILTER_PATHS.country,
          DROPDOWN_FILTER_PATHS.miOwner,
          DROPDOWN_FILTER_PATHS.miProduct
        ],
        [country, miOwner, miProduct]
      );
      break;
    case 'pq':
      filtersMap = zipObject(
        [
          DROPDOWN_FILTER_PATHS.country,
          DROPDOWN_FILTER_PATHS.pqOwner,
          DROPDOWN_FILTER_PATHS.pqProduct,
          DROPDOWN_FILTER_PATHS.pqHighImpact
        ],
        [country, pqOwner, pqProduct, highImpact]
      );
      break;
    default:
      filtersMap = zipObject(
        [
          DROPDOWN_FILTER_PATHS.caseCreators,
          DROPDOWN_FILTER_PATHS.methodOfReceipt,
          DROPDOWN_FILTER_PATHS.country,
          DROPDOWN_FILTER_PATHS.masterCaseSerious,
          DROPDOWN_FILTER_PATHS.pqHighImpact,
          DROPDOWN_FILTER_PATHS.caseOwner
        ],
        [
          caseCreators,
          methodOfReceipt,
          country,
          seriousness,
          highImpact,
          caseOwner
        ]
      );
      break;
  }
  return filtersMap;
};

const valueOrArray = filterValue => {
  const values = filterValue.split(',');
  return values.length > 1 ? values : values[0];
};

export const getDropdownFilters = payload => {
  const { pageType } = payload;

  const filtersMap = getFiltersMap(pageType, payload.filters);
  return reduce(
    filtersMap,
    (acc, filterValue, path) => {
      if (!isEmpty(filterValue)) {
        set(acc, path, valueOrArray(filterValue));
      }
      return acc;
    },
    {}
  );
};

export const getPageCategoryFilter = pageKey =>
  pageKey
    ? {
        summary: { narrative: { categories: { [pageKey]: true } } }
      }
    : {};

export const getSubcaseFilter = subcases => {
  // if all of our subcase filters are selected...
  // we don't want to filter anything and return the full result set.
  if (
    subcases.adverse_event &&
    subcases.medical_inquiry &&
    subcases.product_quality
  ) {
    return {};
  }

  // if none of our subcase filters are selected...
  // we want to return nothing so we pass all false params.
  if (
    !subcases.adverse_event &&
    !subcases.medical_inquiry &&
    !subcases.product_quality
  ) {
    return {
      summary: {
        narrative: {
          categories: { ...subcases }
        }
      }
    };
  }

  // Otherwise, we filter based on which subcases are selected (true)
  return {
    summary: {
      narrative: {
        categories: { ...pickBy(subcases) }
      }
    }
  };
};

export const getUserBasedFilters = payload =>
  payload.filters && payload.filters.dynamicFilters
    ? payload.filters.dynamicFilters
    : {};

export const removeEmpty = object => {
  if (!isObject(object)) {
    return;
  }
  const obj = object;
  keys(object).forEach(key => {
    const localObj = object[key];

    if (isObject(localObj)) {
      if (isEmpty(localObj)) {
        delete obj[key];
        return;
      }

      // Is object, recursive call
      removeEmpty(localObj);

      if (isEmpty(localObj)) {
        delete obj[key];
      }
    }
  });
};

export const getLiteFilters = pageKey =>
  pageKey ? { created: '{EXISTS}' } : {};

export const getAssigneeFilter = payload => {
  const { pageType } = payload;
  const { ASSIGNEE, IN_PROGRESS, COMPLETED } = payload.filters;
  if (isNil(ASSIGNEE) || pageType !== QUEUE_YOUR_CASES) {
    return {};
  }

  // Your cases should never show Archived cases, new is disabled. Show no results if no status is checked.
  const statuses = [];
  if (IN_PROGRESS) statuses.push(CASE_STATUS_IN_PROGRESS);
  if (COMPLETED) statuses.push(CASE_STATUS_COMPLETED);

  return {
    _or: [
      {
        _and: {
          subcases: {
            adverseEvent: {
              assignee: ASSIGNEE,
              status: statuses
            }
          }
        }
      },
      {
        _and: {
          subcases: {
            medicalInfo: {
              assignee: ASSIGNEE,
              status: statuses
            }
          }
        }
      },
      {
        _and: {
          subcases: {
            productQuality: {
              assignee: ASSIGNEE,
              status: statuses
            }
          }
        }
      }
    ]
  };
};
