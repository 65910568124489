import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, isNil } from 'lodash';
import { Spinner, NonIdealState } from '@blueprintjs/core';

import withModal from 'Common/components/withModal';
import NoResults from 'Common/components/NoResults';
import { SCHEMA_PATH_FORM, CMS_PROP_TYPES } from 'Common/constants';
import { resolveElementSchemaForPath } from 'Common/utils/formState';
import { generateCSS } from 'Common/components/Form';
import SearchHeader from './SearchHeader';
import SearchResults from './SearchResults';
import { LOOKUP_CONTACT, LOOKUP_PATIENT } from '../constants';

class LookupController extends PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        duplicate: PropTypes.string
      }),
      url: PropTypes.string
    }).isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchRun: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      emitSchemaFetch: PropTypes.func.isRequired
    }).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData
  };

  static defaultProps = {
    tacticalData: {}
  };

  componentDidMount() {
    const { schema, actions } = this.props;

    if (isEmpty(schema.pages)) {
      actions.emitSchemaFetch(SCHEMA_PATH_FORM);
    }
  }

  fetchSortedResults = () => {}; // TODO: Implement

  renderLoading = () => (
    <div className={generateCSS({ margin: '100px auto' })}>
      <NonIdealState visual={<Spinner />} title="Loading" />
    </div>
  );

  render = () => {
    const {
      results,
      searchRun,
      total,
      match,
      schema,
      tacticalData
    } = this.props;
    const lookupWindow = window; // This popup window (document.window reference)
    const lookupType =
      get(match, 'params.lookupType', '').toLowerCase() === LOOKUP_PATIENT
        ? LOOKUP_PATIENT
        : LOOKUP_CONTACT;

    if (isNil(schema) || isEmpty(tacticalData)) return this.renderLoading();

    // Non-tactical, Form.js fields that we can look up for field option labels
    const caseSchemaFields = !isNil(schema)
      ? {
          patientGender: resolveElementSchemaForPath(
            'patient.patient[0].gender',
            schema
          )
        }
      : {};

    return (
      <div>
        <SearchHeader
          {...this.props}
          caseSchemaFields={caseSchemaFields}
          lookupType={lookupType}
          lookupWindow={lookupWindow}
        />
        {searchRun && total !== 0 ? (
          <SearchResults
            {...this.props}
            caseSchemaFields={caseSchemaFields}
            lookupType={lookupType}
            lookupWindow={lookupWindow}
            results={results}
            onSortClick={this.fetchSortedResults}
          />
        ) : (
          <NoResults />
        )}
      </div>
    );
  };
}

export default withModal(LookupController);
