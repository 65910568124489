import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  base: generateCSS({
    maxWidth: '500px',
    height: 'auto'
  })
});

export default stylesGenerator;
