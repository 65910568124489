/* eslint no-console:0 */
import { merge } from 'lodash';

import caseRequestFormatter from './formatter/caseRequest';
import caseResponseFormatter from './formatter/caseResponse';
import createCaseMutation from './mutations/case/createCaseMutation';

/**
 * @param {Object} state
 * @param {Object} options
 * @param {Object} options.payload
 * @param {TrilogyCase} options.payload.trilogyCase
 * @param {boolean} isFromCreateCaseBtn
 * @param {*} caseFragment
 * @param {Object} tacticalData
 */
const submitCaseMutation = (
  state,
  { payload, ...options },
  isFromCreateCaseBtn,
  caseFragment,
  tacticalData
) => {
  const { trilogyCase, onSuccess, onError } = merge({}, payload || options);
  const transformedDocument = caseRequestFormatter(
    trilogyCase,
    isFromCreateCaseBtn,
    tacticalData
  );

  const variables = {
    ...transformedDocument,
    id: trilogyCase.id
  };
  // fixes tests, if this is at the top-level of the module, some tests (that
  // have this in their dependency chain) will fail
  return require('config/apollo') // eslint-disable-line
    .client // eslint-disable-line
    .mutate({ mutation: createCaseMutation(caseFragment), variables })
    .then(({ data: { createCase } }) => {
      const thawed = Object.isFrozen(createCase)
        ? JSON.parse(JSON.stringify(createCase))
        : createCase;
      return caseResponseFormatter(thawed);
    })
    .then(onSuccess, onError);
};

export default submitCaseMutation;
