import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import TableRow from 'Common/components/TableRow';
import {
  CMS_PROP_TYPES,
  STATUSES_MAP,
  NOT_SPECIFIED,
  CC_SUB_CASE_TYPES_MAP,
  N_A
} from 'Common/constants';
import { abbvieDateFormat } from 'Common/components/Form';
import { MI_STATE_PATHS } from 'Queue/constants';
import { getTasksCount } from 'Queue/utils';
import { getUserName, getOrElse, getSubcaseProductValue } from 'Common/utils';
import DescriptionLink from './DescriptionLink';

const MedicalInquiriesRow = ({
  computedStyles,
  row,
  caseLink,
  tasksLink,
  tacticalData,
  tasksCounts,
  userDesiredColumns
}) => {
  const users = get(tacticalData, 'document-data.user-list', []);
  const maybeAssignee = getUserName(
    users,
    getOrElse(row, MI_STATE_PATHS.ASSIGNEE)
  );
  const subcaseId = getOrElse(row, MI_STATE_PATHS.ID);
  const tasksCount = getTasksCount(tasksCounts, subcaseId);
  const createdDate = getOrElse(row, MI_STATE_PATHS.CREATED);
  const productName = getSubcaseProductValue(
    row,
    tacticalData,
    CC_SUB_CASE_TYPES_MAP.mi,
    NOT_SPECIFIED
  );

  return (
    <TableRow id="queue-mi-table-row">
      {(userDesiredColumns || []).map(column => {
        switch (column.label) {
          case 'Case Information':
            return (
              <td>
                <Link
                  to={caseLink}
                  className={computedStyles.masterCaseId}
                  target="_blank"
                >
                  {subcaseId}
                </Link>
                <DescriptionLink row={row} />
              </td>
            );
          case 'Country of Requester':
            return <td>{row.countryOfPrimaryReporterLabel}</td>;
          case 'Product':
            return (
              <td className={computedStyles.productCell}>{productName}</td>
            );
          case 'Created On':
            return <td>{abbvieDateFormat(createdDate) || N_A}</td>;
          case 'MI Owner':
            return <td>{maybeAssignee || 'Unassigned'}</td>;
          case 'Status':
            return (
              <td>
                {STATUSES_MAP[getOrElse(row, MI_STATE_PATHS.STATUS, 'NEW')]}
              </td>
            );
          case 'Actions':
            return (
              <td className={computedStyles.actions}>
                <div>
                  <Link to={caseLink} target="_blank">
                    View
                  </Link>
                </div>
                <div>
                  <Link to={tasksLink} target="_blank">
                    View Tasks {tasksCount ? `(${tasksCount})` : null}
                  </Link>
                </div>
              </td>
            );
          default:
            return <td>{NOT_SPECIFIED}</td>;
        }
      })}
    </TableRow>
  );
};

MedicalInquiriesRow.propTypes = {
  computedStyles: PropTypes.shape({
    masterCaseId: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    productCell: PropTypes.object.isRequired
  }).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    countryOfPrimaryReporterLabel: PropTypes.string,
    [MI_STATE_PATHS.LOCAL_TRADE_NAME]: PropTypes.string,
    createdDate: PropTypes.object,
    status: PropTypes.string
  }).isRequired,
  caseLink: PropTypes.string.isRequired,
  tasksLink: PropTypes.string.isRequired,
  tasksCounts: PropTypes.arrayOf(PropTypes.object),
  tacticalData: CMS_PROP_TYPES.tacticalData,
  userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

MedicalInquiriesRow.defaultProps = {
  tacticalData: {},
  tasksCounts: []
};

export default MedicalInquiriesRow;
