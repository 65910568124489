import fetchIntercept from 'fetch-intercept';
import { get, includes } from 'lodash';
import { getSession } from 'config/auth';

import Notifier from 'Common/components/Notifier';

const paths = ['task', 'case'];
const PATH_RE = new RegExp(`/(${paths.join('|')})/graphql`);
const getPath = (url = '') => (url.match(PATH_RE) || [])[1];

// NOTIFICATION KEYS
// In order to keep the user's screen from flooding with notifications
// (eg. if several endpoints become unresponsive), we're using a SINGLE notification ID
// per-notification "group"
let seriousError;
let loadingCase;
let savingCase;
let searching;
let loadingData;

const registerInterceptor = () =>
  fetchIntercept.register({
    request: (url, config = {}) => {
      const path = getPath(url);
      const body = get(config, 'body', '');
      const suppressNotifier = get(config, 'suppressNotifier', '');

      if (body.match(/createCase\(/i)) {
        let message;
        if (body.includes('"downstreamProductComplaintMessageRequired":true')) {
          message = 'Sending request...';
        } else {
          message = 'Saving changes...';
        }
        savingCase = Notifier.update(savingCase, {
          message,
          iconName: 'time',
          intent: Notifier.PRIMARY,
          timeout: 0
        });
      } else if (body.match(/searchCase|searchContact|searchPatient/i)) {
        searching = Notifier.update(searching, {
          message: 'Searching...',
          iconName: 'time',
          intent: Notifier.PRIMARY,
          timeout: 0
        });
      } else if (
        path === 'case' &&
        !url.includes('fragmentName') &&
        !suppressNotifier
      ) {
        loadingCase = Notifier.update(loadingCase, {
          message: 'Loading page...',
          iconName: 'time',
          intent: Notifier.PRIMARY,
          timeout: 0
        });
      }

      const requestArr = [
        url,
        {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${getSession().access_token}`
          }
        }
      ];
      return requestArr;
    },
    requestError: error => Promise.reject(error),
    response: response => {
      if (
        response.headers === undefined ||
        response.headers.get('content-type') !== 'application/json'
      ) {
        return response;
      }

      return response.json().then(json => {
        const { errors } = json;
        const errorMessage = get(errors, '[0].message', JSON.stringify(errors));
        if (!response.ok) {
          console.error(errorMessage);
        }

        if (savingCase) {
          let message = 'Changes saved.';
          if (!response.ok) {
            message = includes(errorMessage, 'validation')
              ? 'You cannot save because you have entered invalid data. Please update the data and save again.'
              : 'There was a problem saving your changes. Please try again or contact the help desk if this problem persists.';
          }
          savingCase = Notifier.update(savingCase, {
            message,
            iconName: response.ok ? 'tick-circle' : 'error',
            intent: response.ok ? Notifier.SUCCESS : Notifier.DANGER
          });
        } else if (searching) {
          searching = Notifier.update(searching, {
            message: response.ok
              ? 'Search complete.'
              : 'There was a problem completing your search. Please try again or contact the help desk if this problem persists.',
            iconName: response.ok ? 'tick-circle' : 'error',
            intent: response.ok ? Notifier.SUCCESS : Notifier.DANGER
          });
        } else if (loadingCase) {
          loadingCase = Notifier.update(loadingCase, {
            message: response.ok
              ? 'Page loaded.'
              : 'There was a problem loading the page. Please try again or contact the help desk if this problem persists.',
            iconName: response.ok ? 'tick-circle' : 'error',
            intent: response.ok ? Notifier.SUCCESS : Notifier.DANGER
          });
        } else if (!response.ok) {
          loadingData = Notifier.update(loadingData, {
            message: get(errors, '[0].message', 'An error occurred'),
            iconName: 'error',
            intent: Notifier.DANGER
          });
        }

        return Object.create({
          ...json,
          json: () => new Promise(res => res(json)), // for apollo
          text: () => new Promise(res => res(JSON.stringify(json))) // for apollo
        });
      });
    },
    responseError: error => {
      // Only serious errors will get here. A serious error is most common when
      // request pre-flights (OPTIONS) are not fulfilled correctly by the server
      // or the server itself is unresponsive
      seriousError = Notifier.update(seriousError, {
        message: 'An error occurred',
        iconName: 'error',
        intent: Notifier.DANGER
      });

      return Promise.reject(error);
    }
  });

export default registerInterceptor;
