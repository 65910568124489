/* globals window */
/* eslint global-require:0 */
import { applyMiddleware, compose, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import { beginAuthSession } from './auth.js';
import authMiddleware from './auth.middleware';

import reducers from './reduxRoot';

import history from './history';

// Check for JWT in query string, if found this starts the session
beginAuthSession(window.location.hash || window.location.search);

const combinedReducers = combineReducers({
  routerReducer,
  ...reducers
});

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__
  ? // eslint-disable-next-line
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'TRILOGY',
      actionsBlacklist: ['EMIT_REGISTER_EL_REFS']
    })
  : compose;

const middlewareModules = [
  thunk,
  authMiddleware,
  routerMiddleware(history)
].filter(v => !!v);

const composeStore = composeEnhancers(applyMiddleware(...middlewareModules))(
  createStore
);
const store = composeStore(combinedReducers);

if (module.hot) {
  module.hot.accept('./reduxRoot', () =>
    store.replaceReducer(require('./reduxRoot'))
  );
}

export default store;
