import { createAction, handleActions } from 'redux-actions';
import { EMIT_CLEAN_SLATE } from 'Common/constants';
import caseResponseFormatter from 'api/graphql/formatter/caseResponse';
import { getOrElse } from 'Common/utils';

const EMIT_SEARCH_QUERY_SUCCESS = 'EMIT_SEARCH_QUERY_SUCCESS';
const EMIT_SEARCH_QUERY_FAILURE = 'EMIT_SEARCH_QUERY_FAILURE';

export const emitSearchQuerySuccess = createAction(
  EMIT_SEARCH_QUERY_SUCCESS,
  (results, requestTimestamp) => ({ results, requestTimestamp })
);

export const emitSearchQueryFailure = createAction(EMIT_SEARCH_QUERY_FAILURE);

const initialState = {
  results: [],
  total: 0,
  lastRequestTimestamp: 0
};
const handlers = {
  [EMIT_CLEAN_SLATE]: () => ({
    ...initialState
  }),

  [EMIT_SEARCH_QUERY_SUCCESS]: (state, { payload }) => {
    const { requestTimestamp, results } = payload;
    if (requestTimestamp > state.lastRequestTimestamp) {
      return {
        ...state,
        total: getOrElse(results, 'searchCase.total', 0),
        results: getOrElse(results, 'searchCase.hits', []).map(
          caseResponseFormatter
        ),
        lastRequestTimestamp: requestTimestamp
      };
    }

    return state;
  }
};

const reducer = handleActions(handlers, initialState);
export default reducer;
