import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

const TabHeader = props => (
  <div className={props.computedStyles.base}>
    <div className={props.computedStyles.row}>
      <span id="tab-header-title" className={props.computedStyles.title}>
        {props.title}
      </span>
      <div className={props.computedStyles.contentRight}>
        {props.contentRight}
      </div>
    </div>
    <div className={props.computedStyles.row}>{props.children}</div>
  </div>
);

TabHeader.propTypes = {
  title: PropTypes.node.isRequired,
  contentRight: PropTypes.node,
  children: PropTypes.node,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired,
    contentRight: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired
  }).isRequired
};

TabHeader.defaultProps = {
  contentRight: null,
  children: null
};

export default withStyles(stylesGenerator)(TabHeader);
