/* eslint no-underscore-dangle:0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'Common/components/Form';

const FileForm = ({ computedStyles, file, isEditing, onChange, tags }) => {
  const handleChange = e => onChange(file, e.target.name, e.target.value);
  return isEditing ? (
    <div className={computedStyles.someContainer}>
      <div className={computedStyles.listItemColumn}>
        <label htmlFor={'tags-1'} className={computedStyles.editLabel}>
          Tags
        </label>
        <Select
          key={'tags-1'}
          className={computedStyles.filter}
          options={tags}
          value={file.tags || []}
          onChange={value => onChange(file, 'tags', value)}
          multi
        />
      </div>
      <div key="comments" className={computedStyles.listItemColumn}>
        <label
          htmlFor={`comments-${file._id}`}
          className={computedStyles.editLabel}
        >
          Comments
        </label>
        <textarea
          id={`comment-${file._id}`}
          name="comment"
          onChange={handleChange}
          value={file.comment || ''}
          className={computedStyles.textInput}
        />
      </div>
    </div>
  ) : null;
};

FileForm.propTypes = {
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  computedStyles: PropTypes.objectOf(PropTypes.object).isRequired,
  file: PropTypes.shape({
    _id: PropTypes.string, // see `/Common/utils/attachments` for more info
    id: PropTypes.string,
    comments: PropTypes.string
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired
};

FileForm.defaultProps = {
  isEditing: false
};

export default FileForm;
