/* eslint global-require:0 */

/**
 * This file runs before any other code within the project,
 * including dev tools, hot reloading overlays, etc!
 */

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

if (typeof Array.prototype.includes !== 'function') {
  require('array-includes').shim();
}

// fetch() polyfill for making API calls.
if (typeof fetch !== 'function') {
  require('whatwg-fetch');
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

require('babel-polyfill');
