import { CASE_SERVICE_AUDIT_API } from 'config/values';

const sendAckEmail = (caseId, emailIds, onSuccess, onFailure) => {
  // eslint-disable-next-line no-undef
  const url = new URL(`${CASE_SERVICE_AUDIT_API.uri}/sendAckEmail/${caseId}`);
  return fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify({ emailIds }),
    headers: {
      'x-api-key': CASE_SERVICE_AUDIT_API.apiKey,
      'Content-Type': 'application/json'
    }
  }).then(onSuccess, onFailure);
};

export default sendAckEmail;
