import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  footer: generateCSS({
    padding: '0 10px',
    ' span': {
      fontSize: '12px',
      color: theme.fonts.inputLabel.color,
      textTransform: 'uppercase'
    }
  })
});

export default stylesGenerator;
