/* eslint react/forbid-prop-types:0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import { version } from 'package.json';
import { CMS_PROP_TYPES } from 'Common/constants';
import withStyles from 'Common/components/Form/withStyles';
import styleGenerator from './styles';

class MetaInformation extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired
    }).isRequired,
    schemas: PropTypes.objectOf(CMS_PROP_TYPES.schema).isRequired
  };

  formatMetaSchemaInformation = schemaProps =>
    JSON.stringify({
      id: schemaProps.id,
      currentRevision: schemaProps.currentRevision,
      lastUpdatedUserId: schemaProps.lastUpdatedUserId,
      lastUpdatedUserName: schemaProps.lastUpdatedUserName
    });

  renderSchemaRevision = schemaProps =>
    schemaProps.currentRevision
      ? `${this.formatMetaSchemaInformation(schemaProps)}\n`
      : null;

  render() {
    const { computedStyles, schemas } = this.props;

    return (
      <div id="meta" className={computedStyles.base}>
        <div data-id="meta-schema-information">
          {map(schemas, this.renderSchemaRevision)}
        </div>
        {version}
      </div>
    );
  }
}

export default withStyles(styleGenerator)(MetaInformation);
