import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    minWidth: '350px',
    height: 'auto'
  }),

  table: generateCSS({
    margin: '30px auto',
    width: '1100px',
    tableLayout: 'fixed',
    borderCollapse: 'collapse'
  }),

  tableWrapper: generateCSS({
    overflow: 'auto'
  }),

  tr: generateCSS({
    height: '40px',
    '> td': {
      padding: '4px',
      borderBottom: `solid 1px ${theme.colors.tableResultsBorder}`,
      wordWrap: 'break-word'
    }
  }),

  headerSectionInitial: generateCSS({
    marginBottom: '-20px'
  }),

  headerSection: generateCSS({
    backgroundColor: theme.colors.background,
    marginRight: '-20px',
    marginLeft: '-20px',
    marginTop: '-20px',
    padding: '20px',
    marginBottom: '-20px'
  }),

  actionHeader: generateCSS({
    backgroundColor: theme.colors.background,
    width: '151px'
  }),

  protocolNumberHeader: generateCSS({
    backgroundColor: theme.colors.tableHeader,
    borderRight: `solid 1px ${theme.colors.base}`
  }),

  protocolNumberSubHeader: generateCSS({
    backgroundColor: theme.colors.tableHeader,
    textAlign: 'left'
  }),

  protocolNumberResults: generateCSS({
    backgroundColor: theme.colors.tableResults,
    width: 'auto'
  }),

  inputElement: generateCSS({
    display: 'inline-block',
    width: '50%'
  })
});

export default stylesGenerator;
