import { CASE_SERVICES_API } from 'config/values';
import { saveAs } from 'file-saver';

/**
 *
 * @param {function} fn
 * @param {function} validate
 * @param {integer} interval - number of milliseconds to wait before calling function again
 * @param {integer} maxAttempts
 */

/* eslint-disable */
const pollForBulkExportReady = async (
  fn,
  validate,
  interval,
  maxAttempts,
  exportRequestId
) => {
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    const result = await fn(exportRequestId);
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

const isBulkExportDone = response => {
  return response && response.responseStatus === 'DONE';
};

const checkBulkExportStatus = async exportRequestId => {
  const exportCompleteUrl = new URL(
    `${CASE_SERVICES_API.uri}/getBulkCaseExportStatus/${exportRequestId}`
  );
  return fetch(exportCompleteUrl.toString(), {
    method: 'GET',
    headers: {
      'x-api-key': CASE_SERVICES_API.apiKey,
      'Content-Type': 'application/json'
    }
  }).then(data => data);
};

const downloadBulkExport = exportRequestId => {
  const downloadFileUrl = new URL(
    `${CASE_SERVICES_API.uri}/downloadExport/${exportRequestId}`
  );
  fetch(downloadFileUrl.toString(), {
    method: 'GET',
    headers: {
      'x-api-key': CASE_SERVICES_API.apiKey,
      'Content-Type': 'application/json'
    }
  }).then(response =>
    response.blob().then(data => {
      saveAs(data, `bulk_export_${exportRequestId}.zip`);
    })
  );
};

/**
 * @param {array} caseIdArr
 * @param {*} onSuccess
 * @param {*} onFailure
 */
const requestBulkCaseExport = async (
  caseIdArr,
  includeSourceDocs = false,
  onSuccess,
  onFailure
) => {
  const url = new URL(`${CASE_SERVICES_API.uri}/caseExport`);
  const includeSourceDocsParam = includeSourceDocs === true ? 'true' : 'false';
  const params = {
    includeSourceDocuments: includeSourceDocsParam,
    exportType: 'BULK',
    caseIdArr: caseIdArr
  };
  try {
    const response = await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'x-api-key': CASE_SERVICES_API.apiKey,
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();
    if (json && json.exportRequestId) {
      const exportRequestId = json.exportRequestId;
      pollForBulkExportReady(
        checkBulkExportStatus,
        isBulkExportDone,
        5000,
        20,
        exportRequestId
      )
        .then(response => response.json())
        .then(() => {
          downloadBulkExport(exportRequestId);
          onSuccess();
        })
        .catch(error => {
          onFailure(error);
        });
    }
  } catch (error) {
    onFailure(error);
  }
};

/* eslint-enable */
export default requestBulkCaseExport;
