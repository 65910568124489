import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { SCHEMA_ID_TASKS } from 'Common/constants';
import * as SearchBarActions from 'Common/ducks/searchBar';
import * as SchemaActions from 'Common/ducks/schema';
import * as UserViewActions from 'Common/ducks/userview';
import * as CaseActions from 'CreateCase/ducks';
import * as NavigationActions from 'Navigation/ducks';
import * as TasksActions from 'Tasks/ducks';
import TasksController from './controller';

const mapStateToProps = ({
  routerReducer,
  tasksReducer,
  schemaReducer,
  searchBarReducer,
  navigationReducer,
  commonReducer,
  userViewReducer
}) => ({
  fragments: schemaReducer.fragments,
  schema: schemaReducer.schemas[SCHEMA_ID_TASKS],
  session: commonReducer.jwt,
  location: routerReducer.location,
  tacticalData: schemaReducer.tacticalData,
  isFetchingSchema: schemaReducer.isFetchingSchema,
  isFetchingTacticalData: schemaReducer.isFetchingTacticalData,
  model: tasksReducer.model,
  loadedNextPage: tasksReducer.loadedNextPage,
  taskStatus: tasksReducer.taskStatus,
  isSavingTask: tasksReducer.isSavingTask,
  clientWidth: navigationReducer.clientWidth,
  searchBarQuery: searchBarReducer.query,
  filters: searchBarReducer.filters,
  sortBy: searchBarReducer.sortBy,
  pageNumber: searchBarReducer.pageNumber,
  case: tasksReducer.case,
  lastUpdate: tasksReducer.lastUpdate,
  isFilteringTasks: tasksReducer.isFilteringTasks,
  editTaskCount: tasksReducer.editTaskCount,
  selectedItemsToAssignOrArchive: tasksReducer.selectedItemsToAssignOrArchive,
  areAllCasesSelected: tasksReducer.areAllCasesSelected,
  masterCaseUserViewColumns: userViewReducer.userView,
  inProgressView: userViewReducer.inProgress,
  isAssigningTask: tasksReducer.isAssigningTask
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...TasksActions,
    ...SchemaActions,
    ...CaseActions,
    ...SearchBarActions,
    ...NavigationActions,
    ...UserViewActions,
    push
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksController);
