import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { openEmailClient } from 'Common/utils';
import { SimpleButton, TextInput, TextArea } from 'Common/components/Form';
import { EMAIL_REGEX, EMAILS_REGEX } from 'Common/components/Form/constants';
import { modalStyles } from 'Common/components/withModal';
import Notifier from 'Common/components/Notifier';
import sendAckEmail from 'api/rest/sendAckEmail';

class GenerateEmailModal extends PureComponent {
  static propTypes = {
    recipient: PropTypes.string.isRequired, // Note: For this field to update immediately, you may need to pass the updated value before updating state/redux
    masterCaseId: PropTypes.string.isRequired,
    onRecipientChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    ack: PropTypes.bool
  };

  static defaultProps = {
    onConfirm: () => {},
    ack: false
  };

  handleGenerateEmailConfirm = ack => {
    if (!ack) {
      const { recipient, masterCaseId, onConfirm } = this.props;
      const subject = `[ENCRYPT] ${masterCaseId}`;

      // "Open Outlook"
      openEmailClient(recipient, subject);
      onConfirm();
    } else {
      const { recipient, masterCaseId, onConfirm } = this.props;

      // send request
      sendAckEmail(
        masterCaseId,
        recipient,
        () => {
          Notifier.show({
            message: `Email successfully sent to ${recipient}`,
            intent: Notifier.SUCCESS
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
        () =>
          Notifier.show({
            message:
              'Something went wrong. Please try again checking for proper formatting or contact the help desk if this problem persists.',
            intent: Notifier.DANGER
          })
      );
      Notifier.show({
        message: `Sending email to ${recipient}...`,
        intent: Notifier.PRIMARY
      });
      // closes modal
      onConfirm();
    }
  };

  sanitizeInput = () => {
    const { recipient, ack } = this.props;
    if (ack) {
      return !EMAILS_REGEX.test(recipient) || recipient === '';
    }
    // original behavior
    return !EMAIL_REGEX.test(recipient) || recipient === '';
  };

  render = () => {
    const { recipient, onRecipientChange, onCancel, ack } = this.props;
    return (
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        <span className={modalStyles.title}>
          {ack ? 'Generate Acknowledgement Email' : 'Generate Email'}
        </span>
        {ack ? (
          <TextArea
            label="Recipient Email(s)"
            value={recipient}
            required
            onChange={onRecipientChange}
            locale="US"
          />
        ) : (
          <TextInput
            label="Recipient Email"
            value={recipient}
            required
            onChange={onRecipientChange}
            locale="US"
          />
        )}
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={onCancel}>Cancel</SimpleButton>
          <SimpleButton
            onClick={() => this.handleGenerateEmailConfirm(ack)}
            primary
            disabled={this.sanitizeInput()}
          >
            Generate Email
          </SimpleButton>
        </div>
      </div>
    );
  };
}

export default GenerateEmailModal;
