import { generateCSS } from '../index';

/*
 * Note that the frosted overlay effect is achieved by NavigationController
 * setting the .blur selector on all child elements not contained within .modal
 */
const stylesGenerator = (theme, { primary, disabled }) => {
  const primaryStyles = {
    color: theme.colors.base,
    fontWeight: '300',
    fontSize: '14px',
    textTransform: 'uppercase',
    border: `1px solid ${theme.colors.brand}`,
    background: theme.colors.brand
  };
  const secondaryStyles = {
    color: theme.colors.brand,
    fontWeight: '600',
    fontSize: '16px',
    border: `1px solid ${theme.colors.base}`,
    background: theme.colors.base
  };

  const disabledStyles = {
    cursor: 'not-allowed',
    border: `1px solid ${theme.colors.base}`,
    background: theme.colors.primary
  };

  return {
    base: generateCSS({
      minWidth: '190px',
      cursor: 'pointer',
      padding: '7px 10px 6px',
      fontFamily: theme.fonts.default.fontFamily,
      ...(primary ? primaryStyles : secondaryStyles),
      ...(disabled ? disabledStyles : null)
    })
  };
};

export default stylesGenerator;
