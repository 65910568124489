import { some } from 'lodash';
import { getOrElse } from 'Common/utils';

export const CASE_STATUS_NEW = 'NEW';
export const CASE_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const CASE_STATUS_ARCHIVED = 'ARCHIVED';
export const CASE_STATUS_COMPLETED = 'COMPLETED';
export const STATUSES_MAP = {
  [CASE_STATUS_NEW]: 'New',
  [CASE_STATUS_IN_PROGRESS]: 'In progress',
  [CASE_STATUS_COMPLETED]: 'Complete',
  [CASE_STATUS_ARCHIVED]: 'Archived'
};

const REACTIONS_KEY = 'subcases.adverseEvent.reactions.reaction';
export const SERIOUSNESS_INDICATOR_MAP = {
  serious: {
    label: 'S',
    process: trilogyCase =>
      getOrElse(
        trilogyCase,
        'subcases.adverseEvent.aerinfo.safety.seriousness'
      ) === 'serious'
  },
  lifeThreat: {
    label: 'L',
    process: trilogyCase => {
      const reactions = getOrElse(trilogyCase, REACTIONS_KEY, []);
      return some(reactions, reaction =>
        getOrElse(reaction, 'serious_criteria.life_threat', false)
      );
    }
  },
  death: {
    label: 'D',
    process: trilogyCase => {
      const reactions = getOrElse(trilogyCase, REACTIONS_KEY, []);
      return some(reactions, reaction =>
        getOrElse(reaction, 'serious_criteria.death', false)
      );
    }
  },
  durationOutcome: {
    label: 'D',
    statePath: /subcases\.adverseEvent\.reactions\.reaction\[(\d+)\]\.duration\[0\]\.duration_outcome/g,
    process: trilogyCase => {
      const reactions = getOrElse(trilogyCase, REACTIONS_KEY, []);
      return some(
        reactions,
        reaction =>
          getOrElse(
            reaction,
            'duration[0].duration_outcome',
            ''
          ).toLowerCase() === 'death'
      );
    }
  }
};

export const ONE_DAY_MS = 1000 * 60 * 60 * 24;

export const DATE_FORMAT = 'DD MMM YYYY';

export const DATE_FORMAT_HOURS = 'DD MMM YYYY [at] HH:mm';
export const API_DATE_FORMAT = 'YYYY-MM-DD'; // DO NOT CHANGE! Must be ISO string supporting partial dates.

export const NOT_SPECIFIED = 'Not Specified';
export const N_A = 'N/A';

export const SUB_CASE_TYPES_MAP = {
  ae: 'adverse_event',
  mi: 'medical_inquiry',
  pq: 'product_quality'
};

export const TACTICAL_TYPE_MAP = {
  ae: 'adverseEvent',
  mi: 'medicalInfo',
  pq: 'productQuality',
  mc: 'masterCase'
};

// TODO ACFE-3478 Standardized property key formats
export const CC_SUB_CASE_TYPES_MAP = {
  ae: 'adverseEvent',
  mi: 'medicalInfo',
  pq: 'productQuality'
};

export const CC_SUB_CASE_DEFAULTS = {
  ae: { aerinfo: { safety: { submission_due_date: null } } },
  pq: {},
  mi: {}
};

export const PRODUCTS_MAP = {
  ae: 'product_section.aeproducts[0].product_summary[0].suspect_product',
  aeOther: 'product_section.aeproducts[0].product_summary[0].other_product',
  pq: 'pqproduct.products[0].details[0].marketedName',
  mi: 'request[0].productName',
  mc: 'tracking.productName'
};

export const PRODUCTS_MAP_FULL_PATH = {
  ae: `subcases.${CC_SUB_CASE_TYPES_MAP.ae}.${PRODUCTS_MAP.ae}`,
  pq: `subcases.${CC_SUB_CASE_TYPES_MAP.pq}.${PRODUCTS_MAP.pq}`,
  mi: `subcases.${CC_SUB_CASE_TYPES_MAP.mi}.${PRODUCTS_MAP.mi}`,
  mc: PRODUCTS_MAP.mc
};

export const PRODUCTS_MAP_FULL_PATH_ALTERNATE = {
  ae: `subcases.${CC_SUB_CASE_TYPES_MAP.ae}.${PRODUCTS_MAP.aeOther}`
};

export const MODAL_ACTIONS_ALIAS = 'modalActions';

export const DOCUMENT_TITLE_MAP = {
  undefined: 'Master Case Queue',
  ae: 'Adverse Event Queue',
  mi: 'Medical Inquiry Queue',
  pq: 'Product Complaint Queue',
  ae_page: 'Adverse Event',
  mi_page: 'Medical Inquiry',
  pq_page: 'Product Complaint',
  new: 'Cases to Review Queue',
  yours: 'Your Cases Queue',
  search: 'Search Cases',
  duplicate: 'Duplicate Search',
  tasks: 'Tasks',
  allTasks: 'All Tasks',
  yourTasks: 'Your Tasks',
  review: 'Master Case Dashboard'
};

// Duplicate Search has some query parameters that conditionally display elements
export const DUPE_WITH_CONTACTS = 'showContacts';
export const DUPE_WITH_NO_MATCH = 'showNoMatch';
export const PARENT_CASE = 'parentCase';
export const SOURCE_LOCATION = 'sourceLocation';
export const FROM_CREATE_CASE = 'fromCreateCase';
export const PARENT_CASE_IS_EMAIL = 'createdByEmail';
export const EXTERNAL_SOURCE = 'externalSource';
export const OPEN_DUPE_SEARCH = 'openDuplicateSearch';

export const PAGE_STATUS = {
  INIT: 'INIT', // page's initial state
  LOADING: 'LOADING', // fetching data or processing
  LOADED: 'LOADED', // successfully loaded
  FAILED: 'FAILED', // failed to load
  SAVING: 'SAVING'
};

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';
export const SORT_ORDERS = [SORT_ASC, SORT_DESC];
export const DUMMY_USER_GROUP_PATTERN = 'DU-';
export const BULK_ASSIGN_COUNT = 10;
export const BULK_ASSIGN_TIMEOUT_MILLISECONDS = 5000;

// THESE ARE USED FOR SHARED INFO TAB RECONCILIATION FLAG
export const SHARED_INFO_FIELDS = [
  'subcases.adverseEvent.aerinfo.safety.source',
  'subcases.adverseEvent.reactions.reaction',
  'reaction_seriousness',
  'serious_criteria.death',
  'serious_criteria.life_threat',
  'serious_criteria.hospital',
  'serious_criteria.prolonged_hospital',
  'serious_criteria.disabling_incapacitating',
  'serious_criteria.cong_birth_anomaly',
  'serious_criteria.other',
  'duration[0].duration_outcome',
  'subcases.adverseEvent.product_section.aeproducts',
  'product_summary[0].suspect_product',
  'product_summary[0].local_trade_name',
  'product_summary[0].other_product',
  'product_indication',
  'drug_indications',
  'product_therapy',
  'batch_lot_no',
  'availability.unknown',
  'availability.unavailable',
  'reason',
  'additional_comments',
  'related_to_product_complaint',
  'summary.narrative.categories.product_quality',
  'subcases.productQuality.description',
  'subcases.productQuality.event_date',
  'subcases.productQuality.pqproduct.products',
  'details',
  'marketedName',
  'lotNumber',
  'availability',
  'reason',
  'related_to_adverse_event',
  'summary.narrative.categories.medical_inquiry',
  'availability.unknown',
  'documents.attachments',
  'subcases.adverseEvent.death_details.date',
  'patient.patient[0].dod'
];

export * from './keyCodes';
export * from './propTypes';
export * from './services';
export * from './state';
export * from './colorCodes';
