import { generateCSS } from 'Common/components/Form';

const stylesGenerator = ({ colors }, { editingLocked }) => ({
  base: generateCSS({
    position: 'absolute',
    right: '0',
    top: '0',
    width: '37px',
    height: '37px',
    border: 'none',
    borderRadius: '0',
    backgroundColor: colors.background,
    outline: 'none',
    cursor: editingLocked ? 'arrow' : 'pointer',
    opacity: editingLocked ? '0.25' : '1',
    ':hover': {
      backgroundColor: colors.quaternary
    },
    ':focus': {
      border: `1px solid ${colors.quaternary}`
    }
  })
});

export default stylesGenerator;
