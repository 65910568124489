import { isEmpty } from 'lodash';
import {
  generateCSS,
  genErrorStyles,
  genInvalidBoxShadow,
  genLabelStyles,
  genCaret
} from '../index';

// react-select doesn't seem to take on Glamor styles applied directly to it
const stylesGenerator = (theme, props) => {
  const errorStyles = genErrorStyles(theme);
  const invalidBoxShadow = genInvalidBoxShadow(theme);
  const labelStyles = genLabelStyles(theme, props);

  return {
    base: generateCSS({
      position: 'relative',
      ...props.styles,
      '> .Select': {
        // '&.is-open': { // Use to apply custom is-open styles to the dropdown arrow
        //   '> .Select-control': {
        //     '> .Select-arrow-zone': {
        //       '> .Select-arrow': {
        //         transform: 'rotate(0.5turn)'
        //       }
        //     }
        //   }
        // },
        border: 'none',
        '> .Select-control': {
          '> .Select-multi-value-wrapper': {
            '> .Select-placeholder': {
              padding: '0 15px',
              color: theme.colors.quinary,
              fontSize: '18px'
            },
            '> .Select-input': {
              ...theme.fonts.input,
              height: '30px',

              '> input': {
                padding: '7px 0',
                ...theme.fonts.input
              }
            }
          },
          '> .Select-clear-zone': {
            '> .Select-clear': {
              display: 'none'
            }
          },
          height: '30px',
          boxShadow: `inset 0 0 0 2px ${theme.colors.primary}`,
          ...(isEmpty(props.errors) ? {} : invalidBoxShadow),
          border: 'none',
          borderRadius: '0',
          ...theme.fonts.input,
          '> .Select-arrow-zone': {
            '> .Select-arrow': {
              position: 'absolute',
              top: '10px',
              right: '18px',
              borderColor: `${theme.colors.tertiary} transparent transparent`,
              borderWidth: '10px 7px 1px'
            }
          }
        },
        '> .Select-menu-outer': {
          padding: '0 1px 0 0',
          zIndex: '10',
          top: '38px',
          border: 'none',
          borderRadius: '0',
          ...genCaret({
            border: theme.colors.primary,
            arrow: theme.colors.base
          }),
          '> .Select-menu': {
            border: `solid 2px ${theme.colors.primary}`,
            ...theme.fonts.input,
            '> .is-focused': {
              color: theme.colors.base,
              background: theme.colors.active,
              borderRadius: '0'
            }
          }
        },
        '&.is-focused': {
          '> .Select-control': {
            boxShadow: `inset 0 0 0 2px ${theme.colors.tertiary}`
          },
          '> .Select-menu-outer': {
            boxShadow: 'none'
          }
        },
        ' div.Select-menu-outer div.Select-menu div.Select-option:first-child span': {
          minHeight: '20px',
          display: 'block'
        }
      }
    }),

    label: generateCSS({
      ...theme.fonts.inputLabel
    }),

    helpText: generateCSS({
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
