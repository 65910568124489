/* eslint react/prefer-stateless-function:0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

/**
 * Factory for creating a HOC (Higher-Order Component) with computed styles,
 * for wrapping components with Glamor styling.
 *
 * @param {function} WrappedComponent
 * @returns {Component} - Original WrappedComponent with styles props
 */
const withStyles = styleGenerator => WrappedComponent =>
  class StyledComponent extends PureComponent {
    static contextTypes = {
      theme: PropTypes.object
    };

    static propTypes = {
      layout: PropTypes.object.isRequired // eslint-disable-line
    };

    static defaultProps = {
      layout: {},
      component: undefined
    };

    render() {
      const computedStyles = styleGenerator(this.context.theme, this.props);

      return (
        <WrappedComponent {...this.props} computedStyles={computedStyles} />
      );
    }
  };

export default withStyles;
