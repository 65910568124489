import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    backgroundColor: theme.colors.background
  }),
  messageContainer: generateCSS({
    padding: '2em',
    margin: '10% 127px 50%',
    backgroundColor: theme.colors.base,
    ' h1': {
      display: 'inline-block',
      fontFamily: 'Lato',
      fontSize: '200px',
      fontWeight: '900',
      letterSpacing: '20.8px',
      textAlign: 'left',
      color: theme.colors.brand
    },
    ' p': {
      fontFamily: 'SackersGothicStd',
      fontSize: '20px',
      fontWeight: '200',
      float: 'right',
      letterSpacing: '0.1em',
      lineHeight: '1.45em',
      textAlign: 'left',
      color: theme.colors.secondary,
      width: '47%'
    }
  })
});

export default stylesGenerator;
