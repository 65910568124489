import { handleActions, createAction } from 'redux-actions';
import { store } from 'config';
import { merge, reduce, set, isPlainObject, chunk } from 'lodash';
import {
  EMIT_CLEAN_SLATE,
  SUB_CASE_TYPES_MAP,
  BULK_ASSIGN_TIMEOUT_MILLISECONDS
} from 'Common/constants';

import {
  getPageCategoryFilter,
  getAssigneeFilter,
  getSubcaseFilter,
  getStatusFilters,
  getSubmittedFilters,
  getReconciliationFilters,
  getLiteFilters,
  getUserBasedFilters,
  removeEmpty
} from 'Queue/utils';

import submitCaseSearch from 'api/graphql/submitCaseSearch';
import submitCaseCSVSearch from 'api/graphql/submitCaseCSVSearch';
import queueCase from 'api/graphql/queries/case/queueCase';
// import queueCaseSpecificFields from 'api/graphql/queries/case/queueCaseSpecificFields';
import caseResponseFormatter from 'api/graphql/formatter/caseResponse';
import fetchTasksCounts from 'api/rest/fetchTasksCounts';
import fetchAssignCaseToUser from 'api/rest/fetchAssignCaseToUser';
import fetchBulkArchiveCase from 'api/rest/fetchBulkArchiveCase';
import { DEFAULT_FILTER_STATE } from '../constants';

const EMIT_FETCH_QUEUE = 'EMIT_FETCH_QUEUE';
const EMIT_FETCH_QUEUE_SUCCESS = 'EMIT_FETCH_QUEUE_SUCCESS';
const EMIT_FETCH_QUEUE_FAILURE = 'EMIT_FETCH_QUEUE_FAILURE';
const EMIT_FETCH_TASKS_COUNTS = 'EMIT_FETCH_TASKS_COUNTS';
const EMIT_FETCH_TASKS_COUNTS_SUCCESS = 'EMIT_FETCH_TASKS_COUNTS_SUCCESS';
const EMIT_FETCH_TASKS_COUNTS_FAILURE = 'EMIT_FETCH_TASKS_COUNTS_FAILURE';
const EMIT_UPDATE_QUEUE_SORT_BY = 'EMIT_UPDATE_QUEUE_SORT_BY';
const EMIT_UPDATE_FILTER = 'EMIT_UPDATE_FILTER';
const EMIT_UPDATE_QUEUE_CASE_SELECTION = 'EMIT_UPDATE_QUEUE_CASE_SELECTION';
const EMIT_UPDATE_ASSIGN_CASE_TO_USER = 'EMIT_UPDATE_ASSIGN_CASE_TO_USER';
const EMIT_UPDATE_ASSIGN_CASE_TO_USER_SUCCESS =
  'EMIT_UPDATE_ASSIGN_CASE_TO_USER_SUCCESS';
const EMIT_UPDATE_ASSIGN_CASE_TO_USER_FAILURE =
  'EMIT_UPDATE_ASSIGN_CASE_TO_USER_FAILURE';

export const emitUpdateQueueCaseSelection = createAction(
  EMIT_UPDATE_QUEUE_CASE_SELECTION,
  (selectedItemsToAssignOrArchive, areAllCasesSelected) => ({
    selectedItemsToAssignOrArchive,
    areAllCasesSelected
  })
);

export const emitUpdateAssignCaseToUserSuccess = createAction(
  EMIT_UPDATE_ASSIGN_CASE_TO_USER_SUCCESS
);
const emitUpdateAssignCaseToUserFailure = createAction(
  EMIT_UPDATE_ASSIGN_CASE_TO_USER_FAILURE
);

export const emitFetchQueue = createAction(
  EMIT_FETCH_QUEUE,
  (
    pageType,
    filters,
    pageNumber = 0,
    size = 100,
    popup = false,
    desiredColumns
  ) => ({
    pageType,
    filters,
    pageNumber,
    size,
    popup,
    desiredColumns
  })
);

export const emitFetchCsvQueue = payload => (dispatch, getState) => {
  const { queueReducer, schemaReducer } = getState();
  const { sortBy } = queueReducer;
  const pageKey = SUB_CASE_TYPES_MAP[payload.pageType];

  const subcaseFilters = {
    [SUB_CASE_TYPES_MAP.ae]: payload.filters.AE,
    [SUB_CASE_TYPES_MAP.mi]: payload.filters.MI,
    [SUB_CASE_TYPES_MAP.pq]: payload.filters.PQ
  };

  // if pageKey is undefined (mastercase queue) we want the values of the subcase filters...
  // otherwise we want the page category filter
  const categoriesFilter = pageKey
    ? getPageCategoryFilter(pageKey)
    : getSubcaseFilter(subcaseFilters);

  const filters = merge(
    getAssigneeFilter(payload),
    // New dynamic filters are implemented , so removing existing show/hide filters.
    // getDropdownFilters(payload),
    getStatusFilters(payload),
    getSubmittedFilters(payload),
    getReconciliationFilters(payload),
    getLiteFilters(pageKey),
    getUserBasedFilters(payload)
  );

  const reduceObj = (acc, val, key) => set(acc, key, val);
  const supreSortBy = reduce(sortBy, reduceObj, {});

  const options = {
    // fields: payload.desiredColumns,
    query: queueCase,
    q: {
      ...categoriesFilter,
      ...filters,
      sortBy: {
        ...supreSortBy
      }
    },
    from: payload.pageNumber * payload.size,
    size: payload.size,
    schemaReducer
  };
  return submitCaseCSVSearch(options);
};

export const emitUpdateFilter = createAction(
  EMIT_UPDATE_FILTER,
  (pageType, filters) => ({
    pageType,
    filters
  })
);

// console.log('data', data);

export const emitFetchQueueSuccess = createAction(
  EMIT_FETCH_QUEUE_SUCCESS,
  queue => ({ queue })
);

export const emitFetchQueueFailure = createAction(EMIT_FETCH_QUEUE_FAILURE);

export const emitFetchTasksCounts = createAction(
  EMIT_FETCH_TASKS_COUNTS,
  showSubcases => ({ showSubcases })
);

export const emitFetchTasksCountsSuccess = createAction(
  EMIT_FETCH_TASKS_COUNTS_SUCCESS,
  tasksCounts => ({ tasksCounts })
);

export const emitFetchTasksCountsFailure = createAction(
  EMIT_FETCH_TASKS_COUNTS_FAILURE
);

export const emitUpdateQueueSortBy = createAction(
  EMIT_UPDATE_QUEUE_SORT_BY,
  (sortPath, sortDirection) => ({ sortPath, sortDirection })
);

export const emitUpdateAssignCaseToUser = (
  actionType = '',
  assigneeId = '',
  assigneeName = '',
  selectedItemsToAssignOrArchive = [],
  pageType = ''
) => (dispatch, getState) => {
  dispatch(createAction(EMIT_UPDATE_ASSIGN_CASE_TO_USER)());
  const { filters, pageNumber } = getState().queueReducer;
  const chunkedArrayToAssign = chunk(selectedItemsToAssignOrArchive, 50);
  const multipleAssignServices = chunkedArrayToAssign.map(elementsToAssign =>
    fetchAssignCaseToUser(
      actionType,
      assigneeId,
      assigneeName,
      elementsToAssign
    )
  );
  return Promise.all(multipleAssignServices)
    .then(() => {
      setTimeout(() => {
        dispatch(emitUpdateAssignCaseToUserSuccess());
        dispatch(emitFetchQueue(pageType, filters, pageNumber));
      }, BULK_ASSIGN_TIMEOUT_MILLISECONDS);
    })
    .catch(() => {
      dispatch(emitUpdateAssignCaseToUserFailure());
    });
};

export const emitUpdateBulkArchive = (
  actionType = '',
  archiveComments = '',
  archiveReason = '',
  selectedItemsToAssignOrArchive = [],
  page = '',
  assigneeId = '',
  assigneeName = ''
) => (dispatch, getState) => {
  dispatch(createAction(EMIT_UPDATE_ASSIGN_CASE_TO_USER)());
  const { filters, pageNumber } = getState().queueReducer;
  const chunkedArrayBulkArchive = chunk(selectedItemsToAssignOrArchive, 50);
  const multipleBulkArchiveServices = chunkedArrayBulkArchive.map(
    elementsToArchive =>
      fetchBulkArchiveCase(
        actionType,
        archiveComments,
        archiveReason,
        elementsToArchive,
        assigneeId,
        assigneeName
      )
  );
  return Promise.all(multipleBulkArchiveServices)
    .then(() => {
      dispatch(emitUpdateAssignCaseToUserSuccess());
      dispatch(emitFetchQueue(page, filters, pageNumber));
    })
    .catch(() => {
      dispatch(emitUpdateAssignCaseToUserFailure());
    });
};

const initialState = {
  queue: [],
  tasksCounts: [],
  includeArchived: false,
  filters: DEFAULT_FILTER_STATE,
  sortBy: {
    id: 'desc'
  },
  isLoading: false,
  totalResults: 0,
  pageNumber: 0,
  areAllCasesSelected: false,
  selectedItemsToAssignOrArchive: []
};

const handlers = {
  [EMIT_CLEAN_SLATE]: () => ({ ...initialState }),

  [EMIT_UPDATE_FILTER]: (state, { payload }) => ({
    ...state,
    filters: payload.filters
  }),

  [EMIT_FETCH_QUEUE]: (state, { payload }) => {
    const { sortBy } = state;
    const pageKey = SUB_CASE_TYPES_MAP[payload.pageType];

    const subcaseFilters = {
      [SUB_CASE_TYPES_MAP.ae]: payload.filters.AE,
      [SUB_CASE_TYPES_MAP.mi]: payload.filters.MI,
      [SUB_CASE_TYPES_MAP.pq]: payload.filters.PQ
    };

    // if pageKey is undefined (mastercase queue) we want the values of the subcase filters...
    // otherwise we want the page category filter
    const categoriesFilter = pageKey
      ? getPageCategoryFilter(pageKey)
      : getSubcaseFilter(subcaseFilters);

    const filters = merge(
      // New dynamic filters are implemented , so removing existing show/hide filters.
      // getDropdownFilters(payload),
      getAssigneeFilter(payload),
      getStatusFilters(payload),
      getSubmittedFilters(payload),
      getReconciliationFilters(payload),
      getLiteFilters(pageKey),
      getUserBasedFilters(payload)
    );

    removeEmpty(filters);

    const reduceObj = (acc, val, key) => set(acc, key, val);
    const supreSortBy = reduce(sortBy, reduceObj, {});

    if (categoriesFilter && categoriesFilter.summary && filters.summary) {
      filters.summary = {
        ...filters.summary,
        ...categoriesFilter.summary
      };
    }

    const options = {
      query: queueCase,
      q: {
        ...categoriesFilter,
        ...filters,
        sortBy: {
          ...supreSortBy
        }
      },
      from: payload.pageNumber * payload.size,
      size: payload.size
    };

    // TODO TRGY-1319 remove dispatches from reducer
    submitCaseSearch(options, emitFetchQueueSuccess, emitFetchQueueFailure)(
      store.dispatch,
      store.getState
    );

    return {
      ...state,
      filters: payload.filters,
      isLoading: true,
      pageNumber: payload.pageNumber,
      selectedItemsToAssignOrArchive: [],
      areAllCasesSelected: false
    };
  },

  [EMIT_FETCH_QUEUE_SUCCESS]: (state, { payload }) => {
    const { searchCase } = payload.queue;
    const trilogyCases = (searchCase.hits || []).map(caseResponseFormatter);
    return {
      ...state,
      totalResults: searchCase.total,
      queue: trilogyCases,
      isLoading: false
    };
  },

  [EMIT_FETCH_QUEUE_FAILURE]: state => ({
    ...state,
    isLoading: false
  }),

  [EMIT_FETCH_TASKS_COUNTS]: (state, { payload }) => {
    // TODO TRGY-1319 remove dispatches from reducer
    fetchTasksCounts(
      payload.showSubcases,
      emitFetchTasksCountsSuccess,
      emitFetchTasksCountsFailure
    )(store.dispatch);
    return state;
  },

  [EMIT_FETCH_TASKS_COUNTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    tasksCounts: payload.tasksCounts
  }),

  [EMIT_UPDATE_QUEUE_SORT_BY]: (
    state,
    { payload: { sortPath, sortDirection } }
  ) => ({
    ...state,
    sortBy: isPlainObject(sortPath)
      ? sortPath
      : {
          [sortPath]: sortDirection
        }
  }),
  [EMIT_UPDATE_QUEUE_CASE_SELECTION]: (state, { payload }) => ({
    ...state,
    selectedItemsToAssignOrArchive: payload.selectedItemsToAssignOrArchive,
    areAllCasesSelected: payload.areAllCasesSelected
  }),
  [EMIT_UPDATE_ASSIGN_CASE_TO_USER]: state => ({
    ...state,
    isLoading: true,
    selectedItemsToAssignOrArchive: [],
    areAllCasesSelected: false
  }),
  [EMIT_UPDATE_ASSIGN_CASE_TO_USER_SUCCESS]: state => ({
    ...state,
    isLoading: false
  }),
  [EMIT_UPDATE_ASSIGN_CASE_TO_USER_FAILURE]: state => ({
    ...state,
    isLoading: false
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
