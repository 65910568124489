import {
  WHITE,
  GREY,
  LIGHT_GREY,
  ALT_LIGHT_GREY,
  EXTRA_LIGHT_GREY,
  DARK_GREY,
  CHARCOAL_GREY,
  ALT_GREY,
  EXTRA_DARK_GREY,
  BLUE,
  LIGHT_BLUE,
  ALT_BLUE,
  DARK_BLUE,
  ALT_LIGHT_BLUE,
  EXTRA_LIGHT_BLUE,
  RED,
  LIGHT_GREEN,
  LIGHT_GREEN_BLUE,
  TEAL,
  LIGHT_TEAL,
  RED_ERROR,
  BLACK,
  ORANGE
} from 'Common/styles';

const theme = {
  colors: {
    base: WHITE,
    background: ALT_LIGHT_GREY,
    primary: GREY,
    secondary: DARK_GREY,
    tertiary: LIGHT_BLUE,
    quaternary: LIGHT_GREY,
    quinary: EXTRA_LIGHT_GREY,
    senary: EXTRA_DARK_GREY,
    septenary: ALT_GREY,
    active: BLUE,
    selected: LIGHT_BLUE,
    brand: DARK_BLUE,
    altBrand: ALT_BLUE,
    red: RED,
    error: RED_ERROR,
    header: DARK_BLUE,
    auth: LIGHT_GREEN,
    primaryContrast: TEAL,
    secondaryContrast: LIGHT_TEAL,
    black: BLACK,
    charcoal: CHARCOAL_GREY,
    tableHeader: ALT_LIGHT_BLUE,
    tableResults: EXTRA_LIGHT_BLUE,
    tableResultsBorder: LIGHT_GREEN_BLUE,
    orange: ORANGE
  },
  fonts: {
    default: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"'
    },

    input: {
      fontSize: '16px',
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '200',
      color: DARK_GREY,
      '::placeholder': {
        color: EXTRA_LIGHT_GREY,
        fontWeight: '300',
        fontSize: '18px'
      }
    },
    inputLabel: {
      fontSize: '16px',
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '200',
      color: BLACK
    },
    authLink: {
      fontSize: '18px',
      fontFamily: 'Calibri, "Microsoft Sans Serif"'
    },
    validationError: {
      fontSize: '13px'
    },
    coloumnGroupLabel: {
      color: CHARCOAL_GREY,
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '900',
      fontSize: '14px',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    coloumnHeaderLabel: {
      color: CHARCOAL_GREY,
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '500',
      fontSize: '16px',
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    tabelCellLabel: {
      color: CHARCOAL_GREY,
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '300',
      fontSize: '16px',
      textAlign: 'left'
    },
    caseIdLink: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '900',
      fontSize: '16px',
      textAlign: 'left'
    },
    resultsFooterLabel: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontWeight: '300',
      fontSize: '14px'
    },
    tabHeaderTitle: {
      font: '30px SackersGothicStd-Medium',
      fontWeight: '900',
      textTransform: 'uppercase'
    },
    tabTitle: {
      fontFamily: 'SackersGothicStd-Medium',
      fontSize: '12px',
      fontWeight: '300',
      color: DARK_GREY,
      textTransform: 'uppercase'
    },
    sectionTitle: {
      fontFamily: 'SackersGothicStd-Heavy',
      fontSize: '20px',
      color: DARK_BLUE,
      textTransform: 'uppercase'
    },
    inputGroupTitle: {
      fontFamily: 'SackersGothicStd-Heavy',
      fontSize: '16px',
      color: DARK_BLUE,
      textTransform: 'uppercase'
    },
    sectionNavSectionLink: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontSize: 16,
      fontWeight: 'bold',
      color: DARK_GREY,
      textTransform: 'uppercase'
    },
    sectionNavGroupLink: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontSize: 14,
      fontWeight: 300,
      textTransform: 'capitalize',
      color: DARK_GREY
    },
    sectionStatus: {
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontSize: '12px',
      color: DARK_GREY,
      fontWeight: '300'
    },
    helpText: {
      fontSize: '13px',
      color: EXTRA_DARK_GREY
    },
    seriousnessIndicator: {
      color: WHITE,
      fontFamily: 'Calibri, "Microsoft Sans Serif"',
      fontSize: '12px',
      fontWeight: '900'
    }
  }
};

export default theme;
