/* eslint no-underscore-dangle:0, no-console:0, no-param-reassign:0 */
import { ApolloClient, ApolloLink, from, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws/dist/client';

import { isEmpty, some, isNil, uniqueId } from 'lodash';
import { getSession } from './auth';

import { CASE_GRAPHQL_API, TASK_GRAPHQL_API, CASE_SERVICE_WS } from './values';

let wsClientObj;
let subClientObj;

const getNetworkLink = ({ uri, apiKey }) => {
  const apiProps = {
    headers: {
      'x-api-key': apiKey
    }
  };

  return createHttpLink({
    uri,
    ...(apiKey ? apiProps : null)
  });
};

const caseHttpLink = getNetworkLink(CASE_GRAPHQL_API);

const taskHttpLink = getNetworkLink(TASK_GRAPHQL_API);

const wsClient = (successfulConnectionCallback, failedConnectionCallback) => {
  if (!isNil(wsClientObj)) {
    return wsClientObj;
  }
  wsClientObj = new SubscriptionClient(CASE_SERVICE_WS.uri, {
    reconnect: true,
    reconnectionAttempts: 15,
    connectionParams: {
      jwt: getSession().access_token
    }
  });
  wsClientObj.onDisconnected(() => {
    failedConnectionCallback();
  });
  wsClientObj.onConnected(() => {
    successfulConnectionCallback();
  });
  wsClientObj.onReconnected(() => {
    successfulConnectionCallback();
  });
  return wsClientObj;
};

const loggerLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation;
  console.group(operationName);
  console.log(`operationName: ${operationName}`);
  console.log(`variables : ${JSON.stringify(variables)}`);
  console.groupEnd();
  return forward(operation);
});

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    console.log({ operation, response, graphQLErrors, networkError });
  }
);

const cleanUpAffiliates = affiliates => {
  if (!affiliates) {
    return window.affiliateCodes;
  } else if (Array.isArray(affiliates)) {
    return affiliates.map(affiliate => affiliate.toLowerCase());
  }
  return affiliates.toLowerCase();
};

const affiliateMiddleWareLink = new ApolloLink((operation, forward) => {
  const { variables: { q }, query: { definitions }, operationName } = operation;
  const isQuery = some(definitions, {
    kind: 'OperationDefinition',
    operation: 'query'
  });
  const affiliateCodes = window.affiliateCodes;

  if (operationName === 'searchProduct') {
    // do nothing
    // searchProduct currently does not accept affiliate as an input
  } else if (isQuery && !isEmpty(q)) {
    q.affiliate = cleanUpAffiliates(q.affiliate);
  } else if (isQuery) {
    operation.variables.affiliate = affiliateCodes;
  }
  return forward(operation);
});

const caseSubLink = (successfulConnectionCallback, failedConnectionCallback) =>
  new WebSocketLink(
    wsClient(successfulConnectionCallback, failedConnectionCallback)
  );

const normalizer = object => {
  switch (object.__typename) {
    case 'patientOutput':
      return uniqueId(`${object.id}-`);
    case 'contactsOutput':
      return uniqueId(`${object.id}-`);
    case 'linkedCasesOutput':
      return uniqueId(`${object.id}-`);
    default:
      return object.id;
  }
};

const caseCache = new InMemoryCache({ dataIdFromObject: normalizer });

export const client = new ApolloClient({
  link: from([affiliateMiddleWareLink, loggerLink, errorLink, caseHttpLink]),
  cache: caseCache
});
export const subscriptionClient = (
  successfulConnectionCallback,
  failedConnectionCallback
) => {
  if (!isNil(subClientObj)) {
    return subClientObj;
  }
  subClientObj = new ApolloClient({
    link: caseSubLink(successfulConnectionCallback, failedConnectionCallback),
    cache: new InMemoryCache()
  });
  return subClientObj;
};

export const taskClient = new ApolloClient({
  link: from([affiliateMiddleWareLink, loggerLink, errorLink, taskHttpLink]),
  cache: new InMemoryCache()
});
