/* eslint no-console:0 */
import { client } from 'config/apollo';
import getCaseByRevisionQuery from './queries/case/getCaseByRevisionQuery';
import caseResponseFormatter from './formatter/caseResponse';

const submitCaseRevisionQuery = (
  _state,
  masterCaseId,
  revisionNum,
  caseFragment,
  suppressNotifier = false
) => {
  const variables = { id: masterCaseId, revision: revisionNum };
  return client
    .query({
      context: {
        fetchOptions: {
          suppressNotifier
        }
      },
      query: getCaseByRevisionQuery(caseFragment),
      variables,
      fetchPolicy: 'network-only'
    })
    .then(({ data: { createCase, case: masterCase } }) => {
      const trilogyCase = createCase || masterCase;
      const thawed = Object.isFrozen(trilogyCase)
        ? JSON.parse(JSON.stringify(trilogyCase))
        : trilogyCase;
      return caseResponseFormatter(thawed, false);
    });
};

export default submitCaseRevisionQuery;
