import React from 'react';
import PropTypes from 'prop-types';

import edit from 'public/images/edit.svg';
import close from 'public/images/close-icon-1.svg';

import { withStyles } from 'Common/components/Form';
import stylesGenerator from './styles';

const EditButton = props => {
  const {
    computedStyles,
    editingLocked,
    id,
    isEditing,
    onEditToggleClick,
    onEditCancelClick
  } = props;

  const handleClick = e => {
    if (isEditing) {
      onEditCancelClick(e);
    } else {
      onEditToggleClick(e);
    }
  };

  const imgSrc = isEditing ? close : edit;

  return (
    <button
      className={computedStyles.base}
      data-id={`${id}.editTask`}
      onClick={editingLocked ? null : handleClick}
    >
      <img src={imgSrc} />
    </button>
  );
};

EditButton.propTypes = {
  editingLocked: PropTypes.bool,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  isEditing: PropTypes.bool,
  onEditToggleClick: PropTypes.func.isRequired,
  onEditCancelClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
};

EditButton.defaultProps = {
  editingLocked: false,
  isEditing: false
};

export default withStyles(stylesGenerator)(EditButton);
