/* eslint react/jsx-filename-extension:0 */

import React from 'react';
import { createAction, handleActions } from 'redux-actions';

import { EMIT_CLEAN_SLATE } from 'Common/constants';
import ConfirmChangeContent from 'Common/components/withModal/confirmChange';
import ConfirmDeleteContent from 'Common/components/withModal/confirmDelete';

const EMIT_MODAL_CONTENT_UPDATE = 'EMIT_MODAL_CONTENT_UPDATE';
const EMIT_MODAL_CONTENT_CLEAR = 'EMIT_MODAL_CONTENT_CLEAR';
const EMIT_MODAL_CONFIRM_CHANGE = 'EMIT_MODAL_CONFIRM_CHANGE';
const EMIT_MODAL_CONFIRM_DELETE = 'EMIT_MODAL_CONFIRM_DELETE';
const EMIT_LOCK_CASE = 'EMIT_LOCK_CASE';
const EMIT_LOCK_CASE_MANUAL_DISMISS = 'EMIT_LOCK_CASE_MANUAL_DISMISS';
const EMIT_UNLOCK_CASE = 'EMIT_UNLOCK_CASE';
const EMIT_UNLOCK_CASE_COMPLETELY = 'EMIT_UNLOCK_CASE_COMPLETELY';

export const emitModalContentUpdate = createAction(
  EMIT_MODAL_CONTENT_UPDATE,
  (content, withOverflow = false, displayXCloseButton = true) => ({
    content,
    withOverflow,
    displayXCloseButton
  })
);
export const emitModalContentClear = createAction(EMIT_MODAL_CONTENT_CLEAR);

export const emitLockCase = createAction(EMIT_LOCK_CASE);
export const emitUnlockCase = createAction(EMIT_UNLOCK_CASE);

export const emitLockCaseManualDismiss = createAction(
  EMIT_LOCK_CASE_MANUAL_DISMISS
);
export const emitUnlockCaseCompletely = createAction(
  EMIT_UNLOCK_CASE_COMPLETELY
);

export const emitModalConfirmDelete = createAction(
  EMIT_MODAL_CONFIRM_DELETE,
  (label, onConfirm, onDismiss) => ({
    modalContent: (
      <ConfirmDeleteContent
        label={label}
        handleConfirm={onConfirm}
        handleDismiss={onDismiss}
      />
    )
  })
);

export const emitModalConfirmChange = createAction(
  EMIT_MODAL_CONFIRM_CHANGE,
  (title, message, onConfirm, onDismiss) => ({
    modalContent: (
      <ConfirmChangeContent
        title={title}
        message={message}
        handleConfirm={onConfirm}
        handleDismiss={onDismiss}
      />
    )
  })
);

const initialState = {
  modalContent: false,
  modalWithOverflow: false,
  renderXCloseButton: true,
  isCaseLocked: false
};

const handlers = {
  [EMIT_CLEAN_SLATE]: () => ({
    ...initialState
  }),

  [EMIT_MODAL_CONTENT_UPDATE]: (state, action) => ({
    ...state,
    modalContent: action.payload.content,
    modalWithOverflow: action.payload.withOverflow,
    renderXCloseButton: action.payload.displayXCloseButton
  }),

  [EMIT_MODAL_CONTENT_CLEAR]: state => ({
    ...state,
    modalContent: initialState.modalContent,
    modalWithOverflow: false,
    renderXCloseButton: true
  }),

  [EMIT_LOCK_CASE]: state => ({
    ...state,
    isCaseLocked: true
  }),

  [EMIT_UNLOCK_CASE]: state => ({
    ...state,
    isCaseLocked: false
  }),

  [EMIT_LOCK_CASE_MANUAL_DISMISS]: state => ({
    ...state,
    isCaseLockedManualDismiss: true
  }),

  [EMIT_UNLOCK_CASE_COMPLETELY]: state => ({
    ...state,
    isCaseLocked: false,
    isCaseLockedManualDismiss: false
  }),

  [EMIT_MODAL_CONFIRM_CHANGE]: (state, { payload }) => ({
    ...state,
    ...payload
  }),

  [EMIT_MODAL_CONFIRM_DELETE]: (state, { payload }) => ({
    ...state,
    ...payload
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
