import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  noResultsWrapper: generateCSS({
    textAlign: 'center',
    width: '100%',
    marginTop: '10%',
    minHeight: '200px'
  }),

  noResults: generateCSS({
    fontSize: '16px',
    fontFamily: 'Calibri, "Microsoft Sans Serif"',
    paddingTop: '20px'
  })
});

export default stylesGenerator;
