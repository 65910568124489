import gql from 'graphql-tag';

const searchCaseQuery = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;

  return gql`
    query searchCase($q: SearchQuery!, $size: Int, $from: Int) {
      searchCase(q: $q, size: $size, from: $from) {
        total
        status
        hits {
          ...FullCaseWithSubcases
        }
      }
    }
    ${fragment}
  `;
};
export default searchCaseQuery;
