import { get } from 'lodash';

import {
  actionTypes,
  actions as sessionActions
} from '../Common/ducks/session'; // eslint-disable-line

import { authorize, getSession, isLoggedIn, endSession } from './auth';

const { login, start, end, throwError } = sessionActions;

export default store => next => action => {
  const state = store.getState();
  const session = getSession();

  // checking for state first within a sub-store of the common reducer
  // which will be used if other common reducers come down the line.
  // If there is only one reducer exported, the top-level will have auth_token
  const stateSession = get(
    state,
    'commonReducer.sessionReducer',
    state.commonReducer
  );

  if (!stateSession.access_token && !session.access_token) {
    authorize(); // kick-off authorization flow through authority
    return next(login()); // sets "authorizing" to true on state
  }

  // Let the application state know the user is logged in
  if (!stateSession.access_token && isLoggedIn()) {
    return next(start(session));
  }
  // If the action is not an auth-related action...
  if (!actionTypes.includes(action.type)) {
    // if the session has expired, logout
    if (stateSession.access_token && !isLoggedIn()) {
      return store.dispatch(end());
    }

    return next(action);
  }

  // `session.error` is an error returned from pingdom's openid flow
  if (session.error) return next(throwError(session));
  // remove localStorage value on logout
  if (action.type === actionTypes[1]) {
    endSession();
    if (store.client) store.client.resetStore(); // reset the apollo store
    return next(end());
  }

  return next(action);
};
