import React, { PureComponent } from 'react';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import { withStyles } from 'Common/components/Form';
import TableHeader from 'Common/components/TableHeader';
import NoResults from 'Common/components/NoResults';
import { TASKS_BASE_PATH } from 'Tasks/constants';
import {
  generateCaseLink,
  getSubCaseKeyFromId,
  stripSubCaseId
} from 'Common/utils';
import { NOT_SPECIFIED, TABLE_PROP_TYPES } from 'Common/constants';
import TotalCount from 'Common/components/TotalCount';
import {
  // HEADER_ITEMS_MAP,
  QUEUE_MASTER_CASES,
  QUEUE_CASES_TO_REVIEW,
  QUEUE_YOUR_CASES,
  QUEUE_ADVERSE_EVENT,
  QUEUE_MEDICAL_INQUIRY,
  QUEUE_PRODUCT_QUALITY
} from 'Queue/constants';
import MasterCaseRow from './MasterCaseRow';
import AdverseEventsRow from './AdverseEventsRow';
import MedicalInquiriesRow from './MedicalInquiriesRow';
import ProductComplaintsRow from './ProductComplaintsRow';
import YourCasesRow from './YourCasesRow';
import stylesGenerator from './styles';

class Layout extends PureComponent {
  static propTypes = {
    handleSortChange: PropTypes.func.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      nonIdealState: PropTypes.object.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        page: PropTypes.string
      }).isRequired
    }).isRequired,
    filteredQueue: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        method: PropTypes.string,
        created: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        status: PropTypes.string
      })
    ).isRequired,
    sortBy: TABLE_PROP_TYPES.SORT_BY.isRequired,
    isLoading: PropTypes.bool.isRequired,
    totalResults: PropTypes.number.isRequired,
    inProgressView: PropTypes.bool.isRequired,
    userDesiredColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedItemsToAssignOrArchive: PropTypes.arrayOf(PropTypes.string),
    areAllCasesSelected: PropTypes.bool,
    handleSelectAllCasesOrTasks: PropTypes.func
  };

  static defaultProps = {
    selectedItemsToAssignOrArchive: [],
    areAllCasesSelected: false,
    handleSelectAllCasesOrTasks: () => {}
  };

  ROW_COMPONENTS_MAP = {
    [QUEUE_MASTER_CASES]: MasterCaseRow,
    [QUEUE_CASES_TO_REVIEW]: MasterCaseRow,
    [QUEUE_YOUR_CASES]: YourCasesRow,
    [QUEUE_ADVERSE_EVENT]: AdverseEventsRow,
    [QUEUE_MEDICAL_INQUIRY]: MedicalInquiriesRow,
    [QUEUE_PRODUCT_QUALITY]: ProductComplaintsRow
  };

  dateOrNotSpecified = input =>
    !isNil(input) && input.length > 0 ? input : NOT_SPECIFIED;

  generateTasksLink = (page, row) => {
    if (page !== QUEUE_YOUR_CASES) {
      const subCasePortion = page ? `/${page}` : '';
      return `/${TASKS_BASE_PATH}/${row.masterCaseId}${subCasePortion}`;
    }

    const subCaseKey = getSubCaseKeyFromId(row.id);
    const masterCaseId = stripSubCaseId(row.masterCaseId);
    return `/${TASKS_BASE_PATH}/${masterCaseId}/${subCaseKey}`;
  };

  renderQueueRow = (row, i) => {
    const { page } = this.props.match.params;
    const RowComponent = this.ROW_COMPONENTS_MAP[page];
    const caseLink = generateCaseLink(this.props.match, row);
    const tasksLink = this.generateTasksLink(page, row);
    const { selectedItemsToAssignOrArchive } = this.props;
    return (
      <RowComponent
        key={i}
        id={`queue-row-${row.masterCaseId}`}
        {...this.props}
        processNonMomentDate={this.dateOrNotSpecified}
        index={i}
        row={row}
        caseLink={caseLink}
        tasksLink={tasksLink}
        selectedItemsToAssignOrArchive={selectedItemsToAssignOrArchive}
      />
    );
  };

  renderResultsTable = filteredQueue => {
    const {
      computedStyles,
      match,
      handleSortChange,
      sortBy,
      totalResults,
      areAllCasesSelected,
      userDesiredColumns
    } = this.props;
    const { page } = match.params;

    // As of now bulk assignment checkbox is not required in  Medical Inquiries Page.
    const isSelectionRequired = page !== 'mi';
    return (
      <React.Fragment>
        <div className={computedStyles.totals}>
          {page !== QUEUE_YOUR_CASES && totalResults !== 0 ? (
            <TotalCount totalResults={totalResults} />
          ) : null}
        </div>
        <div className={computedStyles.base}>
          <table className={computedStyles.table}>
            <TableHeader
              // items={HEADER_ITEMS_MAP[page]}
              items={userDesiredColumns || []}
              onSortClick={handleSortChange}
              sortBy={sortBy}
              handleSelectAllCasesOrTasks={
                this.props.handleSelectAllCasesOrTasks
              }
              areAllCasesSelected={areAllCasesSelected}
              isSelectionRequired={isSelectionRequired}
            />
            <tbody className={computedStyles.tableBody}>
              {filteredQueue.map(this.renderQueueRow)}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  };

  renderNoResults = () => <NoResults>No results found</NoResults>;

  renderLoading = () => {
    const loadingMessage = (
      <span>Please wait while we retrieve the results.</span>
    );
    return (
      <div className={this.props.computedStyles.nonIdealState}>
        <NonIdealState
          visual={<Spinner />}
          title="Loading"
          description={loadingMessage}
        />
      </div>
    );
  };

  render() {
    const {
      filteredQueue,
      isLoading,
      inProgressView,
      computedStyles
    } = this.props;
    if (isLoading || inProgressView) {
      return <div className={computedStyles.base}>{this.renderLoading()}</div>;
    }
    return filteredQueue.length ? (
      this.renderResultsTable(filteredQueue)
    ) : (
      <div className={computedStyles.base}>{this.renderNoResults()}</div>
    );
  }
}

export default withStyles(stylesGenerator)(Layout);
