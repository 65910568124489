import { isBoolean } from 'lodash';
import { CASE_SERVICE_AUDIT_API } from 'config/values';

const getFetchHeaderOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': apiKey
    }
  };
  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

const postFetchHeaderBodyOptions = (desiredColumns, apikey) => ({
  method: 'POST',
  headers: {
    'x-api-key': apikey,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(desiredColumns)
});

export const fetchUserView = (
  emitFetchQueueInProcess,
  emitFetchUserViewSuccess,
  emitFetchQueueFailure
) => async dispatch => {
  const URL = `${CASE_SERVICE_AUDIT_API.uri}/getUserPreference`;
  const fetchOptions = getFetchHeaderOptions(CASE_SERVICE_AUDIT_API.apiKey);
  try {
    dispatch(emitFetchQueueInProcess());
    const response = await fetch(URL, fetchOptions);
    const usersViewList = await response.json();
    dispatch(emitFetchUserViewSuccess(usersViewList));
  } catch (error) {
    // console.log('error', error);
    const data = {
      ae: [],
      pq: [],
      mi: [],
      mc: [],
      yours: [],
      masterCase: [],
      allTasks: [],
      yourTasks: []
    };
    // dispatch(emitFetchUserViewSuccess(data));
    dispatch(emitFetchQueueFailure(error, data));
  }
};

export const setUserView = (
  emitFetchQueueInProcess,
  desiredColumns,
  emitFetchUserViewSuccess
  // emitFetchQueueFailure
) => async dispatch => {
  const URL = `${CASE_SERVICE_AUDIT_API.uri}/setUserPreference`;
  const fetchOptions = postFetchHeaderBodyOptions(
    desiredColumns,
    CASE_SERVICE_AUDIT_API.apiKey
  );
  const data = desiredColumns;
  try {
    dispatch(emitFetchQueueInProcess());
    const response = await fetch(URL, fetchOptions);
    console.log('data exported successfully', response);
    dispatch(emitFetchUserViewSuccess(data));
  } catch (error) {
    dispatch(emitFetchUserViewSuccess(data));
  }
};

// const setOptions = desiredColumns => {
//   return {
//     method: 'POST',
//     headers: {
//       'x-api-key': '58swE4y6d47qXyFQG6uC9TxrwzGTxdP3oa8yiXA5',
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       masterCase: desiredColumns
//     })
//   };
// };

// const convertBody = obj =>
//   Object.keys(obj)
//     .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
//     .join('&');

// // const fetchUserView1 = () => {
// //   const URL =
// //     'https://api-ec1.sand.gmatas.net/case/services/getUserPrefernence';
// //   return fetch(URL, getFetchOptions()).then(res => res.json());
// // };

// const fetchUserView = () => {
//   const URL = `${CASE_SERVICE_AUDIT_API.uri}/getUserPrefernence`;
//   const fetchOptions = getFetchHeaderOptions(CASE_SERVICE_AUDIT_API.apiKey);
//   try {
//     return fetch(URL, fetchOptions).then(res => res.json());
//   } catch (error) {
//     return [];
//   }
// };
