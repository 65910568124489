import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, get, set, cloneDeep } from 'lodash';

import { CMS_PROP_TYPES } from 'Common/constants';
import { handleKeyPress } from 'Common/utils';
import { withStyles, SimpleButton, TextInput } from 'Common/components/Form';
import NoResults from 'Common/components/NoResults';
import { modalStyles } from 'Common/components/withModal';
import * as Fuse from 'fuse.js';
import stylesGenerator from './styles';

const stripWildcard = s => (isEmpty(s) ? s : s.replace(/\*/, ''));

class ProductLookUpModal extends PureComponent {
  static propTypes = {
    onProductSelected: PropTypes.func.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      table: PropTypes.object.isRequired,
      tableWrapper: PropTypes.object.isRequired,
      tr: PropTypes.object.isRequired,
      headerSection: PropTypes.object.isRequired,
      headerSectionInitial: PropTypes.object.isRequired,
      actionHeader: PropTypes.object.isRequired,
      productInfoHeader: PropTypes.object.isRequired,
      productInfoSubHeader: PropTypes.object.isRequired,
      productInfoResults: PropTypes.object.isRequired,
      inputElement: PropTypes.object.isRequired
    }).isRequired,
    baseStatePath: PropTypes.string.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired
  };

  state = {
    preferredProductName: '',
    localTradeName: '',
    searchResults: [],
    hasSearched: false
  };

  handleProductSearch = e => {
    e.preventDefault();
    const { tacticalData } = this.props;
    const { localTradeName, preferredProductName } = this.state;
    const products = get(tacticalData, 'product-data.ae-product-data.products');

    if (!isEmpty(products)) {
      const baseOptions = {
        shouldSort: true,
        threshold: 0.0, // exact matches
        location: 0, // start at the start
        distance: 0, // no slop
        maxPatternLength: 32,
        minMatchCharLength: 1
      };

      if (!isEmpty(preferredProductName) && !isEmpty(localTradeName)) {
        const options = {
          ...baseOptions,
          keys: ['preferredProductName.label']
        };
        const preferredProductSearch = new Fuse(products, options);
        const filteredResults = preferredProductSearch.search(
          stripWildcard(preferredProductName)
        );

        const localTradeOpts = {
          ...baseOptions,
          keys: ['localTradeName.label']
        };

        const localTradeSearch = new Fuse(filteredResults, localTradeOpts);
        this.setState({
          searchResults: localTradeSearch.search(stripWildcard(localTradeName))
        });
      } else if (!isEmpty(preferredProductName)) {
        const options = {
          ...baseOptions,
          keys: ['preferredProductName.label']
        };

        const preferredProductSearch = new Fuse(products, options);

        this.setState({
          searchResults: preferredProductSearch.search(
            stripWildcard(preferredProductName)
          )
        });
      } else if (!isEmpty(localTradeName)) {
        const options = {
          ...baseOptions,
          keys: ['localTradeName.label']
        };

        const preferredProductSearch = new Fuse(products, options);
        this.setState({
          searchResults: preferredProductSearch.search(
            stripWildcard(localTradeName)
          )
        });
      }
    }

    this.setState({ hasSearched: true });
  };

  handleSearchInput = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAddToCase = result => {
    const { baseStatePath, trilogyCase } = this.props;
    const updatedCase = cloneDeep(trilogyCase);
    set(
      updatedCase,
      `${baseStatePath}.local_trade_name`,
      result.localTradeName.label
    );
    set(
      updatedCase,
      `${baseStatePath}.suspect_product`,
      result.preferredProductName.label
    );
    if ('type' in result) {
      set(updatedCase, `${baseStatePath}.type`, result.type.label);
    }

    this.props.onProductSelected(updatedCase);
  };

  renderSearchResults = result => {
    const handleClick = () => {
      this.handleAddToCase(result);
    };

    return (
      <tr
        className={this.props.computedStyles.tr}
        key={`${result.preferredProductName.label}-${
          result.localTradeName.label
        }`}
      >
        <td className={this.props.computedStyles.productInfoResults}>
          {result.preferredProductName.label}
        </td>
        <td className={this.props.computedStyles.productInfoResults}>
          {result.localTradeName.label}
        </td>
        <td>
          <a
            data-id="product_modal_add_to_case"
            role="link"
            onClick={handleClick}
            onKeyPress={handleKeyPress(handleClick, ' ', 'Enter')}
            tabIndex="0"
          >
            Add to Case
          </a>
        </td>
      </tr>
    );
  };

  render() {
    const { preferredProductName, localTradeName, searchResults } = this.state;
    const headerSectionClassName = isEmpty(searchResults.length)
      ? `${this.props.computedStyles.headerSectionInitial} ${
          this.props.computedStyles.headerSection
        }`
      : `${this.props.computedStyles.headerSection}`;

    let resultContent = null;
    if (isEmpty(searchResults) && this.state.hasSearched) {
      resultContent = <NoResults>No results found.</NoResults>;
    } else if (!isEmpty(searchResults)) {
      resultContent = (
        <div className={this.props.computedStyles.tableWrapper}>
          <table className={this.props.computedStyles.table}>
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className={this.props.computedStyles.productInfoHeader}
                >
                  PRODUCT INFORMATION
                </th>
                <th
                  rowSpan="2"
                  className={this.props.computedStyles.actionHeader}
                >
                  ACTIONS
                </th>
              </tr>
              <tr>
                <th className={this.props.computedStyles.productInfoSubHeader}>
                  PREFERRED PRODUCT NAME
                </th>
                <th
                  className={`${
                    this.props.computedStyles.productInfoSubHeader
                  } ${this.props.computedStyles.productInfoHeader}`}
                >
                  LOCAL TRADE NAME
                </th>
              </tr>
            </thead>
            <tbody>{map(searchResults, this.renderSearchResults)}</tbody>
          </table>
        </div>
      );
    }

    return (
      <form onSubmit={this.handleProductSearch}>
        <div className={modalStyles.base}>
          <div className={headerSectionClassName}>
            <span className={modalStyles.title}>SEARCH FOR A PRODUCT</span>
            <div>
              <div className={this.props.computedStyles.inputElement}>
                <TextInput
                  data-id="preferred_product_name"
                  label="Preferred Product Name"
                  value={preferredProductName}
                  styles={{ width: '90%', display: 'inline-block' }}
                  onChange={value =>
                    this.handleSearchInput('preferredProductName', value)
                  }
                  locale="US"
                />
              </div>

              <div className={this.props.computedStyles.inputElement}>
                <TextInput
                  label="Local Trade Name"
                  value={localTradeName}
                  styles={{ width: '90%', display: 'inline-block' }}
                  onChange={value =>
                    this.handleSearchInput('localTradeName', value)
                  }
                  locale="US"
                />
              </div>
            </div>
            <div className={modalStyles.buttonsContainerSingle}>
              <SimpleButton
                data-id="product_modal_search"
                type="submit"
                primary
              >
                Search
              </SimpleButton>
            </div>
          </div>
          {resultContent}
        </div>
      </form>
    );
  }
}

export default withStyles(stylesGenerator)(ProductLookUpModal);
