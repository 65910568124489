import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    margin: '37px 0 0',
    borderRight: `2px solid ${theme.colors.background}`,
    background: theme.colors.background,

    ' section > div': {
      width: '100%'
    }
  })
});

export default stylesGenerator;
