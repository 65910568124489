import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { SCHEMA_ID_FORM } from 'Common/constants';
import * as NavigationActions from 'Navigation/ducks';
import * as SchemaActons from 'Common/ducks/schema';
import * as SearchActions from 'Search/ducks';
import * as CaseActions from 'CreateCase/ducks';
import * as SearchBarActions from 'Common/ducks/searchBar';
import SearchController from './controller';

const mapStateToProps = ({
  schemaReducer,
  routerReducer,
  searchReducer,
  searchBarReducer
}) => ({
  fragments: schemaReducer.fragments,
  schema: schemaReducer.schemas[SCHEMA_ID_FORM],
  tacticalData: schemaReducer.tacticalData,
  location: routerReducer.location,
  results: searchReducer.results,
  filters: searchBarReducer.filters,
  sortBy: searchBarReducer.sortBy,
  hasSearched: searchBarReducer.hasSearched
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...SearchActions,
    ...SearchBarActions,
    ...CaseActions,
    ...NavigationActions,
    ...SchemaActons,
    push
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchController);
