export const ITEMS_MAP = [
  {
    label: 'Description',
    order: 1,
    sortPath: 'form.base.description'
  },
  {
    label: 'Case',
    sortPath: 'form.base.subcaseId',
    order: 2,
    isMandatoryColumn: true
  },
  {
    label: 'AER Number',
    sortPath: 'form.base.aerNumber',
    order: 3
  },
  {
    label: 'Argus Number',
    sortPath: 'form.base.argusNumber',
    order: 4
  },
  {
    label: 'Global ID',
    sortPath: 'form.base.globalId',
    order: 5
  },
  {
    label: 'Due Date',
    sortPath: 'form.base.dueDate',
    order: 6,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Date',
      entityPathName: 'form.base.dueDate',
      entityvalue: {
        summary: {
          dueDate: null
        }
      },
      sourceData: null
    }
  },
  {
    label: 'Assignee',
    sortPath: 'form.base.assigneeLastName',
    order: 7,
    isFilterRequired: true,
    excludedForYourPage: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'form.base.assignee',
      entityvalue: { form: { base: { assignee: null } } },
      sourceData: {
        source: 'document-data.user-list',
        options: [{ label: 'Unassigned', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Actions',
    order: 8,
    isMandatoryColumn: true
    /* Empty header for "Complete Task" column */
  },
  {
    label: 'Follow Up Type',
    sortPath: 'form.base.followUp.type',
    order: 9,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'form.base.followUp.type',
      entityvalue: { form: { base: { followUp: { type: null } } } },
      sourceData: {
        source: 'document-data.task-options.pq_follow_up_type',
        options: [{ label: 'Not Specified', value: '{NOTEXISTS}' }]
      }
    }
  },
  {
    label: 'Reactions',
    sortPath: '',
    order: 10
  },
  {
    label: 'Seriousness',
    sortPath: '',
    order: 11
  },
  {
    label: 'Number of attempt',
    sortPath: '',
    order: 12,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'form.additional.attempts.attempt[0].attemptNumber',
      entityvalue: {
        form: { additional: { attempts: { attempt: { attemptNumber: null } } } }
      },
      sourceData: {
        source: null,
        options: [
          { label: '1', value: 1 },
          { label: '2', value: 2 },
          { label: '3', value: 3 },
          { label: '4', value: 4 },
          { label: '5', value: 5 },
          { label: 'Not Specified', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Communication Method',
    sortPath: 'form.additional.attempts.attempt[0].method',
    order: 13,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'form.additional.attempts.attempt[0].method',
      entityvalue: {
        form: { additional: { attempts: { attempt: { method: null } } } }
      },
      sourceData: {
        source: null,
        options: [
          { label: 'Phone', value: 'PHONE' },
          { label: 'Email', value: 'EMAIL' },
          { label: 'Letter', value: 'LETTER' },
          { label: 'Fax', value: 'FAX' },
          { label: 'Not Specified', value: '{NOTEXISTS}' }
        ]
      }
    }
  },
  {
    label: 'Affiliate Location',
    sortPath: 'affiliateCountryLabel',
    order: 14,
    isFilterRequired: true,
    FilterOptions: {
      controlType: 'Select',
      entityPathName: 'affiliateCountryLabel',
      useLabelsAsValue: true,
      sourceData: {
        source: 'dynamic-tactical-data.allowed-affiliates'
      }
    }
  }
];

export const DEFAULT_TASK_HEADER_STATE = {
  dynamicFilters: {},
  typeFilters: {
    PQFOLLOWUP: true,
    PVQUERY: true,
    CASEFLOW: true,
    COMMUNICATION: true,
    REVIEW: true
  },
  statusFilters: {
    OPEN: true,
    CANCELLED: false,
    COMPLETED: false
  }
};
