import { TACTICAL_DATA_API } from 'config/values';
import { isBoolean } from 'lodash';

const getFetchOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': TACTICAL_DATA_API.apiKey
    }
  };

  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

const fetchTacticalData = ({ documentMeta }) => {
  const {
    documentDataId,
    documentDataType = 'documentDataList',
    tacticalType,
    customUri
  } = documentMeta;
  const uri = customUri || TACTICAL_DATA_API.uri;
  const URL = `${uri}/${tacticalType}/${documentDataId}`;
  return fetch(URL, getFetchOptions(TACTICAL_DATA_API.apiKey))
    .then(res => res.json())
    .then(json => ({
      [tacticalType]: {
        [documentDataId]: json[documentDataType]
      }
    }));
};

export default fetchTacticalData;
