import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import { Checkbox } from 'Common/components/Form';
import { TABLE_PROP_TYPES } from 'Common/constants';
import stylesGenerator from './styles';
import SortArrow from './SortArrow';

class TableHeader extends PureComponent {
  static propTypes = {
    onSortClick: PropTypes.func,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      item: PropTypes.object.isRequired,
      selectBox: PropTypes.object.isRequired
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        sortPath: TABLE_PROP_TYPES.SORT_PATH
      })
    ).isRequired,
    sortBy: TABLE_PROP_TYPES.SORT_BY.isRequired,
    isSelectionRequired: PropTypes.bool,
    areAllCasesSelected: PropTypes.bool,
    handleSelectAllCasesOrTasks: PropTypes.func
  };

  static defaultProps = {
    onSortClick: () => {},
    isSelectionRequired: false,
    areAllCasesSelected: false,
    handleSelectAllCasesOrTasks: () => {}
  };

  renderSortArrow = sortPath => (
    <SortArrow
      sortPath={sortPath}
      sortBy={this.props.sortBy}
      onSortClick={this.props.onSortClick}
    />
  );

  renderItem = ({ label, sortPath }, i) => (
    <th key={i} className={this.props.computedStyles.item}>
      {label}
      {sortPath ? this.renderSortArrow(sortPath) : null}
    </th>
  );

  render() {
    const { isSelectionRequired, areAllCasesSelected } = this.props;
    return (
      <thead className={this.props.computedStyles.base}>
        <tr>
          {isSelectionRequired ? (
            <th className={this.props.computedStyles.selectBox}>
              <Checkbox
                value={areAllCasesSelected}
                onChange={this.props.handleSelectAllCasesOrTasks}
              />
            </th>
          ) : null}
          {this.props.items.map(this.renderItem)}
        </tr>
      </thead>
    );
  }
}

export default withStyles(stylesGenerator)(TableHeader);
