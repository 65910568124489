import { createAction, handleActions } from 'redux-actions';
import { EMIT_CLEAN_SLATE } from 'Common/constants';
import { LOOKUP_CONTACT } from 'Lookup/constants';

const EMIT_LOOKUP_QUERY_SUCCESS = 'EMIT_LOOKUP_QUERY_SUCCESS';
const EMIT_LOOKUP_QUERY_FAILURE = 'EMIT_LOOKUP_QUERY_FAILURE';

export const emitLookupQuerySuccess = createAction(
  EMIT_LOOKUP_QUERY_SUCCESS,
  (results, lookupType) => ({ results, lookupType })
);

export const emitLookupQueryFailure = createAction(EMIT_LOOKUP_QUERY_FAILURE);

const initialState = {
  results: [],
  total: 0,
  searchRun: false
};
const handlers = {
  [EMIT_CLEAN_SLATE]: () => ({
    ...initialState
  }),

  [EMIT_LOOKUP_QUERY_SUCCESS]: (state, { payload }) => {
    const searchType =
      payload.lookupType === LOOKUP_CONTACT
        ? 'searchContacts'
        : 'searchPatient';
    return {
      ...state,
      total: payload.results[searchType].total,
      results: payload.results[searchType].hits,
      searchRun: true
    };
  }
};

const reducer = handleActions(handlers, initialState);
export default reducer;
