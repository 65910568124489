import gql from 'graphql-tag';

const getTasksByRevisionQuery = (revision, taskFragment) => {
  const fragment = gql`
    ${taskFragment}
  `;

  return gql`
    query TaskRevisionQuery($id: String!, $rev: Int) {
      task(id: $id, revision: $rev) {
        ...BaseTask
      }
    }
    ${fragment}
  `;
};

export default getTasksByRevisionQuery;
