import React from 'react';
import PropTypes from 'prop-types';

import { SimpleButton } from 'Common/components/Form';
import { modalStyles } from './Modal/styles';

const ConfirmContent = ({ handleConfirm, handleDismiss, title, message }) => (
  <div className={modalStyles.base}>
    <span className={modalStyles.title}>{title}</span>
    <p>{message}</p>
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton id="modal-cancel" onClick={handleDismiss}>
        Cancel
      </SimpleButton>
      <SimpleButton id="modal-confirm" onClick={handleConfirm} primary>
        Confirm
      </SimpleButton>
    </div>
  </div>
);

ConfirmContent.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

ConfirmContent.defaultProps = {
  oldValue: null
};

export default ConfirmContent;
