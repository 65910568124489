/* eslint react/prefer-stateless-function:0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { generateCSS } from 'Common/components/Form';

const withInputWrapper = WrappedComponent =>
  class InputWrapper extends PureComponent {
    static propTypes = {
      layout: PropTypes.object.isRequired // eslint-disable-line
    };

    static defaultProps = {
      layout: {},
      component: undefined
    };

    render() {
      const wrapperClass = generateCSS({
        position: 'relative',
        display: 'inline-block',
        padding: '0 15px 10px',
        width: '50%',
        ...this.props.layout
      });

      return (
        <div className={wrapperClass}>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };

export default withInputWrapper;
