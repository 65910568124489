import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ThemeProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.shape({
      colors: PropTypes.object.isRequired,
      fonts: PropTypes.object.isRequired
    }).isRequired
  };

  static childContextTypes = {
    theme: PropTypes.object.isRequired
  };

  getChildContext = () => ({ theme: this.props.theme });

  render = () => <div>{this.props.children}</div>;
}

export default ThemeProvider;
