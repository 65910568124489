import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SimpleButton, PropTypeSafety } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import { QUEUE_BASE_PATH, QUEUE_YOUR_CASES } from 'Queue/constants';
import { startCase } from 'lodash';

class MultipleEditorsWarningModal extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    actions: PropTypes.shape({
      push: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired,
      emitLockCaseManualDismiss: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    modalName: 'MultipleEditorsWarningModal'
  };

  DEFAULT_PAGE = `${QUEUE_BASE_PATH}/${QUEUE_YOUR_CASES}`;

  handleYourCases = () => {
    const { actions } = this.props;
    actions.emitModalContentClear();
    actions.push(this.DEFAULT_PAGE);
  };

  handleProceed = () => {
    const { actions } = this.props;
    actions.emitModalContentClear();
    actions.emitLockCaseManualDismiss();
  };

  render() {
    const { data } = this.props;

    return (
      <PropTypeSafety>
        <span className={modalStyles.title}>
          ANOTHER USER IS EDITING THIS CASE
        </span>
        <p>
          The case you are attempting to access is currently being edited by
          {` ${startCase(data.name)}.`}
        </p>
        <p>
          You may view the case but will not be able to save your edits or
          perform any actions to the case.
        </p>
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleProceed}>Proceed</SimpleButton>
          <SimpleButton onClick={this.handleYourCases} primary>
            GO TO YOUR CASES
          </SimpleButton>
        </div>
      </PropTypeSafety>
    );
  }
}

export default MultipleEditorsWarningModal;
