import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, get } from 'lodash';
import * as Fuse from 'fuse.js';

import { CMS_PROP_TYPES } from 'Common/constants';
import { handleKeyPress } from 'Common/utils';
import { withStyles, SimpleButton, TextInput } from 'Common/components/Form';
import NoResults from 'Common/components/NoResults';
import { modalStyles } from 'Common/components/withModal';
import { addAEProtocolDataToCase } from 'CreateCase/utils/case';
import stylesGenerator from './styles';

const stripWildcard = s => (isEmpty(s) ? s : s.replace(/\*/, ''));

class ProtocolLookUpModal extends PureComponent {
  static propTypes = {
    onProtocolSelected: PropTypes.func.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      table: PropTypes.object.isRequired,
      tableWrapper: PropTypes.object.isRequired,
      tr: PropTypes.object.isRequired,
      headerSection: PropTypes.object.isRequired,
      headerSectionInitial: PropTypes.object.isRequired,
      actionHeader: PropTypes.object.isRequired,
      protocolNumberHeader: PropTypes.object.isRequired,
      protocolNumberSubHeader: PropTypes.object.isRequired,
      protocolNumberResults: PropTypes.object.isRequired,
      inputElement: PropTypes.object.isRequired
    }).isRequired,
    baseStatePath: PropTypes.string.isRequired,
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired
  };
  state = {
    protocolNumber: '',
    searchResults: [],
    hasSearched: false
  };

  handleProtocolSearch = e => {
    e.preventDefault();

    const { tacticalData } = this.props;
    const { protocolNumber } = this.state;
    const protocols = get(
      tacticalData,
      'document-data.ae-protocol-data.protocols'
    );
    if (!isEmpty(protocols)) {
      const baseOptions = {
        shouldSort: true,
        threshold: 0.0, // exact matches
        location: 0, // start at the start
        distance: 0, // no slop
        maxPatternLength: 32,
        minMatchCharLength: 1
      };
      if (!isEmpty(protocolNumber)) {
        const options = {
          ...baseOptions,
          keys: ['protocolNumber.label']
        };
        const protocolNumberSearch = new Fuse(protocols, options);
        this.setState({
          searchResults: protocolNumberSearch.search(
            stripWildcard(protocolNumber)
          )
        });
      }
    }
    this.setState({ hasSearched: true });
  };

  handleSearchInput = value => this.setState({ protocolNumber: value });

  handleAddToCase = result => {
    const { baseStatePath, trilogyCase } = this.props;
    const updatedCase = addAEProtocolDataToCase(
      trilogyCase,
      baseStatePath,
      result
    );

    this.props.onProtocolSelected(updatedCase);
  };

  renderSearchResults = result => {
    const handleClick = () => {
      this.handleAddToCase(result);
    };

    return (
      <tr
        className={this.props.computedStyles.tr}
        key={`${result.protocolNumber.label}`}
      >
        <td className={this.props.computedStyles.protocolNumberResults}>
          {result.protocolNumber.label}
        </td>
        <td className={this.props.computedStyles.protocolNumberResults}>
          {result.studyTitle.label}
        </td>
        <td className={this.props.computedStyles.protocolNumberResults}>
          {result.studyType.label}
        </td>
        <td className={this.props.computedStyles.protocolNumberResults}>
          {result.studyPhase.label}
        </td>
        <td>
          <a
            role="link"
            onClick={handleClick}
            onKeyPress={handleKeyPress(handleClick, ' ', 'Enter')}
            tabIndex="0"
          >
            Add to Case
          </a>
        </td>
      </tr>
    );
  };
  render() {
    const { protocolNumber, searchResults } = this.state;
    const { computedStyles } = this.props;

    let resultContent = null;
    if (isEmpty(searchResults) && this.state.hasSearched) {
      resultContent = <NoResults>No results found.</NoResults>;
    } else if (!isEmpty(searchResults)) {
      resultContent = (
        <div className={this.props.computedStyles.tableWrapper}>
          <table className={this.props.computedStyles.table}>
            <thead>
              <tr>
                <th
                  colSpan="4"
                  className={this.props.computedStyles.protocolNumberHeader}
                >
                  PROTOCOL NUMBER INFORMATION
                </th>
                <th
                  rowSpan="1"
                  className={this.props.computedStyles.actionHeader}
                >
                  ACTIONS
                </th>
              </tr>
              <tr>
                <th
                  className={this.props.computedStyles.protocolNumberSubHeader}
                >
                  PROTOCOL NUMBER
                </th>
                <th
                  className={this.props.computedStyles.protocolNumberSubHeader}
                >
                  STUDY TITLE
                </th>
                <th
                  className={this.props.computedStyles.protocolNumberSubHeader}
                >
                  STUDY TYPE
                </th>
                <th
                  className={this.props.computedStyles.protocolNumberSubHeader}
                >
                  STUDY PHASE
                </th>
              </tr>
            </thead>
            <tbody>{map(searchResults, this.renderSearchResults)}</tbody>
          </table>
        </div>
      );
    }
    return (
      <form onSubmit={this.handleProtocolSearch}>
        <div className={computedStyles.base}>
          <div className={computedStyles.headerSection}>
            <span className={modalStyles.title}>
              SEARCH FOR A PROTOCOL NUMBER
            </span>
            <div>
              <div className={computedStyles.inputElement}>
                <TextInput
                  label="Protocol Number"
                  value={protocolNumber}
                  styles={{ width: '90%', display: 'inline-block' }}
                  onChange={value => this.handleSearchInput(value)}
                  locale="US"
                />
              </div>
            </div>
            <div className={modalStyles.buttonsContainerSingle}>
              <SimpleButton type="submit" primary>
                Search
              </SimpleButton>
            </div>
          </div>
          {resultContent}
        </div>
      </form>
    );
  }
}

export default withStyles(stylesGenerator)(ProtocolLookUpModal);
