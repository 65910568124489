/* eslint import/prefer-default-export:0 */
import { generateCSS } from 'Common/components/Form';

const stylesGenerator = (theme, { layout, styles }) => ({
  base: generateCSS({
    background: theme.colors.base,
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: '0 0 35px',
    padding: '15px',
    minHeight: 40,
    width: '100%',
    // spacing between two successive inputGroups
    ' div[data-group] + div[data-group]:not([data-id*="phone"])': {
      marginTop: 20
    },
    ...(layout || styles),

    '> span': {
      display: 'block',
      textAlign: 'right'
    }
  }),

  error: generateCSS({
    border: `1px solid ${theme.colors.error}`,
    '> span': {
      color: theme.colors.error
    }
  }),

  headerRemove: generateCSS({
    ' > button': {
      transform: 'scale(0.8)'
    }
  }),

  inlineRemove: generateCSS({
    position: 'absolute',
    right: 0,
    paddingBottom: 10
  }),

  label: generateCSS({
    display: 'inline-block !important',
    padding: '0 1px 0 10px',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontFamily: 'Calibri, "Microsoft Sans Serif"',
    fontWeight: '200',
    color: '#000000'
  })
});

export default stylesGenerator;
