/* eslint react/prefer-stateless-function:0 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const withDataWrapper = WrappedComponent =>
  class DataWrapper extends PureComponent {
    static propTypes = {
      disabled: PropTypes.bool,
      data: PropTypes.shape({
        isCaseLocked: PropTypes.bool,
        isReadOnly: PropTypes.bool
      }).isRequired
    };

    static defaultProps = {
      disabled: false
    };

    enableDashboardCreationOnCompleted = isReadOnly => {
      const { model, relativePath } = this.props;
      if (
        model &&
        model.status === 'COMPLETED' &&
        window.location &&
        window.location.pathname.endsWith('/review/dashboard')
      ) {
        // on the review/mastercase dashboard,
        // enable the subcase-related buttons/inputs
        // for completed cases
        return relativePath !== 'subcases';
      } else {
        // default behavior
        return isReadOnly;
      }
    };

    render() {
      const { data, disabled } = this.props;
      const isDisabled =
        disabled ||
        data.isCaseLocked ||
        this.enableDashboardCreationOnCompleted(data.isReadOnly);
      return <WrappedComponent {...this.props} disabled={isDisabled} />;
    }
  };

export default withDataWrapper;
