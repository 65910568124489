import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@blueprintjs/core';

import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class CheckboxGroup extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        helpText: PropTypes.string,
        disabled: PropTypes.bool
      })
    ).isRequired,
    renderHorizontal: PropTypes.bool,
    optionValues: PropTypes.objectOf(PropTypes.bool),

    // If true, sets .focused class on option even on click
    aggressiveHighlight: PropTypes.bool
  };

  static defaultProps = {
    ...formElementDefaultProps,
    optionValues: {},
    options: [],
    styles: { width: '100%' },
    renderHorizontal: false,
    aggressiveHighlight: false,
    onValidationErrors: () => {}
  };

  state = { focused: '' };

  focus = () => {
    const { id, options } = this.props;
    document.getElementById(`${id}-${options[0].value}`).focus();
  };

  handleBlur = () => this.setState({ focused: '' });

  renderLabel = () => (
    <div>
      <Label {...this.props} />
    </div>
  );

  renderOptionGap = () => (
    <div className={this.props.computedStyles.inputHelpTextCompensation} />
  );

  renderHelpText = helpText => (
    <span className={this.props.computedStyles.helpText}>{helpText}</span>
  );

  renderCheckbox = (option, i) => {
    const {
      $id,
      onChange,
      options,
      computedStyles,
      aggressiveHighlight,
      autoFocus,
      disabled
    } = this.props;

    // CSE typically manages the Form components, some though are manually rendered like in Queue.
    const cseManaged = !!$id;
    const optionId = cseManaged ? `${$id}.${option.value}` : option.value;
    const handleChange = e => {
      if (!aggressiveHighlight) this.handleBlur();
      onChange({
        ...this.props,
        $id: optionId,
        schemaPath: undefined, // Avoid setting the checkbox group itself to the value of a particular checkbox
        value: e.target.checked
      });
    };

    const handleKeyDown = e => {
      // Space triggers the onChange event, ignore it here!
      if (e.key === 'Enter') {
        handleChange(e);
      }
    };

    const handleFocus = () => this.setState({ focused: optionId });
    const focusedClass = this.state.focused === optionId ? 'focused' : '';
    const shouldRenderGap = i < options.length - 1 && !option.helpText;
    const labelElement = (
      <span className={computedStyles.optionLabel}>{option.label}</span>
    );

    return (
      <div key={i} className={computedStyles.inputSet}>
        <Checkbox
          key={optionId}
          name={optionId}
          data-id={optionId}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={this.handleBlur}
          className={`${computedStyles.input} ${focusedClass}`}
          disabled={option.disabled || disabled}
          autoFocus={autoFocus && i === 0}
          {...option}
          checked={option.checked}
          label={undefined}
          labelElement={labelElement}
        />
        {option.helpText ? this.renderHelpText(option.helpText) : null}
        {shouldRenderGap ? this.renderOptionGap() : null}
      </div>
    );
  };

  render() {
    const {
      $id,
      schemaPath,
      id,
      label,
      options,
      computedStyles,
      errors,
      renderHorizontal
    } = this.props;

    const wrapperClassName = renderHorizontal
      ? `${computedStyles.group} horizontal`
      : `${computedStyles.group}`;

    let className = computedStyles.base;

    if (errors && errors.length) {
      className = `${className} ${computedStyles.errors}`;
    }

    return (
      <div
        id={id}
        role="group"
        className={className}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        {label ? this.renderLabel() : null}
        <div className={wrapperClassName}>
          {options.map(this.renderCheckbox)}
        </div>
        <div className={computedStyles.compensateForGroup}>
          <ValidationErrors
            id={id}
            computedStyles={computedStyles}
            validationErrors={errors}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(CheckboxGroup);
