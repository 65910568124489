/* eslint no-console:0 */
import { taskClient } from 'config/apollo';
import getTaskByRevisionQuery from './queries/task/getTaskByRevisionQuery';

const submitTasksByRevisionQuery = async (
  taskId,
  revisionNum,
  taskFragment
) => {
  const variables = { id: taskId, revision: revisionNum };
  return taskClient.query({
    query: getTaskByRevisionQuery(revisionNum, taskFragment),
    variables,
    fetchPolicy: 'network-only'
  });
};

export default submitTasksByRevisionQuery;
