import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import withStyles from '../withStyles';
import stylesGenerator from './styles';
import { csePropTypes } from '../propTypes';

class Tab extends PureComponent {
  static propTypes = {
    ...csePropTypes,
    children: PropTypes.node.isRequired,
    path: PropTypes.string // eslint-disable-line
  };

  render() {
    const { $id, schemaPath, data, children, path } = this.props;

    return path === get(data, 'location.tab') ? (
      <div data-id={$id} data-schema-path={schemaPath}>
        {children}
      </div>
    ) : null;
  }
}

export default withStyles(stylesGenerator)(Tab);
