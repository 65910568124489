export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const GREY = '#9B9B9B';
export const EXTRA_LIGHT_GREY = '#969696';
export const LIGHT_GREY = '#D1D1D1';
export const ALT_LIGHT_GREY = '#F2F2F2';
export const DARK_GREY = '#5A5A5A';
export const CHARCOAL_GREY = '#4A4A4A';
export const EXTRA_DARK_GREY = '#6D6D6D';
export const ALT_GREY = '#E4E4E4';

export const BLUE = '#0082BA';
export const ALT_BLUE = '#0082BA';
export const LIGHT_BLUE = '#00A9E0';
export const DARK_BLUE = '#071D49';
export const ALT_LIGHT_BLUE = '#EBF5FA';
export const EXTRA_LIGHT_BLUE = '#F5FAFC';
export const LIGHT_GREEN_BLUE = '#6BBBAE';

export const RED = '#CD000D';
export const RED_ERROR = '#D0011B';
export const LIGHT_GREEN = '#50E3C2';
export const TEAL = '#6BBBAE';
export const LIGHT_TEAL = '#8CD2D0';
export const BRIGHT_GREEN = '#7ED321';
export const ORANGE = '#F6A623';
