/* eslint no-console:0 */

import { isBoolean } from 'lodash';

import { SUBCASE_VERSION_DIFF_API } from 'config/values';
import { slashToDotDelimitedPaths } from './formatter/deltaUtils';

const getFetchOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': SUBCASE_VERSION_DIFF_API.apiKey,
      'Content-Type': 'text/html'
    }
  };

  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};
/**
 * Request for AE case version diffs (versions 01+)
 *
 * @function
 * @param  {String} subcaseVersionId    - The full subcase versionId (eg. `TE18-1006003-AE01`)
 * @param  {Function} onSuccess(json)   - Callback for successful request
 * @param  {Function} onFailure(error)  - Callback for failed request
 */
export const fetchSubcaseVersionDiff = async subcaseVersionId => {
  const URL = `${SUBCASE_VERSION_DIFF_API.uri}/${subcaseVersionId}`;
  const fetchOptions = getFetchOptions(SUBCASE_VERSION_DIFF_API.apiKey);
  return fetch(URL, fetchOptions)
    .then(res => res.json())
    .then(json => slashToDotDelimitedPaths(json));
};

export default fetchSubcaseVersionDiff;
