import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';

import { withStyles } from 'Common/components/Form';
import { getOrElse } from 'Common/utils';
import { GENERIC_STATE_PATHS } from 'Queue/constants';
import stylesGenerator from './styles';

class DescriptionLink extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      popoverContent: PropTypes.object.isRequired
    }).isRequired,
    row: PropTypes.shape({
      [GENERIC_STATE_PATHS.DESCRIPTION]: PropTypes.string
    })
  };

  static defaultProps = {
    row: {}
  };

  shouldRender = () =>
    !!getOrElse(this.props.row, GENERIC_STATE_PATHS.DESCRIPTION);

  render() {
    const { computedStyles, row } = this.props;

    return this.shouldRender() ? (
      <div className={computedStyles.base}>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          inline
        >
          <span role="button">See Description</span>
          <div className={computedStyles.popoverContent}>
            {getOrElse(row, GENERIC_STATE_PATHS.DESCRIPTION)}
          </div>
        </Popover>
      </div>
    ) : null;
  }
}

export default withStyles(stylesGenerator)(DescriptionLink);
