import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    backgroundColor: theme.colors.brand,
    border: `1px solid ${theme.colors.brand}`,
    borderRadius: '4px',
    display: 'inline-block',
    margin: '0 10px',
    padding: '0 2px',
    lineHeight: '1em',
    maxWidth: '16px'
  }),
  indicator: generateCSS({
    ...theme.fonts.seriousnessIndicator
  }),
  pullRight: generateCSS({
    float: 'right'
  })
});

export default stylesGenerator;
