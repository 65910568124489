import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { modalStyles } from 'Common/components/withModal';
import {
  Select,
  SimpleButton,
  TextInput,
  DateInput,
  Checkbox
} from 'Common/components/Form';
import Notifier from 'Common/components/Notifier';
import { CMS_PROP_TYPES } from 'Common/constants';
import mapping from 'Common/constants/sendProcessingInfoMapping';
import { get, set } from 'lodash';

class SendModal extends PureComponent {
  static propTypes = {
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData,
    flow: PropTypes.string.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    productPath: PropTypes.string.isRequired
  };

  state = {
    hasErrors: false,
    inputValues: {},
    notRequiredFields: {},
    sections: mapping[this.props.flow].sections,
    contactIndex: this.props.trilogyCase.contacts.contact.length.toString(),
    contactDropdownValue: 'new'
  };

  handleChange = (value, statePath, required) => {
    if (required === false) {
      this.setState(prevState => ({
        notRequiredFields: {
          ...prevState.notRequiredFields,
          [statePath]: value
        }
      }));
    } else
      this.setState(prevState => ({
        inputValues: {
          ...prevState.inputValues,
          [statePath]: value
        }
      }));
  };
  handleUpdate = (value, statePath) => {
    const { actions, trilogyCase } = this.props;
    set(trilogyCase, statePath, value);
    this.setSelectedContact(this.state.contactIndex);
    actions.emitInputBatchUpdate(trilogyCase);
  };

  handleSend = () => {
    const { actions, trilogyCase, productPath } = this.props;
    const hasErrors = this.checkFields();
    if (!hasErrors) {
      actions.emitModalContentClear();
      actions
        .emitSendPqMessage(
          trilogyCase,
          get(trilogyCase, `${productPath}.trilogyProductId`) ||
            productPath.replace(/[^\d]/g, '')
        )
        .then(() =>
          Notifier.show({
            message: 'Successfully sent.',
            iconName: 'tick-circle',
            intent: Notifier.SUCCESS
          })
        );
    }
  };

  handleContactChange = (value, trilogyCase) => {
    const { actions } = this.props;
    this.setState({ contactDropdownValue: value });
    if (value !== 'new') {
      this.setState({ contactIndex: value });
      this.setSelectedContact(value);
      actions.emitInputBatchUpdate(trilogyCase);
    }
    if (value === 'new') {
      // add new contact
      const { contacts: { contact } } = trilogyCase;
      this.setState({ contactIndex: contact.length.toString() });
      actions.emitInputBatchUpdate(trilogyCase);
    }
  };

  setSelectedContact = (contactIndex = '0') => {
    const { trilogyCase, tacticalData, productPath } = this.props;
    const shippingInfoStatePath = `${productPath}.complaint.processing.shippingInfo[0]`;

    // address and name fields are grabbed from different places if contact type is patient
    const isPatient =
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) ===
        'patient' ||
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) === 'Patient';
    const contact = isPatient
      ? get(trilogyCase, 'patient.patient[0]', {})
      : get(trilogyCase, `contacts.contact[${contactIndex}]`, {});
    const address = isPatient
      ? get(contact, 'contact_info[0]', {})
      : get(contact, 'address[0]', {});

    if (
      isPatient &&
      get(trilogyCase, `${shippingInfoStatePath}.accountNumber`) === null
    ) {
      set(trilogyCase, `${shippingInfoStatePath}.accountNumber`, 'N/A');
    }

    set(
      trilogyCase,
      `${shippingInfoStatePath}.organization`,
      isPatient ? 'N/A' : contact.organization
    );
    set(
      trilogyCase,
      `${shippingInfoStatePath}.firstName`,
      isPatient ? contact.firstName : get(contact, 'name.first')
    );
    set(
      trilogyCase,
      `${shippingInfoStatePath}.lastName`,
      isPatient ? contact.lastName : get(contact, 'name.last')
    );
    set(
      trilogyCase,
      `${shippingInfoStatePath}.country`,
      isPatient ? address.country : contact.country
    );
    set(trilogyCase, `${shippingInfoStatePath}.line1`, address.line1);
    set(trilogyCase, `${shippingInfoStatePath}.line2`, address.line2);
    set(trilogyCase, `${shippingInfoStatePath}.city`, address.city);
    set(trilogyCase, `${shippingInfoStatePath}.state`, address.state);
    set(
      trilogyCase,
      `${shippingInfoStatePath}.postal_code`,
      address.postal_code
    );
    set(
      trilogyCase,
      `${shippingInfoStatePath}.type`,
      `${
        isPatient
          ? 'Patient'
          : get(
              get(
                tacticalData,
                'document-data.mastercase-options.type_of_contact'
              ).find(element => contact.type === element.value),
              'label'
            )
      }`
    );
    set(
      trilogyCase,
      `${shippingInfoStatePath}.phoneNumber`,
      isPatient
        ? get(address, 'phone[0].number')
        : get(contact, 'phone[0].number')
    );
  };

  checkFields = () => {
    const { trilogyCase } = this.props;

    const { sections, contactIndex } = this.state;
    const values = [];
    Object.entries(sections).forEach(([_sectionLabel, fields]) => {
      fields.forEach(field => {
        const statePath = this.replacePlaceholderPaths(field.statePath);

        const isPatient =
          get(trilogyCase, `contacts.contact[${contactIndex}].type`) ===
            'Patient' ||
          get(trilogyCase, `contacts.contact[${contactIndex}].type`) ===
            'patient';

        const value =
          isPatient &&
          (statePath.includes('organization') ||
            statePath.includes('accountNumber')) &&
          (get(trilogyCase, statePath) === '' ||
            get(trilogyCase, statePath) === null ||
            get(trilogyCase, statePath) === undefined)
            ? 'N/A'
            : get(trilogyCase, statePath, '');

        this.handleChange(value, statePath, field.required);
        if (
          (!field.conditionalPath && field.required !== false) ||
          (field.conditionalPath &&
            !get(
              trilogyCase,
              this.replacePlaceholderPaths(field.conditionalPath)
            ))
        ) {
          values.push(value);
        }
      });
    });
    if (values.includes(null) || values.indexOf(undefined || '') !== -1) {
      this.setState({ hasErrors: true });
      return true;
    } else {
      this.setState({ hasErrors: false });
      return false;
    }
  };

  replaceContactStatePath = statePath => {
    const { trilogyCase } = this.props;
    const { contactIndex } = this.state;
    const isPatient =
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) ===
        'patient' ||
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) === 'Patient';

    if (!isPatient || statePath.includes('type')) {
      return statePath.replace(
        'contacts.contact[]',
        `contacts.contact[${contactIndex}]`
      );
    } else if (statePath.includes('name.first')) {
      return statePath.replace(
        'contacts.contact[].name.first',
        'patient.patient[0].firstName'
      );
    } else if (statePath.includes('name.last')) {
      return statePath.replace(
        'contacts.contact[].name.last',
        'patient.patient[0].lastName'
      );
    } else if (statePath.includes('address')) {
      return statePath.replace(
        'contacts.contact[].address[0]',
        'patient.patient[0].contact_info[0]'
      );
    } else if (statePath.includes('country')) {
      return statePath.replace(
        'contacts.contact[].country',
        'patient.patient[0].contact_info[0].country'
      );
    } else if (statePath.includes('phone')) {
      return statePath.replace(
        'contacts.contact[].phone[0].number',
        'patient.patient[0].contact_info[0].phone[0].number'
      );
    } else if (statePath.includes('organization')) {
      return statePath.replace(
        'contacts.contact[].phone[0].number',
        'patient.patient[0].contact_info[0].phone[0].number'
      );
    } else return statePath.replace('contacts.contact[]', 'patient.patient[0]');
  };

  replacePrescriberStatePath = statePath => {
    const { trilogyCase } = this.props;
    const prescriberIndex = get(trilogyCase, 'contacts.contact', []).findIndex(
      contact => get(contact, 'pq.prescriber') === true
    );
    return statePath.replace(
      'prescriberPath',
      `contacts.contact[${prescriberIndex}]`
    );
  };

  replacePlaceholderPaths = statePath => {
    const { productPath } = this.props;
    if (statePath.includes('productPath')) {
      statePath = statePath.replace('productPath', productPath);
    }
    if (statePath.includes('contacts.contact[]')) {
      statePath = this.replaceContactStatePath(statePath);
    }
    if (statePath.includes('prescriberPath')) {
      statePath = this.replacePrescriberStatePath(statePath);
    }
    return statePath;
  };

  getValue = (trilogyCase, statePath, required) => {
    const { inputValues, notRequiredFields } = this.state;

    const { contactIndex } = this.state;
    const isPatient =
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) ===
        'patient' ||
      get(trilogyCase, `contacts.contact[${contactIndex}].type`) === 'Patient';

    const values = required ? inputValues : notRequiredFields;

    if (
      isPatient &&
      (statePath.includes('organization') ||
        statePath.includes('accountNumber')) &&
      (values[statePath] === null || values[statePath] === undefined)
    ) {
      return 'N/A';
    }

    if (Object.prototype.hasOwnProperty.call(values, statePath)) {
      return values[statePath] === null ||
        values[statePath] === undefined ||
        values[statePath].length === 0
        ? ''
        : values[statePath];
    }
    return get(trilogyCase, statePath);
  };

  getContactsData = (trilogyCase, contactsLabels) => {
    const contactsDropdown = get(trilogyCase, 'contacts.contact', []).flatMap(
      (contact, index) => {
        if (get(contact, 'reporter.private')) {
          return [];
        }
        const key = index;
        const value = index.toString();
        const contactOption = contactsLabels.find(
          element => contact.type === element.value
        );

        let label = '';
        if (contactOption) {
          label += `[${contactOption.label}] `;
        }
        if (contact.type === 'patient' || contact.type === 'patient') {
          label += `${get(trilogyCase, 'patient.patient[0].firstName') || ''} `;
          label += `${get(trilogyCase, 'patient.patient[0].lastName') || ''} `;
        } else {
          label += `${get(contact, 'name.first') || ''} `;
          label += `${get(contact, 'name.last') || ''} `;
        }
        return label.trim() === ''
          ? []
          : [
              {
                key,
                label,
                value
              }
            ];
      }
    );
    contactsDropdown.push({
      key: 'new',
      label: 'Add Contact',
      value: 'new'
    });

    return contactsDropdown;
  };

  getErrors = (statePath, required) => {
    if (required === false) {
      return [];
    } else if (
      this.state.inputValues[statePath] === null ||
      this.state.inputValues[statePath] === '' ||
      // weird case for prescriber where the fields default to false if no prescriber is found
      (statePath.includes('prescriberInfo') &&
        this.state.inputValues[statePath] === false)
    ) {
      return [''];
    }
  };

  renderSelect = (index, field, tacticalData, trilogyCase) => {
    const statePath = this.replacePlaceholderPaths(field.statePath);
    const validations = {
      required: { constraint: field.required === false ? false : true }
    };
    const value = this.getValue(
      trilogyCase,
      statePath,
      validations.required.constraint
    );
    return (
      <Select
        key={index}
        label={field.label}
        options={get(tacticalData, field.optionsPath)}
        value={value}
        onChange={v => {
          this.handleUpdate(v, statePath);
          this.handleChange(v, statePath, field.required);
        }}
        errors={this.getErrors(statePath, field.errors)}
        disabled={field.disabled}
        validations={validations}
      />
    );
  };

  renderCheckbox = (index, field, trilogyCase) => {
    const statePath = this.replacePlaceholderPaths(field.statePath);
    const validations = {
      required: { constraint: field.required === false ? false : true }
    };
    const value = this.getValue(
      trilogyCase,
      statePath,
      validations.required.constraint
    );
    return (
      <Checkbox
        key={index}
        label={field.label}
        value={value}
        onChange={v => {
          this.handleUpdate(v, statePath);
          this.handleChange(v, statePath, field.required);
        }}
        errors={this.getErrors(statePath, field.errors)}
        disabled={field.disabled}
        validations={validations}
      />
    );
  };

  renderTextInput = (index, field, trilogyCase) => {
    const statePath = this.replacePlaceholderPaths(field.statePath);
    const validations = {
      required: { constraint: field.required === false ? false : true }
    };
    const value = this.getValue(
      trilogyCase,
      statePath,
      validations.required.constraint
    );
    return (
      <TextInput
        key={index}
        label={field.label}
        value={value}
        helpText={field.helpText}
        onBlur={v => {
          this.handleUpdate(v, statePath);
          this.handleChange(v, statePath, field.required);
        }}
        onChange={v => this.handleChange(v, statePath, field.required)}
        errors={this.getErrors(statePath, field.errors)}
        disabled={field.disabled}
        validations={validations}
      />
    );
  };

  renderDateInput = (index, field, trilogyCase) => {
    const statePath = this.replacePlaceholderPaths(field.statePath);
    const validations = {
      required: { constraint: field.required === false ? false : true }
    };
    const value = this.getValue(
      trilogyCase,
      statePath,
      validations.required.constraint
    );
    return (
      <DateInput
        key={index}
        label={field.label}
        value={value}
        onChange={v => {
          this.handleUpdate(v, statePath);
          this.handleChange(v, statePath, field.required);
        }}
        errors={this.getErrors(statePath, field.errors)}
        disabled={field.disabled}
        validations={validations}
      />
    );
  };

  renderFields = fields => {
    const { tacticalData, trilogyCase } = this.props;
    return (
      <div
        style={{
          minWidth: '500px',
          display: 'grid',
          gridTemplateColumns: '250px 250px',
          columnGap: '12px',
          rowGap: '6px'
        }}
      >
        {fields.map((field, index) => {
          if (
            field.conditionalPath &&
            get(
              trilogyCase,
              this.replacePlaceholderPaths(field.conditionalPath)
            )
          ) {
            // do not render field if conditionalPath has a value
            return null;
          }
          switch (field.component) {
            case 'Checkbox':
              return this.renderCheckbox(index, field, trilogyCase);
            case 'Select':
              return this.renderSelect(index, field, tacticalData, trilogyCase);
            case 'DateInput':
              return this.renderDateInput(index, field, trilogyCase);
            default:
              return this.renderTextInput(index, field, trilogyCase);
          }
        })}
      </div>
    );
  };

  renderSections = () => {
    const { trilogyCase, tacticalData } = this.props;
    const { contactDropdownValue, sections } = this.state;
    const contactsDropdown = this.getContactsData(
      trilogyCase,
      get(tacticalData, 'document-data.mastercase-options.type_of_contact')
    );
    return Object.entries(sections).map(([sectionLabel, fields]) => (
      <div>
        <span className={modalStyles.title}>{sectionLabel}</span>
        {sectionLabel === 'Shipping Info' ? (
          <div
            style={{
              minWidth: '500px',
              display: 'grid',
              gridTemplateColumns: '250px 250px',
              columnGap: '12px',
              rowGap: '6px',
              paddingBottom: '6px'
            }}
          >
            <Select
              label="Select a contact"
              options={contactsDropdown}
              value={contactDropdownValue}
              onChange={value => {
                this.handleContactChange(value, trilogyCase);
              }}
            />
          </div>
        ) : null}
        {this.renderFields(fields)}
      </div>
    ));
  };

  render = () => {
    const { flow } = this.props;
    return (
      <div className={`${modalStyles.base} ${modalStyles.modalWithOverflow}`}>
        <span
          className={modalStyles.title}
          style={{
            borderBottom: '1px solid #00A9E0',
            paddingBottom: '20px'
          }}
        >
          {mapping[flow].title}
        </span>
        {this.renderSections()}
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            paddingTop: '25px',
            margin: '0',
            fontSize: '16px'
          }}
        >
          {this.state.hasErrors
            ? 'Please enter all required fields in order to send a request.'
            : ''}
        </p>
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton
            onClick={() => this.props.actions.emitModalContentClear()}
          >
            CANCEL
          </SimpleButton>
          <SimpleButton onClick={this.handleSend} primary>
            SEND
          </SimpleButton>
        </div>
      </div>
    );
  };
}

export default SendModal;
