import React from 'react';
import PropTypes from 'prop-types';

const PageButton = props => {
  const { page, children, disabled, onClick, isActive } = props;
  const handleClick = () => onClick(page);
  return (
    <button
      key={`page-${page}`}
      className={`pt-button ${isActive ? 'pt-intent-primary' : ''}`}
      disabled={disabled}
      onClick={handleClick}
    >
      {children || page + 1}
    </button>
  );
};

PageButton.propTypes = {
  page: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired
};

PageButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  isActive: false
};

export default PageButton;
