import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { Checkbox, SimpleButton } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import fetchCaseExport from 'api/rest/fetchCaseExport';

export default class ExportMCToPdfModal extends PureComponent {
  static propTypes = {
    trilogyCaseId: PropTypes.string.isRequired
  };
  state = {
    includeDocs: false,
    loading: false,
    errorMessage: null
  };

  updateCheckbox = checked => {
    this.setState({
      includeDocs: checked
    });
  };

  handleExportClick = () => {
    const { trilogyCaseId } = this.props;
    const onSuccess = () => {
      this.setState({ loading: false });
    };
    const onFailure = errorMessage => {
      this.setState({ loading: false, errorMessage });
    };
    this.setState({ loading: true, errorMessage: null });
    fetchCaseExport(
      [trilogyCaseId],
      'SINGLE',
      this.state.includeDocs,
      onSuccess,
      onFailure
    );
  };

  renderStatusText = () => {
    const { loading, errorMessage } = this.state;
    if (loading) {
      return (
        <div style={{ padding: '25px 25px 0 25px', minWidth: '450px' }}>
          <NonIdealState
            visual={<Spinner />}
            title=""
            description="Please wait while the documents are being prepared..."
          />
        </div>
      );
    } else if (errorMessage) {
      return (
        <div style={{ padding: '25px 25px 0 25px', minWidth: '450px' }}>
          <NonIdealState visual={null} title="" description={errorMessage} />
        </div>
      );
    }
    return null;
  };

  render() {
    const { includeDocs, loading } = this.state;
    return (
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        <span className={modalStyles.title}>
          Export Master Case to PDF {'\u00A0'}
        </span>
        <div>Only Master Case information will be exported.</div>
        <div style={{ padding: '25px 25px 0 25px', minWidth: '450px' }}>
          <Checkbox
            value={includeDocs}
            onChange={this.updateCheckbox}
            label="Include source docs?"
            isFullWidth
          />
        </div>
        {this.renderStatusText()}
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton
            onClick={this.handleExportClick}
            disabled={loading}
            primary
          >
            Export
          </SimpleButton>
        </div>
      </div>
    );
  }
}
