import { client } from 'config/apollo';

import createCaseShellMutation from './mutations/case/createCaseShell';
import caseResponseFormatter from './formatter/caseResponse';

const fetchCaseId = (onSuccess, onFailure, caseFragment) => {
  const variables = {};

  return client
    .mutate({
      mutation: createCaseShellMutation(caseFragment),
      fetchPolicy: 'no-cache',
      variables
    })
    .then(({ data: { createCaseShell } }) => {
      const thawed = Object.isFrozen(createCaseShell)
        ? JSON.parse(JSON.stringify(createCaseShell))
        : createCaseShell;
      return caseResponseFormatter(thawed);
    })
    .then(onSuccess, onFailure);
};

export default fetchCaseId;
