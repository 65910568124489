/* eslint no-console:0 */
import React, { Fragment, PureComponent, Children, createElement } from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';

import TableExpander from 'Common/components/TableExpander';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

class TableRow extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onExpandToggleClick: PropTypes.func,
    expansionLocked: PropTypes.bool,
    isExpanded: PropTypes.bool,
    expandedView: PropTypes.node,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      expandedViewContainer: PropTypes.object.isRequired,
      expandedView: PropTypes.object.isRequired,
      transition: PropTypes.object.isRequired,
      gap: PropTypes.object.isRequired
    }).isRequired,
    children: PropTypes.node
  };

  static defaultProps = {
    onExpandToggleClick: () => {},
    expansionLocked: false,
    isExpanded: false,
    expandedView: null,
    children: null
  };

  componentWillMount() {
    this.checkChildTypes();
  }

  checkChildTypes = () => {
    const areTableDataElements = type => type === 'td';
    /**
     * As we have passed Checkbox as seperate  <td> and a seperate map of <td>s for other column lables,
     * this.props.children is coming as [{type:'td'},[{type:'td'},{type:'td'}]]
     * So we have used a single level deep flatten on this.props.children to make it as a single array like [{type:'td'},{type:'td'},{type:'td'}].
     */
    const hasCorrectChildrenTypes = flatten(this.props.children)
      .map(({ type }) => type)
      .every(areTableDataElements);

    if (!hasCorrectChildrenTypes) {
      console.error(
        'Child elements of TableRow should be wrapped in <td></td>!'
      );
    }
  };

  renderExpandedView = () => {
    const {
      id,
      isExpanded,
      expandedView,
      computedStyles,
      children
    } = this.props;

    /**
     * As we have passed Checkbox as seperate  <td> and a seperate map of <td>s for other column lables,
     * this.props.children is coming as [{type:'td'},[{type:'td'},{type:'td'}]]
     * So we have used a single level deep flatten on this.props.children to make it as a single array like [{type:'td'},{type:'td'},{type:'td'}].
     */

    return (
      <tr
        className={computedStyles.expandedViewContainer}
        data-id={`${id}-table-row`}
      >
        <td colSpan={flatten(children).length + 1}>
          <div className={computedStyles.transition}>
            {isExpanded ? expandedView : null}
          </div>
        </td>
      </tr>
    );
  };

  // Places a 1px gap between rows, allowing the top of the hover border to be displayed
  renderRowGap = () => (
    <tr>
      <td className={this.props.computedStyles.gap} />
    </tr>
  );

  renderExpander = () => (
    <TableExpander
      key={this.props.id}
      $id={`${this.props.id}-expander`}
      expansionLocked={this.props.expansionLocked}
      onClick={this.props.onExpandToggleClick}
      isExpanded={this.props.isExpanded}
    />
  );

  /**
   * Convenience to allow <TableRow>'s children to be <td> elements without adding unnecessary elements.
   * Styling is placed on the inner div to create dividers between each column.
   */
  renderChildren = () => {
    /**
     * As we have passed Checkbox as seperate  <td> and a seperate map of <td>s for other column lables,
     * this.props.children is coming as [{type:'td'},[{type:'td'},{type:'td'}]]
     * So we have used a single level deep flatten on this.props.children to make it as a single array like [{type:'td'},{type:'td'},{type:'td'}].
     */
    const children = Children.toArray(flatten(this.props.children));
    const expanderIndex = children.length + 1;
    const expander = this.props.expandedView
      ? this.renderExpander(expanderIndex)
      : null;

    return children
      .map((child, i) => <td key={i}>{createElement('div', child.props)}</td>)
      .concat(expander);
  };

  render() {
    const { computedStyles, expandedView } = this.props;

    return (
      <Fragment>
        <tr className={computedStyles.base}>{this.renderChildren()}</tr>
        {expandedView ? this.renderExpandedView() : this.renderRowGap()}
      </Fragment>
    );
  }
}

export default withStyles(stylesGenerator)(TableRow);
