import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    position: 'relative',
    color: theme.colors.secondary,
    ...theme.fonts.default,
    fontWeight: '600',
    fontSize: '14px',
    '> .pt-popover-target > span:first-child': {
      cursor: 'pointer'
    }
  }),

  popoverContent: generateCSS({
    background: theme.colors.base,
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '768px'
  })
});

export default stylesGenerator;
