import gql from 'graphql-tag';

const searchProductQuery = () => gql`
  query searchProduct($q: ProductSearchQuery!) {
    searchProducts(q: $q, size: 10000, from: 0) {
      total
      status
      hits {
        batch
        listNumber
        trilogyMarketedName
        productFamily
        SAPMaterialDescription
      }
    }
  }
`;

export default searchProductQuery;
