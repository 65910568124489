import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

/*
 * Set the `controlled` prop to false at the form model level to capture input changes with onBlur.
 * This will force the updating of the Redux store only once the input loses focus, rather than on key press.
 */
class Input extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    value: PropTypes.string,
    children: PropTypes.element.isRequired,
    'data-id': PropTypes.string // Only provided by non-CSE inputs
  };

  static defaultProps = {
    ...formElementDefaultProps,
    layout: { width: '100%' },
    styles: { width: '100%' },
    value: ''
  };

  constructor(props) {
    super(props);
    this.state = { value: props.value || '', lastUpdate: Date.now() };
  }

  componentWillReceiveProps(props) {
    if (props.value !== this.state.value) {
      this.setState({ value: props.value, lastUpdate: Date.now() });
    }
  }

  focus = () => document.getElementById(this.props.id).focus();

  handleChange = e => {
    const { target } = e;
    const { onChange } = this.props;

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.setState({ value: target.value, lastUpdate: Date.now() });
    onChange(target.value);
  };

  handleBlur = e => {
    const { onBlur } = this.props;
    const { target } = e;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onBlur(target.value);
  };

  render() {
    const {
      $id,
      component,
      schemaPath,
      id,
      type,
      helpText,
      errors,
      placeholder,
      computedStyles,
      autoFocus,
      disabled,
      min,
      max,
      onKeyDown,
      maxLength
    } = this.props;

    const inputProps = {
      id,
      type,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      disabled,
      autoFocus,
      placeholder,
      ref: el => (this.component = el),
      className: `pt-input ${computedStyles.input}`,
      value: this.state.value || '',
      'aria-labelledby': `label-${id}`,
      min,
      max,
      onKeyDown,
      maxLength
    };

    return (
      <div
        className={computedStyles.base}
        role="group"
        data-id={$id || this.props['data-id']}
        data-schema-path={schemaPath}
      >
        <Label {...this.props} />
        {component === 'TextArea' || inputProps.type === 'textarea' ? (
          <textarea {...inputProps} />
        ) : (
          <input
            type="text"
            min={min}
            max={max}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            {...inputProps}
          />
        )}
        {helpText ? (
          <span className={computedStyles.helpText}>{helpText}</span>
        ) : null}
        <ValidationErrors
          id={id}
          computedStyles={computedStyles}
          validationErrors={errors}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Input);
