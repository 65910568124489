import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { chain, camelCase, isEqual, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { modalStyles } from 'Common/components/withModal';
import theme from 'config/theme';

import ascending from 'public/images/ascending.svg';
import ascendingSelected from 'public/images/ascending-selected.svg';
import descending from 'public/images/descending.svg';
import descendingSelected from 'public/images/descending-selected.svg';
import {
  CMS_PROP_TYPES,
  STATUSES_MAP,
  CASE_STATUS_COMPLETED,
  CC_SUB_CASE_TYPES_MAP,
  PARENT_CASE,
  FROM_CREATE_CASE,
  // PARENT_CASE_IS_EMAIL,
  // EXTERNAL_SOURCE,
  SORT_ASC,
  SORT_DESC,
  N_A,
  NOT_SPECIFIED
} from 'Common/constants';
import { abbvieDateFormat } from 'Common/components/Form/utils';
import { GENERIC_STATE_PATHS, HEADER_DATA } from 'Search/constants';
import { withStyles, SimpleButton, Checkbox } from 'Common/components/Form';
import NoResults from 'Common/components/NoResults';
import SeriousnessIndicator from 'Common/components/SeriousnessIndicator';
import ExportCasePdfModal from 'Common/components/ExportCasePdfModal';
import ExportMCToPdfModal from 'Common/components/ExportMCToPdfModal';
import ExportBulkCasesModal from 'Common/components/ExportBulkCasesModal';
import {
  processSeriousnessCriteria,
  generateCaseLink,
  generateSubCaseLink,
  getOrElse,
  getMasterCaseProductValue,
  getSubcaseProductValue
} from 'Common/utils';
import stylesGenerator from './styles';
import Notifier from '../../../Common/components/Notifier';

class SearchResults extends PureComponent {
  static propTypes = {
    onSortClick: PropTypes.func,
    isDuplicateSearch: PropTypes.bool.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      caseIdLink: PropTypes.object.isRequired,
      columnGroup: PropTypes.object.isRequired,
      columnHeader: PropTypes.object.isRequired,
      primaryColor: PropTypes.object.isRequired,
      secondaryColor: PropTypes.object.isRequired,
      sortImage: PropTypes.object.isRequired,
      tableCell: PropTypes.object.isRequired,
      tableFooter: PropTypes.object.isRequired,
      viewLink: PropTypes.object.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        page: PropTypes.string
      }).isRequired
    }).isRequired,
    actions: PropTypes.shape({
      push: PropTypes.func.isRequired,
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired,
    sortBy: PropTypes.objectOf(PropTypes.string).isRequired,
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired,
    maxBulkExportSize: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedCasesToLink: [],
      areAllCasesSelected: false,
      allMasterCases: []
    };
  }

  static defaultProps = {
    onSortClick: () => {},
    maxBulkExportSize: 100
  };

  defineColumnGroups = () => {
    const { computedStyles } = this.props;

    return HEADER_DATA.map((headerData, i) => (
      <colgroup
        key={headerData.groupLabel}
        span={headerData.columns.length}
        className={
          i % 2 ? computedStyles.secondaryColor : computedStyles.primaryColor
        }
      />
    ));
  };

  generateMasterCaseResult = masterCase => {
    const { match, tacticalData } = this.props;
    this.createMasterCaseArray(masterCase.id); // used for case Linking
    const awarenessDate =
      abbvieDateFormat(
        getOrElse(masterCase, GENERIC_STATE_PATHS.AWARENESS_DATE)
      ) || N_A;

    const pqAwarenessDate =
      abbvieDateFormat(
        getOrElse(masterCase, GENERIC_STATE_PATHS.PQ_AWARENESS_DATE)
      ) || N_A;
    const patientDOB = getOrElse(
      masterCase,
      GENERIC_STATE_PATHS.PATIENT_DOB,
      N_A
    );

    const columnData = {
      id: masterCase.id,
      masterCaseId: masterCase.id, // needed for Link case
      caseLink: generateCaseLink(match, masterCase),
      affiliateCountryLabel: masterCase.affiliateCountryLabel,
      awarenessDate,
      pqAwarenessDate,
      seriousnessValue: processSeriousnessCriteria(masterCase),
      product: getMasterCaseProductValue(masterCase, tacticalData, N_A),
      patientFn: getOrElse(masterCase, GENERIC_STATE_PATHS.PATIENT_FN, N_A),
      patientLn: getOrElse(masterCase, GENERIC_STATE_PATHS.PATIENT_LN, N_A),
      patientInitials: getOrElse(
        masterCase,
        GENERIC_STATE_PATHS.PATIENT_INITIALS,
        N_A
      ),
      patientDOB:
        patientDOB === N_A ? patientDOB : abbvieDateFormat(patientDOB),
      thirdPartyPatientId: getOrElse(
        masterCase.patient,
        'patient[0].reporter',
        N_A
      ),
      status: STATUSES_MAP[getOrElse(masterCase, 'status', 'NEW')],
      checkbox: this.renderCheckboxCell(masterCase.id, false)
    };

    const subcases = chain(masterCase.subcases)
      .map((subcase, subCaseKey) =>
        this.generateSubcaseResult(
          masterCase,
          subCaseKey,
          columnData,
          tacticalData
        )
      )
      .sortBy(c => c.id) // Sort Subcases
      .value();

    return [columnData].concat(subcases);
  };

  generateSubcaseResult = (
    masterCase,
    subcaseKey,
    columnData,
    tacticalData
  ) => {
    const id = getOrElse(masterCase, `subcases.${subcaseKey}.id`);
    const awarenessDate =
      subcaseKey === CC_SUB_CASE_TYPES_MAP.ae
        ? abbvieDateFormat(getOrElse(masterCase, GENERIC_STATE_PATHS.AE_LRD))
        : abbvieDateFormat(
            getOrElse(masterCase, GENERIC_STATE_PATHS.AWARENESS_DATE)
          );

    const reactions = getOrElse(
      masterCase,
      'subcases.adverseEvent.reactions.reaction',
      [N_A]
    ).map(reaction => getOrElse(reaction, 'adverse_event', NOT_SPECIFIED));

    const listNumbers = getOrElse(
      masterCase,
      'subcases.productQuality.pqproduct.products',
      [N_A]
    ).map(product =>
      getOrElse(product, 'details[0].listNumber', NOT_SPECIFIED)
    );

    return {
      ...columnData,
      id,
      subcaseKey,
      masterCaseId: masterCase.id, // needed for Link case
      caseLink: generateSubCaseLink(masterCase, subcaseKey),
      awarenessDate: awarenessDate || N_A,
      reactions,
      listNumbers,
      product: getSubcaseProductValue(
        masterCase,
        tacticalData,
        subcaseKey,
        N_A
      ),
      status:
        STATUSES_MAP[
          getOrElse(masterCase, `subcases.${subcaseKey}.status`, 'NEW')
        ],
      checkbox: this.renderCheckboxCell(masterCase.id, true)
    };
  };

  renderCheckboxCell = (masterCaseId, isDisabled) => (
    <Checkbox
      styles={
        isDisabled
          ? {
              ':checked': {
                background: theme.colors.quaternary,
                boxShadow: `0 0 0 2px ${theme.colors.quaternary}`,
                '::after': {
                  display: 'block',
                  lineHeight: 1.4,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  content: '✔︎',
                  color: 'white',
                  fontSize: 10,
                  width: 18,
                  textAlign: 'center'
                }
              }
            }
          : null
      }
      disabled={isDisabled} // subcase checkboxes disabled
      value={this.state.selectedCasesToLink.includes(masterCaseId)} // checked = true if case is in the array of cases
      onChange={event => this.handleSelectCaseToLink(masterCaseId, event)} // add or remove cases in array
    />
  );

  // for case linking
  createMasterCaseArray = masterCaseId => {
    const updatedMasterCases = this.state.allMasterCases;
    if (!updatedMasterCases.includes(masterCaseId))
      updatedMasterCases.push(masterCaseId);
    this.setState({ allMasterCases: updatedMasterCases });
  };

  handleSelectCaseToLink = (selectedMasterCaseId, event) => {
    const selectedCasesToLink = this.state.selectedCasesToLink;
    // add or remove cases to be linked based on event value (check/true or unchecked/false)
    const updatedCasesToLink = event
      ? selectedCasesToLink.concat(selectedMasterCaseId)
      : selectedCasesToLink.filter(caseId => caseId !== selectedMasterCaseId);
    this.setState({ selectedCasesToLink: updatedCasesToLink });
  };

  handleSelectAllCases = event => {
    const allMasterCases = this.state.allMasterCases;
    this.setState({ selectedCasesToLink: event ? allMasterCases : [] });
  };

  // determines if checkbox in table header is checked or not
  checkHeaderCheckbox = () => {
    this.setState({
      areAllCasesSelected: isEqual(
        this.state.allMasterCases,
        this.state.selectedCasesToLink.sort()
      )
    });
  };

  handleBulkCaseExport = () => {
    const { maxBulkExportSize, actions } = this.props;
    if (this.state.selectedCasesToLink.length > maxBulkExportSize) {
      Notifier.show({
        intent: Notifier.DANGER,
        message: `Attempting to export greater than ${maxBulkExportSize} records.  Please narrow your selection.`
      });
    } else {
      actions.emitModalContentUpdate(
        <ExportBulkCasesModal
          selectedCases={this.state.selectedCasesToLink}
          actions={actions}
        />
      );
    }
  };

  handleLinkCases = selectedCases => {
    const { actions } = this.props;

    const handleDismiss = (shouldClose = true) => {
      actions.emitModalContentClear();
      if (shouldClose) window.close();
    };
    const handleConfirmLink = () => {
      // This function will only be called as part of Duplicate search when we are a pop-up window
      if (typeof window.linkCase === 'function') {
        // create a shell case if there is no parent case (did not navigate to dupe search from an existing case)
        if (!window[PARENT_CASE] || isEmpty(window[PARENT_CASE])) {
          actions.emitFetchNewCaseId().then(res => {
            const shellCaseId = res.payload.newCase.id;
            window.linkCase(shellCaseId, selectedCases);
            // without setTimeout the redirect was causing problems with the case linking
            setTimeout(() => {
              window.redirectNewCase(shellCaseId);
              handleDismiss(true);
            }, 2000);
          });
        } else if (window[PARENT_CASE]) {
          window.linkCase(window[PARENT_CASE].id, selectedCases);
          handleDismiss(true);
        }
      } else {
        console.error(
          'Link button is expecting fn:linkCase to be on the window'
        );
        handleDismiss(true);
      }
    };
    const content = this.renderLinkModal(
      handleConfirmLink,
      handleDismiss,
      selectedCases
    );
    actions.emitModalContentUpdate(content);
  };

  handleMergeCase = caseId => {
    if (typeof window.mergeCase === 'function') {
      window.mergeCase(caseId);
    } else {
      console.error(
        'F/U To Existing Case button is expecting fn:mergeCase to be on the window'
      );
    }
    window.close();
  };

  renderLinkModal = (handleConfirm, handleDismiss, caseId) => (
    <div className={modalStyles.base}>
      <span className={modalStyles.title}>CONFIRM CASE LINK</span>
      Are you sure you want to link the following case(s)?
      <br />
      <br />
      {caseId.map((id, i) => <p key={i}>{id}</p>)}
      <div className={modalStyles.buttonsContainer}>
        <SimpleButton onClick={() => handleDismiss(false)}>
          No, cancel
        </SimpleButton>
        <SimpleButton onClick={handleConfirm} primary>
          Yes, continue
        </SimpleButton>
      </div>
    </div>
  );

  renderTableHeader = () => (
    <thead>
      <tr>{HEADER_DATA.map(this.renderColumnGroups)}</tr>
      <tr>{HEADER_DATA.map(this.renderTableHeaders)}</tr>
    </thead>
  );

  renderColumnGroups = headerData => {
    const { computedStyles } = this.props;
    return (
      <th
        key={headerData.groupLabel}
        colSpan={headerData.columns.length}
        scope="colgroup"
        className={computedStyles.columnGroup}
      >
        {headerData.groupLabel}
      </th>
    );
  };

  renderTableHeaders = headerData => {
    const { computedStyles } = this.props;
    return headerData.columns.map(cell => (
      <th
        scope="col"
        className={`${computedStyles.columnHeader} ${camelCase(cell.label)}`}
        key={cell.label}
      >
        {cell.label}
        {cell.checkbox ? (
          <div className={computedStyles.center}>
            <Checkbox
              value={this.state.areAllCasesSelected}
              onChange={this.handleSelectAllCases}
            />
          </div>
        ) : null}
        {cell.sortBy ? this.renderSortArrow(cell.sortBy) : null}
      </th>
    ));
  };

  renderSortArrow = sortBy => {
    const { onSortClick, computedStyles } = this.props;
    const handleSortClick = order => onSortClick(sortBy, order);

    return (
      <div className={computedStyles.sortDiv}>
        <img
          className={computedStyles.sortImage}
          src={
            this.props.sortBy[sortBy] === SORT_ASC
              ? ascendingSelected
              : ascending
          }
          onClick={() => handleSortClick(SORT_ASC)}
        />
        <img
          className={computedStyles.sortImage}
          src={
            this.props.sortBy[sortBy] === SORT_DESC
              ? descendingSelected
              : descending
          }
          onClick={() => handleSortClick(SORT_DESC)}
        />
      </div>
    );
  };

  renderTableBody = filteredRows => (
    <tbody>{filteredRows.map(this.renderResultsRow)}</tbody>
  );

  renderResultsRow = columnData => {
    const { actions, computedStyles, isDuplicateSearch } = this.props;
    if (!columnData.id) {
      return null;
    }
    // as per TRIL-2554, case merge can now be performed regardless of method of receipt
    // const parentCreatedByEmail = !!window[PARENT_CASE_IS_EMAIL];
    // const parentFromTrilogyLite = window[EXTERNAL_SOURCE] === 'trilogy-lite';
    const fromCreateCase = !!window[FROM_CREATE_CASE];
    const canMerge =
      isDuplicateSearch &&
      fromCreateCase &&
      // (parentCreatedByEmail || parentFromTrilogyLite) &&
      [CC_SUB_CASE_TYPES_MAP.ae, CC_SUB_CASE_TYPES_MAP.pq].includes(
        columnData.subcaseKey
      ) &&
      columnData.status === STATUSES_MAP[CASE_STATUS_COMPLETED];

    const selected = columnData.checkbox.props.value ? 'selected' : null;
    let rowStyle = null;
    if (columnData.subcaseKey)
      rowStyle = `${computedStyles.tableCell} subCase `.concat(selected);
    else rowStyle = `${computedStyles.tableCell} masterCase `.concat(selected);

    let caseType = '';
    if (columnData.subcaseKey === CC_SUB_CASE_TYPES_MAP.ae) {
      caseType = 'ae';
    } else if (columnData.subcaseKey === CC_SUB_CASE_TYPES_MAP.pq) {
      caseType = 'pq';
    }

    return (
      <tr key={columnData.id}>
        <td
          className={`${computedStyles.tableCell} ${rowStyle} ${
            computedStyles.center
          }`}
        >
          {columnData.checkbox}
        </td>
        <td className={`${computedStyles.caseIdLink} ${rowStyle} light`}>
          {columnData.seriousnessValue ? (
            <SeriousnessIndicator value={columnData.seriousnessValue} />
          ) : null}
          <Link
            to={columnData.caseLink}
            className={computedStyles.id}
            target="_blank"
          >
            {columnData.id}
          </Link>
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle} light`}>
          {columnData.product}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle} light`}>
          {columnData.affiliateCountryLabel === null
            ? 'N/A'
            : columnData.affiliateCountryLabel}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle} light`}>
          {columnData.awarenessDate}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle} light`}>
          {columnData.pqAwarenessDate}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {(columnData.reactions || [N_A]).map((reaction, i) => (
            <p key={i}>{reaction}</p>
          ))}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {(columnData.listNumbers || [N_A]).map((listNumber, i) => (
            <p key={i}>{listNumber}</p>
          ))}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.patientFn}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.patientLn}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.patientInitials}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.patientDOB}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.thirdPartyPatientId}
        </td>
        <td className={`${computedStyles.tableCell} ${rowStyle}`}>
          {columnData.status}
        </td>
        <td className={`${computedStyles.viewLink} ${rowStyle}`}>
          {!isDuplicateSearch ? (
            <div>
              <Link to={columnData.caseLink} target="_blank">
                View
              </Link>
              <br />
            </div>
          ) : null}
          <a
            role="button"
            onClick={() => {
              const isMasterCase = columnData.id.split('-').length === 2;
              if (isMasterCase) {
                actions.emitModalContentUpdate(
                  <ExportMCToPdfModal trilogyCaseId={columnData.id} />
                );
              } else {
                actions.emitModalContentUpdate(
                  <ExportCasePdfModal
                    trilogyCaseId={columnData.id
                      .split('-')
                      .slice(0, 2)
                      .join('-')}
                    page={caseType}
                    {...this.props}
                  />
                );
              }
            }}
          >
            Export
          </a>
        </td>
        {canMerge ? (
          <td className={`${computedStyles.caseIdLink} ${rowStyle}`}>
            <a
              onClick={() => this.handleMergeCase(columnData.id)}
              data-merge-id={columnData.id}
              role="button"
            >
              F/U To Existing Case
            </a>
          </td>
        ) : (
          <td className={`${computedStyles.caseIdLink} ${rowStyle}`} />
        )}
      </tr>
    );
  };

  renderTableFooter = length => {
    const { computedStyles } = this.props;
    // TODO: Calculate colSpan value for td
    return (
      <tfoot className={computedStyles.tableFooter}>
        <tr>
          <td colSpan="11">
            <span>Displaying 1-10 of {length}</span>
          </td>
        </tr>
      </tfoot>
    );
  };

  renderActionButtons = () => {
    const { isDuplicateSearch } = this.props;
    return (
      <div style={{ display: 'flex', alignContent: 'flex-start' }}>
        {isDuplicateSearch && (
          <div style={{ margin: '28px 0 80px' }}>
            <SimpleButton
              primary
              // disabled if no cases selected to link
              disabled={!this.state.selectedCasesToLink.length}
              onClick={() =>
                this.handleLinkCases(this.state.selectedCasesToLink)
              }
            >
              {window[PARENT_CASE] && !isEmpty(window[PARENT_CASE])
                ? 'Link to Existing Case'
                : 'Create New Case & Link'}
            </SimpleButton>
          </div>
        )}
        <div
          style={{
            margin: isDuplicateSearch ? '28px 5px 80px' : '28px 0 80px'
          }}
        >
          <SimpleButton
            primary
            // disabled if no cases selected to export
            disabled={!this.state.selectedCasesToLink.length}
            onClick={this.handleBulkCaseExport}
          >
            Export Selected Cases
          </SimpleButton>
        </div>
      </div>
    );
  };

  renderResultsTable = filteredRows => (
    <div>
      <table cellSpacing="0" style={{ width: '100%' }}>
        {this.defineColumnGroups()}
        {this.renderTableHeader()}
        {/* {this.renderTableFooter(filteredRows.length)} */}
        {this.renderTableBody(filteredRows)}
      </table>
      {this.renderActionButtons()}
    </div>
  );

  renderNoResults = () => <NoResults>No results found</NoResults>;

  render() {
    const { computedStyles, results } = this.props;

    const filteredResults = chain(results)
      .flatMap(this.generateMasterCaseResult)
      .value();

    this.checkHeaderCheckbox();

    return (
      <div id="searchResults" className={computedStyles.base}>
        {filteredResults.length
          ? this.renderResultsTable(filteredResults)
          : this.renderNoResults()}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(SearchResults);
