import React, { PureComponent } from 'react';
import Draggable from 'react-draggable';
import { set, cloneDeep, get, debounce } from 'lodash';
import { TextArea } from 'Common/components/Form';
import { Icon } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { CMS_PROP_TYPES } from 'Common/constants';

export default class CaseNotes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      caseNotes: get(props.trilogyCase, 'common.notes[0].note') || '',
      showNotes: false,
      typing: false,
      height: window.localStorage.getItem('NotesPositionAndSize')
        ? JSON.parse(window.localStorage.getItem('NotesPositionAndSize')).height
        : '200px',
      width: window.localStorage.getItem('NotesPositionAndSize')
        ? JSON.parse(window.localStorage.getItem('NotesPositionAndSize')).width
        : '260px',
      position: window.localStorage.getItem('NotesPositionAndSize')
        ? JSON.parse(window.localStorage.getItem('NotesPositionAndSize'))
            .position
        : { x: 0, y: 0 },
      disabled: false
    };
  }

  static propTypes = {
    trilogyCase: CMS_PROP_TYPES.trilogyCase.isRequired,
    updateCase: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.savePositionToLocalStorage);
    document.body.addEventListener('keydown', this.handleKeys, true);
    this.disable();
  }

  componentWillUnmount() {
    this.savePositionToLocalStorage();
    window.removeEventListener('beforeunload', this.savePositionToLocalStorage);
    document.body.removeEventListener('keydown', this.handleKeys, true);
  }

  componentDidUpdate(prevProps) {
    this.disable();
    if (this.props.trilogyCase !== prevProps.trilogyCase) {
      this.updateProps();
    }
  }

  disable = () => {
    const { trilogyCase } = this.props;
    if (
      trilogyCase.completed ||
      (get(trilogyCase, 'subcases.adverseEvent.status') === 'COMPLETED' &&
        window.location.pathname.includes('ae')) ||
      (get(trilogyCase, 'subcases.productQuality.status') === 'COMPLETED' &&
        window.location.pathname.includes('pq'))
    ) {
      this.setState({ disabled: true });
    } else this.setState({ disabled: false });
  };

  updateProps = debounce(() => {
    this.setState({
      caseNotes: get(this.props.trilogyCase, 'common.notes[0].note')
    });
  }, 1000);

  savePositionToLocalStorage = () => {
    const { height, width, position: { x, y } } = this.state;
    const posProps = { height, width, position: { x, y } };
    window.localStorage.setItem(
      'NotesPositionAndSize',
      JSON.stringify(posProps)
    );
  };

  saveCaseNotes = () => {
    const { caseNotes } = this.state;
    const { trilogyCase, updateCase } = this.props;
    const updatedCase = set(
      cloneDeep(trilogyCase),
      'common.notes[0].note',
      caseNotes
    );
    updateCase(updatedCase);
  };

  handleNotesChange = event => {
    this.setState({ caseNotes: event });
    this.saveOnTimeout(event);
  };

  saveOnTimeout = debounce(() => {
    this.saveCaseNotes();
  }, 1000);

  handleSubmit = event => {
    event.preventDefault();
    this.saveCaseNotes();
  };

  handleShowNotes = () => {
    if (this.state.showNotes) {
      if (
        document.getElementById('floating-notes').style.width !== '' &&
        document.getElementById('floating-notes').style.height !== ''
      ) {
        this.setState({
          showNotes: false,
          height: document.getElementById('floating-notes').style.height,
          width: document.getElementById('floating-notes').style.width
        });
      }
      this.setState({ showNotes: false });
    } else {
      this.setState({ showNotes: true });
    }
  };

  handleKeys = event => {
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === 'M' || event.key === 'm')
    ) {
      event.preventDefault();
      this.handleShowNotes();
    }
  };

  setPosition = event => {
    const xValue = event.layerX - event.offsetX - 8;
    const yValue = event.layerY - event.offsetY - 8;
    this.setState({ position: { x: xValue, y: yValue } });
  };

  handleClickNotes = () => {
    if (
      document.getElementById('floating-notes').style.width !== '' &&
      document.getElementById('floating-notes').style.height !== ''
    ) {
      this.setState({
        height: document.getElementById('floating-notes').style.height,
        width: document.getElementById('floating-notes').style.width
      });
    }
  };

  renderCaseNotes = () => {
    const {
      caseNotes,
      showNotes,
      height,
      width,
      position: { x, y }
    } = this.state;
    return (
      <div
        role="button"
        onMouseUp={this.handleClickNotes}
        style={{ zIndex: 20, position: 'fixed', pointerEvents: 'none' }}
      >
        <Draggable
          handle=".handle"
          defaultPosition={{ x, y }}
          position={{ x, y }}
          onStop={this.setPosition}
        >
          {showNotes ? (
            <div
              style={{
                padding: '0px 0px 8px',
                background: 'white',
                boxShadow: '0 0 5px 2px #BFC0C1',
                pointerEvents: 'auto'
              }}
            >
              <div
                style={{
                  CalifontFamily: "Calibri, 'Microsoft Sans Serif'",
                  color: '#000000',
                  fontSize: '16px',
                  fontWeight: '400',
                  padding: '8px 8px 1px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  className="handle"
                  style={{
                    width: '100%',
                    cursor: 'grab'
                  }}
                >
                  <div>Shared Case Notes</div>
                </div>
                <div
                  role="button"
                  onClick={this.handleShowNotes}
                  style={{ cursor: 'pointer', marginTop: '-5px' }}
                >
                  x
                </div>
              </div>
              <TextArea
                value={caseNotes}
                onChange={this.handleNotesChange}
                id={'floating-notes'}
                styles={{
                  height,
                  width,
                  minWidth: '165px',
                  minHeight: '32px',
                  margin: '0 8px'
                }}
                disabled={this.state.disabled}
              />
            </div>
          ) : (
            <div />
          )}
        </Draggable>
      </div>
    );
  };

  renderNotesButtons = () => (
    <div>
      <Icon
        iconName="pt-icon-annotation"
        onClick={this.handleShowNotes}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );

  render() {
    return (
      <div style={{ height: '0', marginTop: '-20px' }}>
        <br />
        {this.renderNotesButtons()}
        <br />
        {this.renderCaseNotes()}
      </div>
    );
  }
}
