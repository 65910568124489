module.exports = [
  {
    component: 'LabelValue',
    label: '',
    value: '(NEW)',
    pristine: true,
    triggers: {
      onClick: ['onNavigateToSubcase', 'pq']
    },
    layout: {
      display: 'flex',
      flexDirection: 'row'
    },
    styles: {
      background: 'transparent',
      borderColor: 'transparent',
      width: 'inherit',
      fontSize: '20px',
      fontFamily: 'SackersGothicStd-Heavy',
      textAlign: 'inherit'
    },
    referencedProperties: {
      value: {
        $compose: [
          { $get: 'subcases.productQuality.status' },
          {
            $or: [
              {
                $and: [{ $eq: 'NEW' }, { $set: '(NEW)' }]
              },
              {
                $and: [{ $eq: 'IN_PROGRESS' }, { $set: '(IN PROGRESS)' }]
              },
              {
                $and: [{ $eq: 'ARCHIVED' }, { $set: '(ARCHIVED)' }]
              },
              {
                $and: [{ $eq: 'COMPLETED' }, { $set: '(COMPLETED)' }]
              },
              {
                $set: ''
              }
            ]
          }
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'PQ Owner:',
    layout: {
      width: '100%',
      ' > span span:nth-child(2)': {
        cursor: 'pointer',
        fontWeight: 'bold'
      }
    },
    triggers: {
      onClick: ['onAssignSubcase', 'pq']
    },
    valueTemplate: '<%= getUserName(users, value) %>',
    referencedProperties: {
      "layout[' > span span:nth-child(2)'].cursor": {
        $if: [{ $get: 'assigneeName' }, { $set: 'initial' }],
        $elseIf: [
          { 'subcases.productQuality.status': { $eq: 'ARCHIVED' } },
          { $set: 'not-allowed' }
        ],
        $else: { $set: 'pointer' }
      },
      "layout[' > span span:nth-child(2)'].fontWeight": {
        $if: [
          { $get: 'subcases.productQuality.assigneeName' },
          { $set: 'initial' }
        ],
        $else: { $set: 'bold' }
      },
      'triggers.onClick': {
        $if: [
          { 'model.subcases.productQuality.assigneeName': { $ne: null } },
          { $set: '' }
        ],
        $else: { $set: ['onAssignSubcase', 'pq'] }
      },
      valueTemplate: {
        $or: [
          {
            $and: [
              { $get: 'subcases.productQuality.assigneeName' },
              {
                $concat: [
                  '<%=data.userMap["',
                  { $get: 'subcases.productQuality.assigneeName' },
                  '"] || "',
                  { $get: 'subcases.productQuality.assigneeName' },
                  '"%>'
                ]
              }
            ]
          },
          'Assign to me'
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'High Impact?',
    layout: {
      width: '100%'
    },
    referencedProperties: {
      value: {
        $or: [
          {
            $if: [
              {
                'subcases.productQuality.complaint.processing.highImpact': {
                  $eq: 'yes'
                }
              },
              { $set: 'Yes' }
            ]
          },
          {
            $if: [
              {
                'subcases.productQuality.complaint.processing.highImpact': {
                  $eq: 'no'
                }
              },
              { $set: 'No' }
            ]
          },
          { $set: 'Not Specified' }
        ]
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Submitted? ',
    layout: {
      width: '100%'
    },
    referencedProperties: {
      value: {
        statePath: 'subcases.productQuality.submitted',
        $if: [{ $eq: true }, { $set: 'Yes' }],
        $else: { $set: 'No' }
      }
    }
  },
  {
    component: 'LabelValue',
    label: 'Reconciliation Needed:',
    pristine: true,
    layout: {
      width: '100%',
      '> span span:nth-child(2)': {
        fontWeight: 'bold'
      }
    },
    referencedProperties: {
      value: {
        statePath: 'subcases.productQuality.reconciliationNeeded',
        $if: [{ $eq: true }, { $set: 'True' }],
        $else: { $set: false }
      },
      'layout.display': {
        schemaPath: './',
        relative: true,
        $if: [
          {
            'model.subcases.productQuality.reconciliationNeeded': { $eq: true }
          },
          { $set: 'block' }
        ],
        $else: { $set: 'none' }
      }
    }
  }
];
