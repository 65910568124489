import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    backgroundColor: theme.colors.background
  }),
  messageContainer: generateCSS({
    padding: '2em',
    margin: '10% 127px 50%',
    backgroundColor: theme.colors.base,
    ' h1': {
      display: 'inline-block',
      fontFamily: 'Lato',
      fontSize: '200px',
      fontWeight: '900',
      letterSpacing: '20.8px',
      textAlign: 'left',
      color: theme.colors.brand
    },
    ' p': {
      fontFamily: 'SackersGothicStd',
      fontSize: '20px',
      fontWeight: '200',
      letterSpacing: '0.1em',
      lineHeight: '1.45em',
      textAlign: 'left',
      color: theme.colors.secondary
    }
  }),
  buttonsContainerSingle: generateCSS({
    display: 'block',
    margin: '46px 0 0 0',
    width: '100%',
    left: '0',
    bottom: '0',
    '> button': {
      width: '161px',
      height: '50px',
      fontSize: '20px'
    }
  }),
  errorCodeWrapper: generateCSS({
    display: 'inline-block',
    width: '50%'
  }),
  messageWrapper: generateCSS({
    display: 'inline-block',
    verticalAlign: 'top',
    width: '50%'
  })
});

export default stylesGenerator;
