import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import moment from 'moment';
import { Spinner, NonIdealState } from '@blueprintjs/core';

import {
  CMS_PROP_TYPES,
  FRAGMENT_NAME_CASE,
  DATE_FORMAT
} from 'Common/constants';
import { CheckboxGroup, SimpleButton } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import { exportCaseToPdf } from 'Common/utils';
import { getVersions } from 'CreateCase/utils';
import submitCaseQuery from 'api/graphql/submitCaseQuery';
import submitCaseRevisionQuery from 'api/graphql/submitCaseRevisionQuery';

class ExportCasePdfModal extends PureComponent {
  static propTypes = {
    page: PropTypes.string.isRequired,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData.isRequired
  };
  state = { versions: [], selectedVersions: [], exportAll: false };

  componentDidMount() {
    this.initComponent();
  }

  fetchPreviousCase = async revision => {
    const { trilogyCaseId, fragments } = this.props;
    return submitCaseRevisionQuery(
      null,
      trilogyCaseId,
      revision,
      fragments[FRAGMENT_NAME_CASE],
      true // suppress toaster notifications
    ).then(caseObject => caseObject);
  };

  initComponent = async () => {
    const { trilogyCaseId, fragments, page } = this.props;

    const latestTrilogyCase = await submitCaseQuery(
      null,
      trilogyCaseId,
      fragments[FRAGMENT_NAME_CASE],
      false, // do not format subcase versions
      true // suppress toaster notifications
    );
    const versions = getVersions(latestTrilogyCase, page);
    versions.forEach(async (version, idx) => {
      if (!version.isCurrent) {
        versions[idx].trilogyCase = await this.fetchPreviousCase(
          version.revision
        );
      }
    });

    this.setState({ versions });
  };

  handleVersionSelection = ({ $id }) => {
    if ($id === 'ALL') {
      this.setState(prevState => ({
        exportAll: !prevState.exportAll,
        selectedVersions: []
      }));
    } else {
      this.setState(prevState => ({
        selectedVersions: prevState.selectedVersions.includes($id)
          ? prevState.selectedVersions.filter(version => version !== $id)
          : [...prevState.selectedVersions, $id]
      }));
    }
  };

  handleExportClick = () => {
    const { page, schema, tacticalData } = this.props;
    const { exportAll, versions, selectedVersions } = this.state;
    if (exportAll) {
      versions.forEach(version =>
        exportCaseToPdf(version.trilogyCase, page, schema, tacticalData)
      );
    } else {
      selectedVersions.forEach(idx =>
        exportCaseToPdf(
          versions[parseInt(idx, 10)].trilogyCase,
          page,
          schema,
          tacticalData
        )
      );
    }
  };

  render = () => {
    const { versions, selectedVersions, exportAll } = this.state;
    const formatSubmittedDate = dt =>
      !isNil(dt)
        ? `Submitted On: ${moment(dt).format(DATE_FORMAT)}`
        : 'Completed Without Submission';
    const getLinkText = li => {
      if (li.isCurrent)
        return `${li.id} (Current - ${
          li.submittedDate
            ? formatSubmittedDate(li.submittedDate)
            : 'Not Submitted'
        })`;
      return `${li.id} (${formatSubmittedDate(li.submittedDate)})`;
    };
    const options = versions.map((val, idx) => ({
      value: idx.toString(),
      label: getLinkText(val),
      disabled: exportAll,
      checked: exportAll || selectedVersions.includes(idx.toString())
    }));
    options.push({
      value: 'ALL',
      label: 'Export all versions',
      disabled: false,
      checked: exportAll
    });
    return (
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        <span className={modalStyles.title}>Export PDF of Case</span>
        <div>Please select version(s) for export</div>
        <div style={{ padding: '25px' }}>
          {versions.length !== 0 ? (
            <CheckboxGroup
              onChange={this.handleVersionSelection}
              options={options}
              renderVertical
            />
          ) : (
            <NonIdealState
              visual={<Spinner />}
              title="Loading"
              description={<span>Please wait while we retrieve the data.</span>}
            />
          )}
        </div>
        <div className={modalStyles.buttonsContainer}>
          <SimpleButton onClick={this.handleExportClick} primary>
            Export
          </SimpleButton>
        </div>
      </div>
    );
  };
}

export default ExportCasePdfModal;
