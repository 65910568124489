import { get, isEmpty } from 'lodash';
import {
  generateCSS,
  reverseText,
  genDisabledStyles,
  genInvalidBorder,
  genErrorStyles,
  genLabelStyles,
  INPUT_SIDE_PADDING
} from '../index';

import { GREY } from '../../../styles';
import datePickerIcon from '../../../../../public/images/date-picker-icon.svg';

// react-select doesn't seem to take on Glamor styles applied directly to it
const stylesGenerator = (theme, props) => {
  const errorStyles = genErrorStyles(theme);
  const disabledStyles = genDisabledStyles(theme);
  const invalidStyles = genInvalidBorder(theme);
  const labelStyles = genLabelStyles(theme, props);

  return {
    // TODO: Reference base from ../Select/styles
    base: generateCSS({
      position: 'relative',
      margin: 0,
      ' .pt-overlay.pt-overlay-inline': {
        position: 'absolute',
        top: 40,
        right: 0
      },

      ' input.pt-input': {
        padding: `0 ${INPUT_SIDE_PADDING / 2}px 0`,
        display: 'block',
        margin: '0px 0',
        ...props.styles,
        ...theme.fonts.input,
        ...disabledStyles,
        borderRadius: '0',
        border: `2px solid ${theme.colors.primary}`,
        boxShadow: 'none',
        ':focus': {
          border: `2px solid ${theme.colors.active}`
        },
        '::selection': {
          outline: 'none'
        },
        '::placeholder': {
          padding: '0 6px',
          color: theme.colors.quinary,
          fontSize: '18px'
        },
        ...reverseText(get(props, 'config.locale')),
        ...(isEmpty(props.errors) ? {} : invalidStyles)
      }
    }),

    inputRow: generateCSS({
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',

      ' .pt-input:disabled, input.pt-input:disabled': {
        backgroundColor: 'white',
        cursor: 'default',
        borderColor: GREY
      },

      ' .pt-datepicker': {
        backgroundColor: theme.colors.background
      },

      ' .DayPicker-NavButton.DayPicker-NavButton--next': {
        color: theme.colors.tertiary
      },

      ' .pt-datepicker .pt-datepicker-caption select': {
        color: theme.colors.secondary
      },

      ' .DayPicker-NavButton.DayPicker-NavButton--prev': {
        color: theme.colors.tertiary
      },

      ' .DayPicker-Weekday': {
        color: theme.colors.header,
        fontWeight: 600
      },

      ' .pt-datepicker .DayPicker-Day:hover': {
        backgroundColor: theme.colors.tertiary,
        color: theme.colors.base
      },

      ' .pt-popover .pt-popover-arrow-fill': {
        fill: theme.colors.background
      },

      '> .pt-popover-target': {
        pointerEvents: props.disabled ? 'none' : null // BlueprintDatePicker has no disabled toggle
      }
    }),

    datePickerControl: generateCSS({
      appearance: 'none',
      border: 'none',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      backgroundImage: `url("${datePickerIcon}")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      margin: '2px',
      height: 25,
      width: 25
    }),

    popoverWrapper: generateCSS({
      paddingTop: '1.4em',
      margin: '0 0px 0px 5px'
    }),

    label: generateCSS({
      ...theme.fonts.inputLabel
    }),

    helpText: generateCSS({
      ...theme.fonts.helpText
    }),

    errors: generateCSS({
      ...theme.fonts.validationError,
      ...errorStyles
    }),

    ...labelStyles
  };
};

export default stylesGenerator;
