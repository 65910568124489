import { generateCSS } from 'Common/components/Form';

const styleGenerator = () => ({
  base: generateCSS({}),

  label: generateCSS({
    margin: '0 1ex 0 0',
    fontWeight: '400'
  }),

  value: generateCSS({
    fontWeight: '300'
  })
});

export default styleGenerator;
