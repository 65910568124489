/**
 * TABLET_BREAKPOINT should be used to set media queries activating smaller elements.
 *
 * DESKTOP_BREAKPOINT should be used to set components/features that are explicitly desktop-only,
 * and only then to prevent layout issues on smaller-but-not-quite-tablet viewports.
 *
 * Any devices that fall between may have larger layouts with desktop-only features excluded.
 * TL;DR: When in doubt, use TABLET_BREAKPOINT.
 */
export const TABLET_BREAKPOINT = 768; // pixels
export const DESKTOP_BREAKPOINT = 908; // pixels
