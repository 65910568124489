import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    marginLeft: '10px',
    color: theme.colors.header
  }),

  title: generateCSS({
    display: 'inline-block',
    margin: '10px 0 10px',
    ...theme.fonts.tabHeaderTitle
  }),

  row: generateCSS({
    width: '100%'
  }),

  contentRight: generateCSS({
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right'
  }),

  filterLabel: generateCSS({
    font: '18px Calibri',
    fontWeight: '900',
    marginRight: '40px',
    float: 'right'
  })
});

export default stylesGenerator;
