import React from 'react';
import PropTypes from 'prop-types';

import { SimpleButton } from 'Common/components/Form';
import { modalStyles } from './Modal/styles';

const ConfirmDelete = ({ handleConfirm, handleDismiss, label }) => (
  <div className={modalStyles.base}>
    <span className={modalStyles.title}>Remove {label}?</span>
    Are you sure you want to want to remove this {label}?
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton onClick={handleDismiss}>Cancel</SimpleButton>
      <SimpleButton onClick={handleConfirm} primary>
        Yes, remove
      </SimpleButton>
    </div>
  </div>
);

ConfirmDelete.propTypes = {
  handleDismiss: PropTypes.func,
  handleConfirm: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

ConfirmDelete.defaultProps = {
  handleDismiss: () => {}
};

export default ConfirmDelete;
