import React from 'react';

import Input from '../Input';

const TextInput = props => (
  <Input type="text" {...props}>
    <input />
  </Input>
);

export default TextInput;
