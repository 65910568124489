import { get, isEmpty, find, invert, isNil, has } from 'lodash';
import {
  TACTICAL_TYPE_MAP,
  PRODUCTS_MAP_FULL_PATH,
  PRODUCTS_MAP_FULL_PATH_ALTERNATE
} from 'Common/constants';
import { getOrElse } from 'Common/utils';

/** @module Value to Label Utils */

/**
 * @default
 * @type {Object}
 * @property {Object} ae - Adverse Event
 * @property {string} ae.type
 * @property {string} ae.path
 * @property {string} ae.field
 * @property {Object} pq - Product Quality
 * @property {string} pq.type
 * @property {string} pq.path
 * @property {Object} mi - Medical Inquiry
 * @property {string} mi.type
 * @property {string} mi.path
 * @property {Object} mc - Master Case
 * @property {string} mc.type
 * @property {string} mc.path
 */
export const OPTION_TACTICAL_MAP = {
  ae: {
    type: 'productData',
    path: 'product-data.ae-product-data.`',
    field: 'preferredName'
  },
  pq: {
    type: 'documentData',
    path: 'document-data.pq-options.marketed_name'
  },
  mi: {
    type: 'documentData',
    path: 'document-data.mi-options.abbvie_product'
  },
  mc: {
    type: 'documentData',
    path: 'document-data.mastercase-product-options'
  }
};

/**
 * @param {*} config
 * @param {*} data
 * @param {*} defaultValue
 */
const getLabelFromOptions = (config, data, defaultValue) => {
  if (isEmpty(data)) {
    return defaultValue;
  }
  if (config.type === 'documentData') {
    return get(
      find(data, o => o.value === defaultValue),
      'label',
      defaultValue
    );
  } else if (config.type === 'productData') {
    return get(
      find(data, o => o[config.field].value === defaultValue),
      'label',
      defaultValue
    );
  }
  return defaultValue;
};

/**
 * @param {*} tacticalData
 * @param {*} value
 * @param {*} key
 */
const getTacticalFieldLabel = (tacticalData, value, key) => {
  const TACTICAL_FIELD = {
    [TACTICAL_TYPE_MAP.ae]: () => {
      const data = get(tacticalData, OPTION_TACTICAL_MAP.ae.path);
      return getLabelFromOptions(OPTION_TACTICAL_MAP.ae, data, value);
    },
    [TACTICAL_TYPE_MAP.mi]: () => {
      const data = get(tacticalData, OPTION_TACTICAL_MAP.mi.path);
      return getLabelFromOptions(OPTION_TACTICAL_MAP.mi, data, value);
    },
    [TACTICAL_TYPE_MAP.pq]: () => {
      const data = get(tacticalData, OPTION_TACTICAL_MAP.pq.path);
      return getLabelFromOptions(OPTION_TACTICAL_MAP.pq, data, value);
    },
    [TACTICAL_TYPE_MAP.mc]: () => {
      const data = get(tacticalData, OPTION_TACTICAL_MAP.mc.path);
      return getLabelFromOptions(OPTION_TACTICAL_MAP.mc, data, value);
    }
  };
  return TACTICAL_FIELD[key]();
};

/**
 * @function
 * @param {*} caseDoc
 * @param {*} tacticalData
 * @param {*} subcaseKey
 * @param {*} defaultValue
 */
export const getSubcaseProductValue = (
  caseDoc,
  tacticalData,
  subcaseKey,
  defaultValue
) => {
  const dataKey = get(invert(TACTICAL_TYPE_MAP), subcaseKey);
  let defaultProductValue = get(caseDoc, PRODUCTS_MAP_FULL_PATH[dataKey]);
  if (
    isNil(defaultProductValue) &&
    has(PRODUCTS_MAP_FULL_PATH_ALTERNATE, dataKey) &&
    dataKey !== 'ae'
  ) {
    defaultProductValue = get(
      caseDoc,
      PRODUCTS_MAP_FULL_PATH_ALTERNATE[dataKey]
    );
  }
  return isNil(defaultProductValue)
    ? defaultValue
    : getTacticalFieldLabel(
        tacticalData,
        defaultProductValue,
        TACTICAL_TYPE_MAP[dataKey]
      );
};

/**
 * @function
 * @param {*} caseDoc
 * @param {*} tacticalData
 * @param {*} defaultValue
 */
export const getMasterCaseProductValue = (
  caseDoc,
  tacticalData,
  defaultValue
) => {
  const defaultProductValue = get(caseDoc, PRODUCTS_MAP_FULL_PATH.mc);
  return isNil(defaultProductValue)
    ? defaultValue
    : getTacticalFieldLabel(
        tacticalData,
        defaultProductValue,
        TACTICAL_TYPE_MAP.mc
      );
};

/**
 * @function
 * @param {*} tacticalData
 * @param {*} key
 */
export const getMethodOfReceiptLabel = (tacticalData, key) => {
  const methodOfReceiptOptions = getOrElse(
    tacticalData,
    'document-data.mastercase-options.method_of_receipt',
    []
  );
  if (!isEmpty(methodOfReceiptOptions)) {
    return find(methodOfReceiptOptions, { value: key }).label;
  }

  return 'Not Specified';
};
