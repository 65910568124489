/* eslint-disable import/prefer-default-export */

/**
 * JSON-patch uses `/` delimited paths, we're just removing the root-slash
 * and replacing all others with a `.` to play nice with lodash
 * @param {*} json JSON returned by diff endpoints
 */
export const slashToDotDelimitedPaths = json =>
  json.map(
    slashDelimitedPath =>
      slashDelimitedPath
        .replace(/\//g, '.')
        .substr(1)
        .replace(/\.(\d+)/g, '[$1]') // create array accessors (`.0` => `[0]`)
    // JSON-patch uses `/` delimited paths, we're just removing the root-slash
    // and replacing all others with a `.` to play nice with lodash
  );
