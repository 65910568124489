import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';

import { SCHEMA_ID_FORM } from 'Common/constants';
import * as QueueActions from 'Queue/ducks';
import * as NavigationActions from 'Navigation/ducks';
import * as SchemaActions from 'Common/ducks/schema';
import * as UserViewActions from 'Common/ducks/userview';
import * as CaseActions from 'CreateCase/ducks';
import QueueController from './controller';

const mapStateToProps = ({
  routerReducer,
  queueReducer,
  schemaReducer,
  commonReducer,
  userViewReducer
}) => ({
  totalResults: queueReducer.totalResults,
  isLoading: queueReducer.isLoading,
  schema: schemaReducer.schemas[SCHEMA_ID_FORM],
  tacticalData: schemaReducer.tacticalData,
  session: commonReducer.jwt,
  location: routerReducer.location,
  queue: queueReducer.queue,
  tasksCounts: queueReducer.tasksCounts,
  sortBy: queueReducer.sortBy,
  subcaseQueue: queueReducer.subcaseQueue,
  selectedCases: queueReducer.selectedCases,
  filters: queueReducer.filters,
  pageNumber: queueReducer.pageNumber,
  selectedItemsToAssignOrArchive: queueReducer.selectedItemsToAssignOrArchive,
  areAllCasesSelected: queueReducer.areAllCasesSelected,
  masterCaseUserViewColumns: userViewReducer.userView,
  inProgressView: userViewReducer.inProgress
});

const mapDispatchToProps = dispatch => {
  const actions = {
    ...QueueActions,
    ...NavigationActions,
    ...SchemaActions,
    ...CaseActions,
    ...UserViewActions,
    push,
    replace
  };

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueueController);
