import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

const Indicator = ({ computedStyles, children }) => (
  <span className={computedStyles.updatedTag}>{children}</span>
);

Indicator.propTypes = {
  computedStyles: PropTypes.shape({
    updatedTag: PropTypes.object
  }).isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(stylesGenerator)(Indicator);
