import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    marginTop: '30px',
    color: theme.colors.header
  }),

  row: generateCSS({
    width: '100%',
    display: 'flex',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    marginTop: '10px'
  }),

  twoRow: generateCSS({
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '0',
    ' > span ': {
      width: '25%',
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px'
    }
  }),

  refNum: generateCSS({
    font: '26px Calibri',
    fontWeight: '300'
  }),

  title: generateCSS({
    font: '30px SackersGothicStd-Medium',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),

  end: generateCSS({
    display: 'flex',
    justifyContent: 'flex-end',
    ' > div ': {
      marginLeft: '20px'
    }
  }),

  tasks: generateCSS({
    font: '16px Calibri',
    fontWeight: 'bold',
    color: theme.colors.header,
    ' > a ': {
      color: theme.colors.header,
      textDecoration: 'none'
    }
  }),

  divider: generateCSS({
    border: `1px solid ${theme.colors.primaryContrast}`
  }),

  actionMenu: generateCSS({
    position: 'relative',
    float: 'right',
    clear: 'both',
    cursor: 'pointer'
  }),

  dueDateRed: generateCSS({
    fontWeight: 'bold',
    color: theme.colors.error
  }),

  dueDateOrange: generateCSS({
    fontWeight: 'bold',
    color: theme.colors.orange
  }),

  affiliateSelect: generateCSS({})
});

export default stylesGenerator;
