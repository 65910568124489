import { isBoolean } from 'lodash';

import { CASE_AE_VERSION_API } from 'config/values';

const getFetchOptions = apiKey => {
  const apiProps = {
    headers: {
      'x-api-key': CASE_AE_VERSION_API.apiKey,
      'Content-Type': 'text/html'
    }
  };

  return {
    method: 'GET',
    ...(isBoolean(apiKey) && !apiKey ? null : apiProps)
  };
};

export const fetchAEVersion = async (versionId, onSuccess, onFailure) => {
  try {
    const URL = `${CASE_AE_VERSION_API.uri}/${versionId}.html`;
    const fetchOptions = getFetchOptions(CASE_AE_VERSION_API.apiKey);
    const response = await fetch(URL, fetchOptions);
    const json = await response.text();
    onSuccess(json);
  } catch (e) {
    onFailure(e);
  }
};

export default fetchAEVersion;
