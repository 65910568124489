import { createAction, handleActions } from 'redux-actions';
import { store } from 'config';
import { isNil, reduce, set, isArray, isString } from 'lodash';

import { protoToSearchCriteria } from 'Common/utils';
import { SORT_ORDERS } from 'Common/constants';

const DEFAULT_MAX_RESULTS = 500; // As per TRIL-757, we reduced size 1000 to 500

export const EMIT_FETCH_SEARCH = 'EMIT_FETCH_SEARCH';

const EMIT_FILTERS_CLEAR = 'EMIT_FILTERS_CLEAR';
const EMIT_INIT_AND_SEARCH = 'EMIT_INIT_AND_SEARCH';
const EMIT_UPDATE_QUERY = 'EMIT_UPDATE_QUERY';
const EMIT_FILTER_INPUT_UPDATE = 'EMIT_FILTER_INPUT_UPDATE';
const EMIT_SORT_BY_UPDATE = 'EMIT_SORT_BY_UPDATE';
const EMIT_CLEAR_SEARCH = 'EMIT_CLEAR_SEARCH';
const EMIT_UPDATE_FILTERS = 'EMIT_UPDATE_FILTERS';

export const emitUpdateFilters = createAction(EMIT_UPDATE_FILTERS, filters => ({
  filters
}));

export const emitFiltersClear = createAction('EMIT_FILTERS_CLEAR');

export const emitInitAndSearch = createAction(
  EMIT_INIT_AND_SEARCH,
  (
    filter,
    emitFetchDocumentsAction,
    onSuccess,
    onFailure,
    client,
    query,
    pageNumber = 0,
    size = DEFAULT_MAX_RESULTS
  ) => ({
    filter,
    emitFetchDocumentsAction,
    onSuccess,
    onFailure,
    client,
    query,
    pageNumber,
    size
  })
);

export const emitUpdateQuery = createAction(EMIT_UPDATE_QUERY, value => ({
  value
}));

export const emitFilterInputUpdate = createAction(
  EMIT_FILTER_INPUT_UPDATE,
  (value, statePath) => ({ value, statePath })
);

export const emitFetchSearch = createAction(
  EMIT_FETCH_SEARCH,
  (
    emitFetchDocumentsAction,
    gqlQuery,
    onSuccess,
    onFailure,
    pageNumber = 0,
    size = DEFAULT_MAX_RESULTS
  ) => ({
    emitFetchDocumentsAction,
    gqlQuery,
    onSuccess,
    onFailure,
    pageNumber,
    size
  })
);

export const emitSortByUpdate = createAction(
  EMIT_SORT_BY_UPDATE,
  (sortBy, order) => ({
    sortBy,
    order
  })
);

export const emitClearSearch = createAction(EMIT_CLEAR_SEARCH);

// Do not default these to anything as it will impact every feature using <SearchBar />
const initialState = {
  query: '',
  filters: {},
  sortBy: {},
  hasSearched: false,
  pageNumber: 0
};

const handlers = {
  [EMIT_FILTERS_CLEAR]: () => ({
    ...initialState
  }),

  [EMIT_INIT_AND_SEARCH]: (
    state,
    {
      payload: {
        filter,
        emitFetchDocumentsAction,
        onSuccess,
        onFailure,
        client,
        query,
        pageNumber,
        size
      }
    }
  ) => {
    const searchQuery = {
      query: state.query,
      ...filter,
      sortBy: {
        ...state.sortBy
      }
    };
    const reduceObj = (acc, val, key) => set(acc, key, val);
    const variables = reduce(searchQuery, reduceObj, {});
    variables.sortBy = reduce(searchQuery.sortBy, reduceObj, {});
    const config = {
      q: variables,
      from: pageNumber * size,
      size
    };
    store.dispatch(
      emitFetchDocumentsAction(config, onSuccess, onFailure, client, query)
    );
    return { ...state, filters: filter, pageNumber };
  },

  [EMIT_UPDATE_QUERY]: (state, { payload: { value } }) => ({
    ...state,
    query: value
  }),

  [EMIT_FILTER_INPUT_UPDATE]: (state, { payload: { value, statePath } }) => {
    const filters = {
      ...state.filters,
      [statePath]: value
    };

    if (isNil(value) || value.length <= 0) delete filters[statePath];

    return {
      ...state,
      filters
    };
  },

  [EMIT_FETCH_SEARCH]: (
    state,
    {
      payload: {
        emitFetchDocumentsAction,
        gqlQuery,
        onSuccess,
        onFailure,
        pageNumber,
        size
      }
    }
  ) => {
    // The filters need to be transformed from protoDocument statePath format into something GQL can filter on
    const filters = protoToSearchCriteria(state.filters);
    const searchQuery = {
      query: state.query,
      ...filters,
      sortBy: {
        ...state.sortBy
      }
    };
    const reduceObj = (acc, val, key) => set(acc, key, val);
    const variables = reduce(searchQuery, reduceObj, {});
    variables.sortBy = reduce(searchQuery.sortBy, reduceObj, {});
    const options = {
      q: variables,
      query: gqlQuery,
      from: pageNumber * size,
      size
    };
    store.dispatch(emitFetchDocumentsAction(options, onSuccess, onFailure));
    return {
      ...state,
      hasSearched: true,
      pageNumber
    };
  },

  [EMIT_SORT_BY_UPDATE]: (state, { payload: { sortBy, order } }) => {
    // Allow sorting by two paths in the same direction. ie: caseId and subcaseId where one or the other may be defined.
    if (isArray(sortBy)) {
      return {
        ...state,
        sortBy: {
          ...sortBy.reduce((acc, path) => {
            acc[path] = order;
            return acc;
          }, {})
        }
      };
    }
    if (!isString(sortBy) || !isString(order) || !SORT_ORDERS.includes(order)) {
      return state;
    }
    return {
      ...state,
      sortBy: {
        [sortBy]: order
      }
    };
  },

  [EMIT_CLEAR_SEARCH]: state => ({
    ...state,
    query: '',
    sortBy: {},
    filters: {}
  }),

  [EMIT_UPDATE_FILTERS]: (state, { payload: { filters } }) => ({
    ...state,
    filters
  })
};

const reducer = handleActions(handlers, initialState);

export default reducer;
