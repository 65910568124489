import { generateCSS } from 'Common/components/Form/index';

const stylesGenerator = theme => ({
  auditHistoryList: generateCSS({
    paddingTop: '0px',
    marginTop: '0px'
  }),
  auditHistoryListItem: generateCSS({
    ...theme.fonts.authLink,
    display: 'block',
    color: theme.colors.brand,
    cursor: 'pointer',
    fontSize: '16px'
  }),
  auditHistoryLinkSelected: generateCSS({
    backgroundColor: theme.colors.brand,
    color: 'white',
    paddingLeft: '5px',
    paddingRight: '5px'
  }),
  auditHistoryLink: generateCSS({
    paddingLeft: '5px',
    paddingRight: '5px'
  }),
  auditGridWrapper: generateCSS({
    ...theme.fonts.default,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'minmax(100px, auto)',
    width: '80vw'
  }),
  auditHistoryListCol: generateCSS({
    gridColumnStart: 1
  }),
  auditHistoryContentCol: generateCSS({
    gridColumnStart: 2,
    gridColumnEnd: 4
  }),
  auditTable: generateCSS({
    ...theme.fonts.default,
    width: '100%',
    textAlign: 'left'
  }),
  auditTableHeader: generateCSS({
    borderBottom: `solid thin ${theme.colors.altBrand}`
  }),
  fieldSection: generateCSS({
    fontWeight: 'bold',
    color: theme.colors.brand
  })
});

export default stylesGenerator;
