import { get, find } from 'lodash';
import { CONTACT_PATHS, PATIENT_PATHS } from 'Lookup/constants';
import { resolveElementSchemaForPath } from 'Common/utils/formState';
import { abbvieDateFormat } from 'Common/components/Form/utils';

const getDataLabel = (result, resultPath, form, formPath) => {
  const value = get(result, resultPath);
  const options = get(
    resolveElementSchemaForPath(formPath, form),
    'options',
    []
  );
  return get(find(options, { value }), 'label', value);
};

const getTacticalDataLabel = (
  result,
  resultPath,
  tacticalData,
  tacticalDataPath
) => {
  const value = get(result, resultPath);
  const options = get(tacticalData, tacticalDataPath, []);
  return get(find(options, { value }), 'label', value);
};

const getPhoneNumber = (result, phonePath, phoneExtPath, phoneIsFax) => {
  const extension = get(result, phoneExtPath)
    ? `ext. ${get(result, phoneExtPath)}`
    : '';

  const fax = get(result, phoneIsFax) ? 'Fax' : '';

  return get(result, phonePath)
    ? `${get(result, phonePath) || ''} ${fax} ${extension}`
    : null;
};

export const getContactDetails = (result, form, tacticalData) => {
  const type = getTacticalDataLabel(
    result,
    CONTACT_PATHS.CONTACT_TYPE,
    tacticalData,
    'document-data.mastercase-options.type_of_contact'
  );
  const nonPatientType = getDataLabel(
    result,
    CONTACT_PATHS.NON_PATIENT_TYPE,
    form,
    'contacts.contact[0].non_patient.type'
  );
  const gender = getDataLabel(
    result,
    CONTACT_PATHS.GENDER,
    form,
    'contacts.contact[0].gender'
  );
  const employeeType = getDataLabel(
    result,
    CONTACT_PATHS.EMPLOYEE_TYPE,
    form,
    'contacts.contact[0].employee.type'
  );
  const country = getTacticalDataLabel(
    result,
    CONTACT_PATHS.COUNTRY,
    tacticalData,
    'document-data.country-options'
  );
  const hcpSpecialty = getDataLabel(
    result,
    CONTACT_PATHS.HCP_SPECIAL,
    form,
    'contacts.contact[0].hcp.specialty'
  );
  const thirdParty = getDataLabel(
    result,
    CONTACT_PATHS.OTHER_PARTY,
    form,
    'contacts.contact[0].other.third_party'
  );
  return [
    {
      label: 'First Name',
      value: get(result, CONTACT_PATHS.FIRST_NM)
    },
    {
      label: 'Last Name',
      value: get(result, CONTACT_PATHS.LAST_NM)
    },
    {
      label: 'Type of Contact',
      value: type
    },
    {
      label: 'Do Not Report Name',
      value: get(result, CONTACT_PATHS.REPORTER_PRIVATE) ? 'Yes' : 'No'
    },
    {
      label: 'Country',
      value: country
    },
    {
      label: 'Third Party',
      value: thirdParty
    },
    {
      label: 'Other Type',
      value: get(result, CONTACT_PATHS.OTHER_TYPE)
    },
    {
      label: 'Address Line 1',
      value: get(result, CONTACT_PATHS.STREET)
    },
    {
      label: 'Address Line 2',
      value: get(result, CONTACT_PATHS.LINE2)
    },
    {
      label: 'City',
      value: get(result, CONTACT_PATHS.CITY)
    },
    {
      label: 'State/Province/Region',
      value: get(result, CONTACT_PATHS.STATE)
    },
    {
      label: 'Postal/ZIP Code',
      value: get(result, CONTACT_PATHS.POSTAL_CODE)
    },
    {
      label: 'Gender',
      value: gender
    },
    {
      label: 'Employee Type',
      value: employeeType
    },
    {
      label: 'Service Provider',
      value: get(result, CONTACT_PATHS.PROVIDER)
    },
    {
      label: 'Program ID/Name',
      value: get(result, CONTACT_PATHS.OPI_NUMBER)
    },
    {
      label: 'HCP Specialty',
      value: hcpSpecialty
    },
    {
      label: 'HCP License Number',
      value: get(result, CONTACT_PATHS.HCP_LICENSE)
    },
    {
      label: 'Phone Number',
      value: getPhoneNumber(
        result,
        CONTACT_PATHS.PHONE_NUM,
        CONTACT_PATHS.PHONE_EXT,
        CONTACT_PATHS.PHONE_IS_FAX
      )
    },
    {
      label: 'Organization/Company',
      value: get(result, CONTACT_PATHS.ORGANIZATION)
    },
    {
      label: 'Email Address',
      value: get(result, CONTACT_PATHS.EMAIL)
    },
    {
      label: 'Contact Reference Number',
      value: get(result, CONTACT_PATHS.SOURCE_REF)
    },
    {
      label: 'Consumer Type',
      value: nonPatientType
    }
  ];
};

export const getPatientDetails = (result, form, tacticalData) => {
  const gender = getDataLabel(
    result,
    PATIENT_PATHS.GENDER,
    form,
    'patient.patient[0].gender'
  );
  const country = getTacticalDataLabel(
    result,
    PATIENT_PATHS.COUNTRY,
    tacticalData,
    'document-data.country-options'
  );
  return [
    {
      label: 'First Name',
      value: get(result, PATIENT_PATHS.FIRST_NM)
    },
    {
      label: 'Last Name',
      value: get(result, PATIENT_PATHS.LAST_NM)
    },
    {
      label: 'Patient ID',
      value: get(result, PATIENT_PATHS.REPORTER)
    },
    {
      label: 'Do Not Report Name',
      value: get(result, PATIENT_PATHS.PRIVACY) ? 'Yes' : 'No'
    },
    {
      label: 'Ethnicity',
      value: get(result, PATIENT_PATHS.ETHNICITY)
    },
    { label: 'Initials', value: get(result, PATIENT_PATHS.INITIALS) },
    {
      label: 'Date of Birth',
      value: abbvieDateFormat(get(result, PATIENT_PATHS.DOB))
    },
    { label: 'Gender', value: gender },
    { label: 'Email Address', value: get(result, PATIENT_PATHS.EMAIL) },
    {
      label: 'Phone Number',
      value: getPhoneNumber(
        result,
        PATIENT_PATHS.PHONE_NUM,
        PATIENT_PATHS.PHONE_EXT
      )
    },
    { label: 'Address Line 1', value: get(result, PATIENT_PATHS.STREET) },
    { label: 'Address Line 2', value: get(result, PATIENT_PATHS.LINE2) },
    { label: 'City', value: get(result, PATIENT_PATHS.CITY) },
    {
      label: 'State/Province/Region',
      value: get(result, PATIENT_PATHS.STATE)
    },
    {
      label: 'Postal/ZIP Code',
      value: get(result, PATIENT_PATHS.POSTAL_CODE)
    },
    { label: 'Country', value: country }
  ];
};

export const getContactDetailTitle = (result, form, tacticalData) => {
  const type = get(result, CONTACT_PATHS.CONTACT_TYPE);
  const typeDisplay = getTacticalDataLabel(
    result,
    CONTACT_PATHS.CONTACT_TYPE,
    tacticalData,
    'document-data.mastercase-options.type_of_contact'
  );
  const typeHeader = type ? `(${typeDisplay})` : '';
  return `${get(result, CONTACT_PATHS.FIRST_NM) || ''} ${get(
    result,
    CONTACT_PATHS.LAST_NM
  ) || ''} ${typeHeader}`;
};

export const getPatientDetailTitle = result =>
  `${get(result, PATIENT_PATHS.FIRST_NM) || ''} ${get(
    result,
    PATIENT_PATHS.LAST_NM
  ) || ''} (Patient)`;
