import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '../withStyles';
import stylesGenerator from './styles';
import { formElementPropTypes } from '../propTypes';

const SubHeading = ({ computedStyles, title }) => (
  <h2 className={computedStyles.title}>{title}</h2>
);

SubHeading.propTypes = {
  ...formElementPropTypes,
  title: PropTypes.string.isRequired
};
export default withStyles(stylesGenerator)(SubHeading);
