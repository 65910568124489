import { generateCSS } from 'Common/components/Form';
import { TABS_TOP_MARGIN } from 'CreateCase/constants';

const stylesGenerator = theme => {
  const fixedStyles = {
    position: 'fixed',
    padding: `${TABS_TOP_MARGIN}px 58px 0px`,
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%'
    // boxShadow: '0 5px 15px #888888' // TODO ACFE-2170
  };

  const relativeStyles = {
    margin: `${TABS_TOP_MARGIN}px 34px 30px`,
    left: '0',
    position: 'relative'
  };

  return {
    base: generateCSS({
      // boxShadow: '0 10px 10px -5px #888888' // TODO ACFE-2170
    }),

    tabs: tabsSticky =>
      generateCSS({
        zIndex: '10',
        top: '0',
        right: '0',
        left: '0',
        display: 'flex',
        justifyContent: 'space-between',
        background: theme.colors.background,
        ...(tabsSticky ? fixedStyles : relativeStyles)
      }),

    tabTitle: isActive => {
      const activeHighlight = `2px solid ${theme.colors.brand}`;

      return generateCSS({
        padding: '10px 0',
        cursor: 'pointer',
        ...theme.fonts.tabTitle,
        borderBottom: isActive ? activeHighlight : '2px solid rgba(0, 0, 0, 0)',
        textDecoration: 'none',
        '&:hover': {
          borderBottom: activeHighlight
        }
      });
    },

    errors: generateCSS({
      color: `${theme.colors.error} !important`
    })
  };
};

export default stylesGenerator;
