import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover, Position } from '@blueprintjs/core';

import triangle from 'public/images/triangle.svg';
import triangleDisabled from 'public/images/triangle-disabled.svg';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

class CaseActionsMenu extends PureComponent {
  static propTypes = {
    isMenuDisabled: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      trigger: PropTypes.object.isRequired,
      triangle: PropTypes.object.isRequired,
      links: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    isMenuDisabled: false
  };

  state = {
    shouldBeOpen: undefined
  };

  render() {
    const { computedStyles, children, isMenuDisabled } = this.props;
    return (
      <Popover
        position={Position.BOTTOM_RIGHT}
        popoverClassName={`pt-minimal ${computedStyles.base}`}
        isDisabled={isMenuDisabled}
        enforceFocus={false}
        isOpen={this.state.shouldBeOpen}
        popoverWillClose={() => this.setState({ shouldBeOpen: undefined })}
      >
        <div
          data-id="actions-menu"
          data-disabled={isMenuDisabled}
          className={
            isMenuDisabled
              ? computedStyles.triggerDisabled
              : computedStyles.trigger
          }
          role="button"
        >
          Actions <img src={isMenuDisabled ? triangleDisabled : triangle} />
        </div>
        <div
          className={computedStyles.links}
          role="link"
          onClick={() => this.setState({ shouldBeOpen: false })}
        >
          {children}
        </div>
      </Popover>
    );
  }
}

export default withStyles(stylesGenerator)(CaseActionsMenu);
