import React, { PureComponent } from 'react';
import { snakeCase } from 'lodash';
import Indicator from 'Common/components/Indicator';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class Checkbox extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes
  };

  static defaultProps = {
    ...formElementDefaultProps,
    errors: []
  };

  needsReconciliation = () =>
    (this.props.data.reconciliation || []).includes(this.props.$id);

  renderReconciliationTag = () => <Indicator>R</Indicator>;

  renderHelpText = helpText => (
    <span className={this.props.computedStyles.helpText}>{helpText}</span>
  );

  renderUpdateTag = () => <Indicator>U</Indicator>;

  render() {
    const {
      id,
      $id,
      onChange,
      computedStyles,
      autoFocus,
      disabled,
      helpText,
      label,
      schemaPath,
      errors,
      value,
      isFullWidth,
      style
    } = this.props;

    const handleChange = e => {
      e.stopPropagation();
      onChange(e.target.checked);
    };

    const handleKeyDown = e => {
      // Space triggers the onChange event, ignore it here!
      if (e.key === 'Enter') {
        handleChange(e);
      }
    };

    const className = [
      computedStyles.base,
      errors.length ? computedStyles.errors : '',
      isFullWidth ? computedStyles.fullWidth : ''
    ].join(' ');

    const updatedThisVersion = (this.props.data.diff || []).includes($id);

    return (
      <div
        role="group"
        className={className}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <input
          key={$id}
          type="checkbox"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          className={computedStyles.input}
          disabled={disabled}
          autoFocus={autoFocus}
          checked={value || false}
          value={snakeCase(value)}
          style={style}
        />
        <span className={computedStyles.optionLabel}>{label}</span>
        {updatedThisVersion ? this.renderUpdateTag() : null}
        {this.needsReconciliation() ? this.renderReconciliationTag() : null}
        {helpText ? this.renderHelpText(helpText) : null}
        <div className={computedStyles.compensateForGroup}>
          <ValidationErrors
            id={id}
            computedStyles={computedStyles}
            validationErrors={errors}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(Checkbox);
