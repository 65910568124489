import gql from 'graphql-tag';

const getCaseByIdQuery = caseFragment => {
  const fragment = gql`
    ${caseFragment}
  `;

  return gql`
    query case($id: String!) {
      case(id: $id) {
        ...FullCaseWithSubcases
      }
    }
    ${fragment}
  `;
};

export default getCaseByIdQuery;
