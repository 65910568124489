import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  columnSelector: generateCSS({
    width: '10%',
    margin: 'auto'
  }),
  columnSelectorArrow: generateCSS({
    width: '100px',
    height: '36px',
    fontSize: '50px',
    background: 'none',
    border: 'none',
    margin: '20px 100px 0 0'
  })
});

export default stylesGenerator;
