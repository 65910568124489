import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { Spinner, NonIdealState } from '@blueprintjs/core';

import {
  SCHEMA_PATH_FORM,
  OPEN_DUPE_SEARCH,
  DOCUMENT_TITLE_MAP,
  CMS_PROP_TYPES
} from 'Common/constants';
import { resolveElementSchemaForPath } from 'Common/utils/formState';
import withModal from 'Common/components/withModal';
import { generateCSS } from 'Common/components/Form';
import searchCaseBasic from 'api/graphql/queries/case/searchCase';
import submitCaseSearch from 'api/graphql/submitCaseSearch';
import SearchHeader from './SearchHeader';
import SearchResults from './SearchResults';

class SearchController extends PureComponent {
  static propTypes = {
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.shape({
      emitSchemaFetch: PropTypes.func.isRequired,
      emitSortByUpdate: PropTypes.func.isRequired,
      emitFetchSearch: PropTypes.func.isRequired,
      emitFiltersClear: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        duplicate: PropTypes.string
      }).isRequired
    }).isRequired,
    schema: CMS_PROP_TYPES.schema.isRequired,
    tacticalData: CMS_PROP_TYPES.tacticalData
  };

  static defaultProps = {
    tacticalData: {}
  };

  componentDidMount() {
    document.title = DOCUMENT_TITLE_MAP.search;
    const { schema, actions } = this.props;

    if (isEmpty(schema.pages)) {
      actions.emitSchemaFetch(SCHEMA_PATH_FORM);
    }
  }

  componentWillUpdate() {
    const { params } = this.props.match;
    if (params.duplicate) {
      document.title = DOCUMENT_TITLE_MAP.duplicate;
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.emitFiltersClear();
  }

  fetchSortedResults = (sortBy, order) => {
    const { actions } = this.props;
    actions.emitSortByUpdate(sortBy, order);
    this.fetchResults();
  };

  fetchResults = () => {
    const { actions } = this.props;
    const requestTimestamp = Date.now();
    const onSuccess = results =>
      actions.emitSearchQuerySuccess(results, requestTimestamp);

    actions.emitFetchSearch(
      submitCaseSearch,
      searchCaseBasic,
      onSuccess,
      onSuccess
    );
  };

  renderLoading = () => (
    <div className={generateCSS({ margin: '100px auto' })}>
      <NonIdealState visual={<Spinner />} title="Loading" />
    </div>
  );

  render = () => {
    const { results, schema, tacticalData } = this.props;
    const isDuplicateSearch = !!window[OPEN_DUPE_SEARCH];
    const caseSchemaFields = !isNil(schema)
      ? {
          patientGender: resolveElementSchemaForPath(
            'patient.patient[0].gender',
            schema
          )
        }
      : {};

    if (isEmpty(tacticalData)) return this.renderLoading();

    return (
      <div>
        <SearchHeader
          {...this.props}
          isDuplicateSearch={isDuplicateSearch}
          fetchResults={this.fetchResults}
        />
        <SearchResults
          {...this.props}
          results={results}
          caseSchemaFields={caseSchemaFields}
          onSortClick={this.fetchSortedResults}
          isDuplicateSearch={isDuplicateSearch}
        />
      </div>
    );
  };
}

export default withModal(SearchController);
