import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import GenerateEmailModal from 'Common/components/GenerateEmailModal';
import stylesGenerator from './styles';

class EmailLink extends Component {
  static propTypes = {
    computedStyles: PropTypes.shape({
      generateEmailLink: PropTypes.object.isRequired,
      bold: PropTypes.object.isRequired
    }).isRequired,
    caseId: PropTypes.string.isRequired,
    showModal: PropTypes.func.isRequired,
    clearModal: PropTypes.func.isRequired
  };

  state = { recipient: '' };
  handleGenerateEmailModal = explicitState => {
    const { recipient } = explicitState || this.state;

    const handleDismiss = () => {
      this.setState({ recipient: '' });
      this.props.clearModal();
    };

    const handleRecipientChange = value => {
      this.setState({ recipient: value });
      // Modal content needs an action to update its content,
      // so we must explicitly pass the new state for the modal content
      this.handleGenerateEmailModal({ recipient: value });
    };

    const content = (
      <GenerateEmailModal
        recipient={recipient}
        masterCaseId={this.props.caseId}
        onRecipientChange={handleRecipientChange}
        onConfirm={handleDismiss} // Modal will handle email window logic
        onCancel={handleDismiss}
      />
    );
    this.props.showModal(content);
  };
  render() {
    const { computedStyles } = this.props;
    return (
      <span>
        <a
          role="link"
          onClick={() => this.handleGenerateEmailModal(null)}
          className={`${computedStyles.generateEmailLink} ${
            computedStyles.bold
          }`}
        >
          Generate Email
        </a>
      </span>
    );
  }
}

export default withStyles(stylesGenerator)(EmailLink);
