import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import { withRouter } from 'react-router';
import { isEmpty, debounce } from 'lodash';

import { NO_SCROLL_CLASS } from 'Navigation/constants';
import { CMS_PROP_TYPES } from 'Common/constants';
import NotAuthorized from 'Common/components/NotAuthorized';
import { isTestEnv } from 'config';
import { authorize, endSession } from 'config/auth';
import Notifier from 'Common/components/Notifier';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';

class NavigationController extends PureComponent {
  static propTypes = {
    session: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string)
      ])
    ),
    hasValidRoles: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      emitclientWidthUpdate: PropTypes.func.isRequired
    }).isRequired,
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    schemas: PropTypes.objectOf(CMS_PROP_TYPES.schema).isRequired
  };

  static defaultProps = {
    session: {},
    location: {},
    hasValidRoles: false
  };

  componentWillMount() {
    const { emitclientWidthUpdate } = this.props.actions;
    this.handleResize = debounce(e => emitclientWidthUpdate(e), 500, {
      trailing: true,
      leading: false
    });
    window.addEventListener('resize', this.handleResize);
    this.insertBlurStyles();
  }

  componentWillReceiveProps(nextProps) {
    const shouldRestrictScroll = this.isOverlayActive(nextProps);
    this.toggleBodyScroll(shouldRestrictScroll);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onRetry = () => {
    endSession();
    authorize();
  };

  // (Almost)-global application of blur effects to anything not contained within a modal
  insertBlurStyles = () =>
    css.insert('.blur > * > :not(.modal) { filter: blur(2px) }');

  isOverlayActive = ({ modalContent } = this.props) => !!modalContent;

  // css.insert and css.global is not reliable for managing classes that can be toggled
  toggleBodyScroll = shouldRestrictScroll => {
    if (shouldRestrictScroll) {
      document.body.classList.add(NO_SCROLL_CLASS);
    } else {
      document.body.classList.remove(NO_SCROLL_CLASS);
    }
  };

  render() {
    const { session, children, hasValidRoles, schemas } = this.props;
    if (isEmpty(session) && !isTestEnv) return null;

    return hasValidRoles ? (
      <div className={this.isOverlayActive() ? 'blur' : null}>
        <SiteHeader {...this.props} />
        <Notifier {...this.props} />
        {children}
        <SiteFooter schemas={schemas} />
      </div>
    ) : (
      <div className={this.isOverlayActive() ? 'blur' : null}>
        <SiteHeader {...this.props} />
        <NotAuthorized onRetry={this.onRetry} />
        <SiteFooter schemas={schemas} />
      </div>
    );
  }
}

export default withRouter(NavigationController);
