import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

class MultiToggle extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        selected: PropTypes.bool,
        $id: PropTypes.string
      })
    ).isRequired,
    computedStyles: PropTypes.shape({
      base: PropTypes.func.isRequired
    }).isRequired
  };

  handleSelection = value => {
    this.props.onChange(value);
  };

  renderOption = ({ label, value, selected, $id }) => {
    const handleClick = () => this.handleSelection(value);

    return (
      <button
        key={value}
        onClick={handleClick}
        data-id={$id}
        type="button"
        checked={selected}
        className={this.props.computedStyles.base(selected)}
      >
        {label}
      </button>
    );
  };

  render = () => <div>{this.props.options.map(this.renderOption)}</div>;
}

export default withStyles(stylesGenerator)(MultiToggle);
