/* eslint import/prefer-default-export:0 */
/**
 * Keep config-specific utils in here,
 * else they can cause circular dependencies by being
 * present in other silo's utils exports!
 */

import { reduce } from 'lodash';
import { handleActions } from 'redux-actions';

/**
 * Prevents duplicate keys when merging objects.
 * Stopgap solution until silos are merged completely.
 *
 * NOTE/TODO: Default arg values are used liberally here to prevent
 * Jest tests from blowing up on circular dependencies.
 *
 * @param {object} a
 * @param {object} b
 * @returns {boolean}
 */
export const preventDuplicateKeys = (a = {}, b = {}, desc = 'reducers') =>
  Object.keys(a).forEach(key => {
    if (Object.keys(b).includes(key)) {
      throw new Error(`Duplicate key ${key} found while combining ${desc}!`);
    }
  });

export const mergeDucks = (...ducks) => {
  const reduceDucks = (acc = {}, silo = {}) => {
    const { handlers, initialState } = silo;
    preventDuplicateKeys(acc.handlers, handlers);
    preventDuplicateKeys(acc.initialState, initialState);

    return {
      handlers: { ...acc.handlers, ...handlers },
      initialState: { ...acc.initialState, ...initialState }
    };
  };

  const { handlers, initialState } = reduce(ducks, reduceDucks);

  return handleActions(handlers, initialState);
};
