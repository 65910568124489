import { generateCSS } from '../../../Common/components/Form';

const stylesGenerator = () => ({
  filterBlock: generateCSS({
    border: '1px solid rgb(7, 29, 73)',
    background: 'rgba(125, 188, 255, 0.3)',
    margin: '10px',
    padding: '0px 5px 0px 5px'
  }),
  closeButton: generateCSS({
    borderWidth: '1px',
    borderStyle: 'none solid none none',
    borderColor: 'rgb(7, 29, 73)',
    cursor: 'pointer',
    marginRight: '5px',
    paddingRight: '5px'
  }),
  container: generateCSS({
    // edit columns button is currently 36px... need to refactor
    paddingLeft: '26px'
  }),
  clearAll: generateCSS({
    color: '#00A9E0',
    padding: '10px',
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  })
});

export default stylesGenerator;
