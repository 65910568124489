import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    padding: '0 8%',
    '@media(max-width:1440px)': {
      padding: '0 5%'
    },
    '@media(max-width:1200px)': {
      padding: '0 2%'
    },
    background: theme.colors.background
  })
});

export default stylesGenerator;
