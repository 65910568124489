import { isArray, isObject, get, set } from 'lodash';

/** @module Form State Utils */

/**
 * This is used for resolving the `display` of children within a container that
 * has a referenced property on `layout.display`. In order for validations to
 * properly resolve `relative` queries for children, they need an additional
 * `../` tacked on.
 * @param  {Object} obj The referenced property query
 * @param  {Number} n   The number of `../` to prefix to the element `obj` paths
 * @return {Object}     The query relsolved with the newly prefixed relative paths
 */
const addRelativeRoots = (obj, n = 1) =>
  !isObject(obj)
    ? obj
    : Object.keys(obj).reduce((out, path) => {
        if (path.substr(0, 3) === '../') {
          const prefix = new Array(n).fill(0).reduce(rel => `../${rel}`, '');
          return { ...out, [`${prefix}${path}`]: addRelativeRoots(obj[path]) };
        }
        return addRelativeRoots(obj[path]);
      }, {});
/**
 * Given an element (with or without a `statePath`), returns a function whose
 * input is another element (a child of the partially applied element) and output
 * is the same element with the prefixed `statePath` of the partially applied function
 *
 * @param  {Object} element   - An element with a `statePath`
 * @return {Function}         - A partially applied "prefixing" function for `element`
 *
 * @example
 *   const element = { statePath: 'test', children: [{ statePath: 'child' }] };
 *   const children = element.children;
 *
 *   children.map(prefixStatePath(element)) => [{ statePath: 'test.child' }]
 *
 */
const prefixStatePath = parentElement => element => {
  const parentDisplay = get(parentElement, 'referencedProperties');

  return {
    ...element,
    parentElement,
    relativePath: parentElement.statePath,
    statePath: parentElement.statePath
      ? `${parentElement.statePath}.${element.statePath}`
      : element.statePath,
    // Since some of our sections are hiding it's elements, this will interperet
    // the state of any child elements
    referencedProperties: {
      ...(parentDisplay ? addRelativeRoots(parentDisplay) : {}),
      ...get(element, 'referencedProperties', {})
    }
  };
};

/**
 * Given a list of objects with `elements` properties of indeterminate depth,
 * returns a flattened list of ALL elements within the hierarchy.
 *
 * @param  {Array} elements  - The entry point to an element hierarchy
 * @return {Array}           - The flattened element hierarchy
 */
const flattenElements = elements =>
  elements.reduce(
    (els, el) => [
      ...els,
      ...(el.elements
        ? flattenElements(el.elements.map(prefixStatePath(el)))
        : [])
    ],
    elements
  );

/**
 * Returns a flattened list of _ALL_ element schemas defined in `schema`. The
 * schema can be a list of `pages`, or an individual `tab`.
 * @function
 * @param      {Object}  schema  The form schema at top-level, or tab/section
 * @return     {Array}  A list of all schemas used in the given `schema`
 */
export const schemaElements = schema => {
  let tabs = schema.tabs;
  let sections;
  // An entire `form` schema
  if (schema.pages) {
    tabs = schema.pages.reduce(
      (out, page, i) => [...out, ...page.tabs.map(t => ({ ...t, page: i }))],
      []
    );
  }
  // A single `page` from a `form`
  if (tabs) {
    sections = tabs.reduce(
      (out, tab, i) => [
        ...out,
        ...tab.sections.map(s => ({ ...s, tab: i, page: tab.page }))
      ],
      []
    );
  }
  // A single `tab` or a list of `sections`
  if (!sections) sections = schema.sections || schema;

  return sections.reduce(
    (formElements, section) => [
      ...formElements,
      ...flattenElements(section.elements.map(prefixStatePath(section))).map(
        el => ({
          ...el,
          tab: section.tab,
          page: section.page
        })
      )
    ],
    []
  );
};

/**
 * Takes a flattened `document` and resolves all dot-notated keys to their
 * respective nested key-space on the returned `object`.
 * @function
 * @param      {Object}    document        The flattened document
 * @param      {Function}  valueDecorator  The value decorator
 * @return     {Object}    The inflated document
 *
 * @example
 *    const flatDoc = { 'some.dot.notated.path': 1 };
 *
 *    inflateDocument(flatDoc) => { some: { dot: { notated: { path: 1 } } } };
 */
export const inflateDocument = (document, valueDecorator = v => v) =>
  Object.keys(document).reduce(
    (unflattened, path) =>
      set(unflattened, path, valueDecorator(document[path], path)),
    {}
  );

/**
 * Given a flattened `document`, returns a mapping with identical keys whose values
 * are the associated schema for the given element, resolved from `schema`
 * @function
 * @param      {String}  path      The `statePath` of some element schema
 * @param      {Object}  schema    The form schema OR a pre-resolved list of element schemas
 * @return     {Object}  A mapping of `statePath` to its associated element schema defined in `schema`
 *
 * @example
 *  const elementSchema = { component: "TextInput", statePath: "initials" };
 *  const schema = { pages: [{ tabs: [{ sections: [{ statePath: 'section', elements: [elementSchema] }] }] }] };
 *  const protoDoc = { 'section.initials': null };
 *
 *  resolveElementSchemaForPath('section.initials', schema) =>
 *    { 'section.initials': { component: "TextInput", statePath: "initials" } };
 */
export const resolveElementSchemaForPath = (path, schema) => {
  const elements = isArray(schema) ? schema : schemaElements(schema);
  return elements.find(el => {
    const p = path.replace(/\[\d+\]/g, '');
    return el.component === 'CheckboxGroup' && el.options.length > 1
      ? el.options.find(opt => p === `${el.statePath}.${opt.value}`)
      : el.statePath === p;
  });
};
