import { generateCSS } from '../../../Common/components/Form';

const stylesGenerator = theme => ({
  cardTitle: generateCSS({
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.brand
  }),

  cardSection: generateCSS({
    marginTop: '10px',
    marginBottom: '10px'
  })
});

export default stylesGenerator;
