import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    backgroundColor: theme.colors.base,
    color: theme.colors.header,
    padding: '1.5em',
    display: 'inline-block',
    width: '84vw',
    margin: '0 7.5%',
    '@media(max-width:2000px)': {
      margin: '0 4%',
      width: '92vw'
    },
    '@media(max-width:1500px)': {
      width: '100vw',
      maxWidth: 'none',
      margin: '0 auto'
    },
    '> table': {
      width: '100%'
    }
  }),
  caseIdLink: generateCSS({
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.caseIdLink,
    '> a': {
      color: theme.colors.brand
    },
    padding: '0.5em'
  }),
  columnGroup: generateCSS({
    border: '1px solid white',
    ...theme.fonts.coloumnGroupLabel,
    padding: '0.75em 0'
  }),
  columnHeader: generateCSS({
    border: '1px solid white',
    ...theme.fonts.coloumnHeaderLabel,
    padding: '0.5em',
    '.init': {
      minWidth: '60px'
    },
    '.gender': {
      minWidth: '84px'
    }
  }),
  primaryColor: generateCSS({
    backgroundColor: theme.colors.background
  }),
  secondaryColor: generateCSS({
    backgroundColor: '#ebf5fa' // TODO: Abstract to theme ?
  }),
  sortImage: generateCSS({
    float: 'right',
    marginBottom: '3px',
    ':hover': {
      cursor: 'pointer'
    }
  }),
  sortDiv: generateCSS({
    display: 'inline-block',
    float: 'right',
    width: '9px'
  }),
  tableCell: generateCSS({
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.tabelCellLabel,
    height: '46px',
    padding: '0.5em',
    '.light': {
      backgroundColor: theme.colors.base
    },
    '.masterCase': {
      borderTop: `2px solid ${theme.colors.header}`,
      borderBottom: 'none',
      backgroundColor: theme.colors.base,
      fontWeight: '900',
      color: theme.colors.brand,
      fontSize: '15px',
      '.selected': {
        backgroundColor: theme.colors.tableHeader
      }
    },
    '.subCase': {
      borderTop: `1px solid ${theme.colors.primary}`,
      borderBottom: 'none',
      backgroundColor: theme.colors.background,
      color: theme.colors.quinary,
      fontSize: '14px',
      '.selected': {
        backgroundColor: theme.colors.tableHeader
      }
    }
  }),
  tableFooter: generateCSS({
    ' tr': {
      height: '46px',
      textAlign: 'center',
      ' span': {
        ...theme.fonts.resultsFooterLabel
      }
    }
  }),
  viewLink: generateCSS({
    backgroundColor: theme.colors.base,
    borderBottom: `1px solid ${theme.colors.primaryContrast}`,
    ...theme.fonts.tabelCellLabel,
    ' a': {
      color: theme.colors.charcoal
    },
    fontSize: '14px',
    fontWeight: '900',
    padding: '0.5em'
  }),
  center: generateCSS({
    textAlign: 'center',
    padding: '16px 4px 0 0'
  })
});

export default stylesGenerator;
