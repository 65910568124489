import { get } from 'lodash';

import {
  SCHEMA_PATH_FORM,
  SCHEMA_PATH_TASKS,
  AUDIT_PATH_CASE,
  AUDIT_PATH_TASK
} from 'Common/constants';

/*
 * _.get is used liberally here to create null safety for unit tests
 */

export const isProdEnv = process.env.NODE_ENV === 'production';
export const isTestEnv = process.env.NODE_ENV === 'test';

const CASE_GRAPHQL_API_PROD = get(window.trilogyConfig, 'case-svc/graphql', {});
const CASE_SCHEMA_CUSTOM = get(process.env.CUSTOM_SCHEMAS, '/form');

export const CASE_GRAPHQL_API =
  isProdEnv && !CASE_SCHEMA_CUSTOM
    ? CASE_GRAPHQL_API_PROD
    : {
        apiKey: CASE_GRAPHQL_API_PROD.apiKey,
        uri: `${CASE_GRAPHQL_API_PROD.uri}?cmsFormType=${CASE_SCHEMA_CUSTOM}`
      };

export const CASE_GRAPHQL_FRAGMENT_API =
  isProdEnv && !CASE_SCHEMA_CUSTOM
    ? {
        apiKey: CASE_GRAPHQL_API_PROD.apiKey,
        uri: (type, name) =>
          `${CASE_GRAPHQL_API_PROD.uri}/fields/${type}?fragmentName=${name}`
      }
    : {
        apiKey: CASE_GRAPHQL_API_PROD.apiKey,
        uri: (type, name) =>
          `${
            CASE_GRAPHQL_API_PROD.uri
          }/fields/${type}?cmsFormType=${CASE_SCHEMA_CUSTOM}&fragmentName=${name}`
      };
const TASK_GRAPHQL_API_PROD = get(window.trilogyConfig, 'task-svc/graphql', {});
const TASK_SCHEMA_CUSTOM = get(process.env.CUSTOM_SCHEMAS, '/task');
export const TASK_GRAPHQL_API =
  isProdEnv && !TASK_SCHEMA_CUSTOM
    ? TASK_GRAPHQL_API_PROD
    : {
        apiKey: TASK_GRAPHQL_API_PROD.apiKey,
        uri: `${TASK_GRAPHQL_API_PROD.uri}?cmsFormType=${TASK_SCHEMA_CUSTOM}`
      };
export const TASK_GRAPHQL_FRAGMENT_API =
  isProdEnv && !TASK_SCHEMA_CUSTOM
    ? {
        apiKey: TASK_GRAPHQL_API_PROD.apiKey,
        uri: (type, name) =>
          `${TASK_GRAPHQL_API_PROD.uri}/fields/${type}?fragmentName=${name}`
      }
    : {
        apiKey: TASK_GRAPHQL_API_PROD.apiKey,
        uri: (type, name) =>
          `${
            TASK_GRAPHQL_API_PROD.uri
          }/fields/${type}?cmsFormType=${TASK_SCHEMA_CUSTOM}&fragmentName=${name}`
      };
export const ATTACHMENT_API = window.trilogyConfig['attachment-svc'];
export const CASE_AE_VERSION_API =
  window.trilogyConfig['case-svc/adverse-event'];
export const SUBCASE_VERSION_DIFF_API =
  window.trilogyConfig['case-svc/subcase-diff'];
export const SUBCASE_RECONCILIATION_API =
  window.trilogyConfig['case-svc/subcase-reconciliation'];
export const CASE_SERVICES_API = window.trilogyConfig['case-svc/services'];
export const TASK_SERVICES_API = window.trilogyConfig['task-svc/services'];
export const TACTICAL_DATA_API = window.trilogyConfig['tactical-data-svc']
  ? {
      ...window.trilogyConfig['tactical-data-svc'],
      uri: `${window.trilogyConfig['tactical-data-svc'].uri}/affiliate/_DEFAULT`
    }
  : window.trilogyConfig['tactical-data-svc'];
export const CASE_SERVICE_WS = window.trilogyConfig['case-svc/ws'];
export const AUTH_API = window.trilogyConfig['auth-svc'];

const CASE_SVC_SCHEMA_CONFIG = get(window.trilogyConfig, 'case-svc/schema', {});
const CASE_SERVICE_SCHEMA_API =
  isProdEnv && !CASE_SCHEMA_CUSTOM
    ? {
        ...CASE_SVC_SCHEMA_CONFIG,
        uri: `${CASE_SVC_SCHEMA_CONFIG.uri}/form`
      }
    : {
        ...CASE_SVC_SCHEMA_CONFIG,
        uri: `${
          CASE_SVC_SCHEMA_CONFIG.uri
        }/form?cmsFormType=${CASE_SCHEMA_CUSTOM}`
      };
const TASK_SVC_SCHEMA_CONFIG = get(window.trilogyConfig, 'task-svc/schema', {});
const TASK_SERVICE_SCHEMA_API =
  isProdEnv && !TASK_SCHEMA_CUSTOM
    ? {
        ...TASK_SVC_SCHEMA_CONFIG,
        uri: `${TASK_SVC_SCHEMA_CONFIG.uri}/form`
      }
    : {
        ...TASK_SVC_SCHEMA_CONFIG,
        uri: `${
          TASK_SVC_SCHEMA_CONFIG.uri
        }/form?cmsFormType=${TASK_SCHEMA_CUSTOM}`
      };
const TASK_SERVICE_AUDIT_API = get(
  window.trilogyConfig,
  'task-audit-svc/services',
  {}
);
export const CASE_SERVICE_AUDIT_API = get(
  window.trilogyConfig,
  'case-audit-svc/services',
  {}
);

export const SCHEMA_SVC_API_MAP = {
  [SCHEMA_PATH_FORM]: CASE_SERVICE_SCHEMA_API,
  [SCHEMA_PATH_TASKS]: TASK_SERVICE_SCHEMA_API
};

export const AUDIT_SVC_API_MAP = {
  [AUDIT_PATH_CASE]: {
    apiKey: CASE_SERVICE_AUDIT_API.apiKey,
    uri: `${CASE_SERVICE_AUDIT_API.uri}/caseLogs`
  },
  [AUDIT_PATH_TASK]: {
    apiKey: TASK_SERVICE_AUDIT_API.apiKey,
    uri: `${TASK_SERVICE_AUDIT_API.uri}/taskLogs`
  }
};

export const LEGACY_SEARCH_URL = get(
  window.trilogyConfig,
  'legacySearch/app.uri'
);

export const GET_USER_PREFERED_LIST = get(
  window.trilogyConfig,
  '/case/services/getUserPrefernence'
);
