import { FORM_BASE_PATH, SUB_CASE_FORM_ROUTES_MAP } from 'CreateCase/constants';
import {
  DUPE_WITH_CONTACTS,
  DUPE_WITH_NO_MATCH,
  PARENT_CASE,
  SOURCE_LOCATION,
  FROM_CREATE_CASE,
  PARENT_CASE_IS_EMAIL,
  EXTERNAL_SOURCE,
  OPEN_DUPE_SEARCH
} from 'Common/constants';
import { getOrElse } from 'Common/utils';

/** @module Case Routing Utils */

const CATEGORIES_PATH = 'summary.narrative.categories';

/**
 * @function
 * @param {TrilogyCase} trilogyCase
 */
export const getNextFormRoute = trilogyCase => {
  const { id: masterCaseId } = trilogyCase;
  const isChecked = statePath =>
    getOrElse(trilogyCase, `${CATEGORIES_PATH}.${statePath}`, false);

  const selectedCategories = [
    'adverse_event',
    'medical_inquiry',
    'product_quality'
  ].filter(statePath => isChecked(statePath));

  const multipleChecked = selectedCategories.length > 1;
  const subCase = SUB_CASE_FORM_ROUTES_MAP[selectedCategories[0]];
  const caseReviewRoute = `/${FORM_BASE_PATH}/${masterCaseId}/review`;
  const subCaseFormRoute = `/${FORM_BASE_PATH}/${masterCaseId}/${subCase}`;

  return multipleChecked ? caseReviewRoute : subCaseFormRoute;
};

export const DUPLICATE_SEARCH_WINDOW = 'abbvDupeWindow';
const WINDOW_OPTIONS = 'toolbar=0,location=0,menubar=0,width=1200,height=1200';

/**
 * @function
 * @param {*} showContactsButton
 * @param {*} showNoMatchLink
 * @param {*} trilogyCase
 * @param {*} fromCreateCase
 */
export const openDuplicateSearch = (
  showContactsButton,
  showNoMatchLink,
  trilogyCase,
  fromCreateCase = false
) => {
  const withContacts = showContactsButton ? `?${DUPE_WITH_CONTACTS}` : '';
  const withNoMatch = showNoMatchLink ? `&${DUPE_WITH_NO_MATCH}` : '';
  if (showNoMatchLink && !showContactsButton) {
    // edge case: fix later
    console.warn('Unsupported parameters on openDuplicateSearch(false, true)');
  }

  const dupeSearchPopup = window.open(
    '/search/duplicate',
    DUPLICATE_SEARCH_WINDOW,
    WINDOW_OPTIONS
  );
  // Set these fields on the popup window. Search params not stable since we clear that out in Case controller.
  dupeSearchPopup[OPEN_DUPE_SEARCH] = true;
  dupeSearchPopup[DUPE_WITH_CONTACTS] = withContacts;
  dupeSearchPopup[DUPE_WITH_NO_MATCH] = withNoMatch;
  dupeSearchPopup[PARENT_CASE] = trilogyCase;
  dupeSearchPopup[FROM_CREATE_CASE] = fromCreateCase;
  dupeSearchPopup[PARENT_CASE_IS_EMAIL] = getOrElse(
    trilogyCase,
    PARENT_CASE_IS_EMAIL,
    false
  );
  dupeSearchPopup[EXTERNAL_SOURCE] = getOrElse(
    trilogyCase,
    EXTERNAL_SOURCE,
    null
  );
  if (window.location.href.includes('form')) {
    dupeSearchPopup[SOURCE_LOCATION] = window.location.href;
  }
  return dupeSearchPopup;
};

/**
 * @function
 * @param {*} id the case id to redirect to
 * @param {*} path redirect to a full path instead
 */
export const redirectNewCase = (id, path) => {
  window.location = path || `${window.location.origin}/${FORM_BASE_PATH}/${id}`;
};
