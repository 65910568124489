/* eslint react/prefer-stateless-function:0 */
import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'Common/components/Form';
import { store, theme } from 'config';
import Routes from './Routes';

/**
 * MUST be a class Component for Webpack HMR to propagate errors for display
 *
 * This has something to do with how unstable_handleError is used in the process
 * https://github.com/facebook/react/pull/6020/files
 */
class Root extends PureComponent {
  render = () => (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}

export default Root;
