import { get, isEmpty } from 'lodash';
import { css } from 'glamor';

/**
 * Common styles shared between components
 */

/**
 * Convenience for nesting styles in "&&", which is often required to increase specifity.
 * Increased specificity is required to override default styles provided by Blueprint components.
 * https://github.com/threepointone/glamor/blob/master/docs/selectors.md#selectors
 */
export const generateCSS = (rules, ...rest) =>
  css(
    {
      '&&': { '&&': { ...rules } }
    },
    ...rest
  );

export const genCaret = (
  colors,
  props,
  position = { top: -10, right: 19 }
) => ({
  '&:before, &:after': {
    content: '" "',
    position: 'absolute',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent',
    display: 'block',
    width: '0'
  },
  '&:before': {
    top: `${position.top + 5}px`,
    right: `${position.right}px`,
    borderBottom: `7px solid ${colors.arrow}`,
    zIndex: '1'
  },
  '&:after': {
    top: `${position.top + 3}px`,
    right: `${position.right - 1}px`,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderBottom: `8px solid ${colors.border}`
  }
});

export const genDisabledStyles = ({ colors }) => ({
  ':disabled': {
    border: `2px solid ${colors.quaternary}`,
    cursor: 'not-allowed'
  }
});

export const genInvalidBorder = ({ colors }) => ({
  border: `2px solid ${colors.error}`
});

export const genInvalidOptionsStyles = ({ colors }) => ({ errors }) =>
  isEmpty(errors)
    ? {
        border: '2px solid rgba(0, 0, 0, 0)'
      }
    : {
        border: `2px solid ${colors.error}`
      };

export const genInvalidBoxShadow = ({ colors }) => ({
  boxShadow: `inset 0 0 0 2px ${colors.error}`
});

export const genErrorStyles = ({ colors }) => ({
  margin: '0',
  color: colors.error
});

export const reverseText = (locale = 'US') => {
  const isRTLLocale = locale !== 'US'; // TODO build locale map for RLT/LTR

  if (isRTLLocale) {
    return {
      unicodeBidi: 'bidi-override',
      direction: 'rtl'
    };
  }

  return {};
};

export const genLabelStyles = (theme, props) => {
  const disabledStyles = genDisabledStyles(theme);
  const withErrors = get(props, 'errors.length', 0);
  const required = get(props, 'validations.required', {});

  return {
    labelGroup: generateCSS({
      alignContent: 'center',
      justifyContent: 'stretch',
      width: '100%',
      padding: '0',
      whiteSpace: 'nowrap',
      color: theme.colors.primary,
      display: 'flex',
      maxWidth: '100%',

      '& + input': {
        // easily overridden in any inputs already styling their widths
        width: '100%'
      }
    }),

    label: generateCSS({
      display: 'block',
      flex: '0 1 auto',
      // Don't wrap unless we need to
      ...(required.constraint || required.queryConstraint
        ? { whiteSpace: 'normal' }
        : {}),
      padding: '0 1px 0 0', // Keeps "required" text aligned when no label is present
      ...theme.fonts.inputLabel,
      color: withErrors ? theme.colors.error : theme.fonts.inputLabel.color,
      order: 1,
      ...disabledStyles
    }),

    requiredText: generateCSS({
      ...theme.fonts.inputLabel,
      order: 1,
      color: withErrors ? theme.colors.error : theme.fonts.inputLabel.color,
      display: 'block',
      right: '0',
      fontSize: '14px',
      flex: '0 1 auto'
    })
  };
};
