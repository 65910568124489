import React from 'react';
import PropTypes from 'prop-types';
import { modalStyles } from 'Common/components/withModal';
import { SimpleButton } from 'Common/components/Form';

const CancelModal = ({ handleConfirm, handleDismiss }) => (
  <div className={modalStyles.base}>
    <span className={modalStyles.title}>Cancel Task</span>
    <span className={modalStyles.body}>
      Are you sure you want to cancel this task? This cannot be undone.
    </span>
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton onClick={handleDismiss}>{"No, don't cancel"}</SimpleButton>
      <SimpleButton onClick={handleConfirm} primary>
        Yes, Cancel
      </SimpleButton>
    </div>
  </div>
);

CancelModal.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired
};

export default CancelModal;
