/* eslint no-useless-escape:0, no-template-curly-in-string:0, global-require:0 */

/**
 * tasks.js is the schema for the Tasks assigned to a Trilogy Case.
 * It includes/requires additional files to load the schemas for a read and edit view of a task.
 * These additional files are nested in the mocks/schemas/task directory.
 */
module.exports.schema = {
  id: 'task',
  locale: 'US',
  prefabs: [
    {
      description:
        'Sets a components model value to `null` whenever its layout.display is set to `null`',
      id: 'DisplayValueToggle',
      referencedProperties: {
        value: {
          relative: true,
          schemaPath: './',
          $if: [{ '../': { visible: { $eq: false } } }, { $set: null }],
          $else: { $get: 'value' }
        }
      }
    }
  ],
  pages: [
    {
      component: 'TasksTable',
      title: 'Tasks',
      statePath: 'tasks',
      collapsible: true,
      multiple: true,
      collapsed: true,
      length: 0,
      layout: {
        display: 'flex',
        width: '100%'
      },
      elements: [
        {
          component: 'TaskTableRow',
          isExpanded: false,
          isEditing: false,
          isComplete: false,
          referencedProperties: {
            isExpanded: {
              schemaPath: './',
              relative: true,
              $if: [
                {
                  $or: [
                    { 'value.id': { $eq: '$NEW_TASK' } },
                    { previousId: { $eq: '$NEW_TASK' } },
                    { isEditing: { $eq: true } }
                  ]
                },
                { $set: true }
              ],
              $elseIf: [{ isExpanded: { $eq: true } }, { $set: true }],
              $else: [{ isComplete: { $eq: true } }, { $set: false }]
            },
            isEditing: {
              schemaPath: './',
              relative: true,
              $if: [
                { 'value.status': { $ne: 'CANCELLED' } },
                { 'value.id': { $eq: '$NEW_TASK' } },
                { $set: true }
              ],
              $elseIf: [
                { 'value.status': { $ne: 'CANCELLED' } },
                { previousId: { $ne: '$NEW_TASK' } },
                { isEditing: { $eq: true } },
                { $set: true }
              ],
              $else: { $set: false }
            },
            previousId: {
              schemaPath: './',
              relative: true,
              $get: 'value.id'
            },
            // Clear attempts for anything NOT comm or pvquery
            'value.form.additional.attempts': {
              relative: true,
              $if: [
                { 'form.additional.type': { $ne: 'COMMUNICATION' } },
                { 'form.additional.type': { $ne: 'PVQUERY' } },
                { 'form.additional.type': { $ne: 'PQFOLLOWUP' } },
                { $set: null }
              ],
              $else: {
                $get: 'form.additional.attempts'
              }
            },
            'value.form.base.additionalNotes': {
              statePath: 'form.additional.type',
              relative: true,
              $if: [{ $ne: 'CASEFLOW' }, { $ne: 'REVIEW' }, { $set: null }],
              $else: {
                '$this.value.form.base': {
                  $get: 'additionalNotes'
                }
              }
            }
          },
          elements: [
            {
              description:
                'legacy task type section for compatibility with flat schema',
              component: 'Section',
              elements: [
                require('./task/task.read.js'),
                require('./task/task.edit.js')
              ]
            }
          ]
        }
      ]
    }
  ],
  dynamicOptions: [
    {
      id: 'pqFollowUpType',
      documentMeta: {
        documentDataId: 'task-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'pq_follow_up_type',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'pvQueryLetter',
      documentMeta: {
        documentDataId: 'task-options',
        documentDataType: 'documentDataOptionsGroup',
        documentDataField: 'pv_query_letter',
        tacticalType: 'document-data'
      }
    },
    {
      id: 'affiliateOptions',
      documentMeta: {
        documentDataId: 'affiliate-options',
        documentDataType: 'documentDataList',
        tacticalType: 'document-data'
      }
    }
  ]
};

// Convenience for rendering the initial form object in plaintext
module.exports.schemaStringified = JSON.stringify(
  module.exports.schema,
  null,
  2
);
