import { generateCSS } from 'Common/components/Form';

const stylesGenerator = () => ({
  base: generateCSS({
    display: 'inline-block',
    float: 'right',
    width: '9px',
    cursor: 'pointer'
  }),

  image: generateCSS({
    float: 'right',
    marginBottom: '3px'
  })
});

export default stylesGenerator;
