import React, { PureComponent } from 'react';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import { RadioGroup as BPRadioGroup } from '@blueprintjs/core';

import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import Radio from '../Radio';
import Label from '../Label';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';

class RadioGroup extends PureComponent {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.object.isRequired
        ]).isRequired,
        helpText: PropTypes.string
      })
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.object.isRequired,
      PropTypes.bool.isRequired
    ]),

    // If true, sets .focused class on option even on click
    aggressiveHighlight: PropTypes.bool
  };

  static defaultProps = {
    ...formElementDefaultProps,
    value: '',
    options: [],
    styles: { width: '100%' },
    aggressiveHighlight: false
  };

  state = { focused: '' };

  componentDidUpdate(prevProps) {
    const { changeValue } = this.props;
    if (changeValue && changeValue !== prevProps.changeValue) {
      this.props.onChange({
        ...this.props,
        value: this.props.changeValue
      });
    }
  }

  focus = () => {
    const { id, options } = this.props;
    document.getElementById(`${id}-${options[0].value}`).focus();
  };

  handleBlur = () => this.setState({ focused: '' });

  renderOptionGap = () => (
    <div className={this.props.computedStyles.inputHelpTextCompensation} />
  );

  renderHelpText = helpText => (
    <span className={this.props.computedStyles.helpText}>{helpText}</span>
  );

  renderRadio = (option, i) => {
    const {
      id,
      $id,
      onChange,
      options,
      computedStyles,
      value,
      disabled
    } = this.props;

    const checked = option.value === value;
    const optionId = `${id}-${option.value}`;
    const shouldRenderGasp = i < options.length - 1 || option.helpText;
    const handleChange = e => {
      e.stopPropagation();
      onChange(option.value);
    };

    return (
      <Radio
        key={i}
        id={optionId}
        $id={$id}
        name={kebabCase($id)}
        onChange={handleChange}
        focused={this.state.focused === optionId}
        checked={checked}
        value={option.value}
        disabled={option.disabled || disabled}
      >
        <span className={computedStyles.optionLabel}>{option.label}</span>
        {option.helpText ? this.renderHelpText(option.helpText) : null}
        {shouldRenderGasp ? this.renderOptionGap() : null}
      </Radio>
    );
  };

  render() {
    const {
      $id,
      schemaPath,
      id,
      label,
      options,
      value,
      errors,
      computedStyles
    } = this.props;
    return (
      <div
        id={id}
        role="group"
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        {label !== false ? (
          <div className={computedStyles.compensateForGroup}>
            <Label {...this.props} />
          </div>
        ) : null}
        <BPRadioGroup
          className={computedStyles.group}
          selectedValue={value}
          onChange={() => {}}
        >
          {options.map(this.renderRadio)}
        </BPRadioGroup>
        <div className={computedStyles.compensateForGroup}>
          <ValidationErrors
            id={id}
            computedStyles={computedStyles}
            validationErrors={errors}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(RadioGroup);
