import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import withStyles from 'Common/components/Form/withStyles';
import { DATE_FORMAT } from 'Common/components/Form';
import { getUserName } from 'Common/utils';
import stylesGenerator from './styles';
import EmailLink from './EmailLink';

const Footer = ({
  computedStyles,
  creatorId,
  userList,
  createdTimestamp,
  type,
  showModal,
  clearModal,
  caseId,
  taskId
}) => {
  const creator = getUserName(userList, creatorId) || creatorId;
  return (
    <div
      className={computedStyles.footer}
      data-id={`${taskId}.footer.createdInfo`}
    >
      <span>
        CREATED ON {moment(createdTimestamp).format(DATE_FORMAT)} BY {creator}
      </span>
      {type === 'COMMUNICATION' ? (
        <EmailLink
          showModal={showModal}
          clearModal={clearModal}
          caseId={caseId}
        />
      ) : null}
    </div>
  );
};

Footer.propTypes = {
  computedStyles: PropTypes.shape({
    footer: PropTypes.object.isRequired
  }).isRequired,
  creatorId: PropTypes.string.isRequired,
  createdTimestamp: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      sub: PropTypes.string,
      email: PropTypes.string,
      un: PropTypes.string,
      fn: PropTypes.string,
      sn: PropTypes.string
    })
  ).isRequired,
  showModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  caseId: PropTypes.string,
  taskId: PropTypes.string.isRequired
};

// todo: re-evaluate when we wipe tasks if we really can ever have empty caseid
Footer.defaultProps = {
  caseId: ''
};

export default withStyles(stylesGenerator)(Footer);
