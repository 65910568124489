/* eslint no-useless-escape:0 no-template-curly-in-string:0 */

/**
 * patientLookup.js is the schema for the Patient Lookup window that opens from the Case schema.
 * This window is triggered via masterCase.js schema with 'onLookupPatient' action.
 */
module.exports.schema = {
  id: 'patientLookup',
  locale: 'US',
  pages: [
    {
      title: 'Patient Look-up',
      elements: [
        {
          component: 'Section',
          layout: {
            background: 'initial',
            margin: 'initial'
          },
          elements: [
            {
              label: 'First Name',
              component: 'TextInput',
              statePath: 'firstName',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Last Name',
              component: 'TextInput',
              statePath: 'lastName',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Third Party Patient ID',
              component: 'TextInput',
              statePath: 'reporter',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Initials',
              component: 'TextInput',
              statePath: 'initials',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Date of Birth',
              component: 'DateInput',
              statePath: 'dob',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              validations: {
                dateRestriction: {
                  constraint: 'future',
                  errorMessage: 'Date must not occur in the future'
                }
              }
            },
            {
              label: 'City',
              component: 'TextInput',
              statePath: 'contact_info.city',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Country',
              component: 'Select',
              statePath: 'contact_info.country',
              options: [],
              layout: {
                flex: '0 1 auto',
                width: '33%'
              },
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.country-options'
                }
              }
            },
            {
              label: 'State/Province/Region',
              component: 'TextInput',
              statePath: 'contact_info.state',
              layout: {
                flex: '0 1 auto',
                width: '33%',
                marginRight: '34%'
              }
            },
            {
              label: 'Phone Number',
              component: 'TextInput',
              statePath: 'contact_info.phone.number',
              layout: {
                flex: '0 1 auto',
                width: '33%'
              }
            },
            {
              label: 'Email',
              component: 'TextInput',
              statePath: 'contact_info.email.address',
              layout: {
                flex: '0 1 auto',
                width: '33%',
                marginRight: '34%'
              }
            }
          ]
        }
      ]
    }
  ]
};

// Convenience for rendering the initial form object in plaintext
module.exports.stringified = JSON.stringify(module.exports.form, null, 2);
