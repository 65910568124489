/* eslint react/prefer-stateless-function:0 */
import React, { PureComponent } from 'react';
import ThemeProvider from 'Common/components/Form/ThemeProvider';
import theme from 'config/theme';
import NoConfigApp from './NoConfigApp';

/**
 * MUST be a class Component for Webpack HMR to propagate errors for display
 *
 * This has something to do with how unstable_handleError is used in the process
 * https://github.com/facebook/react/pull/6020/files
 */
class NoConfigRoot extends PureComponent {
  render = () => (
    <ThemeProvider theme={theme}>
      <NoConfigApp />
    </ThemeProvider>
  );
}

export default NoConfigRoot;
