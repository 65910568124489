import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, SimpleButton } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import stylesGenerator from './styles';

const ConflictSaveErrorModal = ({ computedStyles, actions }) => (
  <div className={computedStyles.base}>
    <span className={modalStyles.title}>CANNOT SAVE EDITS</span>
    Your edits on this page cannot be saved because they conflict with a save
    that occurred after you opened this case.
    <div className={modalStyles.buttonsContainer}>
      <SimpleButton onClick={actions.emitModalContentClear} primary>
        Cancel, view form
      </SimpleButton>
      <SimpleButton onClick={() => window.location.reload()}>
        RELOAD PAGE
      </SimpleButton>
    </div>
  </div>
);

ConflictSaveErrorModal.propTypes = {
  actions: PropTypes.shape({
    emitModalContentClear: PropTypes.func.isRequired
  }).isRequired,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired
};

export default withStyles(stylesGenerator)(ConflictSaveErrorModal);
