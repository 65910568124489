export const MASTER_STATE_PATHS = {
  AWARENESS_DATE: 'summary.awareness_date',
  AFFILIATE_AWARENESS_DATE: 'summary.affiliate_awareness_date',
  SUBCASE_CATEGORY_AE: 'summary.narrative.categories.adverse_event',
  SERIOUSNESS: 'summary.narrative.seriousness',
  AER_RCT_NUMBER: 'summary.follow_up_case',
  AE_TYPE: 'summary.narrative.adverse_event.type',
  PQ_TYPE: 'summary.productQuality.complaint.processing.type'
};

export const AE_TYPES = {
  INITIAL: 'initial_case',
  FOLLOW_UP: 'follow_up'
};

export const PQ_TYPES = {
  INITIAL: 'initial_case',
  FOLLOW_UP: 'follow_up'
};

export const AE_STATE_PATHS = {
  STATUS: 'subcases.adverseEvent.status',
  ASSIGNEE: 'subcases.adverseEvent.assignee',
  VERSION: 'subcases.adverseEvent.version',
  ARCHIVED_FLAG: 'subcases.adverseEvent.archived',
  NEW_VERSION_FLAG: 'subcases.adverseEvent.newVersion',
  SUBMITTED_FLAG: 'subcases.adverseEvent.submitted',
  REOPENED_FLAG: 'subcases.adverseEvent.reopened',
  REOPENED_DATE: 'subcases.adverseEvent.reopenedDate',
  SAFETY_VERSION: 'subcases.adverseEvent.aerinfo.safety.version',
  LAST_RECEIVED_DATE: 'subcases.adverseEvent.aerinfo.safety.last_received_date',
  SUBMISSION_DUE_DATE:
    'subcases.adverseEvent.aerinfo.safety.submission_due_date',
  SERIOUSNESS: 'subcases.adverseEvent.aerinfo.safety.seriousness',
  AFFILIATE_AWARENESS_DATE:
    'subcases.adverseEvent.aerinfo.safety.affiliate_awareness_date',
  AER_RCT_NUMBER: 'subcases.adverseEvent.aerinfo.safety.aer_rct_no'
};

export const MI_STATE_PATHS = {
  ASSIGNEE: 'subcases.medicalInfo.assignee'
};

export const PQ_STATE_PATHS = {
  ASSIGNEE: 'subcases.productQuality.assignee',
  STATUS: 'subcases.productQuality.status',
  VERSION: 'subcases.productQuality.version',
  NEW_VERSION_FLAG: 'subcases.productQuality.newVersion',
  SUBMITTED_FLAG: 'subcases.productQuality.submitted',
  REOPENED_FLAG: 'subcases.productQuality.reopened',
  REOPENED_DATE: 'subcases.productQuality.reopenedDate',
  FOLLOW_UP: 'subcases.productQuality.complaint.processing.type'
};
