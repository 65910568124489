/* eslint no-console:0 */
import { client } from 'config/apollo';
import getCaseByIdQuery from './queries/case/getCaseByIdQuery';
import caseResponseFormatter from './formatter/caseResponse';

const submitCaseQuery = (
  _state,
  masterCaseId,
  caseFragment,
  formatSubcases = true,
  suppressNotifier = false
) => {
  const variables = { id: masterCaseId };
  return client
    .query({
      context: {
        fetchOptions: {
          suppressNotifier
        }
      },
      query: getCaseByIdQuery(caseFragment),
      variables,
      fetchPolicy: 'network-only'
    })
    .then(({ data: { createCase, case: masterCase } }) => {
      const trilogyCase = createCase || masterCase;
      const thawed = Object.isFrozen(trilogyCase)
        ? JSON.parse(JSON.stringify(trilogyCase))
        : trilogyCase;
      return caseResponseFormatter(thawed, formatSubcases);
    });
};

export default submitCaseQuery;
