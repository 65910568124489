/* eslint no-alert:0, no-useless-escape:0 */

import { get, trim } from 'lodash';
import {
  SUB_CASE_TYPES_MAP,
  CC_SUB_CASE_TYPES_MAP,
  CASE_STATUS_NEW
} from 'Common/constants';
import {
  FORM_BASE_PATH,
  FORM_MI_PATH,
  FORM_AE_PATH,
  FORM_PQ_PATH
} from 'CreateCase/constants';
import { TASKS_BASE_PATH } from 'Tasks/constants';
import { getOrElse } from 'Common/utils';

/** @module Common Routing Utils */

const isNewStatus = row =>
  getOrElse(row, 'status', CASE_STATUS_NEW).toUpperCase() === CASE_STATUS_NEW;

/**
 * @function
 * @param {*} match
 */
export const isSubcaseRoute = match =>
  Object.keys(SUB_CASE_TYPES_MAP).includes(
    get(match, 'params.page', '').toLowerCase()
  );

/**
 * @function
 * @param {*} match
 */
export const isDashboardRoute = match =>
  get(match, 'params.page', '').toLowerCase() === 'review';

/**
 * @function
 * @param {*} row
 * @param {*} key
 */
export const generateSubCaseLink = (row, key) => {
  const CASE_LINK = {
    [CC_SUB_CASE_TYPES_MAP.ae]: `/${FORM_BASE_PATH}/${trim(row.id, '-AE')}/ae`,
    [CC_SUB_CASE_TYPES_MAP.mi]: `/${FORM_BASE_PATH}/${trim(row.id, '-MI')}/mi`,
    [CC_SUB_CASE_TYPES_MAP.pq]: `/${FORM_BASE_PATH}/${trim(row.id, '-PQ')}/pq`
  };
  return CASE_LINK[key];
};

/**
 * @function
 * @param {*} match
 * @param {*} masterCaseId
 */
export const tasksForCaseLink = (match, masterCaseId) =>
  isSubcaseRoute(match)
    ? `/${TASKS_BASE_PATH}/${masterCaseId}/${match.params.page}`
    : `/${TASKS_BASE_PATH}/${masterCaseId}`;

/**
 * @function
 * @param {*} match
 * @param {*} row
 */
export const generateCaseLink = (match, row) => {
  const { page } = match.params;
  const isSubcase = isSubcaseRoute(match);
  const isNewCase = isNewStatus(row);

  if (isSubcase) {
    return `/${FORM_BASE_PATH}/${row.id}/${page}`;
  } else if (isNewCase) {
    return `/${FORM_BASE_PATH}/${row.id}`;
  }

  return `/${FORM_BASE_PATH}/${row.id}/review`;
};

/**
 * Opens the user's default mail client/**
 * @function
 * @param recipient Email To
 * @param subject Email Subject
 */
export const openEmailClient = (recipient, subject) => {
  window.location = `mailto:${recipient}?Subject=${encodeURIComponent(
    subject
  )}`;
};

/**
 * @function
 * @param {*} id
 */
export const getSubCaseKeyFromId = id =>
  id
    .match(/-(\w+)/g)[1]
    .slice(1, 3)
    .toLowerCase();

const subCaseKeys = `${FORM_MI_PATH}|${FORM_PQ_PATH}|${FORM_AE_PATH}`.toUpperCase();
const SUBCASES_REGEX = new RegExp(`-(${subCaseKeys}\\d+)`);

/**
 * @function
 * @param {string} id
 * @returns {string}
 */
export const stripSubCaseId = id => id.replace(SUBCASES_REGEX, '');

/**
 * @function
 * @param {*} location
 * @param {*} onNavigate
 */
export const promptDocumentLocked = (location, onNavigate) => {
  const shouldNavigateAway = window.confirm(
    [
      'Clicking OK will navigate you away from the page and result in unsaved changes being discarded.',
      'If you have made edits, we recommend that you note them outside the application, and return after the case is unoccupied.'
    ].join(' ')
  );

  if (shouldNavigateAway) {
    onNavigate(location);
  }

  return shouldNavigateAway;
};

/**
 * @function
 * @param {*} location
 * @param {*} onNavigate
 */
export const promptDocumentSaved = (location, onNavigate) => {
  const shouldNavigateAway = window.confirm(
    [
      'Save your work before leaving this page?',
      'If cancelled, all changes since your last save will be discarded.'
    ].join(' ')
  );

  if (shouldNavigateAway) {
    onNavigate(location);
  }

  return shouldNavigateAway;
};
