import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase, get } from 'lodash';

import SectionTitle from './SectionTitle/index';
import stylesGenerator from './styles';
import withStyles from '../withStyles';
import { csePropTypes } from '../propTypes';

const Section = ({
  $id,
  id,
  children,
  title,
  label,
  errors,
  schemaPath,
  statePath,
  computedStyles,
  validations
}) => {
  const styles = computedStyles;
  const sectionId = `section-${kebabCase(statePath || id)}`;
  const requiredSection = get(validations, 'required.queryConstraint', false);
  const withErrors = errors && errors.length;
  return (
    <section
      id={sectionId}
      key={sectionId}
      className={`${styles.base} ${withErrors ? `${styles.error}` : ''}`}
      data-id={$id}
      data-schema-path={schemaPath}
    >
      {title ? <SectionTitle title={title} /> : null}
      {label ? <span className={styles.label}>{label}</span> : null}
      {requiredSection ? <span>*required</span> : null}
      {children}
    </section>
  );
};

Section.propTypes = {
  ...csePropTypes,
  title: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  statePath: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Section.defaultProps = {
  title: '',
  statePath: '',
  trilogyCase: {}
};

export default withStyles(stylesGenerator)(Section);
