module.exports = {
  description: 'task read-only view',
  component: 'Section',
  layout: {
    background: 'transparent',
    flexDirection: 'row',
    padding: 0
  },
  referencedProperties: {
    update: {
      relative: true,
      schemaPath: '../../',
      $if: [{ isEditing: { $eq: true } }, { $set: false }],
      $else: [{ $set: true }]
    },
    'layout.display': {
      relative: true,
      schemaPath: '../../',
      $if: [{ isEditing: { $eq: true } }, { $set: 'none' }],
      $else: [{ $set: 'block' }]
    }
  },
  elements: [
    {
      component: 'Section',
      length: 1,
      statePath: 'form.additional.attempts.attempt',
      layout: {
        background: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        ' > div': {
          background: 'white',
          flex: '0 1 auto',
          padding: 15,
          margin: 15,
          width: '30% !important'
        }
      },
      elements: [
        {
          component: 'PlainText',
          label: 'Additional Notes',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [
                { $or: [{ $eq: 'CASEFLOW' }, { $eq: 'REVIEW' }] },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            value: {
              statePath: '../../../',
              relative: true,
              $get: 'base.additionalNotes'
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Feedback',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: '../../type',
              $if: [
                {
                  $eq: 'REVIEW'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            value: {
              statePath: '../../../',
              relative: true,
              $get: 'additional.feedback'
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Requester',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              relative: true,
              statePath: '../../type',
              $if: [
                {
                  $eq: 'REVIEW'
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            value: {
              statePath: '../../../',
              relative: true,
              $get: 'additional.requester'
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Pregnancy-Related',
          pristine: true,
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PVQUERY' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            },
            value: {
              statePath: '../../../',
              relative: true,
              $if: [
                {
                  'base.pregnancyRelated': {
                    $eq: true
                  }
                },
                {
                  $set: 'Yes'
                }
              ],
              $else: {
                $set: 'No'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Follow-Up Type',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PQFOLLOWUP' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            },
            value: {
              relative: true,
              statePath: '../../../',
              $get: 'base.followUp.type'
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Comments',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PQFOLLOWUP' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            },
            value: {
              relative: true,
              statePath: '../../../',
              $get: 'base.followUp.comments'
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Attempt Number',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [
                {
                  $or: [
                    { $eq: 'PVQUERY' },
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'PQFOLLOWUP' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            value: {
              relative: true,
              $set: { $concat: ['', { $get: 'attemptNumber' }] }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Query Due Date',
          isDate: true,
          statePath: 'queryDueDate',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PVQUERY' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Query Date',
          isDate: true,
          statePath: 'queryDate',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [
                { $or: [{ $eq: 'PVQUERY' }, { $eq: 'COMMUNICATION' }] },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            label: {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'COMMUNICATION' }, { $set: 'Attempt Date' }],
              $else: {
                $set: 'Query Date'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Recipient',
          shouldCapitalize: true,
          statePath: 'recipient.name',
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'COMMUNICATION' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Follow-Up Date',
          isDate: true,
          statePath: 'followUp.date',
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PQFOLLOWUP' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Communication Method',
          statePath: 'method',
          shouldCapitalize: true,
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [
                {
                  $or: [
                    { $eq: 'PVQUERY' },
                    { $eq: 'COMMUNICATION' },
                    { $eq: 'PQFOLLOWUP' }
                  ]
                },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            },
            label: {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'COMMUNICATION' }, { $set: 'Method' }],
              $else: {
                $set: 'Communication Method'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Comments',
          statePath: 'comment',
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'COMMUNICATION' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Letter',
          layout: {
            wordBreak: 'break-all',
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [{ $eq: 'PVQUERY' }, { $set: 'block' }],
              $else: {
                $set: 'none'
              }
            },
            value: {
              relative: true,
              statePath: 'letters.letter',
              $join: [{ $get: 'type' }, ', ']
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Reply Received?',
          shouldCapitalize: true,
          statePath: 'reply.replyReceived',
          layout: {
            width: '100%'
          },
          referencedProperties: {
            'layout.display': {
              statePath: '../../type',
              relative: true,
              $if: [
                { $or: [{ $eq: 'PVQUERY' }, { $eq: 'PQFOLLOWUP' }] },
                { $set: 'block' }
              ],
              $else: {
                $set: 'none'
              }
            }
          }
        },
        {
          component: 'PlainText',
          label: 'Actual Reply Date',
          isDate: true,
          statePath: 'reply.actualReplyDate',
          referencedProperties: {
            'layout.display': {
              statePath: 'reply.replyReceived',
              relative: true,
              $if: [{ $eq: 'YES' }, { $set: 'block' }],
              $else: { $set: 'none' }
            }
          }
        }
      ]
    }
  ]
};
