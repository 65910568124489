import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  title: generateCSS({
    padding: '0 15px 5px',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    ...theme.fonts.inputGroupTitle
  })
});

export default stylesGenerator;
