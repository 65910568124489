/* eslint react/no-unused-prop-types:0 */
import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '../withStyles';
import stylesGenerator from './styles';
import { csePropTypes } from '../propTypes';

const SimpleButton = ({
  $id,
  schemaPath,
  onClick,
  computedStyles,
  className,
  children,
  disabled,
  type,
  ...props
}) => (
  <button
    data-id={$id || props['data-id']}
    data-schema-path={schemaPath}
    type={type}
    onClick={onClick}
    className={computedStyles.base}
    disabled={disabled}
    aria-disabled={disabled}
  >
    {children}
  </button>
);

SimpleButton.propTypes = {
  ...csePropTypes,
  onClick: PropTypes.func.isRequired,
  computedStyles: PropTypes.shape({
    base: PropTypes.object.isRequired
  }).isRequired,
  children: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  'data-id': PropTypes.string
};

SimpleButton.defaultProps = {
  primary: false,
  disabled: false,
  type: 'submit',
  'data-id': null
};

export default withStyles(stylesGenerator)(SimpleButton);
