import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    display: 'block',
    padding: '0 15px 10px',
    borderCollapse: 'collapse',
    '> tbody > tr > th': {
      padding: '3px 4px',
      backgroundColor: theme.colors.background,
      border: `1px solid ${theme.colors.quaternary}`
    }
  })
});

export default stylesGenerator;
