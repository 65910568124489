import moment from 'moment';
import {
  get,
  isEmpty,
  isString,
  includes,
  mapValues,
  padStart,
  range,
  reduce,
  isNil,
  trimEnd,
  trimStart
} from 'lodash';

import {
  API_DATE_FORMAT,
  DATE_FORMAT_HOURS,
  DATE_RESTRICTIONS,
  CHARACTER_EX_UNITS,
  EMPTY_SELECTION,
  INPUT_SIDE_PADDING
} from './constants';

/** @module Common Utils */

/**
 * Determines width of input field based on
 * character maxlength validation, input type, and any explicit widths provided.
 *
 * @function
 * @param {object} props
 * @param {object} props.type
 * @param {object} props.width
 * @param {object} props.validations.maxlength
 * @returns {object}
 */
export const determineInputWidth = ({ type, validations }) => {
  const constraint = get(validations, 'maxLength.constraint');
  const charWidth = CHARACTER_EX_UNITS[type];
  const newWidth = charWidth * constraint; // Beware NaN values if this is too big

  const getWidth = x => {
    if (constraint && newWidth) {
      return `calc(${newWidth}ex + ${x}px)`;
    }

    return '100%';
  };

  return {
    width: getWidth(INPUT_SIDE_PADDING),
    maxWidth: '100%'
  };
};

/**
 * Use to safely spread props onto a component if given boolean is true.
 * Prevents providing null values to uncontrolled components, and invalid props
 * to regular input components.
 * See https://facebook.github.io/react/docs/forms.html#controlled-components
 *
 * @function
 * @param {string} value
 * @param {bool} shouldSpread
 * @returns {object|null}
 */
export const spreadPropsIfTruthy = (props, shouldSpread) =>
  shouldSpread ? props : null;

/**
 * @function
 * 🤖
 */
export const genListTerminator = (list, index) => {
  const lastError = list.length <= index + 1;

  return lastError ? '.' : ', ';
};

/**
 * Transform date parts to ISO, supporting partial dates
 * @function
 */
export const dateToIsoString = dateParts => {
  const { yyyy, mm, dd } = mapValues(dateParts, val => (isNil(val) ? '' : val));
  return trimEnd(`${yyyy}-${mm}-${dd}`, '-');
};

/**
 * @function
 * @param {string} dtString
 * @returns {Object}
 */
export const parseDateString = dtString => {
  if (!moment.isMoment(dtString) && (!isString(dtString) || isNil(dtString)))
    dtString = ''; // eslint-disable-line

  if (moment.isMoment(dtString)) {
    return {
      yyyy: dtString.format('YYYY'),
      mm: dtString.format('MM'),
      dd: dtString.format('DD')
    };
  }

  // Partial/Full date parser used by DateInput component. Now with ISO-8601 string support!
  const matches = dtString.match(/^([0-9]{4})?-?([0-9]{2})?-?([0-9]{2})?/);
  if (matches) {
    const dateParts = matches.map(v => (isNil(v) ? '' : v));
    return {
      yyyy: dateParts[1],
      mm: dateParts[2],
      dd: dateParts[3]
    };
  }

  return {
    yyyy: '',
    mm: '',
    dd: ''
  };
};

/**
 * Full dates should be validated against moment for validity.
 * Exported for testing, but it's suggested to use validateDateInput or abbvieDateFormat outside this file
 * @function
 */
export const validFullDate = value => {
  if (isEmpty(value)) {
    // required constraint will handle if an empty date is allowed
    return true;
  } else if (moment.isMoment(value)) {
    // just in case we pass a moment. because why use static typing
    return value.isValid();
  } else if (includes(value, 'T')) {
    // just in case we pass an ISO string from BE. Note: the case service uses fractional sections, Moment does not.
    return moment
      .utc(
        value,
        ['YYYY-MM-DDTHH:mm:ss.SSS[Z]', 'YYYY-MM-DDTHH:mm:ss[Z]'],
        true
      )
      .isValid();
  }
  // Most of our use cases should go here for a 'full' date constraint.
  return moment.utc(value, API_DATE_FORMAT, true).isValid();
};

/**
 * @function
 * @param {*} value
 * @param {*} constraint
 */
export const validateDateInput = (value, constraint) => {
  const dateParts = parseDateString(value);
  // Partial dates must have a month if a year is selected, and a day if a month is selected.
  if (constraint === 'partial') {
    const bitWeights = { yyyy: 4, mm: 2, dd: 1 };
    const sum = reduce(
      dateParts,
      (result, val, key) =>
        val !== EMPTY_SELECTION && val.match(/\d+/)
          ? result + bitWeights[key]
          : result,
      0
    );
    if ([0, 4, 6].includes(sum)) {
      return true;
    } else if (sum === 7) {
      return validFullDate(value);
    }
    return false;
  }

  return validFullDate(value);
};

/**
 * Accepts a full or partial date string, or moment object and displays it in AbbVie format.
 * @function
 * @param value String of YYYY-MM-DD, YYYY-MM, YYYY or a moment object
 * @returns {*} Date string DD MMM YYYY or null if invalid
 */
export const abbvieDateFormat = value => {
  if (!isNil(value) && value !== '' && validateDateInput(value, 'partial')) {
    const { yyyy, mm, dd } = parseDateString(value);
    const MMM = mm === '' ? '' : moment(mm, 'MM').format('MMM');
    const trimmed = trimStart(`${dd} ${MMM} ${yyyy}`);
    return trimmed === '' ? null : trimmed;
  }
  return null;
};

/**
 * @function
 * @param {*} value
 */
export const abbvieDateFormatOnlyForFullDates = value => {
  if (!isNil(value) && value !== '' && validFullDate(value)) {
    return abbvieDateFormat(value);
  }
  return 'Not Specified';
};

/**
 * @function
 * @param {*} value
 */
export const abbvieDateAndAtTime = value => {
  if (!isNil(value) && value !== '' && validFullDate(value)) {
    return moment(value)
      .format(DATE_FORMAT_HOURS)
      .toUpperCase();
  }
  return 'Not Sent';
};

/**
 * @function
 * @param {*} restrictPast
 */
export const calculateMinDate = restrictPast =>
  (restrictPast
    ? moment()
    : moment(dateToIsoString(DATE_RESTRICTIONS.PAST), API_DATE_FORMAT, true)
  ).startOf('day');

/**
 * Future dates are restricted to now + 24hrs
 * @function
 */
export const calculateMaxDate = restrictFuture =>
  (restrictFuture
    ? moment().add(1, 'day')
    : moment(dateToIsoString(DATE_RESTRICTIONS.FUTURE), API_DATE_FORMAT, true)
  ).endOf('day');

/**
 * @function
 * @returns {Object[]}
 */
export const getDayOptions = () => {
  const dayRange = range(1, 32, 1);
  return dayRange.map(day => {
    const paddedNum = padStart(day, 2, '0');
    return {
      label: `${paddedNum}`,
      value: `${paddedNum}`
    };
  });
};

/**
 * @function
 * @returns {Object[]}
 */
export const getMonthOptions = () => {
  // Moment month 0 = Jan
  const monthRange = range(0, 12, 1);
  return monthRange.map(month => ({
    label: moment.monthsShort(month),
    value: `${padStart(month + 1, 2, '0')}`
  }));
};

/**
 * @function
 * @param {*} minDate
 * @param {*} maxDate
 * @returns {Object[]}
 */
export const getYearOptions = (minDate, maxDate) => {
  const yearRange = range(minDate.getFullYear(), maxDate.getFullYear() + 1, 1);
  return yearRange.map(year => ({
    label: `${year}`,
    value: `${year}`
  }));
};
