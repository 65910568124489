import gql from 'graphql-tag';

const searchPatient = () => gql`
  query SearchPatient($q: PatientSearchQuery!, $size: Int, $from: Int) {
    searchPatient(q: $q, size: $size, from: $from) {
      total
      status
      hits {
        id
        firstName
        lastName
        currentRevision
        ethnicity
        initials
        dob
        createdTimestamp
        privacy
        reporter
        gender
        contact_info {
          email {
            address
          }
          phone {
            number
            extension
            isFax
          }
          line1
          line2
          city
          state
          postal_code
          country
        }
      }
    }
  }
`;

export default searchPatient;
