import React from 'react';
import { PropTypes } from 'prop-types';

import Input from '../Input';

const handleKeyDown = evt => {
  // only allow numbers and backspace
  if (!Number.isFinite(parseInt(evt.key, 10)) && evt.keyCode !== 8) {
    evt.preventDefault();
  }
};

const NumberInput = props => (
  <Input onKeyDown={evt => handleKeyDown(evt)} {...props}>
    <input />
  </Input>
);

NumberInput.propTypes = {
  maxLength: PropTypes.number.isRequired
};

NumberInput.defaultProps = {
  maxLength: null
};

export default NumberInput;
