/* eslint import/prefer-default-export:0 */
export const LOOKUP_BASE_PATH = 'lookup';
export const LOOKUP_PATH = `/${LOOKUP_BASE_PATH}/:lookupType`;
export const LOOKUP_CONTACT = 'contact';
export const LOOKUP_PATIENT = 'patient';
export const LOOKUP_WINDOW_NAME = 'AbbVieLookupWindow';

// NOTE: The Search Contacts GQL API does not have the `contacts.contact` prefix
// Only use these within Lookup
export const CONTACT_PATHS = {
  LAST_NM: 'name.last',
  FIRST_NM: 'name.first',
  CONTACT_TYPE: 'type',
  ORGANIZATION: 'organization',
  STREET: 'address[0].line1',
  LINE2: 'address[0].line2',
  CITY: 'address[0].city',
  STATE: 'address[0].state',
  POSTAL_CODE: 'address[0].postal_code',
  COUNTRY: 'country',
  PHONE_NUM: 'phone[0].number',
  EMAIL: 'email[0].address',
  PROVIDER: 'psp.name',
  OTHER_PARTY: 'other.third_party',
  OTHER_TYPE: 'other.type',
  GENDER: 'gender',
  REPORTER_PRIMARY: 'reporter.primary',
  REPORTER_PRIVATE: 'reporter.private',
  EMPLOYEE_TYPE: 'employee.type',
  OPI_NUMBER: 'psp.opi_number',
  SOURCE_REF: 'source_reference',
  AER_OCCUPATION: 'aer.occupation',
  AER_REPORTER: 'aer.reporter_type',
  AER_HCP: 'aer.hcp',
  HCP_SPECIAL: 'hcp.specialty',
  HCP_LICENSE: 'hcp.license_number',
  PHONE_EXT: 'phone[0].extension',
  PHONE_FAX: 'phone[0].fax',
  PHONE_IS_FAX: 'phone[0].isFax',
  NON_PATIENT_TYPE: 'non_patient.type'
};

export const PATIENT_PATHS = {
  ID: 'id',
  REPORTER: 'reporter',
  LAST_NM: 'lastName',
  FIRST_NM: 'firstName',
  THIRD_PARTY_ID: 'reporter',
  INITIALS: 'initials',
  DOB: 'dob',
  GENDER: 'gender',
  CITY: 'contact_info[0].city',
  STREET: 'contact_info[0].line1',
  LINE2: 'contact_info[0].line2',
  STATE: 'contact_info[0].state',
  COUNTRY: 'contact_info[0].country',
  PHONE_NUM: 'contact_info[0].phone[0].number',
  EMAIL: 'contact_info[0].email[0].address',
  ETHNICITY: 'ethnicity',
  PRIVACY: 'privacy',
  POSTAL_CODE: 'contact_info[0].postal_code'
};

// NOTE: The Search Patient GQL API does not have the `patient.patient` prefix
// Only use these within Lookup
export const CONTACT_HEADER = [
  {
    groupLabel: 'Contact Information',
    columns: [
      {
        id: 'lastName', // id is for unique react keys
        label: 'Last Name',
        sortBy: CONTACT_PATHS.LAST_NM
      },
      {
        id: 'firstName',
        label: 'First Name',
        sortBy: CONTACT_PATHS.FIRST_NM
      },
      {
        id: 'type',
        label: 'Type of Contact',
        sortBy: CONTACT_PATHS.CONTACT_TYPE
      },
      {
        id: 'org',
        label: 'Organization/Company',
        sortBy: CONTACT_PATHS.ORGANIZATION
      },
      {
        id: 'country',
        label: 'Country',
        sortBy: CONTACT_PATHS.COUNTRY
      },
      {
        id: 'city',
        label: 'City',
        sortBy: CONTACT_PATHS.CITY
      },
      {
        id: 'state',
        label: 'State/Province/Region',
        sortBy: CONTACT_PATHS.STATE
      }
    ]
  },
  {
    groupLabel: 'Actions',
    columns: [
      {
        id: 'view_link',
        label: '', // Blank column header for 'view' CTA
        sortBy: ''
      },
      {
        id: 'add_link',
        label: '', // Blank column header for 'add' CTA
        sortBy: ''
      }
    ]
  }
];

// TODO
export const PATIENT_HEADER = [
  {
    groupLabel: 'Patient Information',
    columns: [
      {
        label: 'Last Name',
        sortBy: PATIENT_PATHS.LAST_NM
      },
      {
        label: 'First Name',
        sortBy: PATIENT_PATHS.FIRST_NM
      },
      {
        label: 'Patient ID',
        sortBy: PATIENT_PATHS.THIRD_PARTY_ID
      },
      {
        label: 'Initials',
        sortBy: PATIENT_PATHS.INITIALS
      },
      {
        label: 'Date of Birth',
        sortBy: PATIENT_PATHS.DOB
      },
      {
        label: 'Gender',
        sortBy: PATIENT_PATHS.GENDER
      },
      {
        label: 'Country',
        sortBy: PATIENT_PATHS.COUNTRY
      },
      {
        label: 'City',
        sortBy: PATIENT_PATHS.CITY
      },
      {
        label: 'State/Province/Region',
        sortBy: PATIENT_PATHS.STATE
      }
    ]
  },
  {
    groupLabel: 'Actions',
    columns: [
      {
        label: '', // Blank column header for 'view' CTA
        sortBy: ''
      },
      {
        label: '', // Blank column header for 'add' CTA
        sortBy: ''
      }
    ]
  }
];
