/* eslint no-console:0 */
import { SCHEMA_SVC_API_MAP } from 'config/values';
import 'regenerator-runtime';

const fetchFormSchema = async type => {
  const API = SCHEMA_SVC_API_MAP[type];
  const fetchOptions = {
    method: 'GET',
    headers: { 'x-api-key': API.apiKey }
  };

  try {
    const response = await fetch(API.uri, fetchOptions);

    return await response.json();
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export default fetchFormSchema;
