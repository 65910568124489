import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isPlainObject, mapValues } from 'lodash';

import ascending from 'public/images/ascending.svg';
import ascendingSelected from 'public/images/ascending-selected.svg';
import descending from 'public/images/descending.svg';
import descendingSelected from 'public/images/descending-selected.svg';
import { SORT_ASC, SORT_DESC, TABLE_PROP_TYPES } from 'Common/constants';
import withStyles from 'Common/components/Form/withStyles';
import stylesGenerator from './styles';

class SortArrow extends Component {
  static propTypes = {
    sortPath: TABLE_PROP_TYPES.SORT_PATH.isRequired,
    sortBy: TABLE_PROP_TYPES.SORT_BY.isRequired,
    onSortClick: PropTypes.func,
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      image: PropTypes.object.isRequired
    }).isRequired
  };

  static defaultProps = {
    sortPath: null,
    onSortClick: () => {}
  };

  handleSortClick = order => {
    const { sortPath, onSortClick } = this.props;

    if (isPlainObject(sortPath)) {
      onSortClick(mapValues(sortPath, v => (v === null ? order : v)));
    } else {
      onSortClick(sortPath, order);
    }
  };

  resolveSortingDirection = type => {
    const { sortBy, sortPath } = this.props;

    return {
      // When sorting by multiple paths, the direction will always be the same, so we can use the first element.
      Array: () => sortBy[sortPath[0]],
      Object: () => sortBy[Object.keys(sortPath)[0]],
      String: () => sortBy[sortPath]
    }[type]();
  };

  render() {
    const { sortPath, computedStyles } = this.props;
    const sortingDirection = this.resolveSortingDirection(
      sortPath.constructor.name
    );

    return (
      <div className={computedStyles.base}>
        <img
          className={computedStyles.image}
          src={sortingDirection === SORT_ASC ? ascendingSelected : ascending}
          onClick={() => this.handleSortClick(SORT_ASC)}
        />
        <img
          className={computedStyles.image}
          src={sortingDirection === SORT_DESC ? descendingSelected : descending}
          onClick={() => this.handleSortClick(SORT_DESC)}
        />
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(SortArrow);
