import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import history from 'config/history';
import { isLoggedIn } from 'config/auth';

const push = history.push;

class NavigationIntercept extends PureComponent {
  static propTypes = {
    shouldPromptOnUnload: PropTypes.bool,
    hasUnsavedData: PropTypes.bool,
    shouldIntercept: PropTypes.oneOfType([
      PropTypes.func.isRequired,
      PropTypes.bool.isRequired
    ]).isRequired,
    shouldNavigate: PropTypes.oneOfType([
      PropTypes.func.isRequired,
      PropTypes.bool.isRequired
    ]).isRequired,
    confirmationMessage: PropTypes.string
  };

  static defaultProps = {
    shouldPromptOnUnload: false,
    hasUnsavedData: false,
    confirmationMessage:
      'Changes you made may not be saved. Do you want to leave this site?'
  };

  componentWillReceiveProps() {
    this.overridePushState();
    if (this.props.shouldPromptOnUnload) {
      window.addEventListener('beforeunload', this.promptOnUnload);
    }
  }

  componentWillUnmount() {
    this.restorePushState();
    if (this.props.shouldPromptOnUnload) {
      window.removeEventListener('beforeunload', this.promptOnUnload);
    }
  }

  promptOnUnload = e => {
    if (this.props.hasUnsavedData && isLoggedIn()) {
      e.returnValue = this.props.confirmationMessage;
      // Some WebKit-based browsers don't follow the spec
      return this.props.confirmationMessage;
    }
    return undefined;
  };

  overridePushState = () => {
    history.push = this.pushOverride;
  };

  restorePushState = () => {
    history.push = push;
  };

  pushOverride = (...args) => {
    if (typeof push !== 'function') return;

    if (this.shouldIntercept(...args)) {
      const shouldNavigate =
        typeof this.props.shouldNavigate === 'function'
          ? this.props.shouldNavigate(args[2])
          : this.props.shouldNavigate;

      if (shouldNavigate) {
        push.apply(history, args);
      }
    } else {
      push.apply(history, args);
    }
  };

  shouldIntercept = pathname => {
    const { shouldIntercept } = this.props;

    if (typeof shouldIntercept !== 'boolean') {
      return shouldIntercept(pathname);
    }

    return shouldIntercept;
  };

  render() {
    return null;
  }
}

export default NavigationIntercept;
