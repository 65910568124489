import { CASE_SERVICES_API } from 'config/values';
import { saveAs } from 'file-saver';

const fetchCaseExport = async (
  caseIdArr,
  exportType,
  includeSourceDocuments,
  onSuccess,
  onFailure
) => {
  try {
    const url = new URL(`${CASE_SERVICES_API.uri}/caseExport`);
    const response = await fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify({
        caseIdArr,
        exportType,
        includeSourceDocuments
      }),
      headers: {
        'x-api-key': CASE_SERVICES_API.apiKey,
        'Content-Type': 'application/json'
      }
    });
    if (response.status !== 200) {
      onFailure(response);
      return;
    }
    const blob = await response.blob();
    // example header (will always look like this):
    // attachment; filename="TE21-1000000-combined.pdf"
    const filename = response.headers
      .get('content-disposition')
      .split('filename=')[1]
      .replace(/['"]+/g, '');
    saveAs(blob, filename);
    onSuccess(response);
  } catch (err) {
    onFailure(err);
  }
};

export default fetchCaseExport;
