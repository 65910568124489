/* eslint no-underscore-dangle:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getOrElse } from 'Common/utils';
import { PropTypeSafety } from 'Common/components/Form';
import { findIndex, isNil, isEqual } from 'lodash';
import { fileListToJSON } from 'CreateCase/utils';

import stylesGenerator from './styles';
import withStyles from '../withStyles';
import ValidationErrors from '../ValidationErrors';
import {
  formElementPropTypes,
  formElementDefaultProps,
  csePropTypes
} from '../propTypes';
import FileList from './FileList';

class FileInput extends Component {
  static propTypes = {
    ...formElementPropTypes,
    ...csePropTypes,
    accept: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    modal: PropTypes.bool,
    title: PropTypes.string,
    triggers: PropTypes.shape({
      onModalContentUpdate: PropTypes.func.isRequired,
      onModalContentClear: PropTypes.func.isRequired,
      onModalConfirmDelete: PropTypes.func.isRequired,
      onUploadAttachments: PropTypes.func.isRequired,
      onDownloadAttachment: PropTypes.func.isRequired,
      onSaveCaseCSE: PropTypes.func.isRequired
    }).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired, // see `Common/utils/attachments` for info
          dataURL: PropTypes.string.isRequired,
          instance: PropTypes.object.isRequired,
          name: PropTypes.string,
          upload: PropTypes.string,
          ext: PropTypes.string,
          size: PropTypes.number
        })
      ),
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          type: PropTypes.string
        })
      )
    ]),
    data: PropTypes.shape({
      isReadOnly: PropTypes.bool.isRequired,
      'document-data': PropTypes.objectOf(PropTypes.any).isRequired
    }).isRequired
  };

  static defaultProps = {
    ...formElementDefaultProps,
    layout: { width: '100%' },
    styles: { width: '100%' },
    accept: '*',
    modal: false,
    multiple: false,
    value: [],
    disabled: false,
    label: 'Upload a new attachment',
    title: 'Upload a new attachment'
  };

  constructor(props) {
    super(props);
    this.state = {
      files: props.value || [],
      editing: null,
      lastSaveTs: 0,
      lastUpdated: Date.now(),
      selectedTags: {},
      showWarning: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.value, this.props.value) ||
      !isEqual(nextProps.data, this.props.data)
    ) {
      // If the file was removed, make sure its not set as editing
      const stillEditing = this.state.editing
        ? findIndex(
            nextProps.value,
            f => f._id === this.state.editing || f.id === this.state.editing
          )
        : -1;
      const { disabled } = nextProps;
      this.setState({
        files: nextProps.value || [],
        editing: stillEditing !== -1 && !disabled ? this.state.editing : null,
        lastUpdated: Date.now()
      });

      // resets the file input's FileList (https://stackoverflow.com/a/3162319)
      // this allows a file to be added, then removed, then added again.
      // making sure fileInputElement is there and the modal is open
      if (!!this.modalContent && !isNil(this.fileInputElement)) {
        this.fileInputElement.value = '';
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // If the modal is open, re-render it when a file changes
    if (
      this.modalContent &&
      (prevState.lastUpdated < this.state.lastUpdated ||
        prevState.lastSaveTs > this.state.lastSaveTs)
    ) {
      this.renderModalContent();
    }
  }

  setFileInputRef = fileInput => {
    this.fileInputElement = fileInput;
  };

  handleModalClose = () => {
    const { triggers, value } = this.props;

    this.setState({
      files: value || [],
      editing: null,
      lastUpdated: Date.now(),
      showWarning: false
    });

    triggers.onModalContentClear();
    this.modalContent = null;
  };

  handleModalSave = () => {
    const { $id, triggers, model } = this.props;
    const { files, lastSaveTs } = this.state;

    const now = Date.now();
    this.setState({ lastSaveTs: now, lastUpdated: now });

    if (this.state.showWarning == false) {
      triggers.onUploadAttachments(files, model.id, $id, error => {
        if (error) {
          this.setState({ lastSaveTs });
        } else {
          triggers.onModalContentClear();
          this.modalContent = null;
          triggers.onSaveCaseCSE();
        }
      });
    }
  };

  focus = () => this.renderModalContent();

  handleChange = files => {
    if (!this.modalContent) {
      this.props.onChange({ ...this.props, value: files || [] });
    } else if (files.length > 0) {
      files.forEach(file => {
        if (
          (file.tags === 'PV Submission to Veeva' ||
            file.tags === 'PQ Submission to SolTRAQs/OneTrack') &&
          (file.size > 20 * 1024 * 1024 || file.name.length > 95)
        ) {
          this.setState({
            files: files || [],
            lastUpdated: Date.now(),
            showWarning: true
          });
        } else {
          this.setState({
            files: files || [],
            lastUpdated: Date.now(),
            showWarning: false
          });
        }
      });
      this.setState({
        files: files || [],
        lastUpdated: Date.now()
      });
    } else {
      this.setState({
        files: files || [],
        lastUpdated: Date.now()
      });
    }
  };

  handleEvent = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  /**
   * Handles both `onChange` events and `drop` data events
   * @param  {InputEvent|DropEvent} e - File event
   */
  handleFileChange = e => {
    const { multiple } = this.props;
    this.handleEvent(e);

    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    const filesArr = multiple ? Array.from(files) : Array.from(files).slice(-1);
    const processedAttachments = fileListToJSON(filesArr);

    this.handleChange(this.state.files.concat(processedAttachments));
  };

  handleDownloadAttachment = file => {
    const { triggers, model } = this.props;
    triggers.onDownloadAttachment(file, model.id);
  };

  renderFileList = renderEditForm => {
    const {
      multiple,
      computedStyles,
      statePath,
      disabled,
      triggers,
      $id,
      model,
      data
    } = this.props;
    const { files, lastSaveTs } = this.state;
    const tags = getOrElse(data, 'document-data.attachment-tags', []);
    const editFile = file => {
      this.setState(prevState => {
        const { editing } = prevState;

        // Clicking the pencil does not modify the value, so need another way to re-render
        const shouldRerenderModal = renderEditForm
          ? {
              lastUpdated: Date.now()
            }
          : {};

        if (Object.keys(file).indexOf('_id') === -1) {
          return {
            editing: editing === file.id ? null : file.id,
            ...shouldRerenderModal
          };
        }
        return {
          editing: editing === file._id ? null : file._id,
          ...shouldRerenderModal
        };
      });
    };

    const onItemChange = (file, field, val) => {
      const idKey = Object.keys(file).indexOf('_id') !== -1 ? '_id' : 'id';
      const fileIndex = findIndex(
        files,
        f => f[idKey] === file[idKey] || f[idKey] === file[idKey]
      );
      if (fileIndex !== -1) {
        this.handleChange([
          ...files.slice(0, fileIndex),
          { ...files[fileIndex], [field]: val },
          ...files.slice(fileIndex + 1)
        ]);
      }
    };

    const removeFile = file => {
      this.setState({
        showWarning: false
      });
      const idKey = Object.keys(file).indexOf('_id') !== -1 ? '_id' : 'id';
      const filtered = files.filter(
        f => f[idKey] !== file[idKey] && f[idKey] !== file[idKey]
      );
      const filesArr = multiple ? filtered : [];

      const handleConfirmDelete = () => {
        if (!this.modalContent) triggers.onModalContentClear();
        // Odd usage, but it triggers a rerender and then saves the case with the removed file
        triggers.onUploadAttachments(filesArr, model.id, $id, error => {
          if (error) {
            this.setState({ lastSaveTs });
          } else {
            triggers.onSaveCaseCSE();
          }
        });
      };

      if (!this.modalContent) {
        triggers.onModalConfirmDelete(
          'Document',
          handleConfirmDelete,
          triggers.onModalContentClear
        );
      } else {
        this.handleChange(filesArr);
      }
    };

    return (
      <FileList
        statePath={statePath}
        value={files}
        computedStyles={computedStyles}
        multiple={multiple}
        editFile={editFile}
        onChange={onItemChange}
        removeFile={removeFile}
        // only show edit fields in relevant view
        editing={renderEditForm ? this.state.editing : null}
        onDownloadAttachment={this.handleDownloadAttachment}
        disabled={disabled}
        tags={tags}
      />
    );
  };

  renderModalContent = () => {
    const {
      title,
      computedStyles,
      id,
      accept,
      multiple,
      disabled,
      triggers,
      model
    } = this.props;
    const { lastSaveTs } = this.state;
    //const isSaving = lastSaveTs > moment(model.latestDraft).valueOf();

    if (disabled) {
      return;
    }

    const handleKeyPress = e => {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        this.fileInputElement.click();
      }
    };

    triggers.onModalContentUpdate(
      <PropTypeSafety>
        <div
          disabled={disabled}
          key="dropzone"
          className={computedStyles.modalContainer}
          ref={el => {
            this.modalContent = el;
          }}
        >
          {title ? <h2 className={computedStyles.title}>{title}</h2> : null}
          <div
            key="dropzone-label"
            disabled={disabled}
            className={`pt-non-ideal-state ${computedStyles.dropzone}`}
            onClick={() => this.fileInputElement.click()}
            onDragEnter={this.handleEvent}
            onDragOver={this.handleEvent}
            onDrop={this.handleFileChange}
            role="button"
            tabIndex="0"
            onKeyPress={handleKeyPress}
          >
            <div className="pt-non-ideal-state-description">
              <input
                id={id}
                type="file"
                accept={accept}
                multiple={multiple}
                ref={this.setFileInputRef}
                disabled={disabled}
                onChange={this.handleFileChange}
                className={`pt-input ${computedStyles.hiddenInput}`}
              />
              <strong>Choose file</strong> or drag and drop here
            </div>
          </div>
          {this.renderFileList(true)}
        </div>
        <p style={{ color: 'red' }}>
          {this.state.showWarning === true
            ? 'Warning:File size and/or name length exceeded limits, please update and resubmit'
            : ''}
        </p>
        <div key="controls" className={computedStyles.modalButtonContainer}>
          <button
            id="attachment-modal-close"
            className={`${computedStyles.buttonBase}`}
            onClick={this.handleModalClose}
          >
            Cancel
          </button>
          <button
            id="attachment-modal-save"
            className={`${computedStyles.buttonBase} ${
              computedStyles.buttonPrimary
            }`}
            //disabled={isSaving}
            onClick={this.handleModalSave}
          >
            Save
          </button>
        </div>
      </PropTypeSafety>,
      false,
      false
    );
  };

  renderHelpText = helpText =>
    helpText ? (
      <p className={this.props.computedStyles.helpText}>{helpText}</p>
    ) : null;

  renderValidationErrors = errors =>
    errors && errors.length ? (
      <ValidationErrors
        id={this.props.id}
        computedStyles={this.props.computedStyles}
        validationErrors={errors}
      />
    ) : null;

  render() {
    const {
      $id,
      schemaPath,
      label,
      helpText,
      computedStyles,
      validationErrors,
      disabled
    } = this.props;

    return (
      <div
        className={computedStyles.base}
        data-id={$id}
        data-schema-path={schemaPath}
      >
        <div
          className={computedStyles.inputContainer}
          role="button"
          disabled={disabled}
        >
          <i
            role="button"
            className={`pt-icon pt-icon-standard pt-icon-arrow-up ${
              disabled ? computedStyles.iconDisabled : ''
            }`}
            onClick={this.renderModalContent}
          />
          <div>
            <a
              role="button"
              className={`${computedStyles.upload} ${
                disabled
                  ? computedStyles.fileInputTextDisabled
                  : computedStyles.fileInputText
              }
              `}
              onClick={this.renderModalContent}
            >
              {label}
            </a>
          </div>
        </div>
        {this.renderHelpText(helpText)}
        {this.renderValidationErrors(validationErrors)}
        {this.renderFileList(!this.modalContent)}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(FileInput);
