import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { times } from 'lodash';

import { withStyles } from 'Common/components/Form';
import { STATUSES_MAP } from 'Common/constants';
import stylesGenerator from './styles';

class BreadCrumbs extends PureComponent {
  static propTypes = {
    computedStyles: PropTypes.shape({
      base: PropTypes.object.isRequired,
      link: PropTypes.object.isRequired,
      crumb: PropTypes.func.isRequired
    }).isRequired,
    status: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired
  };

  renderStatus = () => {
    const { computedStyles, status } = this.props;

    return (
      <span className={computedStyles.status} data-id="breadcrumb.status">
        {STATUSES_MAP[status]}
      </span>
    );
  };

  renderLink = () => <div className={this.props.computedStyles.link} />;

  renderCrumb = i => {
    const { computedStyles, total, completed } = this.props;
    const fillCrumb = i === completed;
    const shouldRenderLink = i < total - 1;

    return (
      <span key={i}>
        <div className={computedStyles.crumb(fillCrumb)} />
        {shouldRenderLink ? this.renderLink() : null}
      </span>
    );
  };

  render() {
    const { computedStyles, total } = this.props;

    return (
      <div className={computedStyles.base}>
        {times(total, this.renderCrumb)}
        {this.renderStatus()}
      </div>
    );
  }
}

export default withStyles(stylesGenerator)(BreadCrumbs);
