import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  cancelled: generateCSS({
    font: '16px Calibri-Bold',
    color: theme.colors.secondary
  }),

  completed: generateCSS({
    font: '16px Calibri',
    fontWeight: '200',
    textAlign: 'right',
    color: theme.colors.secondary,
    paddingLeft: '5px',
    verticalAlign: 'bottom'
  }),

  action: generateCSS({
    font: '16px Calibri',
    fontWeight: '500',
    textAlign: 'right',
    color: theme.colors.active
  }),

  successCheckmark: generateCSS({
    verticalAlign: 'middle'
  })
});

export default stylesGenerator;
