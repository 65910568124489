import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { Checkbox, SimpleButton } from 'Common/components/Form';
import { modalStyles } from 'Common/components/withModal';
import requestBulkCaseExport from 'api/rest/requestBulkCaseExport';
import Notifier from '../../../Common/components/Notifier';

export default class ExportBulkCasesModal extends PureComponent {
  static propTypes = {
    selectedCases: PropTypes.arrayOf(PropTypes.string).isRequired,
    actions: PropTypes.shape({
      emitModalContentUpdate: PropTypes.func.isRequired,
      emitModalContentClear: PropTypes.func.isRequired
    }).isRequired
  };

  state = {
    includeDocs: false,
    loading: false
  };

  updateCheckbox = checked => {
    this.setState({
      includeDocs: checked
    });
  };

  handleExportClick = () => {
    this.setState({ loading: true });
  };

  handleBulkCaseExport = () => {
    this.setState({ loading: true });
    const { actions } = this.props;

    const onSuccess = () => {
      Notifier.show({
        message: 'Bulk export download successful',
        iconName: 'tick-circle',
        intent: Notifier.SUCCESS
      });
      actions.emitModalContentClear();
    };

    const onFailure = error => {
      console.error(
        'error occurred when attempting to perform bulk export',
        error
      );
      actions.emitModalContentClear();
      Notifier.show({
        intent: Notifier.DANGER,
        message: 'An unknown error occurred during bulk export operation'
      });
    };

    requestBulkCaseExport(
      this.props.selectedCases,
      this.state.includeDocs,
      onSuccess,
      onFailure
    );
  };

  render() {
    const { includeDocs, loading } = this.state;
    return (
      <div className={`${modalStyles.base} ${modalStyles.modal}`}>
        <span className={modalStyles.title}>Bulk Export of Selected Cases</span>
        {this.state.loading ? (
          <div style={{ padding: '25px 25px 0 25px', minWidth: '450px' }}>
            <NonIdealState
              visual={<Spinner />}
              title="Loading"
              description="fetching bulk export"
            />
          </div>
        ) : (
          <React.Fragment>
            <div style={{ padding: '25px 25px 0 25px', minWidth: '450px' }}>
              <Checkbox
                value={includeDocs}
                onChange={this.updateCheckbox}
                label="Include source docs?"
                isFullWidth
              />
            </div>
            <div className={modalStyles.buttonsContainer}>
              <SimpleButton
                onClick={this.handleBulkCaseExport}
                disabled={loading}
                primary
              >
                Start Bulk Export
              </SimpleButton>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
