import PropTypes from 'prop-types';

export const formElementPropTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helpText: PropTypes.string,
  controlled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  computedStyles: PropTypes.object,
  // Added by CSE lib
  config: PropTypes.shape({
    locale: PropTypes.string
  })
};

export const formElementDefaultProps = {
  id: `${Math.random()}`,
  label: '',
  helpText: '',
  controlled: true,
  onBlur: () => {},
  disabled: false,
  validations: {},
  computedStyles: {},
  config: {},
  data: {}
};

export const csePropTypes = {
  '../': PropTypes.object,
  '/': PropTypes.object,
  $id: PropTypes.string,
  component: PropTypes.string,
  config: PropTypes.object,
  data: PropTypes.object,
  model: PropTypes.object,
  relativePath: PropTypes.string,
  schemaPath: PropTypes.string,
  statePath: PropTypes.string,
  triggers: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.any
};
