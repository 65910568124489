import { generateCSS } from 'Common/components/Form';

const stylesGenerator = theme => ({
  base: generateCSS({
    color: theme.colors.header,
    padding: '34px 8%',
    '@media(max-width:1440px)': {
      padding: '0 5%'
    },
    '@media(max-width:1200px)': {
      padding: '0 2%'
    }
  }),
  title: generateCSS({
    display: 'inline-block',
    margin: '10px 0 15px',
    font: '30px SackersGothicStd-Medium',
    fontWeight: '900',
    textTransform: 'uppercase'
  }),
  row: generateCSS({
    width: '100%'
  }),
  stickyFilters: generateCSS({
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '30px'
  }),
  noMatchLink: generateCSS({
    '&:hover, &:active, &:focus': {
      fontWeight: 800
    }
  }),
  noMatch: generateCSS({
    marginBottom: '10px'
  }),

  // Advanced Search
  advanced: generateCSS({
    bottom: '0',
    display: 'flex',
    height: '100%',
    fontFamily: 'Calibri',
    fontSize: '16px'
  }),
  transparentButton: generateCSS({
    float: 'right',
    ' button': {
      background: 'transparent',
      border: '1px solid #0082BA',
      color: theme.colors.altBrand,
      fontWeight: 100,
      fontSize: '14px',
      marginLeft: '20px'
    }
  })
});

export default stylesGenerator;
